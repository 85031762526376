import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Card, CardActions } from '@material-ui/core';

import OverlayLoader from '../core/OverlayLoader';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import CategoryService from '../../services/CategoryService';

class WorkoutCategoryPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: false,
                title: 'Join Live Workouts and Events',
                static: false
            }
        });
        this.state = {
            loader: false,
            categories: [],
            openShareDialog: false
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getAuthUser();
        this.getCategories();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps && nextProps.toolBar.selectedSort !== null) {
            this.sortByPosts(nextProps.toolBar.selectedSort, nextProps.toolBar.selectedSortValues[nextProps.toolBar.selectedSort]);
        }
    }

    getAuthUser = async () => {
        try {
            let res = await UserService.getAuthUser();
            AuthService.setAuthUser(res.user);
            this.props.dispatch({ type: 'SET_USER', data: res.user });
        } catch (e) {
            let user = await AuthService.getAuthUser();
            this.props.dispatch({ type: 'SET_USER', data: user });
        }
    }

    getCategories = async () => {
        try {
            this.setState({ loader: true });
            let res = await CategoryService.getAllCategories();
            this.setState({ loader: false });
            let categories = res.categories.find(obj => obj._id == 'workoutclasses');
            if (categories) categories = categories.cats;
            this.setState({ categories: categories || [] });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    selectCategory(category) {
        localStorage.setItem('Category', JSON.stringify(category));
        this.props.dispatch({ type: 'SET_CAT', category: category });
        if (category._id == 'All') {
            this.props.history.push(`/workouts/${category._id}/all`);
        } else {
            this.props.history.push('/workouts/' + category._id);
        }

    }

    render() {
        let { categories } = this.state;
        return (
            <div>
                <Grid container className={'contentHolderContainer-90vh'} style={{ justifyContent: 'center' }}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="sidebar-menu-list-item-profile">
                            <Grid container style={{ background: 'transparent' }}>
                                <div role='tabpanel' hidden={false} style={{ background: 'white', width: '100%' }}>
                                    <Grid container style={{ padding: 0, overflowY: 'auto' }} className='tabHolder'>
                                        {
                                            <Grid container style={{ padding: 20 }} spacing={1}>
                                                <p style={{ textAlign: 'justify' }}>
                                                    Live workouts and events will be offered by qualified professionals who have been highly rated
                                                    by their peers. Enjoy the amazing variety of unique styles from around the world.
                                                </p>
                                                {
                                                    categories &&
                                                    categories.map((category, key) => {
                                                        return (
                                                            <Grid item xs={4} sm={4} md={4} lg={4} key={key} style={{ cursor: 'pointer', pointerEvents: category.disabled ? 'none' : 'auto' }} onClick={async () => { this.selectCategory(category) }}>
                                                                <Card elevation={0} >
                                                                    <div style={{ backgroundImage: ` url(${require("../../assets/category/" + category._id + ".png")})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderRadius: 7 }}>
                                                                        <div className="card-overlay" style={{
                                                                            backgroundImage: ``, borderRadius: 7
                                                                        }}></div>
                                                                    </div>
                                                                    <CardActions style={{ justifyContent: 'center' }}>
                                                                        <Button size="small" color="primary" style={{ textTransform: 'capitalize' }} disabled={category.disabled}>
                                                                            {category.name}
                                                                        </Button>
                                                                    </CardActions>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer,
    catSubCat: state.catSubCatReducer,
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(WorkoutCategoryPage);