import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    List,
    Card,
    Button,
    ListItem,
    ListItemAvatar
} from '@material-ui/core';
import * as moment from 'moment';
import AlertDialog from '../core/Disclamer';
import OverlayLoader from '../core/OverlayLoader';
import RewardService from '../../services/RewardService';
import AuthService from '../../services/AuthService';
let selectedRewardId;

class RewardsPage extends Component {
    constructor(props) {
        super(props);
        let user = AuthService.getAuthUser();
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: (user && user.accountType === 'ponger' ? 'History of Rewards Sent' : 'My Rewards'),
                static: true
            }
        });
        this.state = {
            page: 1,
            limit: 50,
            pages: 0,
            totalRewards: 0,
            loader: false,
            showAlertDialog: false,
            rewards: []
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getAllRewards();
    }

    getAllRewards = async () => {
        try {
            this.setState({ loader: true });
            let obj = {
                page: this.state.page,
                limit: this.state.limit
            };
            let res = await RewardService.getAllRewards(obj);
            let rewards = this.state.rewards;
            res.rewards.forEach((reward) => {
                let index = this.state.rewards.findIndex((obj) => obj._id === reward._id);
                if (index != -1) {
                    rewards[index] = reward;
                } else {
                    rewards.push(reward);
                }
            });
            this.setState({ rewards: rewards, totalRewards: res.totalRewards, page: res.page, pages: res.pages });
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getAllRewards();
        });
    }

    showRewardDetails = (reward) => {
        selectedRewardId = reward._id;
        this.setState({ showAlertDialog: true });
    }

    handelAlertDialogClose = (isConfirm) => {
        this.setState({ showAlertDialog: false });
        if (isConfirm) {
            this.props.history.push('/reward/' + selectedRewardId)
        }
    }

    render() {
        let user = AuthService.getAuthUser();
        let { rewards, showAlertDialog } = this.state;
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>

                        <List style={{ overflowY: 'scroll' }}>
                            <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                {
                                    rewards.length ?
                                        rewards.map((reward, key) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={6} lg={6} key={key}>
                                                    <ListItem style={{ cursor: 'pointer', marginBottom: '20px' }} key={key} onClick={() => this.showRewardDetails(reward)} boxShadow={2}>
                                                        <Grid container style={{ padding: '10px 10px', boxShadow: '0 1px 10px rgba(0,0,0,0.10), 0 1px 10px rgba(0,0,0,0.10)', borderRadius: '3px' }}>
                                                            <div className="comment-object" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <ListItemAvatar style={{ marginRight: '15px' }}>
                                                                    <img alt="" src={require('../../assets/icons/Reward.svg')} />
                                                                </ListItemAvatar>
                                                                <div className="comment-body">
                                                                    <div style={{ fontSize: 18, fontWeight: 'bold' }}>{reward.title}</div>
                                                                    <div style={{ fontSize: 16, color: 'gray' }}>
                                                                        {moment(reward.createdAt).format('MM/DD/YYYY')}
                                                                        {
                                                                            user.accountType == 'ponger' &&
                                                                            <span style={{ paddingLeft: 10, color: (reward.status == 'approved' ? '#50e3c2' : (reward.status === 'pending' ? '#ffbd51' : '#ff556a')), fontSize: 15, textTransform: 'capitalize' }}> {reward.status}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </ListItem>
                                                </Grid>
                                            )
                                        })
                                        : <div>No rewards found.</div>
                                }
                                <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; (b) I understand that THE AB LAB dAPP makes no representations, warranties or guarantees regarding the goods and/or services that may be offered to users by third parties via THE AB LAB dAPP; and (c) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, including but not limited to claims based on personal injury or financial loss, resulting in any way from the viewing, purchase and/or use of any goods and/or services offered by third parties via THE AB LAB dAPP."
                                    open={showAlertDialog} onClose={this.handelAlertDialogClose} />
                            </Grid>
                        </List>
                    </Card>
                    {
                        this.state.page < this.state.pages &&
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default connect()(RewardsPage);