import React, { Component, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Grid, TextField, Button, IconButton } from '@material-ui/core';
import AuthService from '../../services/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

const Basic = (props) => {
    let history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    return (<div>
        <Formik
            initialValues={{
                newPassword: '',
                retypePassword: ''
            }}
            validate={values => {
                const errors = {};
                let passwordCheck = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
                if (!values.newPassword) {
                    errors.newPassword = "Please enter new password.";
                }
                if (values.newPassword && !passwordCheck.test(values.newPassword)) {
                    errors.newPassword = 'Must contain at least 8 characters, including UPPER/lowercase, special character and numbers.';
                }
                if (!values.retypePassword) {
                    errors.retypePassword = 'Please enter the confirm password.';
                }
                if (values.retypePassword && values.retypePassword !== values.newPassword) {
                    errors.retypePassword = 'Confirm password dose not match new password.';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
                let userDetails = values;

                let obj = await {
                    forgotPasswordId: props.props.userDetails.forgotPasswordId,
                    newPassword: userDetails.newPassword
                };
                try {
                    setDisabled(true);
                    AuthService.setAuthorizationHeader("basic " + process.env.REACT_APP_SERVER_BASE64);
                    let res = await AuthService.resetPassword(obj)
                    if (res) {
                        setDisabled(false);
                        setSuccess(true);
                        setOpen(true);
                        setMessage('Password reset successfully.');
                        props.props.dispatch({ type: 'SET_FORGOT_PASSWORD_ID', forgotPassword: { _id: null, email: null } })
                        setTimeout(() => {
                            history.replace('/login');
                        }, 2000);
                    }
                } catch (e) {
                    setDisabled(false);
                    setSuccess(false);
                    setOpen(true);
                    setMessage(e.message);
                    resetForm({});
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                isSubmitting
            }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container justify="center" style={{ marginTop: '0%', justifyContent: 'center', }}>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-basic"
                                    label="New Password"
                                    type="password"
                                    name="newPassword"
                                    disabled={disabled}
                                    error={errors.newPassword && touched.newPassword ? true : false}
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.newPassword}
                                    style={{ width: '100%' }} required />
                            </Grid>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-basic"
                                    label="Confirm Password"
                                    type="password"
                                    name="retypePassword"
                                    disabled={disabled}
                                    error={errors.retypePassword && touched.retypePassword ? true : false}
                                    value={values.retypePassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.retypePassword}
                                    style={{ width: '100%' }} required />
                            </Grid>

                            <Grid item xs={12} md={7} lg={7} >
                                <div style={{ textDecoration: 'none', padding: 10 }}>
                                    {
                                        !disabled ?
                                            <Button
                                                className="btn-purple"
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disableElevation={true}
                                            >
                                                Change Password
                                    </Button> :
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            onClose={() => setOpen(false)}
                            action={
                                <React.Fragment>
                                    <IconButton
                                        aria-label="close"
                                        color="white"
                                        style={{ color: 'white' }}
                                        onClick={() => setOpen(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </React.Fragment>
                            }>
                            <SnackbarContent style={isSuccess ? {
                                backgroundColor: 'green',
                            } : { backgroundColor: 'red', }}
                                message={<span id="client-snackbar">{message}</span>}
                            />
                        </Snackbar>
                    </form>
                )}
        </Formik>
    </div>
    );
}
class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({ type: 'SHOW', data: false });
        props.dispatch({ type: 'SHOW_TOOLBAR', data: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Grid container className={'contentHolderContainer-90vh'} justify="center" style={{ backgroundColor: 'white', marginTop: '0%', justifyContent: 'center', }}>
                    <Grid container justify="center" className={'profile-div-background'}>
                        <Grid item >
                            <div className="profile-image-holder">
                                <img
                                    src={require('../../assets/new-password.png')}
                                    alt=""
                                    style={{
                                        height: 90,
                                        weight: 90,
                                        borderRadius: 60
                                    }}>
                                </img>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" style={{ padding: '0px 20px' }}>
                        <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'center' }}>
                            <h2 style={{ fontFamily: "'Roboto', sans-serif", fontSize: 26, fontWeight: 400 }}>New Password</h2>
                            <p style={{ marginBottom: 0, fontFamily: "'Roboto', sans-serif", fontSize: 14, fontWeight: 200 }}>Please enter new password</p>
                        </Grid>
                        <Basic props={this.props} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer
});


export default connect(mapStateToProps)(ResetPasswordPage);