import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WowzaPublisher from '../core/WowzaPublisher';
import qs from 'qs';

class ExternalWowzaPublisher extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Schedule My Live Workouts and Events',
                static: true
            }
        });
        this.state = {
            streamName: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).streamName || '',
            applicationName: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).applicationName || '',
            openDialog: false
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11} style={{ display:'flex', justifyContent: 'center' }}>
                    <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.setState({ openDialog: true })}>Click Here to Go Live</Button>
                    {
                        this.state.openDialog &&

                        <Dialog
                            open={true}
                            fullScreen
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent style={{ padding: 0 }}>
                                <AppBar className="app-bar-color">
                                    <Toolbar>
                                        <Typography variant="h6" style={{ width: '100%' }}>
                                            Live
                                        </Typography>
                                        <IconButton edge="end" color="inherit" onClick={() => {
                                            this.setState({ openDialog: false });
                                            window.close()
                                        }}
                                            style={{ float: 'right' }}
                                            aria-label="close">
                                            <CloseIcon />
                                        </IconButton>

                                    </Toolbar>
                                </AppBar>
                                <div style={{ marginTop: 60 }}>
                                    <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Grid container justify="center">
                                                <Grid item lg={8} md={8} sm={12} xs={12} style={{ marginTop: 15, justifyContent: 'center' }}>
                                                    <WowzaPublisher streamName={this.state.streamName} applicationName={this.state.applicationName} />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </DialogContent>
                        </Dialog>
                    }
                </Grid >
            </Grid >
        )
    }
}

export default connect()(ExternalWowzaPublisher);