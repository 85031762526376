import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    Typography,
    Avatar,
    Fab,
    CardContent,
    Hidden,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    IconButton,
    TextareaAutosize,
    Input,
    TextField,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SendIcon from '@material-ui/icons/Send';
import { FiExternalLink } from 'react-icons/fi';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import Rating from 'material-ui-rating';
import ReactPlayer from 'react-player';
import * as GeneralFunctions from '../../GeneralFunctions';
import OverlayLoader from '../core/OverlayLoader';
import PostService from '../../services/PostService';
import * as moment from 'moment';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import ShareDialog from '../core/Share';
import AlertDialog from '../core/Disclamer';
import MenuComponent from '../core/MenuComponent';
import SentimentSatisfiedSharpIcon from '@material-ui/icons/SentimentSatisfiedSharp';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Link } from 'react-router-dom';

let selectedVideoId;

class FeedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: {},
            loader: false,
            open: false,
            isSuccess: false,
            message: '',
            openShareDialog: false,
            shareTitle: '',
            shareUrl: '',
            selectedVideoId: '',
            reaction: '',
            openAlertDialog: false,
            newReply: '',
            openFrom: localStorage.getItem("openFrom"),
            selectdCommentId: ''
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        selectedVideoId = '';
        this.getPostById(this.props.match.params.id);
    }

    getPostById = async (_id) => {
        try {
            this.setState({ loader: true });
            let res = await PostService.getPostById({ _id: _id });
            this.setState({ loader: false });
            this.setState({ post: res.post });
            await this.props.dispatch({
                type: 'SET_TOOLBAR_CONFIG', data: {
                    show: true,
                    color: true,
                    sort: false,
                    search: false,
                    back: true,
                    title: this.state.openFrom === 'videoThumbnail' ? res.post.title : 'Feed Comments',
                    static: true
                }
            });
        } catch (e) {
            await this.setState({ loader: false });
        }
    }

    handleToggleLike = async (post) => {
        try {
            this.setState({ loader: true });
            await PostService.postLike({ postId: post._id });
            this.setState({ loader: false });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsLiked = (post) => {
        let user = AuthService.getAuthUser();
        let liked = post.likes.findIndex(_id => _id == user._id);
        if (liked != -1) return true;
        return false;
    }

    handleToggleFavorite = async (post) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.toggleFavoritePost({ postId: post._id });
            AuthService.setAuthUser(res.user);
            this.setState({ loader: false });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFavorite = (post) => {
        let user = AuthService.getAuthUser();
        let favorite = user.favoritePostIds.findIndex(obj => obj._id === post._id);
        if (favorite != -1) return true;
        return false;
    }

    checkIsRated = (post) => {
        let user = AuthService.getAuthUser();
        let rated = post.ratings.findIndex((rating) => rating._id == user._id);
        if (rated != -1) return true;
        return false;
    }

    handleRate = async (post, rating) => {
        try {
            this.setState({ loader: true });
            await PostService.postRate({ postId: post._id, rating: rating });
            this.setState({ loader: false });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFlagged = (post) => {
        let user = AuthService.getAuthUser();
        if (post.flags.length && post.flags.includes(user._id)) {
            return true;
        } else {
            return false;
        }
    }

    checkIsFlaggedComment = (comment) => {
        let user = AuthService.getAuthUser();
        if (comment.flags.length && comment.flags.includes(user._id)) {
            return true;
        } else {
            return false;
        }
    }

    checkIsFlaggedReply = (reply) => {
        let user = AuthService.getAuthUser();
        if (reply.flags.length && reply.flags.includes(user._id)) {
            return true;
        } else {
            return false;
        }
    }

    handelFlag = async (post, rating) => {
        try {
            this.setState({ loader: true }, async () => {
                await PostService.flagPost({ postId: post._id, rating: rating });
                this.setState({ loader: false });
                this.getPostById(this.props.match.params.id);
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handelCommentFlag = async (post, comment) => {
        try {
            this.setState({ loader: true }, async () => {
                let response = await PostService.flagComment({ postId: post._id, commentId: comment._id });
                this.setState({ loader: false });
                this.getPostById(this.props.match.params.id);
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handelReplyFlag = async (postId, commentId, replyId) => {
        try {
            this.setState({ loader: true }, async () => {
                let response = await PostService.flagReply({ postId, commentId, replyId });
                this.setState({ loader: false });
                this.getPostById(this.props.match.params.id);
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    setAlertOpenDialog = (isOpen) => {
        this.setState({ openAlertDialog: isOpen });
    }

    onPlayVideo(id) {
        selectedVideoId = id;
        this.setAlertOpenDialog(true, id);
    }


    handelAlertDialogClose = (isConfirm) => {
        this.setAlertOpenDialog(false);
        if (isConfirm) {
            this.setState({ selectedVideoId: selectedVideoId });
        } else {
            this.setState({ selectedVideoId: '' });
            selectedVideoId = '';
        }
    }

    getAvarageRating = (post) => {
        let ratingSum = 0;
        for (let ratingObj of post.ratings) {
            ratingSum = ratingSum + ratingObj.rating
        }
        if (!ratingSum) return 0;
        return Math.ceil((ratingSum / post.ratings.length));
    }

    setRating = (post) => {
        let user = AuthService.getAuthUser();
        let ratingObj = post.ratings.find((rating) => rating._id == user._id);
        if (ratingObj) return ratingObj.rating;
        return 0;
    }

    handleShare = (post) => {
        let shareTitle = '';
        if (post.owner) {
            shareTitle += post.owner.firstName;
            shareTitle += (post.owner.middleName ? ' ' + post.owner.middleName : '');
            shareTitle += (post.owner.lastName ? ' ' + post.owner.lastName : '');
        }
        this.setState({ openShareDialog: true, shareTitle: shareTitle, shareUrl: 'feed/' + post._id + '/commentBox' });
    }

    handelShareDialogClose = () => {
        this.setState({ openShareDialog: false, shareTitle: '', shareUrl: '' });
    }

    handleSubmit = async () => {
        try {
            this.setState({ loader: true });
            let body = {};
            body.postId = this.state.post._id;
            body.comment = this.state.newComment;
            await PostService.postComment(body);
            this.setState({ loader: true });
            this.setState({ newComment: '' });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handleReplySubmit = async (postId, commentId) => {
        try {
            this.setState({ loader: true });
            let body = { postId, commentId, reply: this.state.newReply };
            await PostService.reply(body);
            this.setState({ loader: true });
            this.setState({ newReply: '', selectdCommentId: '' });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handleReaction = async (postId, commentId, type, reaction, replyId = 0) => {
        try {
            this.setState({ loader: true });
            let body = { postId, commentId, replyId, type, reaction };
            await PostService.reaction(body);
            this.setState({ loader: true });
            this.getPostById(this.props.match.params.id);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handleToggleFollowPinger = async (pingerUser) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.followPinger({ pingerId: pingerUser._id });
            if (res.pingerUser) {
                let post = this.state.post;
                if (post.ownerId == res.pingerUser._id) {
                    post.owner.followers = res.pingerUser.followers;
                }
                this.setState({ post: post });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFollowed = (pingerUser) => {
        let user = AuthService.getAuthUser();
        if (pingerUser.followers && !pingerUser.followers.length) return false;
        let followed = pingerUser.followers.findIndex(_id => _id === user._id);
        if (followed !== -1) return true;
        return false;
    }

    checkReaction = (reactions, reaction) => {
        let user = AuthService.getAuthUser();
        let value = reactions.find(obj => obj.ownerId == user._id)
        if (value && (value.reaction == reaction)) return true
        else return false
    }

    checkReactionCount = (reactions, reaction) => {
        let value = reactions.filter(obj => obj.reaction == reaction)
        return value.length
    }

    render() {
        let user = AuthService.getAuthUser();
        let post = this.state.post;
        let { open, isSuccess, message } = this.state;
        let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
        let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
        return (
            <Grid container className={'contentHolderContainer-90vh'} style={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    {
                        post && post._id &&
                        <Card style={{ boxShadow: 'none' }}>
                            <CardHeader
                                style={{ textAlign: 'left', width: '95%', float: 'left' }}
                                avatar={
                                    <div>
                                        <img
                                            src={post.owner && post.owner.profilePic ? post.owner.profilePic : require('../../assets/default-user-image.png')}
                                            style={{ height: 50, width: 50, borderRadius: 50 }}
                                            alt="Post owner"
                                        />
                                    </div>
                                }
                                action={
                                    <div style={{ width: '100%', display: 'inline-flex' }}>
                                        {
                                            post.owner.accountType != 'basic' &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleToggleFollowPinger(post.owner)}
                                                style={{
                                                    width: 50,
                                                    backgroundColor: '#9013fe',
                                                    fontSize: '0.7rem',
                                                    marginTop: '7px',
                                                    float: 'right',
                                                    fontWeight: 'bold',
                                                    right: 10
                                                }}>
                                                {
                                                    this.checkIsFollowed(post.owner) ?
                                                        'Unfollow' :
                                                        'Follow'
                                                }
                                            </Button>
                                        }
                                        <MenuComponent props={this.props} items={[{ title: 'Flag', value: this.checkIsFlagged(post) ? 'flagged' : 'unflagged', show: true, }]} type={"myFeedMenu"} selectedItems={(item) => { this.handelFlag(post) }} style={{ marginTop: 20 }} />
                                    </div>
                                }
                                title={
                                    <span style={{ fontSize: '16px', fontWeight: 400 }}>
                                        {post.owner && post.owner.firstName} {post.owner && post.owner.middleName} {post.owner && post.owner.lastName}
                                    </span>
                                }
                                subheader={moment(post.createdAt).format("MM/DD/YYYY HH:mm")}
                            />
                            <CardContent style={{ paddingTop: 0 }}>
                                <Grid container style={{ paddingLeft: '4%', paddingRight: '4%', paddingBottom: 10 }}>
                                    <Grid item xs={6} md={8} lg={8} style={{ display: 'flex' }}>
                                        {post.title}
                                    </Grid>
                                    <Grid item xs={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {
                                            post.type != 'giggles' &&
                                            <span>Level: {GeneralFunctions.capitalize(post.level)}</span>
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    (this.state.openFrom === 'videoThumbnail' || post._id === this.state.selectedVideoId) &&
                                    <ReactPlayer
                                        url={post.wowzaVideoKey ? process.env.REACT_APP_WOWZA_HLS_URL + "/vod/mp4:" + post.wowzaVideoKey + "_transcoded.mp4/playlist.m3u8" : `${imageBaseUrl}/${post._id}/${post._id}_video${post.videoFileExt}`}
                                        playing
                                        playsinline
                                        config={{ file: { attributes: { controlsList: 'nodownload', autoPlay: true, muted: true }, forceHLS: (post.wowzaVideoKey ? true : false) } }}
                                        width="100%"
                                        height="200px"
                                        controls={true}
                                        style={{ overflow: 'hidden' }}
                                    />
                                }
                                {
                                    this.state.openFrom === 'commentBox' && !this.state.selectedVideoId &&
                                    <React.Fragment>
                                        <div className="mediaCardOverlay">
                                            <Fab aria-label="like" className="overlay-button" onClick={() => { this.onPlayVideo(post._id) }}>
                                                <img alt="" className="overlay-button-img" src={require('../../assets/icons/playbutton.svg')} />
                                            </Fab>
                                        </div>
                                        <CardMedia
                                            component="img"
                                            image={post.wowzaVideoKey ? `${thumbnailUrl}/${post.wowzaVideoKey}` : `${imageBaseUrl}/${post._id}/${post._id}_thumbnail.jpeg`}
                                            className='mediaCard'
                                        />
                                    </React.Fragment>
                                }
                            </CardContent>
                            <CardActions disableSpacing >
                                <Grid container style={{ textAlign: 'center' }}>
                                    <React.Fragment>
                                        {user.accountType === 'pinger' &&
                                            <Grid container>
                                                <Grid item xs={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                                                    <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                        <ChatBubbleOutlineRoundedIcon />
                                                    </Fab>
                                                    <div className="comment-count">
                                                        {post.commentsCount}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                    <Fab className={`like-circle-icon ${this.checkIsLiked(post) ? "MuiFab-primary" : "MuiFab-white"}`} size="small" aria-label="share" onClick={() => this.handleToggleLike(post)}>
                                                        <ThumbUpOutlinedIcon />
                                                    </Fab>
                                                    <div className="comment-count">
                                                        {post.likesCount}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                    <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                        <ShareOutlined onClick={() => this.handleShare(post)} />
                                                    </Fab>
                                                </Grid>
                                                <Grid item xs={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                    <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                        {
                                                            this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                : <FavoriteBorderOutlinedIcon />
                                                        }
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        }
                                        {(user.accountType === 'ponger' || user.accountType === 'basic') &&
                                            <Grid container>
                                                <Grid item xs={6} className="star-rating-text-holder-no-border">
                                                    <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} /> {this.getAvarageRating(post)} out of 5
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                    <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                        <ShareOutlined onClick={() => this.handleShare(post)} />
                                                    </Fab>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                    <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                        {
                                                            this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                : <FavoriteBorderOutlinedIcon />
                                                        }
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        }
                                    </React.Fragment>
                                </Grid>
                            </CardActions>
                            {
                                user.accountType === 'pinger' &&
                                <CardActions style={{ padding: '8px 0px' }}>
                                    <Grid container style={{ display: 'contents' }}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className="star-rating-holder">
                                            <Rating
                                                name={('rating' + post._id)}
                                                value={this.setRating(post)}
                                                max={5}
                                                disabled={this.checkIsRated(post)}
                                                iconHovered={<StarRoundedIcon name="rating1" style={{ color: '#6fdf93' }} />}
                                                iconFilled={<StarRoundedIcon name="rating1" style={{ color: this.checkIsRated(post) ? '#6fdf93' : '#22bf45' }} />}
                                                onChange={(value) => {
                                                    this.handleRate(post, value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className="star-rating-text-holder">
                                            <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} />
                                            <span>{this.getAvarageRating(post)} out of 5</span>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            }
                            {
                                user.accountType === 'pinger' &&
                                <React.Fragment>
                                    <Grid container style={{ padding: '0px 20px' }}>
                                        <Grid item xs={10} sm={10} md={10} lg={10}>
                                            <p>Comments</p>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '0px 20px' }}>
                                        <List style={{ height: '100%', width: '100%' }}>
                                            {
                                                post.comments.length ? post.comments.map((comment, key) => {
                                                    return (
                                                        <ListItem key={key}>
                                                            <Grid container>
                                                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                    <ListItemAvatar>
                                                                        <Avatar aria-label="recipe" >
                                                                            <div>
                                                                                <img
                                                                                    src={comment.owner && comment.owner.profilePic ? comment.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                                    style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                                    alt="Post owner"
                                                                                />
                                                                            </div>
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                </Grid>
                                                                <Grid item xs={10} sm={10} md={10} lg={10} >
                                                                    <Grid container style={{ background: '#d3d3d3' }}>
                                                                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '10px 10px', wordBreak: 'break-word' }}>
                                                                            <span style={{ fontSize: 16 }}>
                                                                                {
                                                                                    comment.owner.isCommentsAnonymous ? <span>Anonymous</span>
                                                                                        : <span>{comment.owner.firstName} {comment.owner.middleName} {comment.owner.lastName}</span>
                                                                                }
                                                                            </span>
                                                                        </Grid>
                                                                        <Grid item xs={5} sm={5} md={5} lg={5} style={{ padding: '10px 10px' }}>
                                                                            <span style={{ fontSize: 16, float: 'right' }}>
                                                                                {moment(comment.createdAt).format("MM/DD/YYYY HH:mm")}
                                                                                {
                                                                                    process.env.REACT_APP_EOS_URL && comment.eosTransactionId &&
                                                                                    <FiExternalLink style={{ fontSize: 15, margin: 5, cursor: 'pointer' }} onClick={() => window.open(process.env.REACT_APP_EOS_URL + comment.eosTransactionId, "_blank")}></FiExternalLink>
                                                                                }
                                                                            </span>
                                                                        </Grid>
                                                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                                                            <MenuComponent props={this.props} items={[{ title: 'Flag', value: this.checkIsFlaggedComment(comment) ? 'flagged' : 'unflagged', show: true, }]} type={"myFeedMenu"} selectedItems={(item) => { this.handelCommentFlag(post, comment) }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ background: '#d3d3d3' }}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ overflowWrap: 'break-word', marginBottom: 10, padding: 10 }} >
                                                                            <span style={{ fontSize: 16 }}>{comment.comment}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container >
                                                                        <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }} >
                                                                            <Tooltip title="Agree">
                                                                                <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'agree') ? "MuiFab-primary" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'comment', 'agree')}>
                                                                                    <SentimentVerySatisfiedIcon />
                                                                                </Fab>
                                                                            </Tooltip>
                                                                            <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                {this.checkReactionCount(comment.reactions, 'agree') || 0}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                            <Tooltip title="Neutral">
                                                                                <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'neutral') ? "MuiFab-gray" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'comment', 'neutral')}>
                                                                                    <SentimentSatisfiedSharpIcon />
                                                                                </Fab>
                                                                            </Tooltip>
                                                                            <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                {this.checkReactionCount(comment.reactions, 'neutral') || 0}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                            <Tooltip title="Disagree">
                                                                                <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'disagree') ? "MuiFab-red" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'comment', 'disagree')} >
                                                                                    <SentimentVeryDissatisfiedIcon />
                                                                                </Fab>
                                                                            </Tooltip>
                                                                            <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                {this.checkReactionCount(comment.reactions, 'disagree') || 0}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid className="left-boarder" item xs={12} sm={6} md={6} lg={6} style={{ fontSize: '1rem', display: 'flex', paddingTop: 5, marginTop: 5 }}>
                                                                            <Link onClick={() => this.setState({ selectdCommentId: comment._id })} style={{ textDecoration: 'none', marginLeft: 10 }} to="#">Reply</Link>
                                                                            <span style={{ marginLeft: 15 }}>
                                                                                {comment.replies.length} Replies
                                                                             </span>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {
                                                                        comment.replies && comment.replies.length ?
                                                                            <Grid container>
                                                                                <p>Replies</p>
                                                                            </Grid>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        comment.replies && comment.replies.length ?
                                                                            comment.replies.map((reply, key) => {
                                                                                return (
                                                                                    <Grid container key={key} >
                                                                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                                            <ListItemAvatar>
                                                                                                <Avatar aria-label="recipe" >
                                                                                                    <div>
                                                                                                        <img
                                                                                                            src={reply.owner && reply.owner.profilePic ? reply.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                                                            style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                                                            alt="Post owner"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Avatar>
                                                                                            </ListItemAvatar>
                                                                                        </Grid>
                                                                                        <Grid item xs={10} sm={10} md={10} lg={10} >
                                                                                            <Grid container style={{ background: '#d3d3d3' }}>
                                                                                                <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '10px 10px', wordBreak: 'break-word' }}>
                                                                                                    <span style={{ fontSize: 16 }}>
                                                                                                        {
                                                                                                            reply.owner.isCommentsAnonymous ? <span>Anonymous</span>
                                                                                                                : <span>{reply.owner && reply.owner.firstName ? (reply.owner.firstName + " " + reply.owner.lastName) : ''}</span>
                                                                                                        }
                                                                                                    </span>
                                                                                                </Grid>
                                                                                                <Grid item xs={5} sm={5} md={5} lg={5} style={{ padding: 10 }}>
                                                                                                    <span style={{ fontSize: 16, float: 'right' }}>
                                                                                                        {moment(reply.createdAt).format("MM/DD/YYYY HH:mm")}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                                <Grid item xs={1} sm={1} md={1} lg={1} style={{ float: 'left', marginLeft: '-5px' }}>
                                                                                                    <MenuComponent props={this.props} items={[{ title: 'Flag', value: this.checkIsFlaggedReply(reply) ? 'flagged' : 'unflagged', show: true, }]} type={"myFeedMenu"} selectedItems={(item) => { this.handelReplyFlag(post._id, comment._id, reply._id) }} style={{ padding: 10 }} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ background: '#d3d3d3' }}>
                                                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ overflowWrap: 'break-word', marginBottom: 10, padding: 10 }} >
                                                                                                    <span style={{ fontSize: 16 }}>{reply.reply}</span>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Grid container  >
                                                                                                <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }} >
                                                                                                    <Tooltip title="Agree">
                                                                                                        <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'agree') ? "MuiFab-primary" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'reply', 'agree', reply._id)}>

                                                                                                            <SentimentVerySatisfiedIcon />
                                                                                                        </Fab>
                                                                                                    </Tooltip>
                                                                                                    <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                        {this.checkReactionCount(reply.reactions, 'agree') || 0}
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                    <Tooltip title="Neutral">
                                                                                                        <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'neutral') ? "MuiFab-gray" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'reply', 'neutral', reply._id)} >
                                                                                                            <SentimentSatisfiedSharpIcon />
                                                                                                        </Fab>
                                                                                                    </Tooltip>
                                                                                                    <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                        {this.checkReactionCount(reply.reactions, 'neutral') || 0}
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid item xs={4} sm={2} md={2} lg={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                    <Tooltip title="Disagree">
                                                                                                        <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'disagree') ? "MuiFab-red" : "MuiFab-white"}`} onClick={() => this.handleReaction(post._id, comment._id, 'reply', 'disagree', reply._id)}>
                                                                                                            <SentimentVeryDissatisfiedIcon />
                                                                                                        </Fab>
                                                                                                    </Tooltip>
                                                                                                    <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                        {this.checkReactionCount(reply.reactions, 'disagree') || 0}
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className="left-boarder" item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: 5, marginTop: 5 }} >
                                                                                                    <Link onClick={() => this.setState({ selectdCommentId: comment._id })} style={{ textDecoration: 'none', marginLeft: 10 }} to="#">Reply</Link>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            }) : ''
                                                                    }
                                                                    {comment._id == this.state.selectdCommentId &&
                                                                        <form onSubmit={(event) => { event.preventDefault(); this.handleReplySubmit(post._id, comment._id, event) }} style={{ width: '100%' }}>
                                                                            <Grid container>
                                                                                <Grid item xs={10} sm={10} md={10} lg={10} style={{ width: '100%' }}>
                                                                                    <TextField
                                                                                        style={{ width: '100%', padding: 10 }}
                                                                                        id="outlined-size-small"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        value={this.state.newReply}
                                                                                        autoFocus
                                                                                        onChange={(event) => {
                                                                                            event.persist();
                                                                                            this.setState({
                                                                                                newReply: event.target.value
                                                                                            })
                                                                                        }}
                                                                                        placeholder="Write your reply"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center', padding: '4px 0px 0px 20px' }}>
                                                                                    <IconButton type="submit">
                                                                                        <SendIcon style={{ color: 'gray' }} type="submit" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </form>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    )
                                                })
                                                    : ''}
                                        </List>
                                        <form onSubmit={(event) => { event.preventDefault(); this.handleSubmit() }} style={{ width: '100%' }}>
                                            <Grid container style={{ padding: 5 }}>
                                                <Grid item xs={10} sm={10} md={10} lg={10} style={{ width: '100%' }}>
                                                    <TextareaAutosize
                                                        style={{ width: '100%', border: '2px solid #aaa2a2', padding: 5 }}
                                                        rowsMin={3}
                                                        rowsMax={10}
                                                        id="standard-basic"
                                                        label="comment"
                                                        type="text"
                                                        name="comment"
                                                        value={this.state.newComment}
                                                        onChange={(event) => {
                                                            event.persist();
                                                            this.setState({
                                                                newComment: event.target.value
                                                            });
                                                        }}
                                                        aria-label="Comment"
                                                        placeholder="Write your comment"
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sm={2} md={2} lg={2} style={{ textAlign: 'center', padding: '4px 0px 0px 20px' }}>
                                                    <IconButton type="submit">
                                                        <SendIcon style={{ color: 'gray' }} type="submit" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Card>
                    }
                </Grid>
                <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; and (b) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP."
                    open={this.state.openAlertDialog} onClose={this.handelAlertDialogClose} />
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => this.setState({
                        open: false
                    })}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => this.setState({ open: false })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
                {
                    this.state.openShareDialog && this.state.shareUrl &&
                    < ShareDialog url={this.state.shareUrl} title={this.state.shareTitle} open={this.state.openShareDialog} onClose={this.handelShareDialogClose} />
                }
            </Grid >
        )
    }
}

export default connect()(FeedPage);