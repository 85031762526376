import moment from "moment";

const transformDates = (dates) => {
  if (!dates) return "-";
  if (!Array.isArray(dates)) dates = [dates];
  if (!dates.length) return "-";
  return dates.map((date) => moment(date).format("MM/DD/YYYY")).join(", ");
};

export default transformDates;
