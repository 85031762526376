import React, { Component } from 'react';
import { Grid, Button, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WorkoutClassService from '../../services/WorkoutClassService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
    Capacitor,
    Plugins,
} from '@capacitor/core';
const { Browser } = Plugins;
let wsConnection = null;
let peerConnection;

class WowzaPlayer extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            streamName: props.streamName || '',
            applicationName: props.applicationName || '',
            showPlayer: true,
            open: false,
            isSuccess: false,
            message: '',
            isLive: false,
            totalConnections: '',
            showCastToTV: props.showCastToTV
        }
        this.videoPlayer = React.createRef() || null;
        this.checkLiveTimer = null;
    }

    componentDidMount() {
        this.wsConnect();
        this.checkLive(true);
        this.checkLiveTimer = setInterval(async () => {
            try {
                await this.checkLive(false);
            } catch (error) { }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.checkLiveTimer);
    }

    wsConnect = () => {
        let _this = this;
        let streamName = this.state.streamName;
        let applicationName = this.state.applicationName;
        let streamInfo = { applicationName, streamName };
        let userData = { 'iceServers': [] };
        peerConnection = new RTCPeerConnection(userData);
        let repeaterRetryCount;

        try {
            wsConnection = new WebSocket(process.env.REACT_APP_WOWZA_WSS_URL);
        }
        catch (e) {
            console.log(e);
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
        wsConnection.binaryType = 'arraybuffer';

        wsConnection.onopen = () => {
            console.log("onopen");

            peerConnection.onicecandidate = _this.gotIceCandidate;

            peerConnection.ontrack = (event) => {
                // eslint-disable-next-line no-restricted-globals
                console.log('gotRemoteTrack: kind:' + event.track.kind + ' stream:' + event.streams[0]);
                try {

                    // eslint-disable-next-line no-restricted-globals
                    this.videoPlayer.current.srcObject = event.streams[0];
                } catch (error) {
                    // eslint-disable-next-line no-restricted-globals
                    this.videoPlayer.current.src = window.URL.createObjectURL(event.streams[0]);

                }
            }
            sendPlayGetOffer();
        }

        const sendPlayGetOffer = () => {
            try {
                console.log("sendPlayGetOffer: " + JSON.stringify(streamInfo));
                wsConnection.send('{"direction":"play", "command":"getOffer", "streamInfo":' + JSON.stringify(streamInfo) + ', "userData":' + JSON.stringify(userData) + '}');
            } catch (error) {
                console.log('set local description error', error)
            }
        }

        const stop = () => {
            if (peerConnection != null) { peerConnection.close(); }
            if (wsConnection != null) { wsConnection.close(); }
            peerConnection = null;
            wsConnection = null;
            // this.videoPlayer.current.srcObject = "";
        }

        wsConnection.onmessage = function (evt) {
            console.log("wsConnection.onmessage: " + evt.data);

            let msgJSON = JSON.parse(evt.data);
            let msgStatus = Number(msgJSON['status']);
            let msgCommand = msgJSON['command'];

            if (msgStatus == 514) {
                repeaterRetryCount++;
                if (repeaterRetryCount < 10) {
                    setTimeout(sendPlayGetOffer, 500);
                }
                else {
                    console.log('Live stream repeater timeout: ' + streamName);
                    stop();
                }
            }
            else if (msgStatus != 200) {
                console.log(msgJSON['statusDescription']);
                stop();
            }
            else {

                let streamInfoResponse = msgJSON['streamInfo'];
                if (streamInfoResponse !== undefined) {
                    streamInfo.sessionId = streamInfoResponse.sessionId;
                }

                let sdpData = msgJSON['sdp'];
                if (sdpData != null) {
                    console.log('sdp: ' + JSON.stringify(msgJSON['sdp']));

                    if (this.mungeSDP != null) {
                        msgJSON.sdp.sdp = this.mungeSDP(msgJSON.sdp.sdp);
                    }
                    console.log("SDP Data: " + msgJSON.sdp.sdp);

                    peerConnection
                        .setRemoteDescription(new RTCSessionDescription(msgJSON.sdp))
                        .then(() => peerConnection
                            .createAnswer()
                            .then((description) => {
                                peerConnection.setLocalDescription(description)
                                    .then(() => {
                                        console.log('sendAnswer');
                                        console.log(wsConnection);
                                        wsConnection.send('{"direction":"play", "command":"sendResponse", "streamInfo":' + JSON.stringify(streamInfo) + ', "sdp":' + JSON.stringify(description) + ', "userData":' + JSON.stringify(userData) + '}');
                                    })
                                    .catch(err => console.log('set local description error', err));
                            })
                        ).catch(err => console.log('set remote description error', err));
                }

                let iceCandidates = msgJSON['iceCandidates'];
                if (iceCandidates != null) {
                    for (let index in iceCandidates) {
                        console.log('iceCandidates: ' + JSON.stringify(iceCandidates[index]));
                        peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidates[index]));
                    }
                }
            }

            if ('sendResponse'.localeCompare(msgCommand) == 0) {
                if (wsConnection != null) {
                    wsConnection.close();
                }

                wsConnection = null;
            }
        }

        wsConnection.onclose = function () {
            console.log("wsConnection.onclose");
        }

        wsConnection.onerror = function (evt) {
            console.log(evt);
        }
    }

    mungeSDP = (sdpStr) => {
        let sdpLines = sdpStr.split(/\r\n/);
        let sdpStrRet = '';

        for (var sdpIndex in sdpLines) {
            var sdpLine = sdpLines[sdpIndex];

            if (sdpLine.length == 0)
                continue;

            if (sdpLine.includes("profile-level-id")) {
                let profileLevelId = sdpLine.substr(sdpLine.indexOf("profile-level-id") + 17, 6);
                let profile = Number('0x' + profileLevelId.substr(0, 2));
                let constraint = Number('0x' + profileLevelId.substr(2, 2));
                let level = Number('0x' + profileLevelId.substr(4, 2));
                if (profile > 0x42) {
                    profile = 0x42;
                    constraint = 0xE0;
                    level = 0x1F;
                }
                let newProfileLevelId = ("00" + profile.toString(16)).slice(-2).toLowerCase() +
                    ("00" + constraint.toString(16)).slice(-2).toLowerCase() +
                    ("00" + level.toString(16)).slice(-2).toLowerCase();

                sdpLine = sdpLine.replace(profileLevelId, newProfileLevelId);
            }

            sdpStrRet += sdpLine;
            sdpStrRet += '\r\n';
        }

        return sdpStrRet;
    }

    stopStream = () => {
        if (peerConnection != null) { peerConnection.close(); }
        if (wsConnection != null) { wsConnection.close(); }
        peerConnection = null;
        wsConnection = null;
    }

    checkLive = async (onLoad) => {
        let obj = {
            applicationName: this.state.applicationName,
            wowzaStreamKey: this.state.streamName
        };
        try {
            let response = await WorkoutClassService.checkLive(obj);
            if (response.stream) {
                this.setState({ isLive: true, totalConnections: response.totalConnections || 0 });
            } else {
                this.setState({ isLive: false });
                if (!onLoad) {
                    this.setState({ showPlayer: false });
                }
            }
        } catch (e) {
        }
    }

    handleCastToTV = () => {
        const url = `${process.env.REACT_APP_CLIENT_URL}/cast.php?application=${this.state.applicationName}&stream=${this.state.streamName}`
        if (Capacitor.isNative) {
            Browser.open({ url });
        } else {
            window.open(url, "_blank");
        }
    }

    render() {
        let { open, isSuccess, message } = this.state;
        return (
            <Grid container style={{ justifyContent: 'center', padding: (this.state.showCastToTV ? 20 : 0), display: "flex" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "center", padding: 10 }}>
                    {
                        this.state.isLive &&
                        <div style={{ float: 'right', display: 'flex' }}>
                            <span style={{ background: 'red', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999 }}>LIVE</span>
                            <span style={{ background: 'black', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999 }}> <VisibilityIcon style={{ marginTop: -4 }} /></span>
                            <span style={{ background: 'black', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999, right: 10, }}>{this.state.totalConnections}</span>
                        </div>
                    }
                    {
                        this.state.showPlayer ?
                            <video ref={this.videoPlayer} id="player-video" autoPlay playsInline muted controls height="100%" width="100%"></video>
                            :
                            this.state.showCastToTV ?
                                <p>Live session ended.</p>
                                :
                                <h2 style={{
                                    overflow: "hidden",
                                    margin: 0,
                                    position: "absolute",
                                    top: "45%",
                                    color: "white",
                                    paddingLeft: 20,
                                }}>Live session ended.</h2>
                    }
                    {
                        this.state.showCastToTV &&
                        <div style={{ display: "flex", justifyContent: "center", margin: '10px' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ width: '120px', height: '40px', backgroundColor: '#9013fe', color: 'white' }}
                                onClick={this.handleCastToTV}
                            >
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    Cast on TV
                        </span>
                            </Button>
                        </div>
                    }
                </Grid>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => this.setState({
                        open: false
                    })}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => this.setState({ open: false })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
            </Grid>
        )
    }
}

export default WowzaPlayer;