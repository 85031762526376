import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    TextField,
    MenuItem,
    Button,
    Input,
    IconButton
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverlayLoader from '../core/OverlayLoader';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import CategoryService from '../../services/CategoryService';
import PostService from '../../services/PostService';
import AuthService from '../../services/AuthService';
import AlertDialog from '../core/Disclamer';

let videoType = [
    {
        label: 'Quick Peeks',
        value: 'quickpeeks',
        disbaled: false
    },
    {
        label: 'Deep Dives',
        value: 'deepdives',
        disabled: false
    },
    {
        label: 'Giggles',
        value: 'giggles',
        disabled: false
    }
];

const levelTypes = [
    {
        label: 'Beginner',
        value: 'beginner',
        disabled: false
    },
    {
        label: 'Intermediate',
        value: 'intermediate',
        disabled: false
    },
    {
        label: 'Advanced',
        value: 'advanced',
        disabled: false
    },
    {
        label: 'Everyone',
        value: 'everyone',
        disabled: false
    }
];

const Basic = (props) => {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isSuccess, setMessageStatus] = useState(false);
    const [openAlertDialog, setAlertOpenDialog] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState({});
    const [categories, setCategories] = React.useState([]);

    if (props && props.user && props.user.accountType === 'basic') {
        videoType = [
            {
                label: 'Giggles',
                value: 'giggles',
                disabled: false
            }
        ];
    }

    const handelAlertDialogClose = async (isConfirm) => {
        setAlertOpenDialog(false);
        if (isConfirm && await checkVideoDurationForUpload(selectedValue.media, selectedValue)) {
            submitForm(selectedValue);
        }
    }

    const getFileDuration = (file) => {
        return new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    resolve(video.duration);
                }
                video.src = URL.createObjectURL(file);;
            } catch (error) {
                reject(error)
            }
        });
    }

    const checkVideoDurationForUpload = async (file, values) => {
        let fileExt = file.name.split('.').pop();
        let message = 'Please select only valid MP4 or MOV video format.';
        if (fileExt.toLowerCase() != "mp4" && fileExt.toLowerCase() != "mov") {
            setMessageStatus(false);
            setMessage(message);
            setOpen(true);
            return false;
        }
        let duration = await getFileDuration(file);
        if (values.type == 'quickpeeks' && duration <= 300) return true;
        if (values.type == 'deepdives' && duration >= 1200 && duration <= 5400) return true;
        if (values.type == 'giggles' && duration <= 300) return true;
        message = 'Please select video type';
        if (values.type == 'quickpeeks') message = 'Video should not be more than 5 minutes';
        if (values.type == 'deepdives') message = 'Video should be of min. 20 minutes and max. 90 minutes';
        if (values.type == 'giggles') message = 'Video should not be more than 5 minutes';
        setMessageStatus(false);
        setMessage(message);
        setOpen(true);
        return false;
    }

    const submitForm = async (values) => {
        setDisabled(true);
        setLoader(true);
        try {
            let createObj = values;
            createObj.videoFileExt = values.media.name.split('.').pop();
            let createRes = await PostService.createPost(createObj);
            if (createRes && createRes.post && createRes.post._id) {
                let obj = {};
                obj.postId = createRes.post._id;
                obj.fileName = values.media.name;
                obj.fileSize = values.media.size;
                obj.videoFileExt = obj.fileName.split('.').pop();
                obj.bytesUploaded = 0;
                let chunkSize = 50000000;
                var numberOfChunk = Math.ceil(values.media.size / chunkSize);
                let file = values.media;
                for (let chunkCounter = 0; chunkCounter < numberOfChunk; chunkCounter++) {
                    let res = await PostService.statusPost(obj);
                    if (res) {
                        if (res.fileUploaded) continue;
                        obj.bytesUploaded = Number(res.bytesUploaded);
                        const formData = new FormData();
                        let chunkEnd = Math.min((obj.bytesUploaded + chunkSize), file.size);
                        const chunk = file.slice(obj.bytesUploaded, chunkEnd);
                        formData.append('chunk', chunk, file.name);
                        let contentRange = `bytes=${obj.bytesUploaded}-${obj.bytesUploaded + chunk.size}/${file.size}`
                        obj.formData = formData;
                        obj.contentRange = contentRange;
                        let res1 = await PostService.uploadPost(obj);
                    }
                }
                let finishRes = await PostService.finishPost(obj);
                if (finishRes) {
                    setDisabled(false);
                    setLoader(false);
                    setMessageStatus(true);
                    setMessage(finishRes.message);
                    setOpen(true);
                    setTimeout(() => {
                        props.props.history.push('/myFeeds');
                    }, 3000);
                }
            }
        } catch (e) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(e.message);
            setOpen(true);
        }
    }

    return (<div>
        <Formik
            initialValues={{
                title: '',
                type: (props && props.user && props.user.accountType === 'basic' ? 'giggles' : ''),
                category: '',
                subCategory: '',
                level: '',
                media: null
            }}
            validate={values => {
                const errors = {};
                if (!values.title) {
                    errors.title = 'Please enter title';
                }
                if (!values.type) {
                    errors.type = 'Please select video type';
                }
                if (!values.category) {
                    if (!values.type || values.type !== 'giggles') {
                        errors.category = 'Please select category';
                    }
                }
                if (!values.subCategory) {
                    if (!values.type || values.type !== 'giggles') {
                        errors.subCategory = 'Please select sub category';
                    }
                }
                if (!values.level) {
                    if (!values.type || values.type !== 'giggles') {
                        errors.level = 'Please select level';
                    }
                }
                if (!values.media) {
                    errors.media = 'Please select a video to upload';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
                setAlertOpenDialog(true);
                setSelectedValue(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                isSubmitting
            }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-select-currency"
                                    type="text"
                                    style={{ width: '100%' }}
                                    name="title"
                                    error={errors.title && touched.title ? true : false}
                                    disabled={disabled}
                                    label="Video Title"
                                    value={values.title}
                                    placeholder={'Video Title'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.title && errors.title}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    style={{ width: '100%' }}
                                    name="type"
                                    error={errors.type && touched.type ? true : false}
                                    disabled={disabled}
                                    label="Video Type"
                                    value={values.type}
                                    placeholder={'Video Type'}
                                    onChange={(event) => {
                                        handleChange(event);
                                        let categories = props.allCategories.find(obj => obj._id === event.target.value).cats;
                                        setCategories(categories);
                                    }}
                                    onBlur={handleBlur}
                                    helperText={touched.type && errors.type}
                                    required
                                >
                                    {videoType.map(option => (
                                        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {
                                    values.type &&
                                    <p style={{ color: 'grey' }}>
                                        {
                                            values.type == 'quickpeeks' &&
                                            <span>Video should not be more than 5 minutes</span>
                                        }
                                        {
                                            values.type == 'deepdives' &&
                                            <span>Video should be of min. 20 minutes and max. 90 minutes</span>
                                        }
                                        {
                                            values.type == 'giggles' &&
                                            <span>Video should not be more than 5 minutes</span>
                                        }
                                    </p>
                                }
                            </Grid>
                            {
                                (values.type && values.type !== 'giggles') &&
                                <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        style={{ width: '100%' }}
                                        disabled={disabled}
                                        error={errors.level && touched.level ? true : false}
                                        name="level"
                                        label="Select Level"
                                        placeholder={'Select Level'}
                                        value={values.level}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.level && errors.level}
                                        required
                                    >
                                        {
                                            levelTypes.map((option) => {
                                                return (
                                                    <MenuItem key={option.label} value={option.value} disabled={(option.label === 'All') ? true : false}>
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            }
                            {
                                (values.type && values.type !== 'giggles') ?
                                    <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            style={{ width: '100%' }}
                                            disabled={disabled}
                                            error={errors.category && touched.category ? true : false}
                                            name="category"
                                            label="Select Category"
                                            placeholder={'Select Category'}
                                            value={values.category}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.category && errors.category}
                                            required
                                        >
                                            {categories.map(option => (
                                                <MenuItem key={option._id} value={option._id} disabled={(option.name === 'All') ? true : (option.disabled)} >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    : ''
                            }
                            {
                                (values.type && values.type !== 'giggles' && values.category) ?
                                    <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            style={{ width: '100%' }}
                                            disabled={disabled}
                                            error={errors.subCategory && touched.subCategory ? true : false}
                                            name="subCategory"
                                            label="Select Sub Category"
                                            placeholder={'Select Sub Category'}
                                            value={values.subCategory}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.subCategory && errors.subCategory}
                                            required
                                        >
                                            {
                                                categories.find((e) => e._id === values.category).subCats.map(option => (
                                                    <MenuItem key={option._id} value={option._id} disabled={(option.name === 'All') ? true : (option.disabled)}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid> : ''
                            }
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>


                                {
                                    !disabled ? <React.Fragment> <Button
                                        className="btn-purple"
                                        variant="contained"
                                        type="button"
                                        disabled={!values.type || disabled}
                                        color="primary"
                                        disableElevation={true}
                                        component="label"
                                        style={{ marginTop: 30, textTransform: 'none' }}
                                    >
                                        Select and Upload Video
                                        <input
                                            id="media"
                                            type='file'
                                            name="media"
                                            style={{ display: 'none', width: '100%' }}
                                            label="Video"
                                            disabled={!values.type || disabled}
                                            className='form-control btn-purple'
                                            onClick={e => (e.target.value = null)}
                                            onChange={async (e) => {
                                                let file = e.target.files[0];
                                                let checkResult = await checkVideoDurationForUpload(e.target.files[0], values);
                                                if (checkResult) {
                                                    setFieldValue("media", file);
                                                } else {
                                                    setFieldValue("media", null);
                                                }
                                            }}
                                        ></input>

                                    </Button>
                                        <p style={{ fontSize: "14px", color: 'grey' }}>Only MP4 or MOV video format is allowed.</p>
                                        {(touched.media && errors.media) && (<label style={{ fontSize: "0.74rem", fontFamily: "GilroyLight, sans-serif", fontWeight: '100', color: '#f44336' }}>{errors.media}</label>)} </React.Fragment> : " "
                                }
                            </Grid>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <div style={{ textDecoration: 'none', marginTop: 30 }}>
                                    {
                                        !disabled ?
                                            <Button
                                                className="btn-purple"
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disableElevation={true}
                                            >
                                                Submit
                                        </Button> :
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult, and I am currently a certified fitness professional in good standing; (b) I give THE AB LAB dAPP the right to adapt and use my name, image and likeness and the content that I upload to THE AB LAB dAPP without compensation; (c) I have secured all rights and permissions that are needed for THE AB LAB dAPP’s use of the content I have uploaded, including music clearance and the right to use the names, images and likenesses of individuals depicted in the content; (d) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, other Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP and (e) I have the right to withdraw the content I have uploaded and my personally identifiable information at any time."
                            open={openAlertDialog} onClose={handelAlertDialogClose} />
                    </form>
                )}
        </Formik>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="white"
                        style={{ color: 'white' }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }>
            <SnackbarContent style={isSuccess ? {
                backgroundColor: 'green',
            } : { backgroundColor: 'red', }}
                message={<span id="client-snackbar">{message}</span>}
            />
        </Snackbar>
    </div>
    );
}

class PostVideoPage extends Component {
    constructor(props) {
        super(props);
        let user = AuthService.getAuthUser();
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: (user && user.accountType === 'pinger' ? 'Post New Video' : 'Post Giggles'),
                static: true
            }
        });
        this.state = {
            allCategories: [],
            loader: false
        };
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getCategories();
    }

    getCategories = async () => {
        try {
            this.setState({ loader: true });
            let res = await CategoryService.getAllCategories();
            this.setState({ loader: false });
            this.setState({ allCategories: res.categories });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    render() {
        let user = AuthService.getAuthUser();
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <Basic allCategories={this.state.allCategories} props={this.props} user={user} />
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
});

export default connect(mapStateToProps)(PostVideoPage);