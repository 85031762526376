import * as Server from '../Server';
const RewardService = {
    getAllRewards: async (obj) => {
        try {
            let response = await Server.request({
                url: '/reward/search',
                method: 'POST',
                data: obj
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    getRewardById: async (_id) => {
        try {
            let response = await Server.request({
                url: '/reward/' + _id,
                method: 'GET'
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    getAllPingers: async (obj) => {
        try {
            let response = await Server.request({
                url: '/reward/pingers',
                method: 'POST',
                data: obj
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    createReward: async (obj) => {
        try {
            let response = await Server.request({
                url: '/reward/',
                method: 'POST',
                data: obj
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    }

}

export default RewardService;