import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, Button, Link } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

const allUserMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    Whether you are a fitness professional, enthusiast or fitness product/service provider, you are now on a path to retooling your fitness
    experience. Change was afoot well before Covid but now the need for NEW and BETTER is even greater since so much of our regular
    fitness pathways have been altered or maybe have even disappeared!
</p>
<p>
    Even though the tech tools we use in THE AB LAB dAPP are complicated, our plan is simple. You won’t have to do ANY heavy lifting.
    Just follow along with professionals you see on quick peeks or deep dives and enjoy great offers from companies that reward you for
    just being a part of The AB LAB. Learn from the real influencers in the industry - the certified fitness professionals who can take you
    from unfit to fit both online and onsite.
</p>
<p>
    View the latest trends from around the world. Participate in short or long, archived or live streamed workouts from thousands of
    vetted professionals. Just hang out and watch “giggles” and still receive exciting product offers. Register for educational courses. Buy
    products and services. The opportunities are all unique and lead you to any place you want to go. Switch careers? Visit The Ab Lab
    Lounge? Improve your waistline? Pick up a fabulous new piece of leisurewear? We have it all right here in THE AB LAB.
</p>
<p>
    Carefully developed to include all fitness participants, we are proud to introduce both THE AB LAB and its patent pending partner,
    THE AB LAB dAPP.
</p>
<p>
    This is how it works<br>
        1. Consider THE AB LAB® “The Official Community for Healthy Core Living™<br>
        2. Consider THE AB LAB dAPP the “connector” of all the participants in the fitness industry. professionals, enthusiasts, companies,
        health clubs and more<br>
        3. Now imagine a digital multi player “ping pong like“ adventure where all participants can connect with one another to send and
        receive the latest in content, trends, workouts, products and other opportunities including a chance to profit after joining the
        industry’s first unified fitness community movement.
</p>
<p>
    Convenient, efficient, rewarding and profitable all at the same time. This is where building on a blockchain platform (where
    appropriate) can do all the heavy lifting so you don’t have to do a thing except what you want to do - teach, work out or just
    watch! We have something for everyone. Sign up is free. We can’t wait for you to try us out!
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;

class HomePage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: false,
                title: 'Home',
                static: false
            }
        });
        this.state = {
            showNotificationModel: false
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getAuthUser();
        this.triggerLoggedInEventForMobileNative();
    }

    getAuthUser = async () => {
        try {
            let res = await UserService.getAuthUser();
            AuthService.setAuthUser(res.user);
            this.props.dispatch({ type: 'SET_USER', data: res.user });
        } catch (e) {
            let user = await AuthService.getAuthUser();
            this.props.dispatch({ type: 'SET_USER', data: user });
        }
    }

    triggerLoggedInEventForMobileNative = async () => {
        if (window.webkit) {
            if (window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler) {
                window.webkit.messageHandlers.jsMessageHandler.postMessage('LoggedIn');
            }
        }
        if (window.JSBridge) {
            window.JSBridge.LoggedIn({ message: 'LoggedIn' });
        }
    }

    render() {
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    <Card style={{ boxShadow: 'none', padding: '15px', height: '40%' }}>
                        <img className="homeImage" src={require('../../assets/category/Fitness.png')} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: '100%', borderRadius: 10 }}></img>
                    </Card>
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <p>
                            THE AB LAB​<sup>®</sup> provides something for everyone - professionals, companies and enthusiasts.
                        </p>
                        <ul>
                            <li>Participate in archived or live workouts developed and rated by qualified professionals</li>
                            <li>Receive discounts and rewards</li>
                            <li>Follow trends or just post and/or view “Giggles”</li>
                            <li>Hang out in The Ab Lab “Lounge”</li>
                            <li>Connect to one another in the fitness industry’s first all inclusive blockchain community</li>
                        </ul>
                        <div style={{ textDecoration: 'none', textAlign: 'center', marginBottom: 10 }}>
                            <Button style={{ width: '50%' }} size="small" className="btn-purple" variant="contained" color="primary" disableElevation onClick={() => {
                                this.setState({ showNotificationModel: true });
                            }}>
                                Overview
                            </Button>
                        </div>
                        <div style={{ textDecoration: 'none', textAlign: 'center' }}>
                            <Button style={{ width: '50%' }} size="small" className="btn-purple" variant="contained" color="primary" disableElevation onClick={() => {
                                this.props.history.push('/accessVideos');
                            }}>
                                Access Videos
                            </Button>
                        </div>
                    </Card>
                    {
                        this.state.showNotificationModel &&
                        <Dialog aria-labelledby="simple-dialog-title" open={true}>
                            <DialogTitle id="simple-dialog-title">Welcome to THE AB LAB® dAPP</DialogTitle>
                            <div style={{ paddingRight: 20, paddingLeft: 20 }} dangerouslySetInnerHTML={{ __html: allUserMessage }}></div>
                            <DialogActions>
                                <Button color="primary" onClick={() => this.setState({ showNotificationModel: false })}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer
});

export default connect(mapStateToProps)(HomePage);