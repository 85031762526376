import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UserService from "../../services/UserService";
import AppSnackbar from "../core/AppSnackbar";
import NearbyMap from "../core/NearbyMap";
import OverlayLoader from "../core/OverlayLoader";

const NearbyMPKUsers = () => {
  const dispatch = useDispatch();
  const [radius, setRadius] = useState(15);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const radiusOptions = [
    { label: "15 Kms", value: 15 },
    { label: "25 Kms", value: 25 },
    { label: "50 Kms", value: 50 },
  ];

  useEffect(() => {
    dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        title: "Nearby MPK Proffessionals",
      },
    });
    getCurrentUser();
    getNearbyMpkUsers();
  }, [dispatch]);

  const getCurrentUser = async () => {
    try {
      setLoading(true);
      const res = await UserService.getAuthUser();
      if (res.user) {
        setCurrentUser(res.user);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const getNearbyMpkUsers = async (r) => {
    try {
      setLoading(true);
      const res = await UserService.getNearbyMpkUsers({
        radius: r || radiusOptions[0].value,
      });
      if (res && res.results && Array.isArray(res.results)) {
        setUsers(res.results);
      } else {
        setUsers([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(error.message);
    }
  };

  const viewUserDetails = (user) => {
    setSelectedUser(user);
    setUserDetailsModal(true);
  };

  const closeUserDetailsModal = () => {
    setUserDetailsModal(false);
    setSelectedUser(null);
  };

  const notify = (msg, success = false) => {
    setIsSuccess(success);
    setMessage(msg || "Something went wrong, Try again");
    setSnackbar(true);
  };

  const onRadiusChange = (e) => {
    setRadius(e.target.value);
    getNearbyMpkUsers(e.target.value);
  };

  return (
    <>
      <Grid
        container
        style={{ justifyContent: "center" }}
        className={"contentHolderContainer-90vh"}
      >
        <Grid item xs={12} sm={12} md={11} lg={11}>
          {loading && <OverlayLoader />}
          <Card
            style={{
              boxShadow: "none",
              padding: "15px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 15 }}
            >
              <TextField
                id="radius"
                select
                style={{ width: "100%" }}
                name="Radius"
                label="Select Radius"
                placeholder={"Select Radius (in KMs)"}
                value={radius}
                onChange={onRadiusChange}
                required
              >
                {radiusOptions.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {users && users.length && currentUser ? (
              <div style={{ height: "90vh" }}>
                <NearbyMap
                  radius={radius}
                  onMarkerClick={viewUserDetails}
                  users={users}
                  currentUser={currentUser}
                />
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: 15,
                  paddingTop: 10,
                }}
              >
                No nearby professionals found
              </div>
            )}
          </Card>
          {userDetailsModal && (
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
              <DialogTitle color="textPrimary" id="simple-dialog-title">
                <strong className="custom-dialog-title">User Details</strong>
              </DialogTitle>
              <DialogContent>
                <Grid style={{ minWidth: 400 }}>
                  <DialogContentText
                    color="textPrimary"
                    className="wrap-long-text"
                  >
                    <strong>Name: </strong>
                    {`${selectedUser.firstName} ${
                      selectedUser.middleName
                        ? selectedUser.middleName + " "
                        : ""
                    }${selectedUser.lastName} `}
                  </DialogContentText>
                  <DialogContentText
                    color="textPrimary"
                    className="wrap-long-text"
                  >
                    <strong>Email ID: </strong>
                    {`${selectedUser.email}`}
                  </DialogContentText>
                  <DialogContentText
                    color="textPrimary"
                    className="wrap-long-text"
                  >
                    <strong>Mobile No.: </strong>
                    {`${selectedUser.mobileNumber || "-"}`}
                  </DialogContentText>
                  <DialogContentText
                    color="textPrimary"
                    className="wrap-long-text"
                  >
                    <strong>Address: </strong>
                    {`${selectedUser.address}`}
                  </DialogContentText>
                  <DialogContentText
                    color="textPrimary"
                    className="wrap-long-text"
                  >
                    <strong>Distance: </strong>
                    {Math.fround(selectedUser.distance / 1000).toFixed(2)} Kms
                  </DialogContentText>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={closeUserDetailsModal}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <AppSnackbar
            props={{
              message,
              state: snackbar,
              setState: setSnackbar,
              isSuccess,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NearbyMPKUsers;
