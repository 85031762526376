const intialState = {
  loading: false,
  user: {},
  emailVerificationId: "",
  forgotPasswordId: "",
  accessToken: "",
  selectedUserType: "basic",
};

const userReducer = (state = intialState, action) => {
  switch (action.type) {
    case "SET_EMAIL_VERIFICATION_ID":
      state.emailVerificationId = action.emailVerification._id;
      state.verifyEmail = action.emailVerification.email;
      return state;
    case "SET_USER_TYPE":
      state.selectedUserType = action.userType;
      return state;
    case "SET_ACCESS_TOKEN":
      state.accessToken = action.accessToken;
      return state;
    case "SET_FORGOT_PASSWORD_ID":
      state.forgotPasswordId = action.forgotPassword._id;
      state.verifyEmail = action.forgotPassword.email;
      return state;
    case "SET_USER":
      return Object.assign({}, state, {
        user: action.data,
      });
   
    default:
      return state;
  }
};

export default userReducer;
