import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Card, Button } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import OverlayLoader from '../core/OverlayLoader';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import Divider from '@material-ui/core/Divider';
import * as moment from 'moment';
import ListSubheader from '@material-ui/core/ListSubheader';

const allUserMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    Whether you are a fitness professional, enthusiast or fitness product/service provider, you are now on a path to retooling your fitness
    experience. Change was afoot well before Covid but now the need for NEW and BETTER is even greater since so much of our regular
    fitness pathways have been altered or maybe have even disappeared!
</p>
<p>
    Even though the tech tools we use in THE AB LAB dAPP are complicated, our plan is simple. You won’t have to do ANY heavy lifting.
    Just follow along with professionals you see on quick peeks or deep dives and enjoy great offers from companies that reward you for
    just being a part of The AB LAB. Learn from the real influencers in the industry - the certified fitness professionals who can take you
    from unfit to fit both online and onsite.
</p>
<p>
    View the latest trends from around the world. Participate in short or long, archived or live streamed workouts from thousands of
    vetted professionals. Just hang out and watch “giggles” and still receive exciting product offers. Register for educational courses. Buy
    products and services. The opportunities are all unique and lead you to any place you want to go. Switch careers? Visit The Ab Lab
    Lounge? Improve your waistline? Pick up a fabulous new piece of leisurewear? We have it all right here in THE AB LAB.
</p>
<p>
    Carefully developed to include all fitness participants, we are proud to introduce both THE AB LAB and its patent pending partner,
    THE AB LAB dAPP.
</p>
<p>
    This is how it works<br>
        1. Consider THE AB LAB® “The Official Community for Healthy Core Living™<br>
        2. Consider THE AB LAB dAPP the “connector” of all the participants in the fitness industry. professionals, enthusiasts, companies,
        health clubs and more<br>
        3. Now imagine a digital multi player “ping pong like“ adventure where all participants can connect with one another to send and
        receive the latest in content, trends, workouts, products and other opportunities including a chance to profit after joining the
        industry’s first unified fitness community movement.
</p>
<p>
    Convenient, efficient, rewarding and profitable all at the same time. This is where building on a blockchain platform (where
    appropriate) can do all the heavy lifting so you don’t have to do a thing except what you want to do - teach, work out or just
    watch! We have something for everyone. Sign up is free. We can’t wait for you to try us out!
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;
const pingerMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    As CEO of THE AB LAB®, I want to personally invite you to actively participate in a vibrant, new kind of fitness community built for
    today’s challenging world..
</p>
<p>
    This new community, THE AB LAB, will be powered by an innovative, first of its kind patent pending THE AB LAB dAPP, built on our
    very own blockchain platform! Now that is a lot to take in, so let me carefully break it down.
</p>
<p>
    Consider THE AB LAB® “The Official Home for Healthy Core Living”. Consider yourself the head of the household in your new home.
    There are many guests coming to your home. other professionals, fitness companies and enthusiasts. All your guests want to interact
    with you. try your workouts, take your courses, get your opinions, see what you like and of course, leave you a lovely thank you gift
    for welcoming them to your tech enabled home at THE AB LAB®. Don’t ever forget, it is your influence with all your education that
    needs to be heard. We at THE AB LAB are simply the provider for you to keep up with all the digital changes occurring in the fitness
    ecosystem today and, of course, help you take advantage of them in building new and profitable fitness careers.
</p>
<p>
    Here are some details. Visit terms and conditions for specifics.
</p>
<p style="margin-left:10px">
    ● You are a “pinger” if you are a qualified, vetted professional.<br>
    ● You join us because you want to participate in a community of like minded professionals wishing to lead during the complex
        days that lie ahead.<br>
    ● Your short (Quick Peeks) demo submissions to THE AB LAB will be rated and commented on by other Pingers so you can
        benefit from knowledgeable input as you try out your new routines etc. Keep in mind that you own your video submissions
        and can remove them at any time. Also please be aware that it will take 48-72 hours to verify your account.<br>
    ● Only Pingers will rate and comment on your submissions. Those highly rated “Quick Peeks” video submissions, called
        “pings”, will be reviewed by our team of experts. It is then possible that we will invite those pingers with the highest rated
        videos to submit longer workouts and/or courses for future revenue sharing with THE AB LAB.<br>
    ● As a pinger you will receive incentives, rewards, questionnaires, discounts, job offers, and gifts etc. from the very fitness
        companies (pongers) that value your influence and want to support you.<br>
    ● As Pingers, you are also invited to send us your clients’ best excuses and reasons called “Giggles” why they don’t exercise or
        why they think a big piece of chocolate cake is the best reward for their efforts!<br>
</p>
<p>
    And the best part is that our live video streaming platform is already built ready for you to create your schedule for all these
    enthusiasts who saw you on your “Quick Peeks”
</p>
<p>
    What could be better at a time where the fitness industry needs to unite to rebuild in new ways. while not abandoning what has
    worked in the past.
</p>
<p>
    I hope you are as excited as I am and would love to hear from you.
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;
const pongerMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    You are a PONGER if you are a company or institution that wishes to reach both our global professional pinger community as well as
    exercise enthusiasts wishing to work out at THE AB LAB using our customized live video platform.
</p>
<p>
    You are an authorized representative for your company joining other pongers such as educational organizations, equipment
    manufacturers, health clubs , other merchandisers etc. , desirous of utilizing our THE AB LAB dAPP to reach out to our pinger
    influencers and enthusiasts. It is anticipated that hundreds of thousands of professionals and millions of enthusiasts will want to
    receive your rewards, incentives, questionnaires, surveys, product demos, job opportunities and the like. Their responses will assist
    you in reaching your goals in a much more affordable and even more direct way than what currently exists in today’s traditional
    marketing endeavors. Also please be aware that it will take 48-72 hours to verify your account.
</p>
<p>You can see the ROI from your offer and keep track of valuable Pinger influencer input. The possibilities are endless.</p>
<p>
    I hope you are as excited as I am and would love to hear from you.
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;
const enthusiastMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    You will be guided on your journey with us by utilizing our patent pending THE AB LAB dAPP. This innovative technology will make it
    easier for you to explore all that we will be offering.
</p>
<p>
    Much more than looking good, a strong core affects the quality of your life now and in the future. you will discover it is easy to find
    the information and the qualified professionals you need to reach your goals. even if you aren’t ready yet to start moving.
</p>
<p>
    If exercise isn’t your thing (but you know you should) just watch a few “Giggles” and you won’t feel alone. Listen or watch other
    enthusiasts offer excuses you can relate to. We invite you to upload your own “Giggles” showing how you are (or aren’t)
    strengthening your core during covid lockdown.
</p>
<p>
    If you are curious what kinds of exercises our professionals demonstrate, just look at our under 5 minute “Quick Peeks” and try out a
    few. Of course, we want you to be cleared by your doctor if you haven’t been exercising or have any physical limitations. Be sure
    and start with beginner level. We hope to move you along and progress you to longer workouts and maybe even participate in our
    live streamed workouts designed for every participant level.
</p>
<p>
    And best of all, now that you have joined us at THE AB LAB, you are eligible to receive rewards and incentives from companies
    hoping to get you motivated. you can’t beat that!
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;
const mpkMessage = `<h4>WELCOME</h4><p>Thank you for joining THE AB LAB®, powered by THE AB LAB dAPP.</p>
<p>
    As CEO of THE AB LAB®, I want to personally invite you to actively participate in a vibrant, new kind of fitness community built for
    today’s challenging world..
</p>
<p>
    This new community, THE AB LAB, will be powered by an innovative, first of its kind patent pending THE AB LAB dAPP, built on our
    very own blockchain platform! Now that is a lot to take in, so let me carefully break it down.
</p>
<p>
    Consider THE AB LAB® “The Official Home for Healthy Core Living”. Consider yourself the head of the household in your new home.
    There are many guests coming to your home. other professionals, fitness companies and enthusiasts. All your guests want to interact
    with you. try your workouts, take your courses, get your opinions, see what you like and of course, leave you a lovely thank you gift
    for welcoming them to your tech enabled home at THE AB LAB®. Don’t ever forget, it is your influence with all your education that
    needs to be heard. We at THE AB LAB are simply the provider for you to keep up with all the digital changes occurring in the fitness
    ecosystem today and, of course, help you take advantage of them in building new and profitable fitness careers.
</p>
<p>
    Here are some details. Visit terms and conditions for specifics.
</p>
<p style="margin-left:10px">
    ● You are a "MPK" if you are a qualified, vetted professional.<br>
    ● You join us because you want to participate in a community of like minded professionals wishing to lead during the complex
        days that lie ahead.<br>
    ● Your short (Quick Peeks) demo submissions to THE AB LAB will be rated and commented on by other Pingers so you can
        benefit from knowledgeable input as you try out your new routines etc. Keep in mind that you own your video submissions
        and can remove them at any time. Also please be aware that it will take 48-72 hours to verify your account.<br>
    ● Only Pingers will rate and comment on your submissions. Those highly rated “Quick Peeks” video submissions, called
        “pings”, will be reviewed by our team of experts. It is then possible that we will invite those pingers with the highest rated
        videos to submit longer workouts and/or courses for future revenue sharing with THE AB LAB.<br>
    ● As a pinger you will receive incentives, rewards, questionnaires, discounts, job offers, and gifts etc. from the very fitness
        companies (pongers) that value your influence and want to support you.<br>
    ● As Pingers, you are also invited to send us your clients’ best excuses and reasons called “Giggles” why they don’t exercise or
        why they think a big piece of chocolate cake is the best reward for their efforts!<br>
</p>
<p>
    And the best part is that our live video streaming platform is already built ready for you to create your schedule for all these
    enthusiasts who saw you on your “Quick Peeks”
</p>
<p>
    What could be better at a time where the fitness industry needs to unite to rebuild in new ways. while not abandoning what has
    worked in the past.
</p>
<p>
    I hope you are as excited as I am and would love to hear from you.
</p>
<p>
    Linda Pfeffer<br>
    Founder of AFAA 1983<br>
    CEO 1983- 2015<br>
</p>
<p>For more info please contact me at info@theablab.com</p>
<p>KEY TERMS</p>
<p>
    THE AB LAB - The Official Community for Healthy Core Living<br>
    THE AB LAB dAPP - The Blockchain Hub for Fitness (patent pending)<br>
    THE AB LAB LOUNGE - Your home away from home<br>
    PINGERS - Fitness / Allied health professionals with vetted credentials<br>
    PONGERS - Companies and organizations<br>
    ENTHUSIASTS - Consumers, corporate employees<br>
    QUICK PEEKS - under 5 minute sample workouts<br>
    DEEP DIVES - Longer workouts and courses<br>
    GIGGLES - Humorous exercise stories we can all relate to<br>
</p>
<p>© 2020 THE AB LAB® All Rights Reserved</p>
<p>The Ab Lab dAPP (patent pending)</p>`;

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: 'SET_TOOLBAR_CONFIG',
      data: {
        show: true,
        color: true,
        sort: false,
        search: false,
        back: true,
        title: 'Notifications',
        static: true,
      },
    });
    this.state = {
      showNotificationModel: false,
      notificationMessage: '',
      notificationTitle: '',
      notifications: [],
      page: 1,
      limit: 50,
      pages: 0,
      totalNotifications: 0,
      loader: false,
      error: '',
    };
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (!user) this.props.history.replace('/login');
    window.scrollTo(0, 0);
    this.getNotifications();
  }

  getNotifications = async () => {
    let obj = {
      page: this.state.page,
      limit: this.state.limit,
    };
    await this.setState({ loader: true });
    try {
      let res = await UserService.getNotifications(obj);
      let notifications = this.state.notifications;
      res.notifications.forEach((notification) => {
        let index = this.state.notifications.findIndex(
          (obj) => obj._id === notification._id
        );
        if (index != -1) {
          notifications[index] = notification;
        } else {
          notifications.push(notification);
        }
      });
      this.setState({
        notifications: notifications,
        totalNotifications: res.totalNotifications,
        page: res.page,
        pages: res.pages,
      });
      this.setState({ loader: false });
    } catch (e) {
      this.setState({
        loader: false,
        error: e.message,
      });
    }
  };

  refreshPagination = async () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.getNotifications();
    });
  };

  showNotificationModel = (notification) => {
    let notificationMessage = '';
    let notificationTitle = '';
    if (notification.title == '__welcomeAll') {
      notificationMessage = allUserMessage;
      notificationTitle = 'Welcome to THE AB LAB® dAPP';
    }
    if (notification.title == '__welcomePinger') {
      notificationMessage = pingerMessage;
      notificationTitle = 'Getting started as a Pinger (Professional)';
    }
    if (notification.title == '__welcomePonger') {
      notificationMessage = pongerMessage;
      notificationTitle = 'Getting started as a Ponger (Company)';
    }
    if (notification.title == '__welcomeEnthusiast') {
      notificationMessage = enthusiastMessage;
      notificationTitle = 'Getting started as an Enthusiast';
    }
    if (notification.title == '__welcomeMpk') {
      notificationMessage = enthusiastMessage;
      notificationTitle = 'Getting started as a MPK';
    }
    this.setState({
      showNotificationModel: true,
      notificationTitle: notificationTitle,
      notificationMessage: notificationMessage,
    });
  };

  render() {
    return (
      <Grid
        container
        style={{ justifyContent: 'center' }}
        className={'contentHolderContainer-90vh'}
      >
        <Grid item xs={12} sm={12} md={11} lg={11}>
          {this.state.loader && <OverlayLoader />}
          <Card style={{ boxShadow: 'none', padding: '15px' }}>
            <List style={{ width: '100%' }}>
              {this.state.notifications.length ? (
                this.state.notifications.map((notification, key) => {
                  return (
                    <React.Fragment>
                      <ListItem
                        style={{ padding: '20px 0px', textAlign: 'center' }}
                      >
                        <div className="notification-profile-image-holder">
                          <img
                            src={
                              notification.sender &&
                              notification.sender.profilePic
                                ? notification.sender.profilePic
                                : require('../../assets/default-user-image.png')
                            }
                            style={{
                              height: 50,
                              width: 50,
                              weight: 45,
                              borderRadius: 100,
                            }}
                          />
                        </div>
                        <Grid
                          item
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          className="notification-message-holder"
                        >
                          {notification.title == '__welcomeAll' ||
                          notification.title == '__welcomePinger' ||
                          notification.title == '__welcomePonger' ||
                          notification.title == '__welcomeEnthusiast' ||
                          notification.title == '__welcomeMpk' ? (
                            <Grid container>
                              <Grid item xs={10} sm={10} md={10} lg={10}>
                                <span>{notification.message} </span>
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Link
                                  onClick={() =>
                                    this.showNotificationModel(notification)
                                  }
                                  style={{ textDecoration: 'none' }}
                                  to="#"
                                >
                                  View
                                </Link>
                              </Grid>
                            </Grid>
                          ) : (
                            <span>{notification.message} </span>
                          )}
                        </Grid>
                      </ListItem>
                      <ListItem
                        style={{
                          padding: '0px 60px',
                          textAlign: 'center',
                          marginTop: '-10px',
                        }}
                      >
                        {' '}
                        <ListSubheader>
                          {moment(notification.createdAt).fromNow()}
                        </ListSubheader>{' '}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })
              ) : (
                <div style={{ textAlign: 'center' }}>
                  No notifications found.
                </div>
              )}
            </List>
          </Card>
          {this.state.page < this.state.pages && (
            <Grid container style={{ justifyContent: 'center' }}>
              <Button
                className="btn-purple btn-purple-highligted"
                style={{ width: '210px', marginTop: 10 }}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => this.refreshPagination()}
              >
                View more
              </Button>
            </Grid>
          )}
          {this.state.showNotificationModel && (
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
              <DialogTitle id="simple-dialog-title">
                {this.state.notificationTitle}
              </DialogTitle>
              <div
                style={{ paddingRight: 20, paddingLeft: 20 }}
                dangerouslySetInnerHTML={{
                  __html: this.state.notificationMessage,
                }}
              ></div>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({ showNotificationModel: false })
                  }
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default connect()(NotificationsPage);
