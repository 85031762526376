import * as Server from "../Server";

const ServiceRequestService = {
  getServiceRequestsByUser: async (serviceId, body) => {
    try {
      let response = await Server.request({
        url: `/serviceRequest/search/${serviceId}`,
        method: "POST",
        data: body || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
};

export default ServiceRequestService;
