import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Grid, TextField, Button, IconButton } from "@material-ui/core";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
const Basic = (props) => {
  let history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setMessageStatus] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Please enter email";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let body = {};
          body = values;
          await setDisabled(true);
          try {
            AuthService.setAuthorizationHeader(
              "basic " + process.env.REACT_APP_SERVER_BASE64
            );
            let res = await AuthService.sendForgotPasswordOTP(body);
            if (res) {
              setDisabled(false);
              setMessageStatus(true);
              setOpen(true);
              setMessage("We have sent OTP on your email address.");
              props.props.dispatch({
                type: "SET_FORGOT_PASSWORD_ID",
                forgotPassword: {
                  _id: res.forgotPasswordId,
                  email: values.email,
                },
              });
              resetForm({});
              setTimeout(() => {
                history.replace("/verify");
              }, 2000);
            }
          } catch (e) {
            setDisabled(false);
            setMessageStatus(false);
            setMessage(e.message);
            setOpen(true);
            resetForm({});
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid container style={{ textAalign: "center" }}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Enter Registered Email"
                  type="email"
                  name="email"
                  disabled={disabled}
                  error={errors.email && touched.email ? true : false}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <div style={{ textDecoration: "none", padding: 10 }}>
                  {!disabled ? (
                    <Button
                      className="btn-purple"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disableElevation={true}
                    >
                      Next
                    </Button>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="white"
              style={{ color: "white" }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarContent
          style={
            isSuccess
              ? {
                  backgroundColor: "green",
                }
              : { backgroundColor: "red" }
          }
          message={<span id="client-snackbar">{message}</span>}
        />
      </Snackbar>
    </div>
  );
};

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: "",
        static: false,
      },
    });
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Grid
          container
          className={"contentHolderContainer-90vh"}
          justify="center"
          style={{
            backgroundColor: "white",
            marginTop: "0%",
            justifyContent: "center",
          }}
        >
          <Grid container justify="center" className={"profile-div-background"}>
            <Grid item>
              <div className="profile-image-holder">
                <img
                  src={require("../../assets/forgot-password.png")}
                  alt=""
                  style={{
                    height: 90,
                    weight: 90,
                    borderRadius: 60,
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ padding: "0px 20px" }}>
            <Grid item xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
              <h2
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: 26,
                  fontWeight: 400,
                }}
              >
                Forgot Password
              </h2>
              <p
                style={{
                  marginBottom: 0,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: 14,
                  fontWeight: 200,
                }}
              >
                Please enter your registered email address
              </p>
              <p
                style={{
                  marginTop: 0,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: 14,
                  fontWeight: 200,
                }}
              >
                to reset your password
              </p>
            </Grid>
            <Basic props={this.props} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(ForgotPasswordPage);
