import * as Server from '../Server';

const TakeawayService = {
    sync: async (obj) => {
        try {
            let response = await Server.request({
                url: '/takeaway/sync',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },
    search: async (obj) => {
        try {
            let response = await Server.request({
                url: '/takeaway/search',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },
    collect: async (obj) => {
        try {
            let response = await Server.request({
                url: '/takeaway/collect',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },
    transfer: async (obj) => {
        try {
            let response = await Server.request({
                url: '/takeaway/transfer',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    }
};

export default TakeawayService;
