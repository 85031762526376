/*global google*/
import React, {
  useRef,
  useState,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof google.maps.LatLng
  ) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  return deepEqual(a, b);
});

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

const useDeepCompareEffectForMaps = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

const GoogleMap = ({ onIdle, children, style, radius, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  const [circle, setCircle] = useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  useEffect(() => {
    if (map) {
      const c = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "black",
        fillOpacity: 0.06,
        center: options.center,
        radius: radius * 1000,
      });
      if (circle) {
        circle.setMap(null);
      }
      c.setMap(map);
      setCircle(c);
    }
  }, [radius, map]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            map,
          });
        }
      })}
    </>
  );
};

export default GoogleMap;
