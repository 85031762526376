import menuReducer from './menuReducers';
import toolbarReducer from './toolbarReducer';
import userReducer from './userReducer';
import catSubCatReducer from './catSubCatReducer';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
    menuReducer,
    toolbarReducer,
    userReducer,
    catSubCatReducer
})
export default rootReducer;