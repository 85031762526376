import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import ShowMoreText from 'react-show-more-text';

class SelectUserTypePage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: 'SET_TOOLBAR_CONFIG',
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: '',
        static: false,
      },
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectUserType = (userType) => {
    localStorage.setItem('accountType', userType);
    this.props.history.push('/preview');
  };

  selectUser = (userType) => {
    if (!userType) return;
    this.props.dispatch({ type: 'SET_USER_TYPE', userType: userType });
    localStorage.setItem('acceptAgreement', 'No');
    localStorage.setItem('accountType', userType);
    this.props.history.push('/signup');
  };

  render() {
    return (
      <div style={{ overflowY: 'auto', backgroundColor: 'white' }}>
        <Grid
          container
          justify="center"
          className={'profile-div-background'}
          style={{ marginTop: 0 }}
        >
          <Grid item>
            <div className="profile-image-holder">
              <img
                src={require('../../assets/logo.png')}
                alt=""
                style={{
                  height: 90,
                  weight: 90,
                  borderRadius: 60,
                }}
              ></img>
            </div>
          </Grid>
        </Grid>

        <Grid container justify="center" style={{ padding: '0px 20px' }}>
          <Grid item xs={12} md={7} lg={7} style={{ marginTop: 0 }}>
            <h2
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontSize: '28px',
                fontWeight: 400,
                marginTop: 0,
                textAlign: 'center',
              }}
            >
              Select a user type to continue
            </h2>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              padding: '0px 10px',
              marginBottom: 10,
              border: '2px solid gray',
            }}
          >
            <Grid
              container
              justify="center"
              style={{ textAlign: 'center', paddingTop: 10 }}
            >
              <div
                className="user-type-circle-wrapper"
                style={{
                  padding: '0px 0px',
                  borderRadius: '100%',
                  background: 'transparent',
                  border: `2px solid gray`,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={require('../../assets/icons/lifestyle.png')}
                  alt=""
                  className="user-type-image"
                />
              </div>
            </Grid>
            <p
              style={{
                textAlign: 'center',
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Pinger
            </p>
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass=""
              expanded={false}
            >
              <p style={{ textAlign: 'left' }}>
                You are a PINGER if you are a qualified, vetted fitness
                professional.{' '}
              </p>
              <ul>
                <li style={{ textAlign: 'left' }}>
                  You join us because you want to partIcipate in a community of
                  like minded professionals wishing to demonstrate the latest
                  and safest exercise moves that are effective and loved by your
                  own clients.
                </li>
                <li style={{ textAlign: 'left' }}>
                  Your short (Quick Peeks) demo submissions will be rated and
                  commented on only by other Pingers so you can benefit from
                  knowledgeable input as you try out your new routines etc.
                </li>
                <li style={{ textAlign: 'left' }}>
                  Those highly rated “Quick Peeks” video submissions will be
                  reviewed by our team of experts. It is then possible that we
                  will invite you to submit longer workouts and/or courses for
                  future revenue sharing.
                </li>
                <li style={{ textAlign: 'left' }}>
                  As a Pinger you will receive various exciting incentives and
                  rewards from fitness companies.
                </li>
              </ul>
            </ShowMoreText>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 10 }}>
              <div style={{ textDecoration: 'none', textAlign: 'center' }}>
                <Button
                  className="btn-purple btn-purple-highligted"
                  style={{ width: '50%' }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUserType('pinger');
                  }}
                >
                  Watch a Preview
                </Button>
                <Button
                  className="btn-purple"
                  style={{ width: '42%', margin: 5 }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUser('pinger');
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              padding: '0px 10px',
              marginBottom: 10,
              border: '2px solid gray',
            }}
          >
            <Grid
              container
              justify="center"
              style={{ textAlign: 'center', paddingTop: 10 }}
            >
              <div
                className="user-type-circle-wrapper"
                style={{
                  padding: '0px 0px',
                  borderRadius: '100%',
                  background: 'transparent',
                  border: `2px solid gray`,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={require('../../assets/icons/investor.png')}
                  alt=""
                  className="user-type-image"
                />
              </div>
            </Grid>
            <p
              style={{
                textAlign: 'center',
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Ponger
            </p>
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass=""
              expanded={false}
            >
              <p style={{ textAlign: 'left' }}>
                You are a PONGER if you are a company wishing to reach these
                pinger influencers globally.{' '}
              </p>
              <ul>
                <li style={{ textAlign: 'left' }}>
                  You join us because you want to offer your best incentives
                  ("Pongs") on your products and services directly to Pingers
                  and Enthusiasts in unique ways that blockchain technology can
                  provide.
                </li>
                <li style={{ textAlign: 'left' }}>
                  You can see the ROI on your offer and keep track of valuable
                  Pinger influencer input. The possibilities are endless.
                </li>
              </ul>
            </ShowMoreText>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 10 }}>
              <div style={{ textDecoration: 'none', textAlign: 'center' }}>
                <Button
                  className="btn-purple btn-purple-highligted"
                  style={{ width: '50%' }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUserType('ponger');
                  }}
                >
                  Watch a Preview
                </Button>
                <Button
                  className="btn-purple"
                  style={{ width: '42%', margin: 5 }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUser('ponger');
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ padding: '0px 10px', border: '2px solid gray' }}
          >
            <Grid
              container
              justify="center"
              style={{ textAlign: 'center', paddingTop: 10 }}
            >
              <div
                className="user-type-circle-wrapper"
                style={{
                  padding: '0px 0px',
                  borderRadius: '100%',
                  background: 'transparent',
                  border: `2px solid gray`,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={require('../../assets/icons/patient.png')}
                  alt=""
                  className="user-type-image"
                />
              </div>
            </Grid>
            <p
              style={{
                textAlign: 'center',
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Enthusiast
            </p>
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass=""
              expanded={false}
            >
              <p style={{ textAlign: 'left' }}>
                You are an ENTHUSIAST if you are neither an instructor or a
                company. While THE AB LAB TEAM is collecting thousands of
                workouts, tips and demos for future viewing, we invite you now
                to post your "GIGGLE" video showing how you are (or aren't)
                strengthening your core during covid lockdown!
              </p>
            </ShowMoreText>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 10 }}>
              <div style={{ textDecoration: 'none', textAlign: 'center' }}>
                <Button
                  className="btn-purple btn-purple-highligted"
                  style={{ width: '50%' }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUserType('basic');
                  }}
                >
                  Watch a Preview
                </Button>
                <Button
                  className="btn-purple"
                  style={{ width: '42%', margin: 5 }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUser('basic');
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ padding: '0px 10px', border: '2px solid gray' }}
          >
            <Grid
              container
              justify="center"
              style={{ textAlign: 'center', paddingTop: 10 }}
            >
              <div
                className="user-type-circle-wrapper"
                style={{
                  padding: '0px 0px',
                  borderRadius: '100%',
                  background: 'transparent',
                  border: `2px solid gray`,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={require('../../assets/icons/patient.png')}
                  alt=""
                  className="user-type-image"
                />
              </div>
            </Grid>
            <p
              style={{
                textAlign: 'center',
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              MPK
            </p>
            <ShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass=""
              expanded={false}
            >
              <p style={{ textAlign: 'left' }}>
                You are an MPK if you are neither an instructor or a company.
                While THE AB LAB TEAM is collecting thousands of workouts, tips
                and demos for future viewing, we invite you now to post your
                "GIGGLE" video showing how you are (or aren't) strengthening
                your core during covid lockdown!
              </p>
            </ShowMoreText>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 10 }}>
              <div style={{ textDecoration: 'none', textAlign: 'center' }}>
                <Button
                  className="btn-purple btn-purple-highligted"
                  style={{ width: '50%' }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUserType('mpk');
                  }}
                >
                  Watch a Preview
                </Button>
                <Button
                  className="btn-purple"
                  style={{ width: '42%', margin: 5 }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.selectUser('mpk');
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.userReducer,
});

export default connect(mapStateToProps)(SelectUserTypePage);
