import React, { Component } from 'react';
import Script from 'react-load-script';
import { TextField } from '@material-ui/core';

class SearchAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.value || ''
        }
        this.addressObj = {};
        this.addressObj.city = '';
        this.addressObj.address = '';
        this.addressObj.state = '';
        this.addressObj.zip = '';
        this.addressObj.zip = '';
        this.addressObj.lat = '';
        this.addressObj.lng = '';
    }

    handleScriptLoad = () => {
        const options = {
            types: ['geocode'],
        };
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options,
        );
        this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

    }

    handlePlaceSelect = () => {
        const place = this.autocomplete.getPlace();
        const address = place.address_components;
        let lat, lng;
        if (place.geometry) {
            lat = place.geometry.location.lat();
            lng = place.geometry.location.lng();
        }
        if (address) {
            let addressObj = {};
            let country_short = null;
            let state_short = null;
            address.forEach((addr) => {
                if (addr.types.includes("administrative_area_level_1")) {
                    Object.assign(addressObj, { state: addr.long_name });
                    if (addr.short_name && !state_short) state_short = addr.short_name;
                }
                if (addr.types.includes("administrative_area_level_2")) {
                    Object.assign(addressObj, { city: addr.short_name });
                }
                if (addr.types.includes("country")) {
                    Object.assign(addressObj, { country: addr.long_name });
                    if (addr.short_name && !country_short) country_short = addr.short_name;
                }
                if (addr.types.includes("postal_code")) {
                    Object.assign(addressObj, { zip: addr.long_name });
                }
            });

            let formatedAddress = place.formatted_address.replace(new RegExp(addressObj.country), "");
            formatedAddress = formatedAddress.replace(new RegExp(addressObj.state), "");
            if (country_short) {
                formatedAddress = formatedAddress.replace(new RegExp(country_short), "");
                formatedAddress = formatedAddress.replace(new RegExp(country_short + ' '), "");
            }
            if (state_short) {
                formatedAddress = formatedAddress.replace(new RegExp(state_short + ','), "");
                formatedAddress = formatedAddress.replace(new RegExp(state_short + ' '), "");
            }
            formatedAddress = formatedAddress.replace(new RegExp(addressObj.zip), "");
            formatedAddress = formatedAddress.replace(new RegExp(addressObj.city), "");
            formatedAddress = formatedAddress.replace(/ ,/g, '');
            formatedAddress = formatedAddress.trim().replace(/.$/g, '');
            formatedAddress = formatedAddress.replace(/, $/g, '');

            Object.assign(addressObj, { address: formatedAddress });
            Object.assign(addressObj, { lat: lat });
            Object.assign(addressObj, { lng: lng });
            this.props.onSelect(addressObj);
            this.setState({ address: addressObj.address });
        }
    }

    render() {
        return (
            <div>
                <Script url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
                    onLoad={this.handleScriptLoad}
                    async defer
                />
                <TextField
                    id="autocomplete"
                    label="Address"
                    name="address"
                    type="text"
                    autoFocus={this.props.autoFocus || false}
                    onChange={event => {
                        this.setState({ address: event.target.value });
                        this.addressObj.address = event.target.value;
                        this.props.onChange(this.addressObj);
                    }}
                    value={this.state.address}
                    style={{ width: '100%' }}
                    error={this.props.error}
                    onBlur={this.props.blur}
                    helperText={this.props.helperText}
                    required />
            </div>
        );
    }
}

export default SearchAddress;