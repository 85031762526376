import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import loadjs from 'loadjs';
import LoungeWowzaPlayer from "../core/LoungeWowzaPlayer";
import OverlayLoader from "../core/OverlayLoader";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import TakeawayService from "../../services/TakeawayService";
import ChatBox from "../core/ChatBox";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class AbLabLoungePage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        sort: false,
        search: false,
        back: true,
        title: "",
        static: true,
      },
    });
    this.state = {
      lounge: {},
      loader: false,
      takeaway: {},
      viewTakeawayModal: false,
      openSnackBar: false,
      isSuccess: false,
      message: "",
      open: false,
      showLiveStreamVideo: false,
      showChatBox: false,
    };
    this.viewTakeawayModalToggle = this.viewTakeawayModalToggle.bind(this);
  }

  viewTakeawayModalToggle() {
    this.setState({ viewTakeawayModal: !this.state.viewTakeawayModal });
  }

  async componentDidMount() {
    let user = await AuthService.getAuthUser();
    if (!user) this.props.history.replace("/login");
    window.scrollTo(0, 0);
    loadjs([
      process.env.REACT_APP_CLIENT_URL + '/takeaway/widget1.js',
      process.env.REACT_APP_CLIENT_URL + '/takeaway/bag.js'
    ]);
    this.getLounge(true);
  }

  async collectTakeaway() {
    try {
      this.setState({ loader: true });
      let res = await TakeawayService.collect({
        _id: this.state.takeaway && this.state.takeaway._id,
      });
      let res1 = await UserService.getAuthUser();
      AuthService.setAuthUser(res1.user);
      this.viewTakeawayModalToggle();
      this.getLounge(false);
      this.setState({
        openSnackBar: true,
        isSuccess: true,
        message: res.message,
      });
      this.props.history.replace("/abLabLounge");
      this.setState({ loader: false });
    } catch (error) {
      this.setState({ openSnackBar: true, isSuccess: false, message: error });
    }
  }

  getLounge = async (onload) => {
    try {
      this.setState({ loader: true });
      let res = await TakeawayService.search({});
      this.setState({ loader: false });
      this.setState({ lounge: res });
      if (onload) {
        this.setState({ showLiveStreamVideo: true });
        setTimeout(() => {
          this.setState({ showChatBox: true });
        }, 10000);
      }
    } catch (e) {
      await this.setState({ loader: false });
    }
  };

  render() {
    let user = AuthService.getAuthUser();
    let lounge = this.state.lounge;
    let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
    let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
    return (
      <React.Fragment>
        <Grid xs={12} item container spacing={0} style={{ justifyContent: "center" }}>
          {this.state.loader && <OverlayLoader />}

          <Grid
            item
            xs={10}
            sm={10}
            md={5}
            lg={5}
            spacing={2}
            style={{
              position: "relative",
              width: "100%",
              height: "calc(80vh - 100px)",
              marginTop: "-10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            className="video-mockup"

          >
            {lounge && lounge.selectedType == "post" && (
              <ReactPlayer
                url={
                  lounge.selectedLounge.wowzaVideoKey
                    ? process.env.REACT_APP_WOWZA_HLS_URL +
                    "/vod/mp4:" +
                    lounge.selectedLounge.wowzaVideoKey +
                    "_transcoded.mp4/playlist.m3u8"
                    : `${imageBaseUrl}/${lounge.selectedLounge._id}/${lounge.selectedLounge._id}_video${lounge.selectedLounge.videoFileExt}`
                }
                playing
                playsinline
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      autoPlay: true,
                      muted: true,
                    },
                  },
                }}
                height="auto"
                controls={true}
                style={{
                  overflow: "hidden",
                  margin: 0,
                  width: "80% !important",
                  height: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              />
            )}
            {lounge && lounge.selectedType == "workoutClass" ? (
              lounge.selectedLounge.status === "live" ? (
                <div
                  style={{
                    overflow: "hidden",
                    margin: 0,
                    position: "absolute",
                    height: "450px"
                  }}
                >
                  {this.state.showLiveStreamVideo && (
                    <LoungeWowzaPlayer
                      showCastToTV={false}
                      streamName={lounge.selectedLounge.owner.wowzaStreamKey}
                      hlsKey={lounge.selectedLounge.owner.wowzaHlsKey}
                      applicationName={lounge.selectedLounge.owner._id}
                    />
                  )}
                  {this.state.showChatBox && <ChatBox user={user}></ChatBox>}
                </div>
              ) : (
                  <h2
                    style={{
                      overflow: "hidden",
                      margin: 0,
                      position: "absolute",
                      top: "40%",
                      color: "white",
                      paddingLeft: 20,
                    }}
                  >
                    Live session will start shortly please visit after sometime.
                  </h2>
                )
            ) : (
                ""
              )}
          </Grid>
        </Grid>
        <div style={{
            left: 0,
            width: '80px',
            height: '80px',
            position: 'absolute',
            padding: '8% 0px 0px 8%'
          }}>
            <div id="theablab-first-takeaway-container"></div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
});

export default connect(mapStateToProps)(AbLabLoungePage);
