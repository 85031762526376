import * as Server from "../Server";

const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const UserService = {
  getAuthUser: async () => {
    try {
      let response = await Server.request({
        url: "/user/me",
        method: "GET",
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  toggleFavoritePost: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/toggleFavorite/${obj.postId}`,
        method: "PUT",
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getNotifications: async (obj) => {
    try {
      let response = await Server.request({
        url: "user/notifications",
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getFavoritePosts: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/myFavorites`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  changePassword: async (obj) => {
    try {
      let response = await Server.request({
        url: "/user/changePassword",
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getMyFeeds: async (obj) => {
    try {
      let response = await Server.request({
        url: "/user/myFeeds",
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  updateNotificationSetting: async (obj) => {
    try {
      let response = await Server.request({
        url: "/user/updateNotificationSetting",
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  deleteUser: async () => {
    try {
      let response = await Server.request({
        url: "/user/remove",
        method: "DELETE",
        data: {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  logout: async () => {
    try {
      let response = await Server.request({
        url: "/user/logout",
        method: "POST",
        data: {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  updateProfile: async (user) => {
    try {
      let response = await Server.request({
        url: "/user/updateProfile",
        method: "POST",
        data: user || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  uploadProfilePic: async (obj) => {
    const formData = await new FormData();
    await formData.append("media", obj.media);
    try {
      let response = await Server.request({
        url: "/user/uploadProfilePic",
        method: "POST",
        data: formData || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  uploadResume: async (obj) => {
    const formData = await new FormData();
    await formData.append("media", obj.media);
    try {
      let response = await Server.request({
        url: "/user/uploadResume",
        method: "POST",
        data: formData || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  followPinger: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/followPinger`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  contactUs: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/contactUs`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  updateWebPushToken: async (obj) => {
    if (!("serviceWorker" in navigator))
      throw Error("Service worker not supported.");
    if (!("PushManager" in window)) throw Error("Web push not supported.");
    let registration = await navigator.serviceWorker.getRegistration(
      process.env.REACT_APP_CLIENT_URL
    );
    if (!registration) throw Error("Registration not found.");
    let applicationServerKey = urlB64ToUint8Array(
      process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY
    );
    if (!applicationServerKey) throw Error("Web push public key not decoded");
    let subscription = await registration.pushManager.subscribe({
      applicationServerKey,
      userVisibleOnly: true,
    });
    if (!subscription) throw Error("Subscription not found.");
    try {
      let response = await Server.request({
        url: "/user/updateWebPushToken",
        method: "post",
        data: { webPushToken: subscription, token: obj.token } || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getNearbyMpkUsers: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/mpk/nearby`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  addMpkCertificate: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/mpk/certificate`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getMpkDashboard: async (obj) => {
    try {
      let response = await Server.request({
        url: `/user/mpk/dashboard`,
        method: "POST",
        data: obj || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
};

export default UserService;
