/**
 * Overlay Loader
 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const OverlayLoader = () => (
    <div className="overlay-loader" style={{zIndex:2100}}>
        <CircularProgress />
    </div>
);

export default OverlayLoader;