import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import AuthService from "../../services/AuthService";

const Basic = (props) => {
  let history = useHistory();
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Please enter email";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
          localStorage.setItem("emailAddress", values.email);
          history.replace("/selectUserType");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid container style={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={7}
                lg={7}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Email Address"
                  type="email"
                  name="email"
                  error={errors.email && touched.email ? true : false}
                  defaultValue={values.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <div style={{ textDecoration: "none", padding: 10 }}>
                  <Button
                    className="btn-purple btn-purple-highligted"
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Continue
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

class LoginSignup extends Component {
  constructor(props) {
    super(props);
    this.hidemenu();
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
  }

  hidemenu() {
    this.props.dispatch({ type: "SHOW", data: false });
    this.props.dispatch({ type: "SHOW_TOOLBAR", data: false });
  }

  render() {
    return (
      <div>
        <Grid
          container
          justify="center"
          className={"profile-div-background"}
          style={{ background: "white" }}
        >
          <Grid item>
            <div className="profile-image-holder">
              <img
                src={require("../../assets/logo.png")}
                alt=""
                style={{
                  height: 90,
                  weight: 90,
                  borderRadius: 60,
                }}
              ></img>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{ textAlign: "center", background: "white" }}
        >
          <Grid item xs={12} md={12} lg={12} style={{ padding: "0px 10px" }}>
            <h2
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontSize: 26,
                fontWeight: 400,
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Hello!
            </h2>
            <p
              style={{
                textAlign: "left",
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 200,
              }}
            >
              By now you have surely heard of blockchain technology but never
              thought it could help the fitness industry and all its
              participants in delivering safe fitness services and products from
              around the world.
            </p>
            <p
              style={{
                textAlign: "left",
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 200,
              }}
            >
              THE AB LAB (r) dAPP has created a digital multi player “ping pong
              like“ adventure where all participants can connect with one
              another to send and receive the latest in content, trends,
              workouts, products and other opportunities including a chance to
              profit after joining the industry’s first unified fitness
              community movement.
            </p>
            <p
              style={{
                textAlign: "left",
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: "'Roboto', sans-serif",
                fontSize: 14,
                fontWeight: 200,
              }}
            >
              We invite all fitness professionals, fitness enthusiasts and
              fitness companies to join us at THE AB LAB dAPP and see what we
              have in store for you!
            </p>
          </Grid>
          <Grid container justify="center" style={{ padding: "0px 20px" }}>
            <Basic props={this.props} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(LoginSignup);
