import React, { useEffect } from "react";
import "./App.css";
import AppLayout from "./components/core/AppLayout";
import { createTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import store from "./store/store";
import { Provider } from "react-redux";
import HttpsRedirect from "react-https-redirect";

const THEME = createTheme({
  typography: {
    fontFamily: `GilroyLight, sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function App() {
  return (
    <HttpsRedirect>
      <MuiThemeProvider theme={THEME}>
        <Provider store={store}>
          <Grid container style={{ justifyContent: "center" }}>
            <AppLayout />
          </Grid>
        </Provider>
      </MuiThemeProvider>
    </HttpsRedirect>
  );
}

export default App;
