import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import OverlayLoader from './OverlayLoader';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import TakeawayService from '../../services/TakeawayService';

let selectedTakeawayToTransfer = '';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function TakeawayDialog(props) {
    let { onClose, open } = props;
    const [openTransferDailog, setOpenTransferDailog] = useState(false);
    const [email, setEmail] = useState('');
    const [showLoader, setLoader] = useState(false);
    const [openSnackbar, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [takeaways, setTakeaways] = useState(props.takeaways);
    const [isSuccess, setMessageStatus] = useState(false)

    const handleClose = (value) => {
        onClose(value);
    };

    const handleTransferDailog = (takeaway) => {
        selectedTakeawayToTransfer = takeaway;
        setOpen(false);
        setOpenTransferDailog(true);
    }

    const handleTransferClose = () => {
        setOpenTransferDailog(false);
    }

    const handleEmailChange = (email) => {
        setEmail(email);
    }

    const handleTransfer = async () => {
        if (!email) {
            setMessageStatus(false);
            setMessage('Please enter email');
            setOpen(true);
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
        ) {
            setMessageStatus(false);
            setMessage('Invalid email address');
            setOpen(true);
        } else {
            try {
                setLoader(true);
                let body = {
                    _id: selectedTakeawayToTransfer._id,
                    email: email
                }
                let response = await TakeawayService.transfer(body);
                let userResponse = await UserService.getAuthUser();
                setLoader(false);
                setOpenTransferDailog(false);
                AuthService.setAuthUser(userResponse.user);
                let user = AuthService.getAuthUser();
                setTakeaways(user.takeaways);
                setMessageStatus(true);
                setMessage(response.message);
                setOpen(true);
                setEmail('');
            } catch (error) {
                setOpenTransferDailog(false);
                setLoader(false);
                setMessageStatus(false);
                setMessage(error.message);
                setOpen(true);
            }
        }
    }

    return (
        <div>
            <Dialog onClose={() => handleClose(false)} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                    My Takeaways
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                        {
                            takeaways && takeaways.length ?
                                takeaways.map((takeaway, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                            <Card style={{ boxShadow: 'none' }}>
                                                <CardHeader
                                                    style={{ textAlign: 'left', paddingBottom: 5 }}
                                                    avatar={
                                                        <div>
                                                            <img
                                                                src={`${process.env.REACT_APP_SERVER_URL}/media/takeaways/${takeaway._id}/coverPhoto${takeaway.coverPhotoExt}`}
                                                                style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                alt="Takeaway cover photo"
                                                            />
                                                        </div>
                                                    }
                                                    action={
                                                        <div style={{ width: '100%', display: 'inline-flex' }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleTransferDailog(takeaway)}
                                                                style={{
                                                                    width: 50,
                                                                    backgroundColor: '#9013fe',
                                                                    fontSize: '0.7rem',
                                                                    marginTop: '7px',
                                                                    float: 'right',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                Transfer
                                                            </Button>
                                                        </div>
                                                    }
                                                    title={
                                                        <span style={{ fontSize: '16px', fontWeight: 400 }}>
                                                            {takeaway.title}
                                                        </span>
                                                    }
                                                    subheader={takeaway.message}
                                                />
                                            </Card>
                                        </Grid>
                                    )
                                }) :
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Card elevation={0}>
                                        <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                            <div style={{ textAlign: 'center' }}>No takeaways found.</div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                        }
                    </Grid>
                </DialogContent>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={() => setOpen(false)}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => setOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
            </Dialog>
            <Dialog open={openTransferDailog} maxWidth={'md'} onClose={() => handleTransferClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Transfer Takeaway</DialogTitle>
                <DialogContent>
                    <TextField
                        id="standard-basic"
                        label="Email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(event) => handleEmailChange(event.target.value)}
                        style={{ width: '100%' }} required />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleTransferClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleTransfer()} color="primary">
                        Transfer
                    </Button>
                </DialogActions>
                {
                    showLoader &&
                    <OverlayLoader />
                }
            </Dialog>

        </div>
    );
}

export default TakeawayDialog