import * as Server from "../Server";

const ServiceService = {
  addService: async (body) => {
    try {
      let response = await Server.request({
        url: "/service",
        method: "POST",
        data: body,
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
  updateService: async (id, body) => {
    try {
      let response = await Server.request({
        url: `/service/${id}`,
        method: "PUT",
        data: body,
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
  getServicesByUser: async (body = {}) => {
    try {
      let response = await Server.request({
        url: "/service/search",
        method: "POST",
        data: body,
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
  deleteService: async (id) => {
    try {
      let response = await Server.request({
        url: `/service/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
};

export default ServiceService;
