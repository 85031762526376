import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ContactRequestService from "../../services/ContactRequestService";
import AppSnackbar from "../core/AppSnackbar";
import OverlayLoader from "../core/OverlayLoader";

const fields = [
  {
    label: "Name",
    field: "fullName",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Phone",
    field: "phoneNumber",
  },
];

const ContactRequestsPage = () => {
  const [contactRequests, setContactRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requestDetailsModal, setRequestDetailsModal] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        title: "Contact Requests",
      },
    });
    getContactRequestsByUser();
  }, [dispatch]);

  const getContactRequestsByUser = async (newPage = 0, newRows = 10) => {
    try {
      setLoading(true);
      const res = await ContactRequestService.getContactRequestsByUser({
        page: newPage + 1,
        limit: newRows,
      });
      if (res && res.results && Array.isArray(res.results)) {
        setContactRequests(
          res.results.map((item) => ({
            ...item,
            fullName: `${item.firstName} ${
              item.middleName ? item.middleName + " " : " "
            }${item.lastName}`,
          }))
        );
        setTotal(res.total);
      } else {
        setContactRequests([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(error.message);
    }
  };

  const notify = (msg, success = false) => {
    setIsSuccess(success);
    setMessage(msg || "Something went wrong, Try again");
    setSnackbar(true);
  };

  const viewRequestDetails = (request, e) => {
    e.preventDefault();
    setSelectedRequest(request);
    setRequestDetailsModal(true);
  };

  const closeRequestDetailsModal = () => {
    setRequestDetailsModal(false);
    setSelectedRequest(null);
  };

  const handleChangePage = (event, newPage, newRows) => {
    setPage(newPage);
    getContactRequestsByUser(newPage, newRows);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    handleChangePage(event, 0, +event.target.value);
  };

  return (
    <Grid
      container
      style={{ justifyContent: "center" }}
      className={"contentHolderContainer-90vh"}
    >
      <Grid item xs={12} sm={12} md={11} lg={11}>
        {loading && <OverlayLoader />}
        <Card
          style={{
            boxShadow: "none",
            padding: "15px",
          }}
        >
          {total ? (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      {fields.map((item, index) => (
                        <TableCell key={item.field + index}>
                          {item.label}
                        </TableCell>
                      ))}
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contactRequests.map((obj) => (
                      <TableRow
                        key={obj._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {fields.map((item, index) => (
                          <TableCell key={item.field + index}>
                            <p className="shrink-text" title={obj[item.field]}>
                              {obj[item.field] || " - "}
                            </p>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Link
                            onClick={(e) => viewRequestDetails(obj, e)}
                            style={{ textDecoration: "none" }}
                            to="#"
                          >
                            View
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={total || 0}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <div
              style={{ textAlign: "center", paddingBottom: 30, paddingTop: 10 }}
            >
              No contact requests found
            </div>
          )}
        </Card>
        {requestDetailsModal && (
          <Dialog aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle color="textPrimary" id="simple-dialog-title">
              <strong className="custom-dialog-title">
                Contact Request Details
              </strong>
            </DialogTitle>
            <DialogContent>
              <Grid style={{ minWidth: 400 }}>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Name: </strong>
                  {`${selectedRequest.fullName}`}
                </DialogContentText>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Email: </strong>
                  {`${selectedRequest.email}`}
                </DialogContentText>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Phone: </strong>
                  {`${selectedRequest.phoneNumber || "-"}`}
                </DialogContentText>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Message: </strong>
                  {`${selectedRequest.message}`}
                </DialogContentText>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={closeRequestDetailsModal}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
      <AppSnackbar
        props={{ message, state: snackbar, setState: setSnackbar, isSuccess }}
      />
    </Grid>
  );
};

export default ContactRequestsPage;
