import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, IconButton, CardHeader, CardMedia, CardContent, Button, Card, Fab, CardActions, AppBar, Tabs, Tab } from '@material-ui/core';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import SearchIcon from '@material-ui/icons/Search';
import OverlayLoader from '../core/OverlayLoader';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import CategoryService from '../../services/CategoryService';
import PostService from '../../services/PostService';
import Rating from 'material-ui-rating';
import ShareDialog from '../core/Share';
import AlertDialog from '../core/Disclamer';
import MenuComponent from '../core/MenuComponent';
import moment from 'moment';
class AccessVideosPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: false,
                title: 'Access Videos',
                static: false
            }
        });
        this.state = {
            loader: false,
            selectedTab: 0,
            openAlertDialog: false,
            filter: {
                type: 'giggles',
                name: null
            },
            tabs: [],
            setPlayVideoId: 0,
            sort: {
                createdAt: -1
            },
            page: 1,
            limit: 20,
            pages: 0,
            totalPosts: 0,
            posts: [],
            categories: [],
            allCategories: [],
            openShareDialog: false,
            shareTitle: '',
            shareUrl: '',
            searchName: ''
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getAuthUser();
        this.getCategories();
        this.getGiggleByFilter(false);
        this.triggerLoggedInEventForMobileNative();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps && nextProps.toolBar.selectedSort !== null) {
            this.sortByPosts(nextProps.toolBar.selectedSort, nextProps.toolBar.selectedSortValues[nextProps.toolBar.selectedSort]);
        }
    }

    getMenuList = () => {
        const user = AuthService.getAuthUser();
        const menuItems = [
            {
                title: (this.props.history.location.pathname === '/myFavorites') ? 'Recent' : 'Date',
                value: 'date',
                show: true
            },
            {
                title: 'Rating',
                value: 'rating',
                show: (this.props.history.location.pathname.includes('/feeds') || this.props.history.location.pathname.includes('/accessVideos')) ? true : false
            },
            {
                title: 'Likes',
                value: 'likes',
                show: ((this.props.history.location.pathname.includes('/feeds') || this.props.history.location.pathname.includes('/accessVideos')) && (user && user.accountType === 'pinger')) ? true : false
            },
            {
                title: 'Comments',
                value: 'comments',
                show: ((this.props.history.location.pathname.includes('/feeds') || this.props.history.location.pathname.includes('/accessVideos')) && (user && user.accountType === 'pinger')) ? true : false
            },
            {
                title: 'Name',
                value: 'title',
                show: (this.props.history.location.pathname.includes('/myFavorites')) ? true : false
            },
        ];
        return menuItems;
    }

    getAuthUser = async () => {
        try {
            let res = await UserService.getAuthUser();
            AuthService.setAuthUser(res.user);
            this.props.dispatch({ type: 'SET_USER', data: res.user });
        } catch (e) {
            let user = await AuthService.getAuthUser();
            this.props.dispatch({ type: 'SET_USER', data: user });
        }
    }

    triggerLoggedInEventForMobileNative = async () => {
        if (window.webkit) {
            if (window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler) {
                window.webkit.messageHandlers.jsMessageHandler.postMessage('LoggedIn');
            }
        }
        if (window.JSBridge) {
            window.JSBridge.LoggedIn({ message: 'LoggedIn' });
        }
    }

    getCategories = async () => {
        try {
            this.setState({ loader: true });
            let res = await CategoryService.getAllCategories();
            this.setState({ loader: false });
            let tabs = [];
            for (let cat of res.categories) {
                if (cat._id != 'workoutclasses') {
                    tabs.push({
                        _id: cat._id,
                        name: cat.name,
                        disabled: cat.disabled
                    });
                }
            }
            this.setState({ categories: res.categories[this.state.selectedTab].cats, allCategories: res.categories, tabs: tabs });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    getGiggleByFilter = async (isPaginated) => {
        this.setState({ loader: true });
        let obj = {
            filter: this.state.filter,
            sort: this.state.sort,
            page: this.state.page,
            limit: this.state.limit
        }
        try {
            let res = await PostService.getPostByFilter(obj);
            this.setState({ loader: false });
            let posts = this.state.posts;
            let post;
            res.posts.forEach((post) => {
                let index = this.state.posts.findIndex((obj) => obj._id === post._id);
                if (index != -1) {
                    posts[index] = post;
                } else {
                    posts.push(post);
                }
            });
            this.setState({ posts: isPaginated ? posts : res.posts, totalPosts: res.totalPosts, pages: res.pages });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getGiggleByFilter(true);
        });
    }

    sortByPosts = async (sortBy, value) => {
        if (sortBy === 'date') {
            this.setState({ sort: { createdAt: -1 }, page: 1 }, () => { this.getGiggleByFilter(false); });
        } else if (sortBy === 'rating') {
            this.setState({ sort: { ratings: value ? 1 : -1 }, page: 1 }, () => { this.getGiggleByFilter(false); });
        } else if (sortBy === 'likes') {
            this.setState({ sort: { likes: value ? 1 : -1 }, page: 1 }, () => { this.getGiggleByFilter(false); });
        } else if (sortBy === 'comments') {
            this.setState({ sort: { comments: value ? 1 : -1 }, page: 1 }, () => { this.getGiggleByFilter(false); });
        } else {
            this.setState({ sort: { createdAt: -1 }, page: 1 }, () => { this.getGiggleByFilter(false); });
        }
    }

    selectCategory(category, type) {
        localStorage.setItem('Category', JSON.stringify(category));
        this.props.dispatch({ type: 'SET_CAT', category: category });
        localStorage.setItem('Type', JSON.stringify(type));
        this.props.dispatch({ type: 'SET_TYPE', tab: type });
        if (category._id == 'All') {
            localStorage.setItem('SubCategory', JSON.stringify({}));
            this.props.dispatch({ type: 'SET_SUB_CAT', subCategory: {} });
            this.props.history.push(`/feeds/${category._id}/all`);
        } else {
            this.props.history.push('/feeds/' + category._id);
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState((prevState) => ({
            ...prevState,
            selectedTab: newValue
        }));
        this.props.dispatch({ type: 'SET_TYPE', tab: this.state.tabs[newValue] });
        this.setState({ categories: this.state.allCategories[newValue].cats });
    }

    handleToggleLike = async (post) => {
        try {
            this.setState({ loader: true });
            let res = await PostService.postLike({ postId: post._id });
            if (res.post) {
                let posts = this.state.posts;
                let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                posts[postIndex].likes = res.post.likes;
                posts[postIndex].likesCount = res.post.likes.length;
                this.setState({ posts: posts });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsLiked = (post) => {
        let user = AuthService.getAuthUser();
        let liked = post.likes.findIndex(_id => _id == user._id);
        if (liked != -1) return true;
        return false;
    }

    handleToggleFavorite = async (post) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.toggleFavoritePost({ postId: post._id });
            AuthService.setAuthUser(res.user);
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFavorite = (post) => {
        let user = AuthService.getAuthUser();
        let favorite = user.favoritePostIds.findIndex(obj => obj._id === post._id);
        if (favorite != -1) return true;
        return false;
    }

    checkIsRated = (post) => {
        let user = AuthService.getAuthUser();
        let rated = post.ratings.findIndex((rating) => rating._id == user._id);
        if (rated != -1) return true;
        return false;
    }

    handleRate = async (post, rating) => {
        try {
            this.setState({ loader: true }, async () => {
                let res = await PostService.postRate({ postId: post._id, rating: rating });
                if (res.post) {
                    let posts = this.state.posts;
                    let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                    posts[postIndex].ratings = res.post.ratings;
                    this.setState({ posts: posts });
                }
                this.setState({ loader: false });
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFlagged = (post) => {
        let user = AuthService.getAuthUser();
        if (post.flags.length && post.flags.includes(user._id)) {
            return true;
        } else {
            return false;
        }
    }

    handelFlag = async (post, rating) => {
        try {
            this.setState({ loader: true }, async () => {
                let res = await PostService.flagPost({ postId: post._id, rating: rating });
                if (res.post) {
                    let posts = this.state.posts;
                    let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                    posts[postIndex].flags = res.post.flags;
                    this.setState({ posts: posts });
                }
                this.setState({ loader: false });
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    playVideo() {
        this.setState({ playId: this.state.setPlayVideoId });
    }

    setAlertOpenDialog = (isOpen, id) => {
        this.setState({ openAlertDialog: isOpen, setPlayVideoId: id });
    }

    setRating = (post) => {
        let user = AuthService.getAuthUser();
        let ratingObj = post.ratings.find((rating) => rating._id == user._id);
        if (ratingObj) return ratingObj.rating;
        return 0;
    }

    getAvarageRating = (post) => {
        let ratingSum = 0;
        for (let ratingObj of post.ratings) {
            ratingSum = ratingSum + ratingObj.rating
        }
        if (!ratingSum) return 0;
        return Math.ceil((ratingSum / post.ratings.length));
    }

    openCommentPage = async (postId, openFrom) => {
        localStorage.setItem("openFrom", openFrom);
        this.props.history.push('/feed/' + postId);
    }

    handleShare = (post) => {
        let shareTitle = '';
        if (post.owner) {
            shareTitle += post.owner.firstName;
            shareTitle += (post.owner.middleName ? ' ' + post.owner.middleName : '');
            shareTitle += (post.owner.lastName ? ' ' + post.owner.lastName : '');
        }
        this.setState({ openShareDialog: true, shareTitle: shareTitle, shareUrl: 'feed/' + post._id + '/commentBox' });
    }


    onPlayVideo(id) {
        this.setAlertOpenDialog(true, id);
    }

    handelAlertDialogClose = (isConfirm) => {
        this.setAlertOpenDialog(false);
        if (isConfirm) {
            this.openCommentPage(this.state.setPlayVideoId, 'videoThumbnail');
        }
    }

    handelShareDialogClose = () => {
        this.setState({ openShareDialog: false, shareTitle: '', shareUrl: '' });
    }

    handelSearch = async () => {
        this.setState({
            page: 1
        }, async () => {
            this.getGiggleByFilter(false);
        });
    }

    render() {
        let { tabs, categories, posts, selectedTab } = this.state;
        let user = AuthService.getAuthUser();
        let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
        let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
        return (
            <div>
                <Grid container className={'contentHolderContainer-90vh'} style={{ justifyContent: 'center', overflow: 'hidden' }}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="sidebar-menu-list-item-profile">
                            <Grid container style={{ background: 'transparent' }}>
                                {
                                    tabs.length ?
                                        <AppBar position="static" elevation={0} style={{ background: 'transparent', zIndex: 1000 }}>
                                            <Tabs value={selectedTab}
                                                onChange={this.handleTabChange}
                                                aria-label="full width tabs example"
                                                variant="fullWidth"
                                                TabIndicatorProps={{ style: { background: 'white', zIndex: '1' } }}
                                            >
                                                {
                                                    tabs.map((tab, key) => {
                                                        return (<Tab label={tab.name} key={key} disabled={tab.disabled} className="tab-bar" style={{ textTransform: 'none' }} />)
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        : ''}
                                <div role='tabpanel' hidden={false} style={{ background: 'white', width: '100%' }}>
                                    <Grid container style={{ padding: 10, overflowY: 'auto' }} spacing={1}>
                                        {
                                            this.state.selectedTab !== 2 ? <Grid container style={{ padding: 20 }} spacing={1}>
                                                {
                                                    this.state.selectedTab === 0 &&
                                                    <p style={{ textAlign: 'justify' }}>Up to 5 minute samples of fitness workouts and / or content submitted by vetted qualified professionals (Pingers). Only other vetted pingers can rate or comment on safety and effectiveness. See exciting new trends as well as tried and true exercise moves.</p>
                                                }
                                                {
                                                    this.state.selectedTab === 1 &&
                                                    <p style={{ textAlign: 'justify' }}>THE AB LAB dAPP admin team may invite pingers with the highest rated Quick Peeks workouts and/or content to prepare lengthier versions for distribution on the THE AB LAB dAPP platform.</p>
                                                }
                                                {
                                                    this.state.selectedTab !== 2 &&
                                                    categories.map((category, key) => {
                                                        return (
                                                            <Grid item xs={4} sm={4} md={4} lg={4} key={key} style={{ cursor: 'pointer', pointerEvents: category.disabled ? 'none' : 'auto' }} onClick={async () => { this.selectCategory(category, { name: tabs[selectedTab].name, id: selectedTab, _id: tabs[selectedTab]._id }) }}>
                                                                <Card elevation={0} >
                                                                    <div style={{ backgroundImage: ` url(${require("../../assets/category/" + category._id + ".png")})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderRadius: 7 }}>
                                                                        <div className="card-overlay" style={{
                                                                            backgroundImage: ``, borderRadius: 7
                                                                        }}></div>
                                                                    </div>
                                                                    <CardActions style={{ justifyContent: 'center' }}>
                                                                        <Button size="small" color="primary" style={{ textTransform: 'capitalize' }} disabled={category.disabled}>
                                                                            {category.name}
                                                                        </Button>
                                                                    </CardActions>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid> : ''
                                        }
                                        <Grid container style={{ padding: 0 }} spacing={1}>
                                            {
                                                this.state.selectedTab === 2 &&
                                                <p style={{ padding: 20, textAlign: 'justify' }}>Up to 5 minutes of fitness bloopers or excuses that fitness professionals can hardly believe came from their clients. Enthusiasts in turn can post their own versions of fitness excuses that we call “giggles”.</p>
                                            }
                                            {
                                                this.state.selectedTab === 2 &&
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Card elevation={0} >
                                                        <CardContent style={{ padding: '10px 0px 15px 15px' }}>
                                                            <Grid container justify="center">
                                                                <Grid item lg={9} md={9} sm={9} xs={7}>
                                                                    <TextField placeholder="Search giggles by pinger" style={{ width: '100%' }} onChange={
                                                                        (event) => this.setState({
                                                                            filter: {
                                                                                ...this.state.filter,
                                                                                name: event.currentTarget.value
                                                                            }
                                                                        })} />
                                                                </Grid>
                                                                <Grid item lg={1} md={1} sm={1} xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <IconButton type="submit" aria-label="search" onClick={() => this.handelSearch()}>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item lg={2} md={2} sm={2} xs={3} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                    <MenuComponent props={this.props} items={this.getMenuList()} type={"titleSort"} />
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            }
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {
                                                    this.state.selectedTab === 2 ?
                                                        posts.length ?
                                                            posts.map((post, key) => {
                                                                return (
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} key={key}>
                                                                        <Card style={{ boxShadow: 'none' }}>
                                                                            <CardHeader
                                                                                style={{ textAlign: 'left' }}
                                                                                avatar={
                                                                                    <div>
                                                                                        <img
                                                                                            src={post.owner && post.owner.profilePic ? post.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                                            style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                                            alt="Post owner"
                                                                                        />
                                                                                    </div>
                                                                                }

                                                                                action={
                                                                                    <MenuComponent props={this.props} items={[{ title: 'Flag', value: this.checkIsFlagged(post) ? 'flagged' : 'unflagged', show: true, }]} type={"myFeedMenu"} selectedItems={(item) => { this.handelFlag(post) }} />
                                                                                }

                                                                                title={
                                                                                    <span style={{ fontSize: '16px', fontWeight: 400 }}>
                                                                                        {post.owner && post.owner.firstName} {post.owner && post.owner.middleName} {post.owner && post.owner.lastName}
                                                                                    </span>
                                                                                }
                                                                                subheader={moment(post.createdAt).format("MM/DD/YYYY HH:mm")}
                                                                            />
                                                                            <Grid container style={{ paddingLeft: '4%', paddingRight: '4%', paddingBottom: 10 }}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ display: 'flex' }}>
                                                                                    {post.title}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <div className="mediaCardOverlay">

                                                                                <Fab aria-label="like" className="overlay-button" onClick={() => { this.onPlayVideo(post._id) }}>
                                                                                    <img alt="" className="overlay-button-img" src={require('../../assets/icons/playbutton.svg')} />
                                                                                </Fab>

                                                                            </div>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={require('../../assets/thumbnail.jpg')}
                                                                                image={post.wowzaVideoKey ? `${thumbnailUrl}/${post.wowzaVideoKey}` : `${imageBaseUrl}/${post._id}/${post._id}_thumbnail.jpeg`}
                                                                                className='mediaCard'
                                                                            />
                                                                            <CardContent style={{ paddingTop: 0 }}>
                                                                            </CardContent>
                                                                            <CardActions disableSpacing >
                                                                                <Grid container style={{ textAlign: 'center' }}>
                                                                                    <React.Fragment>
                                                                                        {user.accountType === 'pinger' &&
                                                                                            <Grid container>
                                                                                                <Grid item xs={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                                                                                                    <Fab size="small" aria-label="share" className="card-bottom-icons" onClick={() => this.openCommentPage(post._id, 'commentBox')}>
                                                                                                        <ChatBubbleOutlineRoundedIcon />
                                                                                                    </Fab>
                                                                                                    <div className="comment-count">
                                                                                                        {post.commentsCount}
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid item xs={4} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                    <Fab className={`like-circle-icon ${this.checkIsLiked(post) ? "MuiFab-primary" : "MuiFab-white"}`} size="small" aria-label="share" onClick={() => this.handleToggleLike(post)}>
                                                                                                        <ThumbUpOutlinedIcon />
                                                                                                    </Fab>
                                                                                                    <div className="comment-count">
                                                                                                        {post.likesCount}
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid item xs={2} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                    <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                                                                        <ShareOutlined onClick={() => this.handleShare(post)} />
                                                                                                    </Fab>
                                                                                                </Grid>
                                                                                                <Grid item xs={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                                                    <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                                                                        {
                                                                                                            this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                                                                : <FavoriteBorderOutlinedIcon />
                                                                                                        }
                                                                                                    </Fab>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        }
                                                                                        {(user.accountType === 'ponger' || user.accountType === 'basic') &&
                                                                                            <Grid container>
                                                                                                <Grid item xs={6} className="star-rating-text-holder-no-border">
                                                                                                    <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} /> {this.getAvarageRating(post)} out of 5
                                                                                    </Grid>
                                                                                                <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                                                    <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                                                                        <ShareOutlined onClick={() => this.handleShare(post)} />
                                                                                                    </Fab>
                                                                                                </Grid>
                                                                                                <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                                                    <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                                                                        {
                                                                                                            this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                                                                : <FavoriteBorderOutlinedIcon />
                                                                                                        }
                                                                                                    </Fab>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                </Grid>
                                                                            </CardActions>
                                                                            {
                                                                                user.accountType === 'pinger' &&
                                                                                <CardActions style={{ padding: '8px 0px' }}>
                                                                                    <Grid container style={{ display: 'contents' }}>
                                                                                        <Grid item xs={7} sm={6} md={6} lg={6} className="star-rating-holder">
                                                                                            <Rating
                                                                                                value={this.setRating(post)}
                                                                                                max={5}
                                                                                                disabled={this.checkIsRated(post)}
                                                                                                iconHovered={<StarRoundedIcon name="rating1" className="ratingIcon" style={{ color: '#6fdf93' }} />}
                                                                                                iconFilled={<StarRoundedIcon name="rating1" style={{ color: this.checkIsRated(post) ? '#6fdf93' : '#22bf45' }} className="ratingIcon" />}
                                                                                                onChange={(value) => {
                                                                                                    this.handleRate(post, value);
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={5} sm={6} md={6} lg={6} className="star-rating-text-holder">
                                                                                            <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} className="ratingIcon" />
                                                                                            <span>{this.getAvarageRating(post)} out of 5</span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </CardActions>
                                                                            }
                                                                        </Card>
                                                                    </Grid>
                                                                )
                                                            })
                                                            : <Grid item lg={11} md={11} sm={12} xs={12}>
                                                                <Card elevation={0}>
                                                                    <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                                                        <div style={{ textAlign: 'center' }}>No giggles found.</div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        : ''
                                                }
                                            </Grid>
                                            {
                                                (this.state.page < this.state.pages && this.state.selectedTab === 2) &&
                                                <Grid container style={{ justifyContent: 'center' }}>
                                                    <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                                                </Grid>
                                            }
                                            <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; and (b) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP."
                                                open={this.state.openAlertDialog} onClose={this.handelAlertDialogClose} />
                                            {
                                                this.state.openShareDialog && this.state.shareUrl &&
                                                <ShareDialog url={this.state.shareUrl} title={this.state.shareTitle} open={this.state.openShareDialog} onClose={this.handelShareDialogClose} />
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer,
    catSubCat: state.catSubCatReducer,
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(AccessVideosPage);