import { Button, Card, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UserService from "../../services/UserService";
import AppSnackbar from "../core/AppSnackbar";
import OverlayLoader from "../core/OverlayLoader";

const SubscriptionsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        title: "Subscriptions",
      },
    });
  }, [dispatch]);

  const notify = (msg, success = false) => {
    setIsSuccess(success);
    setMessage(msg || "Something went wrong, Try again");
    setSnackbar(true);
  };

  const submitForm = async (values) => {
    try {
      setLoading(true);
      setMessage("");
      const res = await UserService.addMpkCertificate(values);
      if (res && res === "OK") {
        setLoading(false);
        notify("Certificate added successfully", true);
      }
    } catch (error) {
      setLoading(false);
      notify(error.message, false);
    }
  };

  return (
    <Grid
      container
      style={{ justifyContent: "center" }}
      className={"contentHolderContainer-90vh"}
    >
      <Grid item xs={12} sm={12} md={11} lg={11}>
        {loading && <OverlayLoader />}
        <Card
          style={{
            boxShadow: "none",
            padding: "15px",
          }}
        >
          <Formik
            enableReinitialize={false}
            initialValues={{
              certificateLink: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.certificateLink) {
                errors.certificateLink = "Please enter certificate link";
              }
              if (
                !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/g.test(
                  values.certificateLink
                )
              ) {
                errors.certificateLink = "Invalid certificate link";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              await submitForm(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}
                  >
                    <TextField
                      id="certificateLink"
                      type="text"
                      style={{ width: "100%" }}
                      name="certificateLink"
                      error={
                        errors.certificateLink && touched.certificateLink
                          ? true
                          : false
                      }
                      label="Certificate Link"
                      value={values.certificateLink}
                      placeholder={"Enter certificate link"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.certificateLink && errors.certificateLink
                      }
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginBottom: 15,
                    }}
                  >
                    <div style={{ textDecoration: "none", marginTop: 15 }}>
                      <Button
                        className="btn-purple"
                        variant="contained"
                        type="submit"
                        color="primary"
                        disableElevation={true}
                      >
                        Add Certificate
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
      </Grid>
      <AppSnackbar
        props={{ message, state: snackbar, setState: setSnackbar, isSuccess }}
      />
    </Grid>
  );
};

export default SubscriptionsPage;
