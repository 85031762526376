import React, { Component, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  IconButton,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";

const Basic = (props) => {
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(
    localStorage.getItem("acceptAgreement") == "Yes" ? true : false
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setMessageStatus] = useState(false);
  return (
    <div>
      <Formik
        initialValues={{
          email: (props.loginDetails && props.loginDetails.email) || "",
          password: (props.loginDetails && props.loginDetails.password) || "",
          agreeTermsConditions:
            (props.loginDetails && props.loginDetails.agreeTermsConditions) ||
            "No",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "Please enter email";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Please enter password";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
          let body = {};
          body = values;
          setDisabled(true);
          setLoader(true);
          try {
            AuthService.setAuthorizationHeader(
              "basic " + process.env.REACT_APP_SERVER_BASE64
            );
            let response = await AuthService.signIn(body);
            AuthService.setVerifyLoginID(response.loginVerificationId);
            setDisabled(false);
            setLoader(false);
            localStorage.removeItem("isFromLogin");
            localStorage.removeItem("loginDetails");
            localStorage.removeItem("acceptAgreement");
            history.replace("/verifyLoginOTP");
          } catch (error) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(error.message);
            setOpen(true);
            resetForm({});
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid container style={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={7}
                lg={7}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Email"
                  type="email"
                  name="email"
                  disabled={disabled}
                  error={errors.email && touched.email ? true : false}
                  defaultValue={values.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                lg={7}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Password"
                  type="password"
                  name="password"
                  disabled={disabled}
                  error={errors.password && touched.password ? true : false}
                  defaultValue={values.password}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password && errors.password}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                lg={7}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <p>
                  <FormControl component="fieldset">
                    <Checkbox
                      name="agreeTermsConditions"
                      defaultChecked={agreeTerms}
                      checked={agreeTerms || false}
                      color="primary"
                      onChange={(event) => {
                        if (event.target.checked) {
                          values.agreeTermsConditions = "Yes";
                          localStorage.setItem("isFromLogin", true);
                          let loginDetails = values;
                          localStorage.setItem(
                            "loginDetails",
                            JSON.stringify(loginDetails)
                          );
                          history.replace("/signupAgreement");
                        } else {
                          values.agreeTermsConditions = "No";
                          localStorage.setItem("acceptAgreement", "No");
                          setAgreeTerms(false);
                        }
                      }}
                      disabled={disabled}
                      style={{
                        display: "inline",
                        padding: "0px",
                        paddingRight: "5px",
                        marginTop: "-2px",
                      }}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </FormControl>
                  I agree to{" "}
                  <span
                    className="blue-links"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={(event) => {
                      values.agreeTermsConditions = "Yes";
                      setAgreeTerms(true);
                      let loginDetails = values;
                      localStorage.setItem(
                        "loginDetails",
                        JSON.stringify(loginDetails)
                      );
                      localStorage.setItem("isFromLogin", true);
                      history.replace("/signupAgreement");
                    }}
                  >
                    {" "}
                    Terms and Conditions{" "}
                  </span>
                </p>
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                {!disabled ? (
                  <div style={{ textDecoration: "none", padding: 10 }}>
                    <Button
                      className="btn-purple"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={!agreeTerms}
                      disableElevation={true}
                    >
                      Login
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="white"
              style={{ color: "white" }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarContent
          style={
            isSuccess
              ? {
                  backgroundColor: "green",
                }
              : { backgroundColor: "red" }
          }
          message={<span id="client-snackbar">{message}</span>}
        />
      </Snackbar>
    </div>
  );
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: "",
        static: false,
      },
    });
    props.dispatch({
      type: "SHOW",
      data: false,
    });
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
  }

  render() {
    let loginDetails = localStorage.getItem("loginDetails")
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : {};
    return (
      <div
        style={{
          justifyContent: "center",
          overflowY: "auto",
          backgroundColor: "white",
        }}
      >
        <Grid container justify="center" className={"profile-div-background"}>
          <Grid item style={{ marginTop: "25% !important" }}>
            <div className="profile-image-holder">
              <img
                src={require("../../assets/logo.png")}
                alt=""
                style={{
                  height: 90,
                  weight: 90,
                  borderRadius: 60,
                }}
              ></img>
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ padding: "0px 20px" }}>
          <Grid item xs={12} md={7} lg={7}>
            <Grid
              container
              justify="center"
              style={{ marginTop: 0, justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ padding: "0px 10px", textAlign: "center" }}
              >
                <h2
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "28px",
                    fontWeight: 400,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  Log-in
                </h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ padding: "0px 20px" }}>
          <Basic loginDetails={loginDetails} props={this.props} />
        </Grid>
        <Grid container justify="center" style={{ marginTop: "10px" }}>
          <span
            className="blue-links"
            style={{
              fontSize: 15,
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 200,
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.history.replace("/forgot");
            }}
          >
            Forgot Password?
          </span>
        </Grid>
        <Grid container justify="center" style={{ marginTop: "10px" }}>
          <p
            style={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: 15,
              fontWeight: 200,
              marginTop: 0,
            }}
          >
            Don't have an account?
            <span style={{ color: "blue", cursor: "pointer" }}>
              <span
                className="blue-links"
                onClick={() => {
                  this.props.history.replace("/loginSignup");
                }}
              >
                &nbsp;&nbsp;Signup
              </span>
            </span>
          </p>
        </Grid>
      </div>
    );
  }
}

export default connect()(LoginPage);
