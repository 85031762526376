import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    List,
    Card,
    CardMedia,
    CardContent,
    CardHeader,
    Button,
    Fab,
    Divider,
    ListItem,
    Hidden
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import OverlayLoader from '../core/OverlayLoader';
import ConfirmationDialog from '../core/Confirmation';
import UserService from '../../services/UserService';
import MenuComponent from '../core/MenuComponent';
import AlertDialog from '../core/Disclamer';
import AuthService from '../../services/AuthService';
let selectedPostId;
let selectedVideoId;

class FavoritesPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: true,
                search: false,
                back: true,
                title: 'My Favorites',
                static: true,
            }
        });
        this.state = {
            loader: false,
            showConfirmationDialog: false,
            openAlertDialog: false,
            selectedVideoId: '',
            sort: {
                createdAt: 1,
                title: 0
            },
            favoritePosts: []
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        selectedVideoId = '';
        this.getFavoritePosts();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.toolBar.selectedSort !== null) {
            this.sortByFavoritePosts(nextProps.toolBar.selectedSort, nextProps.toolBar.selectedSortValues[nextProps.toolBar.selectedSort]);
        }
    }

    sortByFavoritePosts = async (sortBy, value) => {
        if (sortBy === 'date') {
            this.setState({ sort: { createdAt: 1 } }, () => { this.getFavoritePosts(); });
        } else if (sortBy === 'title') {
            this.setState({ sort: { title: 1 } }, () => { this.getFavoritePosts(); });
        } else {
            this.setState({ sort: { createdAt: 1 } }, () => { this.getFavoritePosts(); });
        }
    }

    getFavoritePosts = async () => {
        try {
            this.setState({ loader: true });
            let obj = {
                sort: this.state.sort,
                page: 1,
                limit: 500
            }
            let res = await UserService.getFavoritePosts(obj);
            this.setState({ loader: false });
            this.setState({ favoritePosts: res.posts });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    removeFavoritePost = async (post) => {
        selectedPostId = post._id;
        this.setState({ showConfirmationDialog: true });
    }

    toggleFavoritePost = async () => {
        if (!selectedPostId) {
            this.setState({
                open: true,
                message: 'Please select favorites post.',
                isSuccess: false
            });
            return false;
        }
        try {
            this.setState({ loader: true });
            let res = await UserService.toggleFavoritePost({ postId: selectedPostId });
            this.setState({ loader: false });
            AuthService.setAuthUser(res.user);
            this.getFavoritePosts();
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }


    handleConfirmationDailogClose = (isConfirm) => {
        this.setState({ showConfirmationDialog: false });
        if (isConfirm) {
            this.toggleFavoritePost()
        }
    }

    setAlertOpenDialog = (isOpen) => {
        this.setState({ openAlertDialog: isOpen });
    }

    onPlayVideo(id) {
        selectedVideoId = id;
        this.setAlertOpenDialog(true, id);
    }


    handelAlertDialogClose = (isConfirm) => {
        this.setAlertOpenDialog(false);
        if (isConfirm) {
            this.setState({ selectedVideoId: selectedVideoId });
        } else {
            this.setState({ selectedVideoId: '' });
            selectedVideoId = '';
        }
    }

    render() {
        let { favoritePosts, showConfirmationDialog } = this.state;
        let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
        let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <List>
                            {
                                favoritePosts.length ?
                                    favoritePosts.map((post, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <ListItem key={post._id} style={{}}>

                                                    <Card style={{ boxShadow: 'none', width: '100%' }}>
                                                        <CardHeader
                                                            style={{ textAlign: 'left', padding: 0, width: '100%', float: 'left' }}
                                                            title={
                                                                <div style={{ fontSize: '16px', fontWeight: 400 }}>
                                                                    <Grid container >
                                                                        <Grid item xs={10} sm={10} md={10} lg={10} style={{ padding: 10 }}>
                                                                            {post.title}
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'end' }}>
                                                                            <MenuComponent props={this.props} items={[{ title: 'Remove', value: 'remove', show: true }]} type={"favoriteMenu"} selectedItems={(item) => { this.removeFavoritePost(post) }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            }

                                                        />

                                                        <CardContent style={{ paddingTop: 0, paddingLeft: 0, overflow: 'hidden' }}>
                                                            {
                                                                post._id === this.state.selectedVideoId &&
                                                                <div>
                                                                    <ReactPlayer
                                                                        url={post.wowzaVideoKey ? process.env.REACT_APP_WOWZA_HLS_URL + "/vod/mp4:" + post.wowzaVideoKey + "_transcoded.mp4/playlist.m3u8" : `${imageBaseUrl}/${post._id}/${post._id}_video${post.videoFileExt}`}
                                                                        playing
                                                                        playsinline
                                                                        className={'videoClass'}
                                                                        config={{ file: { attributes: { controlsList: 'nodownload', autoPlay: true, muted: true } } }}
                                                                        width="100%"
                                                                        controls={true}
                                                                        style={{ overflow: 'hidden !important', position: 'relative', top: 0 }}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                post._id !== this.state.selectedVideoId &&
                                                                <React.Fragment >
                                                                    <div className="mediaCardOverlay">
                                                                        <Fab aria-label="like" className="overlay-button" onClick={() => { this.onPlayVideo(post._id) }}>
                                                                            <img alt="" className="overlay-button-img" src={require('../../assets/icons/playbutton.svg')} />
                                                                        </Fab>
                                                                    </div>
                                                                    <CardMedia
                                                                        component="img"
                                                                        image={(post.hasOwnProperty("wowzaVideoKey") && post.wowzaVideoKey) ? `${thumbnailUrl}/${post.wowzaVideoKey}` : `${imageBaseUrl}/${post._id}/${post._id}_thumbnail.jpeg`}
                                                                        className='mediaCard'
                                                                    />
                                                                </React.Fragment>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                </ListItem>
                                                <Divider />
                                            </Fragment>
                                        )
                                    })
                                    : <div style={{ textAlign: 'center' }}>No favorites posts found.</div>
                            }
                        </List>
                    </Card>
                    <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; and (b) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP."
                        open={this.state.openAlertDialog} onClose={this.handelAlertDialogClose} />
                    <ConfirmationDialog message="Are you sure you want to remove this video from your favorites list?" open={showConfirmationDialog} onClose={this.handleConfirmationDailogClose} />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(FavoritesPage);