import React, { useState } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import { connect } from 'react-redux';
import Menu from '../core/Menu';

function MobileSideBar(props) {

    const handelOnToggle = () => {
        props.dispatch({ type: 'TOGGLE_MENU' });
    }

    return (
        <SwipeableDrawer
            open={props.menuState.open}
            anchor="left"
            variant="persistent"
            onOpen={() => handelOnToggle()}
            onClose={() => handelOnToggle()}
            className="sidebar">
            <Menu />
        </SwipeableDrawer>
    );
}

const mapStateToProps = (state) => ({
    menuState: state.menuReducer
});

export default connect(mapStateToProps)(MobileSideBar);