import * as Server from '../Server';
const WorkoutClassService = {
    getAllMySchedules: async (obj) => {
        try {
            let response = await Server.request({
                url: 'user/mySchedules',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    create: async (obj) => {
        try {
            let response = await Server.request({
                url: '/workoutClass',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    edit: async (obj) => {
        try {
            let response = await Server.request({
                url: '/workoutClass',
                method: 'PUT',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    deleteWorkoutClassById: async (obj) => {
        try {
            let response = await Server.request({
                url: `/workoutClass/${obj._id}`,
                method: 'DELETE'
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    cancelWorkoutClassById: async (obj) => {
        try {
            let response = await Server.request({
                url: '/workoutClass/cancel',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    getWorkoutClassesByFilter: async (obj) => {
        try {
            let response = await Server.request({
                url: '/workoutClass/search',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    createDeepDivesPost: async (obj) => {
        try {
            let response = await Server.request({
                url: '/workoutClass/createDeepDivesPost',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    goLive: async (obj) => {
        try {
            Object.assign(obj, { currentTime: new Date().toISOString() })
            let response = await Server.request({
                url: '/workoutClass/goLive',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    },

    checkLive: async (obj) => {
        try {
            Object.assign(obj, { currentTime: new Date().toISOString() })
            let response = await Server.request({
                url: '/workoutClass/liveStatus',
                method: 'POST',
                data: obj || {}
            });
            return response;
        } catch (error) {
            throw Error(error.message);
        }
    }
}

export default WorkoutClassService;