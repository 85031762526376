import LoginPage from "./pages/Login";
import LoginSignup from "./pages/LoginSignup";
import SignupPage from "./pages/Signup";
import SingupAgreement from "./pages/SingupAgreement";
import VerifyEmailOTP from "./pages/VerifyEmail";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import SetNewPasswordPage from "./pages/SetNewPassword";
import HomePage from "./pages/Home";
import AccessVideosPage from "./pages/AccessVideosPage";
import SubCategoriesPage from "./pages/SubCategories";
import FeedsPage from "./pages/Feeds";
import MyFeedsPage from "./pages/MyFeeds";
import FeedPage from "./pages/Feed";
import RewardsPage from "./pages/Rewards";
import SendRewardPage from "./pages/SendReward";
import FavoritesPage from "./pages/Favorites";
import PostVideoPage from "./pages/PostVideo";
import RewardPage from "./pages/Reward";
import MoreInfo from "./pages/MoreInfo";
import SelectUserType from "./pages/SelectUserType";
import ProfilePage from "./pages/ProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import NotificationsPage from "./pages/NotificationsPage";
import AgreementPage from "./pages/AgreementPage";
import VerifyLoginOTP from "./pages/VerifyLoginOTP";
import Splash from "./pages/Splash";
import MySchedulePage from "./pages/MySchedulePage";
import WorkoutCategoryPage from "./pages/WorkoutCategoryPage";
import WorkoutClassesPage from "./pages/WorkoutClassesPage";
import WorkoutSubCategoriesPage from "./pages/WorkoutSubCategoriesPage";
import PreviewPage from "./pages/PreviewPage";
import AbLabLoungePage from "./pages/AbLabLoungePage";
import ExternalWowzaPublisher from "./pages/ExternalWowzaPublisher";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import ContactRequestsPage from "./pages/ContactRequestsPage";
import ServicesPage from "./pages/ServicesPage";
import ServiceRequestsPage from "./pages/ServiceRequestsPage";
import MpkDashboardPage from "./pages/MpkDashboardPage";
import NearbyMPKUsers from "./pages/NearbyMPKUsers";

const routes = {
  menuRoutes: [
    {
      path: "/home",
      text: "Home",
      component: HomePage,
      icon: "Home.svg",
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/accessVideos",
      text: "Access Videos",
      component: AccessVideosPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/postVideo",
      text: "Post New Video",
      icon: "PostNewVideo.svg",
      component: PostVideoPage,
      exact: true,
      show: true,
      allow: ["pinger", "basic"],
    },
    {
      path: "/mySchedule",
      text: "Schedule My Live Workouts and Events",
      icon: "MySchedule.svg",
      component: MySchedulePage,
      exact: true,
      show: true,
      allow: ["pinger"],
    },
    {
      path: "/workouts",
      text: "Join Live Workouts and Events",
      icon: "LiveWorkouts.svg",
      component: WorkoutCategoryPage,
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/workouts/:category/:subCategory",
      text: "Live Workouts",
      icon: "MyFavorites.svg",
      component: WorkoutClassesPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/sendReward",
      text: "Submit Reward Request",
      component: SendRewardPage,
      icon: "SendRewards.svg",
      exact: true,
      show: true,
      allow: ["ponger"],
    },
    {
      path: "/myFeeds",
      text: "My Posted Videos",
      icon: "MyFeeds.svg",
      component: MyFeedsPage,
      exact: true,
      show: true,
      allow: ["pinger", "basic"],
    },
    {
      path: "/myRewards",
      text: "My Rewards",
      icon: "MyRewards.svg",
      component: RewardsPage,
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/myFavorites",
      text: "My Favorites",
      icon: "MyFavorites.svg",
      component: FavoritesPage,
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/abLabLounge",
      text: "The Ab Lab Lounge",
      icon: "Info.svg",
      component: AbLabLoungePage,
      exact: true,
      show: true,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/notifications",
      text: "Notifications",
      component: NotificationsPage,
      exact: true,
      show: true,
      icon: "Notification.svg",
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/agreement",
      text: "Legal Agreements",
      component: AgreementPage,
      exact: true,
      show: true,
      icon: "Agreement.svg",
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/feeds/:categoryId",
      text: "SubCategories",
      component: SubCategoriesPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/feeds/:categoryId/:subCategoryId",
      text: "Feeds",
      component: FeedsPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/feed/:id",
      text: "Feed",
      component: FeedPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/workouts/:categoryId",
      text: "Workout Sub Categories",
      icon: "MyFavorites.svg",
      component: WorkoutSubCategoriesPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/reward/:id",
      text: "Reward Detail",
      component: RewardPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/profile",
      text: "Profile",
      component: ProfilePage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/changePassword",
      text: "Change Password",
      component: ChangePasswordPage,
      exact: true,
      show: false,
      allow: ["pinger", "ponger", "basic"],
    },
    {
      path: "/dashboard",
      text: "Dashboard",
      component: MpkDashboardPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["mpk"],
    },
    {
      path: "/contactRequests",
      text: "Contact Requests",
      component: ContactRequestsPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["mpk"],
    },
    {
      path: "/services",
      text: "Services",
      component: ServicesPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["mpk"],
    },
    {
      path: "/nearbyMpkUsers",
      text: "Nearby MPK Proffessionals",
      component: NearbyMPKUsers,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["mpk"],
    },
    {
      path: "/serviceRequests/:serviceId",
      text: "Service Requests",
      component: ServiceRequestsPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: false,
      allow: ["mpk"],
    },
    {
      path: "/subscriptions",
      text: "Subscriptions",
      component: SubscriptionsPage,
      icon: "AccessVideo.svg",
      exact: true,
      show: true,
      allow: ["mpk"],
    },
  ],
  authRoutes: [
    {
      path: "/",
      text: "Splash",
      component: Splash,
      exact: true,
      show: false,
    },
    {
      path: "/loginSignup",
      text: "Authentication",
      component: LoginSignup,
      exact: true,
      show: false,
    },
    {
      path: "/login",
      text: "Login",
      component: LoginPage,
      exact: true,
      show: false,
    },
    {
      path: "/signup",
      text: "Signup",
      component: SignupPage,
      exact: true,
      show: false,
    },
    {
      path: "/moreInfo",
      text: "More Info",
      component: MoreInfo,
      exact: true,
      show: false,
    },
    {
      path: "/verify",
      text: "Verify",
      component: VerifyEmailOTP,
      exact: true,
      show: false,
    },
    {
      path: "/verifyLoginOTP",
      text: "Verify Login",
      component: VerifyLoginOTP,
      exact: true,
      show: false,
    },
    {
      path: "/forgot",
      text: "Forgot",
      component: ForgotPasswordPage,
      exact: true,
      show: false,
    },
    {
      path: "/resetPassword",
      text: "Reset Password",
      component: ResetPasswordPage,
      exact: true,
      show: false,
    },
    {
      path: "/setNewPassword",
      text: "Set New Password",
      component: SetNewPasswordPage,
      exact: true,
      show: false,
    },
    {
      path: "/selectUserType",
      text: "Select User Type",
      component: SelectUserType,
      exact: true,
      show: false,
    },
    {
      path: "/signupAgreement",
      text: "Agreement",
      component: SingupAgreement,
      exact: true,
      show: true,
    },
    {
      path: "/preview",
      text: "Preview",
      component: PreviewPage,
      exact: true,
      show: true,
    },
    {
      path: "/publisher",
      text: "Publisher",
      component: ExternalWowzaPublisher,
      exact: true,
      show: true,
    },
  ],
};

export default routes;
