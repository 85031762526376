import React, { Component, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MessageDialog from '../core/Message';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import AuthService from '../../services/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

let certificateArr = [
  {
    id: 1,
    name: 'AFAA',
    value: 'AFAA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 2,
    name: 'NASM',
    value: 'NASM',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 3,
    name: 'ISSA',
    value: 'ISSA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 4,
    name: 'ACE',
    value: 'ACE',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 5,
    name: 'ACSM',
    value: 'ACSM',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 6,
    name: 'NSCA',
    value: 'NSCA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 7,
    name: 'Other',
    value: 'Other',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
];

const Basic = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(
    localStorage.getItem('acceptAgreement') == 'Yes' ? true : false
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setMessageStatus] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [certificates, setCertificates] = useState(
    props.signUpDetails &&
      props.signUpDetails.certificates &&
      props.signUpDetails.certificates.length
      ? props.signUpDetails.certificates
      : certificateArr
  );

  const handelMessageClose = (value) => {
    setOpenMessageDialog(false);
    history.replace('/login');
  };

  return (
    <div>
      <Formik
        initialValues={{
          password: (props.signUpDetails && props.signUpDetails.password) || '',
          confirmPassword:
            (props.signUpDetails && props.signUpDetails.confirmPassword) || '',
          website: (props.signUpDetails && props.signUpDetails.website) || '',
          isFitnessProf:
            (props.signUpDetails && props.signUpDetails.isFitnessProf) || 'No',
          speciality:
            (props.signUpDetails && props.signUpDetails.speciality) || '',
          isCertified:
            (props.signUpDetails && props.signUpDetails.isCertified) || 'No',
          otherCertificate:
            (props.signUpDetails && props.signUpDetails.otherCertificate) || '',
          liabilityInsuranceCompany:
            (props.signUpDetails &&
              props.signUpDetails.liabilityInsuranceCompany) ||
            '',
          dateOfExpiration:
            (props.signUpDetails && props.signUpDetails.dateOfExpiration) || '',
          certificateVerificationLink:
            (props.signUpDetails &&
              props.signUpDetails.certificateVerificationLink) ||
            '',
          haveDegree:
            (props.signUpDetails && props.signUpDetails.haveDegree) || 'No',
          degreeName:
            (props.signUpDetails && props.signUpDetails.degreeName) || '',
          isMemberOfOrg:
            (props.signUpDetails && props.signUpDetails.isMemberOfOrg) || 'No',
          groupName:
            (props.signUpDetails && props.signUpDetails.groupName) || '',
          isCompany:
            (props.signUpDetails && props.signUpDetails.isCompany) || 'No',
          companyName:
            (props.signUpDetails && props.signUpDetails.companyName) || '',
          isCommentsAnonymous:
            (props.signUpDetails && props.signUpDetails.isCommentsAnonymous) ||
            'No',
          agreeTermsConditions:
            (props.signUpDetails && props.signUpDetails.agreeTermsConditions) ||
            'No',
        }}
        validate={(values) => {
          const errors = {};
          let passwordCheck =
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
          if (!values.password) {
            errors.password = 'Please enter password.';
          }
          if (values.password && !passwordCheck.test(values.password)) {
            errors.password =
              'Must contain at least 8 characters, including UPPER/lowercase, special character and numbers.';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Please enter your confirm password';
          }
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword =
              'Password and confirm password do not match';
          }
          if (values.isFitnessProf == 'Yes' && !values.speciality) {
            errors.speciality = 'Please enter your specialty';
          }
          if (values.haveDegree == 'Yes' && !values.degreeName) {
            errors.degreeName = 'Please enter your degree name';
          }
          if (values.isMemberOfOrg == 'Yes' && !values.groupName) {
            errors.groupName = 'Please enter your group name';
          }
          if (values.isCompany == 'Yes' && !values.companyName) {
            errors.companyName = 'Please enter your company name';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, errors }) => {
          let isChecked = certificates.find((obj) => obj.checked);
          if (!isChecked && values.isCertified === 'Yes') {
            setMessageStatus(false);
            setMessage('Please select at least one certificate');
            setOpen(true);
            return;
          }
          let certificateValueEmptyCount = 0;
          for (let certificate of certificates) {
            if (certificate.checked) {
              if (certificate.name != 'Other') {
                if (
                  certificate.personalTrainerNo == '' ||
                  certificate.groupExerciseNo == ''
                ) {
                  certificateValueEmptyCount++;
                }
              } else {
                if (values.otherCertificate == '') {
                  certificateValueEmptyCount++;
                }
              }
            }
          }
          if (certificateValueEmptyCount) {
            setMessageStatus(false);
            setMessage('Please provide certificate details');
            setOpen(true);
            return;
          }
          let body = {};
          body = values;
          body.isCertified = values.isCertified === 'Yes' ? true : false;
          body.isFitnessProf = values.isFitnessProf === 'Yes' ? true : false;
          body.haveDegree = values.haveDegree === 'Yes' ? true : false;
          body.isMemberOfOrg = values.isMemberOfOrg === 'Yes' ? true : false;
          body.isCompany = values.isCompany === 'Yes' ? true : false;
          body.isCommentsAnonymous =
            values.isCommentsAnonymous === 'Yes' ? true : false;
          body.accountType = props.props.userDetails.selectedUserType;
          body.emailVerificationId =
            props.props.userDetails.emailVerificationId;
          body.certificates = certificates;
          body.otherCertificate = values.otherCertificate || null;
          setLoader(true);
          setDisabled(true);
          try {
            AuthService.setAuthorizationHeader(
              'basic ' + process.env.REACT_APP_SERVER_BASE64
            );
            let res = await AuthService.signup(body);
            if (res) {
              setLoader(false);
              setDisabled(false);
              if (
                body.accountType == 'pinger' ||
                body.accountType == 'ponger'
              ) {
                setMessage(
                  'Thank you for submitting your profile. We will review your profile and expect you can log back in and begin posting within 48-72 hours.'
                );
              } else {
                setMessage('Account created successfully.');
              }
              // setCertificates(res.user.certificates);
              localStorage.removeItem('isFromLogin');
              localStorage.removeItem('signUpDetails');
              localStorage.removeItem('acceptAgreement');
              setOpenMessageDialog(true);
            }
          } catch (error) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(error.message);
            setOpen(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" style={{ padding: '0px 20px' }}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  container
                  justify="center"
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item xs={12} md={12} lg={12} style={{ padding: 10 }}>
                    <h2
                      style={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: '28px',
                        fontWeight: 400,
                        textAlign: 'center',
                      }}
                    >
                      Add More Info
                    </h2>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} style={{}}>
                    <TextField
                      name="password"
                      type="password"
                      disabled={disabled}
                      error={errors.password && touched.password ? true : false}
                      defaultValue={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="standard-basic"
                      label="Password"
                      helperText={errors.password}
                      style={{ width: '100%' }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{}}>
                    <TextField
                      name="confirmPassword"
                      type="password"
                      disabled={disabled}
                      error={
                        errors.confirmPassword && touched.confirmPassword
                          ? true
                          : false
                      }
                      defaultValue={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="standard-basic"
                      label="Confirm Password"
                      helperText={errors.confirmPassword}
                      style={{ width: '100%' }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{}}>
                    <TextField
                      name="website"
                      error={errors.website && touched.website ? true : false}
                      disabled={disabled}
                      defaultValue={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="standard-basic"
                      label="What is your website?"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {(props.userType === 'pinger' ||
                    props.userType === 'mpk') && (
                    <React.Fragment>
                      <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Are you a fitness professional?
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="isFitnessProf"
                                  error={
                                    errors.isFitnessProf &&
                                    touched.isFitnessProf
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.isFitnessProf}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  aria-label="gender"
                                  style={{ display: 'inline' }}
                                >
                                  <FormControlLabel
                                    value={'Yes'}
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value={'No'}
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.isFitnessProf === 'Yes' ? (
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="speciality"
                                  error={
                                    errors.speciality && touched.speciality
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.speciality}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="What specialty?"
                                  helperText={errors.speciality}
                                  style={{ width: '100%' }}
                                  required
                                />
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                      <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Are you currently certified? if Yes choose one
                                or more.{' '}
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="isCertified"
                                  error={
                                    errors.isCertified && touched.isCertified
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.isCertified}
                                  disabled={disabled}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'isCertified',
                                      event.target.value
                                    );
                                    setCertificates(certificateArr);
                                    setFieldValue('otherCertificate', '');
                                  }}
                                  onBlur={handleBlur}
                                  style={{ display: 'inline' }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          {values.isCertified === 'Yes' && (
                            <React.Fragment>
                              {certificates.map((certificate, key) => {
                                return (
                                  <Grid container id={key}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: 'left' }}
                                    >
                                      <div>
                                        <label
                                          className="checkbox-container"
                                          for={certificate.id}
                                        >
                                          {certificate.name}
                                          <input
                                            type="checkbox"
                                            id={certificate.id}
                                            onChange={(event) => {
                                              let tempCertificates = [];
                                              certificates.forEach((obj) => {
                                                if (
                                                  obj.value ===
                                                  event.target.value
                                                ) {
                                                  obj.checked =
                                                    event.target.checked;
                                                  if (!event.target.checked) {
                                                    obj.groupExerciseNo = '';
                                                    obj.personalTrainerNo = '';
                                                  }
                                                }
                                                tempCertificates.push(obj);
                                              });
                                              setCertificates(tempCertificates);
                                              if (
                                                event.target.value == 'Other' &&
                                                !event.target.checked
                                              ) {
                                                setFieldValue(
                                                  'otherCertificate',
                                                  ''
                                                );
                                              }
                                            }}
                                            checked={certificate.checked}
                                            name={certificate.name}
                                            value={certificate.value}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </Grid>
                                    {certificate.checked && (
                                      <Grid container>
                                        {certificate.name == 'Other' && (
                                          <Grid xs={12} md={12} lg={12}>
                                            <TextField
                                              name="otherCertificate"
                                              defaultValue={
                                                values.otherCertificate
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              id="standard-basic"
                                              label="Enter other certification(s) along with the certificate ID's"
                                              helperText={
                                                errors.otherCertificate
                                              }
                                              style={{
                                                width: '100%',
                                                marginBottom: 15,
                                              }}
                                            />
                                          </Grid>
                                        )}
                                        {certificate.name != 'Other' && (
                                          <React.Fragment>
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              lg={6}
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                              }}
                                            >
                                              <TextField
                                                name={
                                                  'personalTrainer_' +
                                                  certificate.name
                                                }
                                                onChange={(event) => {
                                                  let tempCertificates = [];
                                                  certificates.forEach(
                                                    (obj) => {
                                                      if (
                                                        obj.name ===
                                                        certificate.name
                                                      ) {
                                                        obj.personalTrainerNo =
                                                          event.target.value;
                                                      }
                                                      tempCertificates.push(
                                                        obj
                                                      );
                                                    }
                                                  );
                                                  setCertificates(
                                                    tempCertificates
                                                  );
                                                }}
                                                id="standard-basic"
                                                label="Personal trainer certification number"
                                                value={
                                                  certificate.personalTrainerNo
                                                }
                                                style={{
                                                  width: '100%',
                                                  marginBottom: 15,
                                                }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={6}
                                              lg={6}
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                              }}
                                            >
                                              <TextField
                                                name={
                                                  'groupExercise_' +
                                                  certificate.name
                                                }
                                                onChange={(event) => {
                                                  let tempCertificates = [];
                                                  certificates.forEach(
                                                    (obj) => {
                                                      if (
                                                        obj.name ===
                                                        certificate.name
                                                      ) {
                                                        obj.groupExerciseNo =
                                                          event.target.value;
                                                      }
                                                      tempCertificates.push(
                                                        obj
                                                      );
                                                    }
                                                  );
                                                  setCertificates(
                                                    tempCertificates
                                                  );
                                                }}
                                                id="standard-basic"
                                                label="Group exercise certification number"
                                                value={
                                                  certificate.groupExerciseNo
                                                }
                                                style={{
                                                  width: '100%',
                                                  marginBottom: 15,
                                                }}
                                              />
                                            </Grid>
                                          </React.Fragment>
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                );
                              })}
                            </React.Fragment>
                          )}
                        </CardContent>
                      </Card>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                          <TextField
                            name="liabilityInsuranceCompany"
                            error={
                              errors.liabilityInsuranceCompany &&
                              touched.liabilityInsuranceCompany
                                ? true
                                : false
                            }
                            defaultValue={values.liabilityInsuranceCompany}
                            disabled={disabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="standard-basic"
                            label="Liability Insurance company"
                            style={{ width: '100%', marginBottom: 15 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                          <TextField
                            name="dateOfExpiration"
                            error={
                              errors.dateOfExpiration &&
                              touched.dateOfExpiration
                                ? true
                                : false
                            }
                            defaultValue={values.dateOfExpiration}
                            disabled={disabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="standard-basic"
                            label="Date of expiration"
                            style={{ width: '100%', marginBottom: 15 }}
                          />
                        </Grid>
                      </Grid>
                      <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Where can we verify your certification?
                              </p>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                              <TextField
                                name="certificateVerificationLink"
                                error={
                                  errors.certificateVerificationLink &&
                                  touched.certificateVerificationLink
                                    ? true
                                    : false
                                }
                                defaultValue={
                                  values.certificateVerificationLink
                                }
                                disabled={disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="standard-basic"
                                label={`www.dummyprofileurl.com`}
                                style={{ width: '100%' }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                      <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Are you a 4 year degreed fitness/allied health
                                professional?
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="haveDegree"
                                  error={
                                    errors.haveDegree && touched.haveDegree
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.haveDegree}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ display: 'inline' }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.haveDegree === 'Yes' ? (
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="degreeName"
                                  error={
                                    errors.degreeName && touched.degreeName
                                      ? true
                                      : false
                                  }
                                  disabled={disabled}
                                  defaultValue={values.degreeName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="If yes, where?"
                                  helperText={errors.degreeName}
                                  style={{ width: '100%' }}
                                  required
                                />
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                      <Card style={{ width: '100%', marginTop: 20 }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Are you referred by an organization or
                                institution?
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="isMemberOfOrg"
                                  error={
                                    errors.isMemberOfOrg &&
                                    touched.isMemberOfOrg
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.isMemberOfOrg}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ display: 'inline' }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.isMemberOfOrg === 'Yes' ? (
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="groupName"
                                  error={
                                    errors.groupName && touched.groupName
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.groupName}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="If yes, which one?"
                                  helperText={errors.groupName}
                                  style={{ width: '100%' }}
                                  required
                                />
                              </Grid>
                            ) : (
                              ' '
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </React.Fragment>
                  )}
                  {props.userType === 'ponger' && (
                    <Card style={{ width: '100%', marginTop: 20 }}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6} md={8} lg={8}>
                            <p style={{ textAlign: 'left' }}>
                              Are you a company?
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={4}
                            lg={4}
                            style={{ textAlign: 'right' }}
                          >
                            <FormControl component="fieldset">
                              <RadioGroup
                                name="isCompany"
                                error={
                                  errors.isCompany && touched.isCompany
                                    ? true
                                    : false
                                }
                                defaultValue={values.isCompany}
                                disabled={disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{ display: 'inline' }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  disabled={disabled}
                                  control={<Radio />}
                                  label="Yes"
                                  labelPlacement="start"
                                />
                                <FormControlLabel
                                  value="No"
                                  disabled={disabled}
                                  control={<Radio />}
                                  label="No"
                                  labelPlacement="start"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {values.isCompany === 'Yes' ? (
                            <Grid item xs={12} md={12} lg={12} style={{}}>
                              <TextField
                                name="companyName"
                                error={
                                  errors.companyName && touched.companyName
                                    ? true
                                    : false
                                }
                                defaultValue={values.companyName}
                                disabled={disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="standard-basic"
                                label="Name of company"
                                style={{ width: '100%' }}
                                required
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                  {(props.userType === 'pinger' ||
                    props.userType === 'mpk') && (
                    <Card style={{ width: '100%', marginTop: 20 }}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6} md={8} lg={8}>
                            <p style={{ textAlign: 'left' }}>
                              Do you want your comments to be anonymous?
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={4}
                            lg={4}
                            style={{ textAlign: 'right' }}
                          >
                            <FormControl component="fieldset">
                              <RadioGroup
                                name="isCommentsAnonymous"
                                error={
                                  errors.isCommentsAnonymous &&
                                  touched.isCommentsAnonymous
                                    ? true
                                    : false
                                }
                                defaultValue={values.isCommentsAnonymous}
                                disabled={disabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{ display: 'inline' }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  disabled={disabled}
                                  control={<Radio />}
                                  label="Yes"
                                  labelPlacement="start"
                                />
                                <FormControlLabel
                                  value="No"
                                  disabled={disabled}
                                  control={<Radio />}
                                  label="No"
                                  labelPlacement="start"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <p>
                        <FormControl component="fieldset">
                          <Checkbox
                            name="agreeTermsConditions"
                            defaultChecked={agreeTerms}
                            checked={agreeTerms || false}
                            color="primary"
                            onChange={(event) => {
                              if (event.target.checked) {
                                values.agreeTermsConditions = 'Yes';
                                let signUpDetails = values;
                                signUpDetails.certificates = certificates;
                                localStorage.setItem(
                                  'signUpDetails',
                                  JSON.stringify(signUpDetails)
                                );
                                localStorage.setItem('isFromLogin', false);
                                history.replace('/signupAgreement');
                              } else {
                                values.agreeTermsConditions = 'No';
                                localStorage.setItem('acceptAgreement', 'No');
                                setAgreeTerms(false);
                              }
                            }}
                            disabled={disabled}
                            style={{
                              display: 'inline',
                              padding: '0px',
                              paddingRight: '5px',
                              marginTop: '-2px',
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </FormControl>
                        I agree to{' '}
                        <span
                          style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            color: '#9013fe',
                          }}
                          onClick={(event) => {
                            values.agreeTermsConditions = 'Yes';
                            setAgreeTerms(true);
                            let signUpDetails = values;
                            signUpDetails.certificates = certificates;
                            localStorage.setItem(
                              'signUpDetails',
                              JSON.stringify(signUpDetails)
                            );
                            localStorage.setItem('isFromLogin', false);
                            history.replace('/signupAgreement');
                          }}
                        >
                          {' '}
                          Terms and Conditions{' '}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                  {
                    <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
                      {!disabled ? (
                        <Button
                          type="submit"
                          disabled={!agreeTerms}
                          className="btn-purple"
                          variant="contained"
                          color="primary"
                          disableElevation
                        >
                          Signup
                        </Button>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="white"
              style={{ color: 'white' }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarContent
          style={
            isSuccess
              ? {
                  backgroundColor: 'green',
                }
              : { backgroundColor: 'red' }
          }
          message={<span id="client-snackbar">{message}</span>}
        />
      </Snackbar>
      <MessageDialog
        message={message}
        open={openMessageDialog}
        onClose={handelMessageClose}
      />
    </div>
  );
};

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: '',
    };
    props.dispatch({
      type: 'SET_TOOLBAR_CONFIG',
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: '',
        static: false,
      },
    });
    if (
      props.userDetails.emailVerificationId === '' ||
      props.userDetails.selectedUserType === ''
    ) {
      props.history.replace('/signup');
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let signUpDetails = localStorage.getItem('signUpDetails')
      ? JSON.parse(localStorage.getItem('signUpDetails'))
      : {};
    let userType = this.props.userDetails.selectedUserType;
    return (
      <div style={{ overflowY: 'auto', backgroundColor: 'white' }}>
        <Grid
          container
          className={'contentHolderContainer-90vh'}
          justify="center"
          style={{ marginTop: '0%', justifyContent: 'center' }}
        >
          <Grid container justify="center" className={'profile-div-background'}>
            <Grid item xs={12} lg={12}>
              <IconButton
                onClick={() => this.props.history.goBack()}
                style={{
                  float: 'left',
                  position: 'relative',
                  left: 0,
                  color: 'black',
                  margin: 10,
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <div
                className="profile-image-holder"
                style={{ marginTop: '-30px' }}
              >
                <img
                  src={require('../../assets/logo.png')}
                  alt=""
                  style={{
                    height: 90,
                    weight: 90,
                    borderRadius: 60,
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Basic
            userType={userType}
            signUpDetails={signUpDetails}
            props={this.props}
          />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.userReducer,
});

export default connect(mapStateToProps)(MoreInfo);
