
const intialState = {
    toolbarconfig: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: '',
        static: false
    },
    color: true,
    selectedSort: null,
    selectedSortValues: {
        date: false,
        rating: false,
        likes: false,
        comments: false
    }
}

const toolbarReducer = (state = intialState, action ) => {
    switch(action.type){         
        case 'SET_TOOLBAR_CONFIG' :
            return Object.assign({}, state , {
                 toolbarconfig: action.data   
            })
        case 'SELECT_SORT' : 
        return Object.assign({}, state , {
            selectedSort: action.data   
        })
        case 'SELECTED_SORT_VALUES' : 
        return Object.assign({}, state , {
            selectedSortValues: action.data   
        })                            
        default : 
        return state;        
    }
}

export default toolbarReducer;