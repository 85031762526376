import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    List,
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    IconButton,
    Fab,
    ListItem,
    Avatar,
    MenuItem,
    Select,
    InputLabel,
    Input,
    Checkbox,
    DialogContentText,
    DialogActions,
    FormControl,
    Dialog,
    Snackbar,
    ListItemText,
    SnackbarContent,
    DialogTitle,
    DialogContent,
    Tooltip,
    FormHelperText,
    AppBar,
    Toolbar,
    Typography,
    FormControlLabel
} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AlertDialog from '../core/Disclamer';
import OverlayLoader from '../core/OverlayLoader';
import AuthService from '../../services/AuthService';
import CategoryService from '../../services/CategoryService';
import MenuComponent from '../core/MenuComponent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as GeneralFunctions from '../../GeneralFunctions';
import WorkoutClassService from '../../services/WorkoutClassService';
import ConfirmationDialog from '../core/Confirmation';
import WowzaPublisher from '../core/WowzaPublisher';
import ChatBox from '../core/ChatBox';
import {
    Capacitor,
    Plugins,
} from '@capacitor/core';
const { Browser } = Plugins;

let selectedWorkoutClassId;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const levelTypes = [
    {
        label: 'Beginner',
        value: 'beginner',
        disabled: false
    },
    {
        label: 'Intermediate',
        value: 'intermediate',
        disabled: false
    },
    {
        label: 'Advanced',
        value: 'advanced',
        disabled: false
    },
    {
        label: 'Everyone',
        value: 'everyone',
        disabled: false
    }
];


const menuItems = [
    // {
    //     title: 'Delete',
    //     value: 'delete',
    //     show: true
    // },
    {
        title: 'Edit',
        value: 'edit',
        show: true
    },
    {
        title: 'Cancel',
        value: 'cancel',
        show: true
    }
]

const menuItems2 = [
    // {
    //     title: 'Delete',
    //     value: 'delete',
    //     show: true
    // },
    {
        title: 'Edit',
        value: 'edit',
        show: true
    },
    {
        title: 'Cancel',
        value: 'cancel',
        show: false
    }
]

const Basic = (props) => {
    let history = useHistory();
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }));
    const duration = [
        20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90
    ];
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isSuccess, setMessageStatus] = useState(false);
    const [openAlertDialog, setAlertOpenDialog] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [scheduledAtDate, setScheduledAtDate] = React.useState(
        props.workout.scheduledAt ? moment(props.workout.scheduledAt).format("YYYY-MM-DDTHH:mm")
            : new Date()
    );

    const handleDateChange = date => {
        setScheduledAtDate(date);
    };

    const handelAlertDialogClose = (isConfirm) => {
        setAlertOpenDialog(false);
        if (isConfirm) {
            submitForm(selectedValue);
        }
    }

    const submitForm = async (values) => {
        setDisabled(true);
        setLoader(true);
        let scheduledAt = new Date(scheduledAtDate);
        values.scheduledAt = scheduledAt.toISOString();
        try {
            let res;
            if (values.formType === 'edit') {
                values._id = props.workout._id;
                res = await WorkoutClassService.edit(values);
            } else {
                res = await WorkoutClassService.create(values);
            }
            if (res) {
                setDisabled(false);
                setLoader(false);
                setMessageStatus(true);
                setSelectedValue({})
                setMessage(res.message || 'Workout class edited successfully.');
                setOpen(true);
                props.onClose();
            }
        } catch (e) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(e.message);
            setOpen(true);
        }
    }

    return (<div>
        <Formik
            enableReinitialize={false}
            initialValues={{
                formType: props.workout ? 'edit' : 'add',
                title: props.workout.title || '',
                category: props.workout.category || '',
                subCategory: props.workout.subCategory || '',
                level: props.workout.level || '',
                duration: props.workout.duration || '',
                description: props.workout.description || '',
                scheduledAt: props.workout.scheduledAt ? moment(props.workout.scheduledAt).format("YYYY-MM-DDTHH:mm") : '',
            }}
            validate={values => {
                const errors = {};
                if (!values.title) {
                    errors.title = 'Please enter title';
                }
                if (!values.category) {
                    errors.category = 'Please select category';
                }
                if (!values.subCategory) {
                    errors.subCategory = 'Please select sub category';
                }
                if (!values.level) {
                    errors.level = 'Please select level';
                }
                if (!scheduledAtDate) {
                    errors.scheduledAt = 'Please select scheduled at';
                }
                if (!values.duration) {
                    errors.duration = 'Please select a duration of the session';
                }
                return errors;
            }}

            onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
                setAlertOpenDialog(true);
                setSelectedValue(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                resetForm,
                isSubmitting
            }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <TextField
                                    id="standard-select-currency"
                                    type="text"
                                    style={{ width: '100%' }}
                                    name="title"
                                    error={errors.title && touched.title ? true : false}
                                    disabled={disabled}
                                    label="Title"
                                    value={values.title}
                                    placeholder={'Title'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.title && errors.title}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    style={{ width: '100%' }}
                                    disabled={disabled}
                                    error={errors.level && touched.level ? true : false}
                                    name="level"
                                    label="Select Level"
                                    placeholder={'Select Level'}
                                    value={values.level}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.level && errors.level}
                                    required
                                >
                                    {
                                        levelTypes.map((option) => {
                                            return (
                                                <MenuItem key={option.label} value={option.value} disabled={(option.label === 'All') ? true : false}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    style={{ width: '100%' }}
                                    disabled={disabled}
                                    error={errors.category && touched.category ? true : false}
                                    name="category"
                                    label="Select Category"
                                    placeholder={'Select Category'}
                                    value={values.category}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.category && errors.category}
                                    required
                                >
                                    {props.categories.map(option => (
                                        <MenuItem key={option._id} value={option._id} disabled={(option.name === 'All') ? true : (option.disabled)}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {values.category &&
                                <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        style={{ width: '100%' }}
                                        disabled={disabled}
                                        error={errors.subCategory && touched.subCategory ? true : false}
                                        name="subCategory"
                                        label="Select Sub Category"
                                        placeholder={'Select Sub Category'}
                                        value={values.subCategory}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.subCategory && errors.subCategory}
                                        required
                                    >
                                        {values.category &&
                                            props.categories.find((e) => e._id === values.category).subCats.map(option => (
                                                <MenuItem key={option._id} value={option._id} disabled={(option.name === 'All') ? true : false}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            }
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={6} style={{ paddingRight: 5 }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    id="scheduledAt"
                                                    name="scheduledAt"
                                                    value={scheduledAtDate}
                                                    onChange={handleDateChange}
                                                    style={{ width: '100%' }}
                                                    disablePast={true}
                                                    disabled={disabled}
                                                    format={'dd/MM/yyyy, HH:mm'}
                                                    label="Date & Time"
                                                    showTodayButton
                                                    helperText={touched.scheduledAt && errors.scheduledAt}
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6} style={{ paddingLeft: 5, marginBottom: 5 }}>
                                            <TextField
                                                id="standard-select-currency"
                                                select
                                                style={{ width: '100%' }}
                                                disabled={disabled}
                                                error={errors.duration && touched.duration ? true : false}
                                                name="duration"
                                                label="Duration"
                                                placeholder={'Duration'}
                                                value={values.duration}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.duration && errors.duration}
                                                required
                                            >
                                                {
                                                    duration.map(option => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <TextField
                                    aria-label="minimum height"
                                    type="text"
                                    multiline
                                    rows={2}
                                    style={{ width: '100%', marginTop: 10 }}
                                    name="description"
                                    error={errors.description && touched.description ? true : false}
                                    placeholder="Description"
                                    disabled={disabled}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}>
                                <div style={{ textDecoration: 'none', marginTop: 30 }}>
                                    {
                                        !disabled ?
                                            <Button
                                                className="btn-purple"
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disableElevation={true}
                                            >
                                                {props.workout ? 'Update' : 'Submit'}
                                            </Button> :
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        {
                            <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult, and I am currently a certified fitness professional in good standing; (b) I give THE AB LAB dAPP the right to adapt and use my name, image and likeness and the content that I upload to THE AB LAB dAPP without compensation; (c) I have secured all rights and permissions that are needed for THE AB LAB dAPP’s use of the content I have uploaded, including music clearance and the right to use the names, images and likenesses of individuals depicted in the content; (d) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, other Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP and (e) I have the right to withdraw the content I have uploaded and my personally identifiable information at any time."
                                open={openAlertDialog} onClose={handelAlertDialogClose} />
                        }
                    </form>
                )}
        </Formik>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="white"
                        style={{ color: 'white' }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }>
            <SnackbarContent style={isSuccess ? {
                backgroundColor: 'green',
            } : { backgroundColor: 'red', }}
                message={<span id="client-snackbar">{message}</span>}
            />
        </Snackbar>
    </div>
    );
}

class MySchedulePage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Schedule My Live Workouts and Events',
                static: true
            }
        });
        this.state = {
            page: 1,
            limit: 50,
            pages: 0,
            open: false,
            isSuccess: false,
            message: '',
            selectedWorkout: {},
            totalWorkoutClasses: 0,
            loader: false,
            showAlertDialog: false,
            workoutClasses: [],
            categories: [],
            showConfirmationDialog: false,
            openDialog: false,
            showConfirmationDialogForCancel: false,
            showConfirmationDialogForFullModal: false,
            openDialogForCancel: false,
            cancelMessage: '',
            openFullModal: false,
            user: {},
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.setState({ user: user });
        this.getCategories();
        this.getAllMySchedules(false);
    }

    getAllMySchedules = async (isPaginated) => {
        try {
            let obj = {
                page: this.state.page,
                limit: this.state.limit
            }
            this.setState({ loader: true });
            let res = await WorkoutClassService.getAllMySchedules(obj);
            let workoutClasses = this.state.workoutClasses;
            res.workoutClasses.forEach((workoutClass) => {
                let index = this.state.workoutClasses.findIndex((obj1) => obj1._id === workoutClass._id);
                if (index != -1) {
                    workoutClasses[index] = workoutClass;
                } else {
                    workoutClasses.push(workoutClass);
                }
            });
            this.setState({ loader: false });
            this.setState({ workoutClasses: isPaginated ? workoutClasses : res.workoutClasses, totalWorkoutClasses: res.totalWorkoutClasses, pages: res.pages });
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handelOptions = async (item, workoutClass) => {
        selectedWorkoutClassId = workoutClass._id;
        await this.setState((prevState) => ({
            ...prevState,
            selectedWorkout: workoutClass
        }));
        // if (item == 'delete') {
        //     this.setState({ showConfirmationDialog: true });
        // }
        if (item == 'cancel') {
            this.setState({ showConfirmationDialogForCancel: true })
        }
        if (item == 'edit') {
            this.handelToggleModal();
        }
    }

    handleConfirmationDailogClose = (isCofirm) => {
        this.setState({ showConfirmationDialog: false })
        if (isCofirm) {
            this.deleteWorkoutClassById();
        }

    }

    deleteWorkoutClassById = async () => {
        try {
            this.setState({ loader: true });
            let res = await WorkoutClassService.deleteWorkoutClassById({ _id: selectedWorkoutClassId });
            if (res) {
                let workoutClasses = this.state.workoutClasses;
                let workoutClassIndex = this.state.workoutClasses.findIndex((obj) => obj._id === selectedWorkoutClassId)
                workoutClasses.splice(workoutClassIndex, 1);
                this.setState({ workoutClasses: workoutClasses });
                this.setState({
                    open: true,
                    message: res.message,
                    isSuccess: true
                });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getAllMySchedules(true);
        });
    }

    getCategories = async () => {
        try {
            this.setState({ loader: true });
            let res = await CategoryService.getAllCategories();
            this.setState({ loader: false });
            this.setState({ allCategories: res.categories });
            this.setState({ categories: res.categories.find((obj) => obj._id === 'workoutclasses').cats });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    handelToggleModal = async () => {
        this.setState({
            openDialog: !this.state.openDialog,
        });
    }

    handleConfirmationDailogCloseForCancel = async (isConfirm) => {
        this.setState({ showConfirmationDialogForCancel: false })
        if (isConfirm) {
            this.setState({ openDialogForCancel: true })
        }
    }

    handleConfirmationDailogCloseForFullModal = async (isConfirm) => {
        this.setState({ showConfirmationDialogForFullModal: false });
        if (isConfirm) {
            this.toggleFullModal();
            this.getAllMySchedules(false);
        }
    }

    toggleCancelModal = async () => {
        this.setState({
            openDialogForCancel: !this.state.openDialogForCancel
        })
    }

    handleGoLive = async (workoutClass) => {
        this.setState({ loader: true });
        try {
            let res = await WorkoutClassService.goLive({ _id: workoutClass._id });
            this.setState({
                loader: false ,
                selectedWorkout: res.workoutClass
            }, () => {
                const url = process.env.REACT_APP_CLIENT_URL + "/#/publisher?applicationName=" + this.state.selectedWorkout.owner._id + "&streamName=" +this.state.selectedWorkout.owner.wowzaStreamKey;
                if (Capacitor.isNative) {
                    Browser.open({ url: url});
                } else {
                    this.toggleFullModal();
                    setTimeout(() => {
                        document.querySelector('.MuiDialog-scrollBody').removeAttribute('tabindex');
                        this.setState({ showChatBox: true });
                    }, 10000);
                }
            });
            this.getAllMySchedules(true);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    cancelWorkoutClassById = async () => {
        try {
            this.setState({ loader: true });
            let res = await WorkoutClassService.cancelWorkoutClassById({ _id: selectedWorkoutClassId, cancelMessage: this.state.cancelMessage });
            if (res) {
                let workoutClasses = this.state.workoutClasses;
                let myWorkoutIndex = this.state.workoutClasses.findIndex((obj) => obj._id === selectedWorkoutClassId)
                workoutClasses[myWorkoutIndex].status = res.workoutClass.status;
                this.setState({ workoutClasses: workoutClasses, cancelMessage: '' });
                this.toggleCancelModal()
                this.setState({
                    open: true,
                    message: res.message || "Workout class cancelled successfully.",
                    isSuccess: true
                });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.toggleCancelModal()
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    toggleFullModal = () => {
        this.setState({
            openFullModal: !this.state.openFullModal
        });
    }

    handelUploadClass = async (_id) => {
        try {
            this.setState({ loader: true });
            let res = await WorkoutClassService.createDeepDivesPost({ _id });
            if (res) {
                this.setState({
                    open: true,
                    message: res.message || "Workout class uploaded successfully.",
                    isSuccess: true
                });
            }
            this.setState({ loader: false });
            this.getAllMySchedules(false);
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    render() {
        let user = AuthService.getAuthUser();
        let { open, isSuccess, message } = this.state;
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxshadow: 'none', padding: '15px' }} >
                        <CardHeader
                            className="myScheduleCardHeader"
                            title=""
                            subheader="Live workouts and events will be offered by qualified professionals who have been highly rated
                            by their peers. Enjoy the amazing variety of unique styles from around the world. Here are all
                            your scheduled workouts and events listed below."
                            action={
                                < Fab disabled={(this.state.workoutClasses.length <= 1) ? false : true} color={(this.state.workoutClasses.length <= 1) ? 'primary' : 'default'} aria-label="add" style={{ zIndex: 10, position: 'relative' }} onClick={() => { this.setState((prevState) => ({ ...prevState, selectedWorkout: '' })); this.handelToggleModal() }}>
                                    <AddCircleIcon />
                                </Fab>
                            }
                        />
                        <List style={{ overflowY: 'scroll' }}>
                            <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                {
                                    this.state.workoutClasses.length ?
                                        this.state.workoutClasses.map((workoutClass, key) => {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={12} lg={12} >
                                                    <ListItem style={{ marginBottom: '20px' }} boxshadow={2}>
                                                        <Card style={{ width: '100%' }}>
                                                            <CardContent >
                                                                <Grid container style={{ width: '100%', display: 'flex-inline', justifyContent: 'space-between' }}>
                                                                    <Grid item xs={5} md={3} lg={3}>
                                                                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <Avatar alt="Remy Sharp" variant="square" src={require('../../assets/icons/workout-bg.svg')} style={{ height: "100%", width: "90%" }} />
                                                                            </Grid>
                                                                            <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                {
                                                                                    workoutClass.status == 'pending' &&
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        aria-label="live soon"
                                                                                        style={{ width: '90%', height: 30, marginTop: 10, backgroundColor: 'rgb(232, 205, 14)', color: 'white' }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                            {GeneralFunctions.capitalize(workoutClass.status)}
                                                                                        </span>
                                                                                    </Button>
                                                                                }
                                                                                {
                                                                                    (workoutClass.status == 'approved' || workoutClass.status === 'live') &&
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        aria-label="go live"
                                                                                        style={{ width: '90%', height: 30, marginTop: 10, backgroundColor: 'green', color: 'white' }}
                                                                                        endIcon={
                                                                                            <PlayCircleOutlineIcon fontSize="small" />
                                                                                        }
                                                                                        onClick={() => this.handleGoLive(workoutClass)}
                                                                                    >
                                                                                        <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                            Go Live
                                                                                </span>
                                                                                    </Button>
                                                                                }
                                                                                {
                                                                                    (workoutClass.status == 'rejected' || workoutClass.status == 'cancelled') &&
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        aria-label={workoutClass.status}
                                                                                        style={{ width: '90%', height: 30, marginTop: 10, backgroundColor: 'red', color: 'white' }}
                                                                                        endIcon={
                                                                                            workoutClass.status == 'cancelled' &&
                                                                                            <Tooltip arrow title={workoutClass.cancelMessage} aria-label="add">
                                                                                                <InfoIcon fontSize="small" />
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                            {GeneralFunctions.capitalize(workoutClass.status)}
                                                                                        </span>
                                                                                    </Button>
                                                                                }
                                                                                {
                                                                                    workoutClass.status == 'completed' &&
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        aria-label="completed"
                                                                                        style={{ width: '90%', height: 30, marginTop: 10, backgroundColor: 'green', color: 'white', pointerEvents: 'none' }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                            {GeneralFunctions.capitalize(workoutClass.status)}
                                                                                        </span>
                                                                                    </Button>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={7} md={7} lg={8}>
                                                                        <Grid container>
                                                                            <Grid item xs={9} lg={10}>
                                                                                <p style={{ marginBlockStart: 0 }}>{workoutClass.title}</p>
                                                                            </Grid>
                                                                            <Grid item xs={3} lg={2}>
                                                                                {
                                                                                    workoutClass.status !== 'completed' &&
                                                                                    <div style={{ width: '100%', display: 'inline-flex', marginTop: '-20px' }}>
                                                                                        <MenuComponent props={this.props} items={(workoutClass.status === 'pending' || workoutClass.status === 'cancelled' || workoutClass.status === 'rejected') ? menuItems2 : menuItems} type={"myLiveWorkoutMenu"} selectedItems={(item) => { this.handelOptions(item, workoutClass) }} />
                                                                                    </div>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container>
                                                                            <Grid item xs={12} md={12} lg={12}>
                                                                                <p style={{ marginBlockStart: 0 }}>
                                                                                    {moment(workoutClass.scheduledAt).format("llll")} , {workoutClass.duration && workoutClass.duration + " min"}
                                                                                </p>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container>
                                                                            <Grid item xs={12} md={12} lg={12}>
                                                                                <p style={{ marginBlockStart: 0 }}>
                                                                                    {GeneralFunctions.capitalize(workoutClass.level)}
                                                                                </p>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            workoutClass.status === 'completed' &&
                                                                            <Grid container>
                                                                                <Grid item xs={12} md={12} lg={12}>
                                                                                    {
                                                                                        workoutClass.postId ?
                                                                                            <Grid container>
                                                                                                <Grid item xs={12} xl={4} md={4} lg={4}>
                                                                                                    <label>Post Status:</label>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} xl={8} md={8} lg={8}>
                                                                                                    <label>{GeneralFunctions.capitalize(workoutClass.postStatusMessage)}</label>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        onChange={(event) => {
                                                                                                            if (event.target.checked) {
                                                                                                                this.handelUploadClass(workoutClass._id)
                                                                                                            }
                                                                                                        }}
                                                                                                        name="checkedB"
                                                                                                        color="primary"
                                                                                                    />
                                                                                                }
                                                                                                label="Do you want to upload class to deep dives"
                                                                                            />
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <p style={{ overflowWrap: 'anywhere !important' }}>{workoutClass.description}</p>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </ListItem>
                                                </Grid>
                                            )
                                        })
                                        : <div>You have not created any live workout class yet..</div>
                                }
                            </Grid>
                        </List>
                    </Card>
                    {
                        this.state.page < this.state.pages &&
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                        </Grid>
                    }
                </Grid>
                <ConfirmationDialog message="Are you sure you want to close window?" open={this.state.showConfirmationDialogForFullModal} onClose={this.handleConfirmationDailogCloseForFullModal} />
                <ConfirmationDialog message="Are you sure you want to cancel workout class?" open={this.state.showConfirmationDialogForCancel} onClose={this.handleConfirmationDailogCloseForCancel} />
                <ConfirmationDialog message="Are you sure you want to delete workout class?" open={this.state.showConfirmationDialog} onClose={this.handleConfirmationDailogClose} />
                {
                    this.state.openDialog &&
                    <Dialog
                        open={this.state.openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => { this.handelToggleModal() }}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
                            {this.state.selectedWorkout ? "Edit" : "Add"} workout class
                            <IconButton style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => this.handelToggleModal()}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">

                            </DialogContentText>
                            <Basic categories={this.state.categories} onClose={async () => { await this.getAllMySchedules(false); this.handelToggleModal() }} props={this.props} workout={this.state.selectedWorkout} />
                        </DialogContent>
                        <DialogActions>

                        </DialogActions>
                    </Dialog>
                } <Dialog
                    open={this.state.openDialogForCancel}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.toggleCancelModal()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Workout class cancel message"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="cancel"
                            type="text"
                            style={{ width: '100%' }}
                            name="CancelMessage"
                            label="Cancel Message"
                            value={this.state.cancelMessage}
                            placeholder={'Message'}
                            onChange={(e) => this.setState({ cancelMessage: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button
                            style={{
                                width: '210px',
                                marginTop: 10,
                            }}
                            className="btn-purple"
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={!this.state.cancelMessage}
                            onClick={this.cancelWorkoutClassById}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    this.state.openFullModal &&
                    <Dialog
                        open={this.state.openFullModal}
                        fullScreen
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll={"body"}
                    >
                        <DialogContent style={{ padding: 0 }} id="publishWorkoutClassContent">
                            <AppBar className="app-bar-color">
                                <Toolbar>
                                    <Typography variant="h6" style={{ width: '100%' }}>
                                        {this.state.selectedWorkout ? this.state.selectedWorkout.title : "Unknown"}
                                    </Typography>
                                    <IconButton edge="end" color="inherit" onClick={() => {
                                        this.setState({ showConfirmationDialogForFullModal: true });
                                    }}
                                        style={{ float: 'right' }}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>

                                </Toolbar>
                            </AppBar>
                            <div style={{ marginTop: 60 }}>
                                <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Grid container justify="center">
                                            <Grid item lg={8} md={8} sm={12} xs={12} style={{ marginTop: 35, justifyContent: 'center' }}>
                                                <WowzaPublisher streamName={this.state.selectedWorkout.owner.wowzaStreamKey} applicationName={this.state.selectedWorkout.owner._id} />
                                                {
                                                    this.state.showChatBox &&
                                                    <ChatBox user={user}></ChatBox>
                                                }
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} style={{ justifyContent: "center", padding: 10, marginTop: 60 }}>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }}  >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Title :</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label> {this.state.selectedWorkout.title}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Date & Time :</label>
                                                    </Grid>
                                                    <Grid item xs={6
                                                    } xl={8} md={8} lg={8}>
                                                        <label> {moment(this.state.selectedWorkout.scheduledAt).format("llll")}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Duration :</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label>{this.state.selectedWorkout.duration && this.state.selectedWorkout.duration + " min"}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }}  >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Category :</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label> {this.state.selectedWorkout.category}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Sub Category:</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label> {this.state.selectedWorkout.subCategory}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Level :</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label>{GeneralFunctions.capitalize(this.state.selectedWorkout.level)}</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                    <Grid item xs={6} xl={4} md={4} lg={4}>
                                                        <label>Description :</label>
                                                    </Grid>
                                                    <Grid item xs={6} xl={8} md={8} lg={8}>
                                                        <label> {this.state.selectedWorkout.description}</label>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => this.setState({
                        open: false
                    })}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => this.setState({ open: false })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
            </Grid >
        )
    }
}

export default connect()(MySchedulePage);