import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import OtpInput from 'react-otp-input';
import AuthService from '../../services/AuthService';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

class VerifyEmailOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            show: false,
            error: '',
            loader: false,
            disabled: false,
            isSuccess: false,
            open: false
        };

        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: false,
                color: true,
                sort: false,
                search: false,
                back: false,
                title: '',
                static: false
            }
        });
        if (!this.props.userDetails.verifyEmail) {
            this.props.history.replace('/loginSignup');
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleOtpChange = otp => {
        this.setState({ otp });
    };

    onSubmit = async () => {
        this.setState({
            loader: true,
            disabled: true
        });
        try {
            let body = {
                otp: this.state.otp
            };
            AuthService.setAuthorizationHeader("basic " + process.env.REACT_APP_SERVER_BASE64);
            let res;
            if (this.props.userDetails.forgotPasswordId) {
                body.forgotPasswordId = this.props.userDetails.forgotPasswordId;
                res = await AuthService.verifyForgotPasswordOTP(body);
            }
            if (this.props.userDetails.emailVerificationId) {
                body.emailVerificationId = this.props.userDetails.emailVerificationId;
                res = await AuthService.verifyEmailOTP(body);
            }
            this.setState({
                loader: false,
                open: true,
                isSuccess: true,
                error: 'OTP verified successfully.'
            });
            setTimeout(() => {
                this.setState({
                    disabled: false
                });
                if (res.hasOwnProperty('forgotPasswordId')) {
                    this.props.history.replace('/resetPassword');
                }
                if (res.hasOwnProperty('emailVerificationId')) {
                    this.props.history.replace('/moreInfo');
                }
            }, 2000);

        } catch (e) {
            if (e.message) {
                this.setState({
                    error: e.message,
                    loader: false,
                    disabled: false,
                    open: true,
                    isSuccess: false,
                    otp: ''
                });
            }
        }
    }

    onResendEmail = async () => {
        this.setState({
            loader: true,
            disabled: true
        });
        try {
            AuthService.setAuthorizationHeader("basic " + process.env.REACT_APP_SERVER_BASE64);
            if (this.props.userDetails.forgotPasswordId) {
                await AuthService.resendForgotPasswordOTP({
                    forgotPasswordId: this.props.userDetails.forgotPasswordId
                });
            }
            if (this.props.userDetails.emailVerificationId) {
                await AuthService.resendEmailOTP({
                    emailVerificationId: this.props.userDetails.emailVerificationId
                });
            }
            this.setState({
                loader: false,
                disabled: false,
                open: true,
                isSuccess: true,
                error: 'We have re-sent OTP on your email address.',
                otp: ''
            });
        } catch (e) {
            this.setState({
                error: e.message,
                loader: false,
                open: true,
                isSuccess: false,
                disabled: false
            });
        }
    }

    onResetEmail = () => {
        if (this.props.userDetails.forgotPasswordId) {
            this.props.history.replace('/forgot');
        }
        if (this.props.userDetails.emailVerificationId) {
            this.props.history.replace('/signup');
        }
    }

    render() {
        return (
            <div style={{ overflowY: 'auto', backgroundColor: 'white' }}>
                <Grid container className={'contentHolderContainer-90vh'} justify="center" style={{ marginTop: '0%', justifyContent: 'center', }}>
                    <Grid container justify="center" className={'profile-div-background'}>
                        <Grid item >
                            <div className="profile-image-holder">
                                <img
                                    src={require('../../assets/verify-email.png')}
                                    alt=""
                                    style={{
                                        height: 90,
                                        weight: 90,
                                        borderRadius: 60
                                    }}>
                                </img>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ padding: '0px 20px' }}>
                        <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'center' }}>
                            <h2 style={{ fontFamily: "'Roboto', sans-serif", fontSize: "28px", fontWeight: 400 }}>Verify Your Email</h2>
                            <p style={{ marginBottom: 0, fontFamily: "'Roboto', sans-serif", fontSize: 14, fontWeight: 200 }}>Please enter the 4 digit code sent to</p>
                            <p style={{ marginTop: 0, fontFamily: "'Roboto', sans-serif", fontSize: 14, fontWeight: 200 }}>{this.props.userDetails.verifyEmail}</p>
                            <a style={{ color: 'blue', cursor: 'pointer' }} onClick={this.onResetEmail}>Change Email</a>
                        </Grid>
                        <form style={{ width: '100%' }}>
                            <Grid
                                container
                                justify="center"
                                style={{
                                    flexWrap: 'nowrap',
                                    marginTop: 20,
                                    marginBottom: 25,
                                    textAlign: 'center'
                                }}>
                                <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'center' }}>
                                    <OtpInput
                                        isDisabled={this.state.disabled}
                                        containerStyle="otp-container"
                                        inputStyle="otp-input"
                                        value={this.state.otp}
                                        onChange={this.handleOtpChange}
                                        shouldAutoFocus
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" style={{ textAlign: 'center' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <span style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); this.onResendEmail(); }}>Resend Code</span>
                                </Grid>
                                <div style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                </div>
                                <Grid item xs={12} md={7} lg={7} style={{ padding: '40px' }}>
                                    {
                                        !this.state.disabled ?
                                            <Button
                                                className="btn-purple"
                                                variant="contained"
                                                color="primary"
                                                onClick={(event) => { event.preventDefault(); this.onSubmit(); }}
                                                disableElevation
                                            >
                                                Done
                                            </Button> :
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </Grid>
                            </Grid>
                            <Snackbar
                                open={this.state.open}
                                autoHideDuration={3000}
                                onClose={() => this.setState({
                                    open: false
                                })}
                                action={
                                    <React.Fragment>
                                        <IconButton
                                            aria-label="close"
                                            color="white"
                                            style={{ color: 'white' }}
                                            onClick={() => this.setState({
                                                open: false
                                            })}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }>
                                <SnackbarContent style={this.state.isSuccess ? {
                                    backgroundColor: 'green',
                                } : { backgroundColor: 'red', }}
                                    message={<span id="client-snackbar">{this.state.error}</span>}
                                />
                            </Snackbar>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer
})

export default connect(mapStateToProps)(VerifyEmailOTP);