import React from "react";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const AppSnackbar = ({ props }) => {
  const { state, setState, message, isSuccess } = props;
  return (
    <Snackbar
      open={state}
      autoHideDuration={3000}
      onClose={() => setState(false)}
      action={
        <IconButton
          aria-label="close"
          style={{ color: "white" }}
          onClick={() => setState(false)}
        >
          <Close />
        </IconButton>
      }
    >
      <SnackbarContent
        style={{ backgroundColor: isSuccess ? "green" : "red" }}
        message={<span id="client-snackbar">{message}</span>}
      />
    </Snackbar>
  );
};

export default AppSnackbar;
