import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import OtpInput from "react-otp-input";
import AuthService from "../../services/AuthService";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import UserService from "../../services/UserService";
import * as GeneralFunctions from "../../GeneralFunctions";

class VerifyLoginOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      show: false,
      error: "",
      loader: false,
      disabled: false,
      isSuccess: false,
      open: false,
    };

    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: "",
        static: false,
      },
    });
  }

  componentDidMount = () => {
    if (!AuthService.getVerifyLoginID()) {
      this.props.history.push("/login");
      window.scrollTo(0, 0);
    }
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
  };

  onSubmit = async () => {
    this.setState({
      loader: true,
      disabled: true,
    });
    try {
      AuthService.setAuthorizationHeader(
        "basic " + process.env.REACT_APP_SERVER_BASE64
      );
      let body = {
        otp: this.state.otp,
        loginVerificationId: AuthService.getVerifyLoginID(),
      };
      let res = await AuthService.verifyLoginOTP(body);
      if (res.user) {
        AuthService.setAuthUser(res.user);
        AuthService.setAuthorizationHeader("bearer " + res.token);
        UserService.updateWebPushToken({ token: res.token });
        this.props.dispatch({ type: "SET_USER", user: res.user });
        if (window.webkit) {
          if (
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.jsMessageHandler
          ) {
            window.webkit.messageHandlers.jsMessageHandler.postMessage(
              "LoggedIn"
            );
          }
        }
        if (window.JSBridge) {
          window.JSBridge.LoggedIn({ message: "LoggedIn" });
        }
        this.setState({
          loader: false,
          disabled: false,
          open: true,
          isSuccess: true,
          error: "OTP verified successfully.",
          otp: "",
        });
        localStorage.removeItem("verifyLoginId");
        let visitorId = GeneralFunctions.getCookie("theablab-visitorId");
        let takeawayIds = JSON.parse(
          GeneralFunctions.getCookie("theablab-takeawayIds")
        );
        if (res.user.visitorId && !visitorId) {
          GeneralFunctions.setCookie("theablab-visitorId", res.user.visitorId);
        } else if (res.user.visitorId && res.user.visitorId != visitorId) {
          GeneralFunctions.setCookie("theablab-visitorId", res.user.visitorId);
        }
        if (res.user.takeawayIds && (!takeawayIds || !takeawayIds.length)) {
          GeneralFunctions.setCookie(
            "theablab-takeawayIds",
            JSON.stringify(res.user.takeawayIds)
          );
        }
        this.props.history.push(
          res.user.accountType === "mpk" ? "/dashboard" : "/home"
        );
      } else {
      }
    } catch (e) {
      if (e.message) {
        this.setState({
          error: e.message,
          loader: false,
          disabled: false,
          open: true,
          isSuccess: false,
          otp: "",
        });
      }
    }
  };

  onResendEmail = async () => {
    this.setState({
      loader: true,
      disabled: true,
    });
    try {
      AuthService.setAuthorizationHeader(
        "basic " + process.env.REACT_APP_SERVER_BASE64
      );
      let response = await AuthService.resendLoginOTP({
        loginVerificationId: AuthService.getVerifyLoginID(),
      });
      AuthService.setVerifyLoginID(response.loginVerificationId);
      this.setState({
        loader: false,
        disabled: false,
        open: true,
        isSuccess: true,
        error: "We have re-sent OTP on your email address.",
        otp: "",
      });
    } catch (e) {
      this.setState({
        error: e.message,
        loader: false,
        open: true,
        isSuccess: false,
        disabled: false,
      });
    }
  };

  render() {
    return (
      <div style={{ overflowY: "auto", backgroundColor: "white" }}>
        <Grid
          container
          className={"contentHolderContainer-90vh"}
          justify="center"
          style={{ marginTop: "0%", justifyContent: "center" }}
        >
          <Grid container justify="center" className={"profile-div-background"}>
            <Grid item>
              <div className="profile-image-holder">
                <img
                  src={require("../../assets/verify-email.png")}
                  alt=""
                  style={{
                    height: 90,
                    weight: 90,
                    borderRadius: 60,
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ padding: "0px 20px" }}>
            <Grid item xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
              <h2
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "28px",
                  fontWeight: 400,
                }}
              >
                Verify Your Login OTP
              </h2>
              <p
                style={{
                  marginBottom: 0,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: 14,
                  fontWeight: 200,
                }}
              >
                Please enter the 4 digit code sent to your registered email
              </p>
            </Grid>
            <form style={{ width: "100%" }}>
              <Grid
                container
                justify="center"
                style={{
                  flexWrap: "nowrap",
                  marginTop: 20,
                  marginBottom: 25,
                  textAlign: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ textAlign: "center" }}
                >
                  <OtpInput
                    isDisabled={this.state.disabled}
                    containerStyle="otp-container"
                    inputStyle="otp-input"
                    value={this.state.otp}
                    onChange={this.handleOtpChange}
                    shouldAutoFocus
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ textAlign: "center" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.onResendEmail();
                    }}
                  >
                    Resend Code
                  </span>
                </Grid>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                ></div>
                <Grid item xs={12} md={7} lg={7} style={{ padding: "40px" }}>
                  {!this.state.disabled ? (
                    <Button
                      className="btn-purple"
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        event.preventDefault();
                        this.onSubmit();
                      }}
                      disableElevation
                    >
                      Done
                    </Button>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  )}
                </Grid>
              </Grid>
              <Snackbar
                open={this.state.open}
                autoHideDuration={3000}
                onClose={() =>
                  this.setState({
                    open: false,
                  })
                }
                action={
                  <React.Fragment>
                    <IconButton
                      aria-label="close"
                      color="white"
                      style={{ color: "white" }}
                      onClick={() =>
                        this.setState({
                          open: false,
                        })
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <SnackbarContent
                  style={
                    this.state.isSuccess
                      ? {
                          backgroundColor: "green",
                        }
                      : { backgroundColor: "red" }
                  }
                  message={<span id="client-snackbar">{this.state.error}</span>}
                />
              </Snackbar>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.userReducer,
});

export default connect(mapStateToProps)(VerifyLoginOTP);
