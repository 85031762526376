import {
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AddSharp, DeleteRounded, UpdateRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ServiceService from "../../services/ServiceService";
import transformDates from "../../utils/transformDates";
import AppSnackbar from "../core/AppSnackbar";
import OverlayLoader from "../core/OverlayLoader";
import moment from "moment";
import ConfirmationDialog from "../core/Confirmation";
import { useDispatch } from "react-redux";

//TODO: error validations proper for availableOn
const AddUpdateServiceForm = ({ props }) => {
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  const submitForm = async (formData) => {
    setDisabled(true);
    setLoader(true);
    try {
      let res;
      if (!props.service) {
        res = await ServiceService.addService(formData);
      } else {
        if (!props.service._id) {
          throw Error("Something went wrong, Please try again");
        }
        res = await ServiceService.updateService(props.service._id, formData);
      }
      if (res && res.result) {
        setDisabled(false);
        setLoader(false);
        props.onClose(
          true,
          res.message ||
            `Service ${props.service ? "updated" : "added"} successfully`
        );
      }
    } catch (error) {
      setLoader(false);
      setDisabled(false);
      setIsSuccess(false);
      setMessage(error.message || "Something went wrong, Please try again");
      setOpen(true);
    }
  };

  const addDay = (date, values, setValues) => {
    let temp = new Date(date);
    temp.setHours(23, 59, 59, 999);
    temp = temp.toISOString();
    if (values.availableOn.includes(temp)) return false;
    setValues({
      ...values,
      availableOn: [...values.availableOn, temp],
    });
  };

  const removeDay = (e, index, values, setValues) => {
    e.persist();
    const temp = values.availableOn;
    temp.splice(index, 1);
    setValues({ ...values, availableOn: temp });
  };

  return (
    <div>
      {loader && <OverlayLoader />}
      <Formik
        enableReinitialize={false}
        initialValues={{
          title: props.service?.title || "",
          description: props.service?.description || "",
          availableOn: props.service?.rawDates || [],
        }}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title = "Please enter title";
          }
          if (!values.description) {
            errors.description = "Please enter description";
          }
          if (!values.availableOn || !values.availableOn.length) {
            errors.availableOn = "Please add atleast one date";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setValues,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid container style={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}
              >
                <TextField
                  id="title"
                  type="text"
                  style={{ width: "100%" }}
                  name="title"
                  error={errors.title && touched.title ? true : false}
                  disabled={disabled}
                  label="Title"
                  value={values.title}
                  placeholder={"Title"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.title && errors.title}
                  required
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginBottom: 5,
                  position: "relative",
                }}
              >
                <div
                  className="custom-dates-field"
                  style={{
                    padding: values.availableOn.length
                      ? "6px 0 7px"
                      : "8px 0 4px",
                  }}
                >
                  <label
                    className="MuiInputLabel-root Mui-focused MuiInputLabel-standard  MuiInputLabel-shrink"
                    style={{ position: "absolute", top: "8px", left: "6px" }}
                  >
                    Available On *
                  </label>
                  {values.availableOn.length ? (
                    <span>
                      {values.availableOn.map((date, idx) => (
                        <Chip
                          key={date}
                          label={moment(date).format("MM/DD/YYYY")}
                          style={{
                            fontSize: "1rem",
                            marginRight: 4,
                            marginBottom: 4,
                          }}
                          onDelete={(e) => removeDay(e, idx, values, setValues)}
                        />
                      ))}
                    </span>
                  ) : (
                    <span style={{ marginLeft: 2 }}>No days added</span>
                  )}
                </div>
                {errors.availableOn && (
                  <p className="custom-field-error">{errors.availableOn}</p>
                )}
                <IconButton
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 16,
                    zIndex: 101,
                  }}
                  onClick={() => setPickerOpen(true)}
                  title="Add Date"
                >
                  <AddSharp color="primary" />
                </IconButton>
              </Grid>

              {pickerOpen && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    open={true}
                    minDate={new Date()}
                    onChange={(date) => addDay(date, values, setValues)}
                    onClose={() => setPickerOpen(false)}
                    inputVariant="standard"
                    okLabel="Add Day"
                  />
                </MuiPickersUtilsProvider>
              )}

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}
              >
                <TextField
                  id="description"
                  multiline
                  rows={2}
                  type="text-area"
                  style={{ width: "100%", marginTop: 5 }}
                  name="description"
                  error={
                    errors.description && touched.description ? true : false
                  }
                  disabled={disabled}
                  label="Description"
                  value={values.description}
                  placeholder={"Description"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.description && errors.description}
                  required
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 15 }}
              >
                <div style={{ textDecoration: "none", marginTop: 30 }}>
                  <Button
                    className="btn-purple"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disableElevation={true}
                  >
                    {props.service ? "Update Service" : "Add Service"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <AppSnackbar
        props={{ message, isSuccess, state: open, setState: setOpen }}
      />
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceDetailsModal, setServiceDetailsModal] = useState(false);
  const [serviceAddUpdateModal, setServiceAddUpdateModal] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        title: "Services",
      },
    });
    getServices();
  }, [dispatch]);

  const notify = (msg, success = false) => {
    setIsSuccess(success);
    setMessage(msg || "Something went wrong, Try again");
    setSnackbar(true);
  };

  const getServices = async (newPage = 0, newRows = 10) => {
    try {
      setLoading(true);
      const res = await ServiceService.getServicesByUser({
        page: newPage + 1,
        limit: newRows,
      });
      if (res && res.results && Array.isArray(res.results)) {
        setServices(
          res.results.map((item) => ({
            ...item,
            rawDates: item.availableOn,
            availableOn: transformDates(item.availableOn),
          }))
        );
        setTotal(res.total);
      } else {
        setServices([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(error.message);
    }
  };

  const viewServiceDetails = (service, e) => {
    e.preventDefault();
    setSelectedService(service);
    setServiceDetailsModal(true);
  };

  const closeServiceDetailsModal = () => {
    setServiceDetailsModal(false);
    setSelectedService(null);
  };

  const viewServiceRequests = (service, e) => {
    e.preventDefault();
    history.push(`/serviceRequests/${service._id}`);
  };

  const updateService = (service, e) => {
    e.preventDefault();
    openAddUpdateServiceModal(service);
  };

  const deleteService = async () => {
    try {
      setLoading(true);
      await ServiceService.deleteService(selectedService._id);
      notify("Service deleted successfully", true);
      getServices();
    } catch (error) {
      setLoading(false);
      notify(error.message);
    }
  };

  const openDeleteConfirmDialog = async (e, service) => {
    e.preventDefault();
    setSelectedService(service);
    setDeleteConfirmDialog(true);
  };

  const onDeleteDialogClose = async (isConfirm) => {
    setDeleteConfirmDialog(false);
    if (isConfirm) {
      deleteService();
    }
  };

  const openAddUpdateServiceModal = (service = null) => {
    setSelectedService(service);
    setServiceAddUpdateModal(true);
  };

  const onAddUpdateModalClose = (auto = false, message) => {
    setServiceAddUpdateModal(false);
    if (auto) {
      setIsSuccess(true);
      setMessage(message);
      setSnackbar(true);
      getServices();
    }
  };

  const handleChangePage = (event, newPage, newRows) => {
    setPage(newPage);
    getServices(newPage, newRows);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    handleChangePage(event, 0, +event.target.value);
  };

  return (
    <Grid
      container
      style={{ justifyContent: "center" }}
      className={"contentHolderContainer-90vh"}
    >
      <Grid item xs={12} sm={12} md={11} lg={11}>
        {loading && <OverlayLoader />}
        <Card
          style={{
            boxShadow: "none",
            padding: "15px",
          }}
        >
          <CardHeader
            action={
              <Button
                onClick={() => openAddUpdateServiceModal(null)}
                style={{
                  backgroundColor: "#533bbe",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <AddSharp style={{ fontSize: "1rem", color: "white" }} />
                Add Service
              </Button>
            }
          />
          {services && services.length ? (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Available On</TableCell>
                      <TableCell>Service Req.</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services.map((obj) => (
                      <TableRow
                        key={obj._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Link
                            onClick={(e) => viewServiceDetails(obj, e)}
                            style={{ textDecoration: "none" }}
                            to="#"
                          >
                            <span className="shrink-text" title={obj.title}>
                              {obj.title}
                            </span>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <p className="shrink-text" title={obj.availableOn}>
                            {obj.availableOn}
                          </p>
                        </TableCell>
                        <TableCell>
                          <Link
                            onClick={(e) => viewServiceRequests(obj, e)}
                            style={{ textDecoration: "none" }}
                            to="#"
                          >
                            View
                          </Link>
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "flex" }}>
                            <IconButton
                              style={{ marginLeft: -15 }}
                              onClick={(e) => updateService(obj, e)}
                              title="Update service"
                            >
                              <UpdateRounded color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={(e) => openDeleteConfirmDialog(e, obj)}
                              title="Delete service"
                            >
                              <DeleteRounded color="error" />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={total || 0}
                rowsPerPage={rowsPerPage || 10}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <div
              style={{ textAlign: "center", paddingBottom: 30, paddingTop: 10 }}
            >
              No services found
            </div>
          )}
        </Card>
        {serviceDetailsModal && (
          <Dialog aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle color="textPrimary" id="simple-dialog-title">
              <strong className="custom-dialog-title">Service Details</strong>
            </DialogTitle>
            <DialogContent>
              <Grid style={{ minWidth: 400 }}>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Title: </strong>
                  {`${selectedService.title}`}
                </DialogContentText>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Available On: </strong>
                  {`${selectedService.availableOn}`}
                </DialogContentText>
                <DialogContentText
                  color="textPrimary"
                  className="wrap-long-text"
                >
                  <strong>Description: </strong>
                  {`${selectedService.description || "-"}`}
                </DialogContentText>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={closeServiceDetailsModal}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <ConfirmationDialog
          message="Are you sure you want to delete this service?"
          open={deleteConfirmDialog}
          onClose={onDeleteDialogClose}
        />
      </Grid>
      {serviceAddUpdateModal && (
        <Dialog open={true} TransitionComponent={Transition} keepMounted>
          <DialogTitle style={{ textAlign: "center" }}>
            {selectedService ? "Update" : "Add"} Service
            <IconButton
              style={{ position: "absolute", top: 10, right: 10 }}
              onClick={() => setServiceAddUpdateModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <AddUpdateServiceForm
              props={{
                service: selectedService,
                onClose: (auto, msg) => onAddUpdateModalClose(auto, msg),
              }}
            />
          </DialogContent>
        </Dialog>
      )}
      <AppSnackbar
        props={{ message, state: snackbar, setState: setSnackbar, isSuccess }}
      />
    </Grid>
  );
};

export default ServicesPage;
