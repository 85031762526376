import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { MenuComponent } from "./MenuComponent";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TakeawayDialog from "../core/Takeaway";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

function AppTitleBar(props) {
  let history = useHistory();
  let user = AuthService.getAuthUser();
  const [safeAreaCSS, setSafeAreaCSS] = useState({});
  const [takeawayOpenDialog, setTakeawayOpenDialog] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === "ios") {
        setSafeAreaCSS({
          paddingTop: "constant(safe-area-inset-top)",
          paddingTop: "env(safe-area-inset-top)",
        });
      }
    };
    checkPlatform();
  }, []);

  const menuItems = [
    {
      title: history.location.pathname.includes("/myFavorites")
        ? "Recent"
        : "Date",
      value: "date",
      show: true,
    },
    {
      title: "Rating",
      value: "rating",
      show:
        history.location.pathname.includes("/feeds") ||
          history.location.pathname.includes("/home")
          ? true
          : false,
    },
    {
      title: "Likes",
      value: "likes",
      show:
        (history.location.pathname.includes("/feeds") ||
          history.location.pathname.includes("/home")) &&
          user &&
          user.accountType === "pinger"
          ? true
          : false,
    },
    {
      title: "Comments",
      value: "comments",
      show:
        (history.location.pathname.includes("/feeds") ||
          history.location.pathname.includes("/home")) &&
          user &&
          user.accountType === "pinger"
          ? true
          : false,
    },
    {
      title: "Name",
      value: "title",
      show: history.location.pathname.includes("/myFavorites") ? true : false,
    },
  ];

  const handelOpenMenu = () => {
    props.dispatch({ type: "TOGGLE_MENU" });
  };

  const handelTakeawayDialogClose = () => {
    setTakeawayOpenDialog(false);
  };

  const openTakeawayDailog = (takeaway) => {
    setTakeawayOpenDialog(true);
  };

  return (
    <div>
      {props.toolState.toolbarconfig.show ? (
        <AppBar
          position={"fixed"}
          elevation={props.toolState.toolbarconfig.color ? 0 : 2}
          className={
            props.toolState.toolbarconfig.color ? (props.showWebApp ? "app-bar-tranperent" : "app-bar-color") : "app-bar"
          }
          style={safeAreaCSS}
        >
          <Toolbar variant="dense" style={{ justifyContent: "center" }}>
            {props.showWebApp ? (
              <IconButton
                onClick={() => history.goBack()}
                style={{
                  float: "left",
                  position: "absolute",
                  left: 0,
                  color: "white",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : null}

            {props.toolState.toolbarconfig.back ? (
              <IconButton
                className="button-back"
                onClick={() => history.goBack()}
                style={{
                  float: "left",
                  position: "absolute",
                  left: 0,
                  color: "white",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  style={{
                    padding: 0,
                    float: "left",
                    position: "absolute",
                    left: 0,
                    marginLeft: 0,
                  }}
                  onClick={() => handelOpenMenu()}
                  className="hamburger-Menu"
                >
                  <img
                    src={
                      props.toolState.color
                        ? require("../../assets/icons/Hamburger-white.svg")
                        : require("../../assets/icons/Hamburger.svg")
                    }
                    style={{ width: 55 }}
                    alt=""
                  />
                </IconButton>
              )}
            <Typography
              variant="h6"
              style={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: "GilroyLight",
              }}
            >
              {props.toolState.toolbarconfig.title}
            </Typography>
            {
              <div
                style={{
                  float: "right",
                  position: "absolute",
                  right: 0,
                  color: "white",
                  top: 0
                }}
              >
                {/* {history.location.pathname.includes("/abLabLounge") &&
                  user &&
                  user.takeaways &&
                  user.takeaways.map((takeaway, index) => {
                    return (
                      index < 2 && (
                        <img
                          key={index}
                          alt=""
                          onClick={() => openTakeawayDailog(takeaway)}
                          src={`${process.env.REACT_APP_SERVER_URL}/media/takeaways/${takeaway._id}/coverPhoto${takeaway.coverPhotoExt}`}
                          style={{
                            height: 35,
                            width: 35,
                            borderRadius: 35,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                        ></img>
                      )
                    );
                  })} */}
                {
                  history.location.pathname.includes("/abLabLounge") &&
                  <div id="theablab-bag-container"></div>
                }
                {takeawayOpenDialog && (
                  <TakeawayDialog
                    open={takeawayOpenDialog}
                    onClose={handelTakeawayDialogClose}
                    takeaways={user.takeaways}
                  />
                )}
                {props.toolState.toolbarconfig.sort ? (
                  <MenuComponent
                    props={props}
                    items={menuItems}
                    type={"titleSort"}
                  />
                ) : props.toolState.toolbarconfig.search ? (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ) : (
                      ""
                    )}
              </div>
            }
          </Toolbar>
        </AppBar>
      ) : (
          ""
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.userReducer,
  toolState: state.toolbarReducer,
});

export default connect(mapStateToProps)(AppTitleBar);
