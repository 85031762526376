import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    Grid, Card, TextField, Button, IconButton
} from '@material-ui/core';
import { Formik } from 'formik';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

const Basic = (props) => {
    const [disabled, setDisabled] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    return (<div>
        <Formik
            initialValues={{
                oldPassword: '',
                newPassword: '',
                retypePassword: ''

            }}
            validate={values => {
                const errors = {};
                if (!values.oldPassword) {
                    errors.oldPassword = 'Please enter old password.';
                }
                let passwordCheck = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
                if (!values.newPassword) {
                    errors.newPassword = "Please enter a new password.";
                }
                if (values.newPassword && !passwordCheck.test(values.newPassword)) {
                    errors.newPassword = 'Must contain at least 8 characters, including UPPER/lowercase, special character and numbers.';
                }
                if (!values.retypePassword) {
                    errors.retypePassword = 'Please enter the confirm password.';
                }
                if (values.retypePassword !== values.newPassword) {
                    errors.retypePassword = 'Confirm password dose not match new password.';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
                let body = {};
                body = values;
                try {
                    setDisabled(true);
                    let res = await UserService.changePassword(body)
                    if (res) {
                        setDisabled(false);
                        setSuccess(true);
                        setOpen(true);
                        setMessage(res.message);
                        resetForm({});
                    }
                } catch (e) {
                    setDisabled(false);
                    setSuccess(false);
                    setOpen(true);
                    setMessage(e.message);
                    resetForm({});
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                isSubmitting
            }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container justify="center" style={{ marginTop: '5%', justifyContent: 'center', }}>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-basic"
                                    label="Old Password"
                                    type="password"
                                    name="oldPassword"
                                    disabled={disabled}
                                    error={errors.oldPassword && touched.oldPassword ? true : false}
                                    value={values.oldPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.oldPassword}
                                    style={{ width: '100%' }} required />
                            </Grid>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-basic"
                                    label="New Password"
                                    type="password"
                                    name="newPassword"
                                    disabled={disabled}
                                    error={errors.newPassword && touched.newPassword ? true : false}
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.newPassword}
                                    style={{ width: '100%' }} required />
                            </Grid>
                            <Grid item xs={12} md={7} lg={7} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    id="standard-basic"
                                    label="Re-enter Password"
                                    type="password"
                                    name="retypePassword"
                                    disabled={disabled}
                                    error={errors.retypePassword && touched.retypePassword ? true : false}
                                    value={values.retypePassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.retypePassword}
                                    style={{ width: '100%' }} required />
                            </Grid>

                            <Grid item xs={12} md={7} lg={7} >
                                <div style={{ textDecoration: 'none', padding: 10 }}>
                                    {
                                        !disabled ?
                                            <Button
                                                className="btn-purple"
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disableElevation={true}
                                                style={{ textTransform: "none" }}

                                            >
                                                Change Password
                                    </Button> :
                                            <div style={{ textAlign: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            onClose={() => setOpen(false)}
                            action={
                                <React.Fragment>
                                    <IconButton
                                        aria-label="close"
                                        color="white"
                                        style={{ color: 'white' }}
                                        onClick={() => setOpen(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </React.Fragment>
                            }>
                            <SnackbarContent style={isSuccess ? {
                                backgroundColor: 'green',
                            } : { backgroundColor: 'red', }}
                                message={<span id="client-snackbar">{message}</span>}
                            />
                        </Snackbar>
                    </form>
                )}
        </Formik>
    </div>
    );
}

class ChangePasswordPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Change Password',
                static: true
            }
        });
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <Basic props={this.props} />
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ChangePasswordPage);