import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Card, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AuthService from "../../services/AuthService";
import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

class SingupAgreement extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: "",
        static: false,
      },
    });
    props.dispatch({
      type: "SHOW",
      data: false,
    });
    this.state = {
      safeAreaCSS: {
        backgroundColor: "white",
        marginTop: "0%",
        justifyContent: "center",
      },
    };
  }

  async componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
    const info = await Device.getInfo();
    if (info.platform === "ios") {
      this.setState({
        safeAreaCSS: {
          backgroundColor: "white",
          marginTop: "0%",
          justifyContent: "center",
          marginTop: "constant(safe-area-inset-top)",
          marginTop: "env(safe-area-inset-top)",
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          className={"contentHolderContainer-90vh"}
          justify="center"
          style={this.state.safeAreaCSS}
        >
          <h2
            style={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: 26,
              fontWeight: 400,
            }}
          >
            Legal Agreement
          </h2>
          <Grid container justify="center" style={{ padding: "0px 20px" }}>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <Card style={{ boxShadow: "none" }}>
                <div>
                  <p align="left">
                    <u>
                      <strong>PRIVACY POLICY</strong>
                    </u>
                  </p>
                  <p>Effective date: October 1, 2020</p>
                  <p align="justify">
                    We at Company know you care about how your personal
                    information is used and shared, and we take your privacy
                    seriously. Please read the following to learn more about our
                    Privacy Policy.
                    <strong>
                      By using or accessing the Services in any manner, you
                      acknowledge that you accept the practices and policies
                      outlined in this Privacy Policy, and you hereby consent
                      that we will collect, use, and share your information in
                      the following ways.
                    </strong>
                  </p>
                  <p>
                    Remember that your use of Company’s Services is at all times
                    subject to the Terms of Use, which incorporates this Privacy
                    Policy. Any terms we use in this Policy without defining
                    them have the definitions given to them in the Terms of Use.
                  </p>
                  <p align="left">
                    <u>
                      <strong>WHAT DOES THIS PRIVACY POLICY COVER</strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    This Privacy Policy covers our treatment of personally
                    identifiable information ("Personal Information") that we
                    gather when you are accessing or using our Services, but not
                    to the practices of companies we don’t own or control, or
                    people that we don’t manage. We gather various types of
                    Personal Information from our users, as explained in more
                    detail below, and we use this Personal Information
                    internally in connection with our Services, including to
                    personalize, provide, and improve our services, to allow you
                    to set up a user account and profile, to contact you and
                    allow other users to contact you, to fulfill your requests
                    for certain products and services, and to analyze how you
                    use the Services. In certain cases, we may also share some
                    Personal Information with third parties, but only as
                    described below.
                  </p>
                  <p align="justify">
                    As noted in the Terms of Use, we do not knowingly collect or
                    solicit personal information from anyone under the age of
                    13. If you are under 13, please do not attempt to register
                    for the Services or send any personal information about
                    yourself to us. If we learn that we have collected personal
                    information from a child under age 13, we will delete that
                    information as quickly as possible. If you believe that a
                    child under 13 may have provided us personal information,
                    please contact us at{" "}
                    <u>
                      <a href="mailto:info@theablab.com">info@theablab.com</a>
                    </u>
                    .
                  </p>
                  <p align="justify">
                    <u>
                      <strong>
                        Will Company ever change this Privacy Policy
                      </strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    We’re constantly trying to improve our Services, so we may
                    need to change this Privacy Policy from time to time as
                    well, but we will alert you to changes by placing a notice
                    on the Services, by sending you an email, and/or by some
                    other means. Please note that if you’ve opted not to receive
                    legal notice emails from us (or you haven’t provided us with
                    your email address), those legal notices will still govern
                    your use of the Services, and you are still responsible for
                    reading and understanding them. If you use the Services
                    after any changes to the Privacy Policy have been posted,
                    that means you agree to all of the changes.
                  </p>
                  <p align="justify">
                    <u>
                      <strong>What Information does Company Collect</strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    <em>
                      <u>Information You Provide to Us:</u>
                    </em>
                  </p>
                  <p align="justify">
                    We receive and store any information you knowingly provide
                    to us. For example, through the registration process and/or
                    through your account settings, we may collect Personal
                    Information such as your name, email address, gender,
                    education level, location, phone number, and third-party
                    account credentials (for example, your log-in credentials
                    for Facebook or other third party sites). If you provide
                    your third-party account credentials to us or otherwise sign
                    in to the Services through a third party site or service,
                    you understand some content and/or information in those
                    accounts (“Third Party Account Information”) may be
                    transmitted into your account with us, and that Third Party
                    Account Information transmitted to our Services is covered
                    by this Privacy Policy. Certain information may be required
                    to register with us or to take advantage of some of our
                    features.
                  </p>
                  <p align="justify">
                    We may communicate with you if you’ve provided us the means
                    to do so. For example, if you’ve given us your email
                    address, we may send you promotional email offers on behalf
                    of other businesses, or email you about your use of the
                    Services. Also, we may receive a confirmation when you open
                    an email from us. This confirmation helps us make our
                    communications with you more interesting and improve our
                    services. If you do not want to receive communications from
                    us, please indicate your preference by indicating to us that
                    you no longer wish to receive such communications by
                    clicking on the unsubscribe option in our email
                    communications or by contacting us at{" "}
                    <u>
                      <a href="mailto:info@theablab.com">info@theablab.com</a>
                    </u>
                    .
                  </p>
                  <p align="justify">
                    <em>
                      <u>Information Collected Automatically</u>
                    </em>
                  </p>
                  <p align="justify">
                    Whenever you interact with our Services, we automatically
                    receive and record information on our server logs from your
                    browser or device, which may include your IP address,
                    geolocation data, device identification, “cookie”
                    information, the type of browser and/or device you’re using
                    to access our Services, and the page or feature you
                    requested. “Cookies” are identifiers we transfer to your
                    browser or device that allow us to recognize your browser or
                    device and tell us how and when pages and features in our
                    Services are visited and by how many people. You may be able
                    to change the preferences on your browser or device to
                    prevent or limit your device’s acceptance of cookies, but
                    this may prevent you from taking advantage of some of our
                    features. Our advertising partners, if any, may also
                    transmit cookies to your browser or device, when you click
                    on ads that appear on the Services. Also, if you click on a
                    link to a third party website or service, such third party
                    may also transmit cookies to you. Again, this Privacy Policy
                    does not cover the use of cookies by any third parties, and
                    we aren’t responsible for their privacy policies and
                    practices. Please be aware that cookies placed by third
                    parties may continue to track your activities online even
                    after you have left our Services, and those third parties
                    may not honor “Do Not Track” requests you have set using
                    your browser or device.
                  </p>
                  <p align="justify">
                    We may use this data to customize content for you that we
                    think you might like, based on your usage patterns. We may
                    also use it to improve the Services - for example, this data
                    can tell us how often users use a particular feature of the
                    Services, and we can use that knowledge to make the Services
                    interesting to as many users as possible.
                  </p>
                  <p align="justify">
                    <em>
                      <u>
                        Information Collected From Other Websites and Do Not
                        Track Policy
                      </u>
                    </em>
                  </p>
                  <p align="justify">
                    Through cookies we place on your browser or device, we may
                    collect information about your online activity after you
                    leave our Services. Just like any other usage information we
                    collect, this information allows us to improve the Services
                    and customize your online experience, and otherwise as
                    described in this Privacy Policy. Your browser may offer you
                    a “Do Not Track” option, which allows you to signal to
                    operators of websites and web applications and services
                    (including behavioral advertising services) that you do not
                    wish such operators to track certain of your online
                    activities over time and across different websites. Our
                    Services do not support Do Not Track requests at this time,
                    which means that we collect information about your online
                    activity both while you are using the Services and after you
                    leave our Services.
                  </p>
                  <p align="justify">
                    <u>
                      <strong>
                        Will Company Share Any of the Personal Information it
                        Receives
                      </strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    We may share your Personal Information with third parties as
                    described in this below:
                  </p>
                  <p align="justify">
                    <strong>Access Code Provider: </strong>
                    If you register for or otherwise use the Services using an
                    Access Code then your User Submissions may be shared with
                    such Access Code Provider automatically.
                  </p>
                  <p align="justify">
                    <strong>Information that’s been de-identified: </strong>
                    We may de-identify your Personal Information so that you are
                    not identified as an individual, and provide that
                    information to our partners. We may also provide aggregate
                    usage information to our partners (or allow partners to
                    collect that information from you), who may use such
                    information to understand how often and in what ways people
                    use our Services, so that they, too, can provide you with an
                    optimal online experience. However, we never disclose
                    aggregate usage or de-identified information to a partner
                    (or allow a partner to collect such information) in a manner
                    that would identify you as an individual.
                  </p>
                  <p align="justify">
                    <strong>Analytics: </strong>
                    We may use third party analytics services, such as Google
                    Analytics, to grow our business, to improve and develop our
                    Services, to monitor and analyze use of our Services, to aid
                    our technical administration, to increase the functionality
                    and user-friendliness of our Services, and to verify that
                    users have the authorization needed for us to process their
                    requests. These services may collect and retain some
                    information about you. Google Analytics collects the IP
                    address assigned to you on the date you use the Services,
                    but not your name or other personally identifying
                    information. We do not combine the information generated
                    through the use of Google Analytics with your Personal
                    Information. Although Google Analytics plants a persistent
                    cookie on your web browser to identify you as a unique user
                    the next time you use the Services, the cookie cannot be
                    used by anyone but Google. Google’s ability to use and share
                    information collected by Google Analytics about your use of
                    the Services is restricted by the Google Analytics Terms of
                    Use and the Google Privacy Policy. You may find additional
                    information about Google Analytics at
                    www.google.com/policies/privacy/partners/. Finally, you can
                    opt out of Google Analytics by visiting
                    https://tools.google.com/dlpage/gaoptout/.
                  </p>
                  <p align="justify">
                    <strong>Advertisers:</strong>
                    We allow advertisers and/or merchant partners
                    (“Advertisers”) to choose the demographic information of
                    users who will see their advertisements and/or promotional
                    offers and you agree that we may provide any of the
                    information we have collected from you in non-personally
                    identifiable form to an Advertiser, in order for that
                    Advertiser to select the appropriate audience for those
                    advertisements and/or offers. For example, we might use the
                    fact you are located in San Francisco to show you ads or
                    offers for San Francisco businesses, but we will not tell
                    such businesses who you are. Or, we might allow Advertisers
                    to display their ads to users with similar usage patterns to
                    yours, but we will not disclose usage information to
                    Advertisers except in aggregate form, and not in a manner
                    that would identify you personally. Note that if an
                    advertiser asks us to show an ad to a certain audience or
                    audience segment and you respond to that ad, the advertiser
                    may conclude that you fit the description of the audience
                    they were trying to reach.
                  </p>
                  <p align="justify">
                    We may deliver a file to you through the Services (known as
                    a “web beacon”) from an ad network. Web beacons allow ad
                    networks to provide anonymized, aggregated auditing,
                    research and reporting for us and for advertisers. Web
                    beacons also enable ad networks to serve targeted
                    advertisements to you when you visit other websites. Because
                    your web browser must request these advertisements and web
                    beacons from the ad network’s servers, these companies can
                    view, edit, or set their own cookies, just as if you had
                    requested a web page from their site. You may be able to
                    opt-out of web beacon tracking conducted by third parties
                    through our Services by adjusting the Do Not Track settings
                    on your browser; please note that we don’t control whether
                    or how these third parties comply with Do Not Track
                    requests.
                  </p>
                  <p align="justify">
                    <strong>Affiliated Businesses:</strong>
                    In certain situations, businesses or third party websites
                    we’re affiliated with may sell or provide products or
                    services to you through or in connection with the Services
                    (either alone or jointly with us). You can recognize when an
                    affiliated business is associated with such a transaction or
                    service, and we will share your Personal Information with
                    that affiliated business only to the extent that it is
                    related to such transaction or service. One such service may
                    include the ability for you to automatically transmit Third
                    Party Account Information to your Services profile or to
                    automatically transmit information in your Services profile
                    to your third party account; for example, we may permit you
                    to share content from our Services to other websites, such
                    as Facebook and Twitter. We have no control over the
                    policies and practices of third party websites or businesses
                    as to privacy or anything else, so if you choose to take
                    part in any transaction or service relating to an affiliated
                    website or business, please review all such business’ or
                    websites’ policies.
                  </p>
                  <p align="justify">
                    <strong>Our Agents:</strong>
                    We employ other companies and people to perform tasks on our
                    behalf and need to share your information with them to
                    provide products or services to you; for example, we may use
                    a payment processing company to receive and process your
                    credit card transactions for us. Unless we tell you
                    differently, our agents do not have any right to use the
                    Personal Information we share with them beyond what is
                    necessary to assist us. Note that an “agent” may also be
                    considered a “partner” in certain circumstances, and may be
                    subject to the terms of the “{" "}
                    <strong>Information that’s been de-identified</strong>”
                    section above in that regard.
                  </p>
                  <p align="justify">
                    <strong>Other Users:</strong>
                    <em> </em>
                    Certain user profile information, including your name,
                    location, and any video or image content that such user has
                    uploaded to the Services, may be displayed to other users to
                    facilitate user interaction within the Services or address
                    your request for our services. Your account privacy settings
                    may allow you to limit the other users who can see the
                    Personal Information in your user profile and/or what
                    information in your user profile is visible to others.
                    Please remember that any content you upload to your public
                    user profile, along with any Personal Information or content
                    that you voluntarily disclose online in a manner other users
                    can view (on discussion boards, in messages and chat areas,
                    etc.) becomes publicly available, and can be collected and
                    used by anyone. Your user name may also be displayed to
                    other users if and when you send messages or comments or
                    upload images or videos through the Services and other users
                    can contact you through messages and comments. Additionally,
                    if you sign into the Services through a third party social
                    networking site or service, your list of “friends” from that
                    site or service may be automatically imported to the
                    Services, and such “friends,” if they are also registered
                    users of the Services, may be able to access certain
                    non-public information you have entered in your Services
                    user profile. Again, we do not control the policies and
                    practices of any other third party site or service.
                  </p>
                  <p align="justify">
                    <strong>Business Transfers:</strong>
                    We may choose to buy or sell assets, and may share and/or
                    transfer customer information in connection with the
                    evaluation of and entry into such transactions.
                    <em>
                      <strong> </strong>
                    </em>
                    Also, if we (or our assets) are acquired, or if we go out of
                    business, enter bankruptcy, or go through some other change
                    of control, Personal Information could be one of the assets
                    transferred to or acquired by a third party.
                  </p>
                  <p align="justify">
                    <strong>Protection of Company and Others:</strong>
                    We reserve the right to access, read, preserve, and disclose
                    any information that we reasonably believe is necessary to
                    comply with law or court order; enforce or apply our Terms
                    of Use and other agreements; or protect the rights,
                    property, or safety of Company, our employees, our users, or
                    others.
                  </p>
                  <p align="justify">
                    <u>
                      <strong>Is Personal Information about me secure</strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    Your account is protected by a password for your privacy and
                    security. If you access your account via a third party site
                    or service, you may have additional or different sign-on
                    protections via that third party site or service. You must
                    prevent unauthorized access to your account and Personal
                    Information by selecting and protecting your password and/or
                    other sign-on mechanism appropriately and limiting access to
                    your computer or device and browser by signing off after you
                    have finished accessing your account.
                  </p>
                  <p align="justify">
                    We endeavor to protect the privacy of your account and other
                    Personal Information we hold in our records, but
                    unfortunately, we cannot guarantee complete security.
                    Unauthorized entry or use, hardware or software failure, and
                    other factors, may compromise the security of user
                    information at any time.
                  </p>
                  <p align="justify">
                    Company is based in the United States and the information we
                    collect is governed by U.S. law. By accessing or using the
                    Company Services or otherwise providing information to us,
                    you consent to the processing and transfer of information in
                    and to the U.S. and other countries.
                  </p>
                  <p align="justify">
                    <u>
                      <strong>What Personal Information can I access</strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p>
                    Through your account settings, you may access, and, in some
                    cases, edit or delete the following information you’ve
                    provided to us:
                  </p>
                  <ul>
                    <li>
                      <p>name and password</p>
                    </li>
                    <li>
                      <p>email address</p>
                    </li>
                    <li>
                      <p>location</p>
                    </li>
                    <li>
                      <p>education level, including certifications</p>
                    </li>
                    <li>
                      <p>
                        <a name="_GoBack"></a>
                        user profile information, including images and videos
                        you have uploaded to the site, comments, ratings and
                        likes.
                      </p>
                    </li>
                  </ul>
                  <p align="left">
                    THE INFORMATION YOU CAN VIEW, UPDATE, AND DELETE MAY CHANGE
                    AS THE SERVICES CHANGE. IF YOU HAVE ANY QUESTIONS ABOUT
                    VIEWING OR UPDATING INFORMATION WE HAVE ON FILE ABOUT YOU,
                    PLEASE CONTACT US AT{" "}
                    <u>
                      <a href="mailto:info@theablab.com">INFO@THEABLAB.COM</a>
                    </u>
                    .
                  </p>
                  <p align="left">
                    UNDER CALIFORNIA CIVIL CODE SECTIONS 1798.83-1798.84,
                    CALIFORNIA RESIDENTS ARE ENTITLED TO CONTACT US TO PREVENT
                    DISCLOSURE OF PERSONAL INFORMATION TO THIRD PARTIES FOR SUCH
                    THIRD PARTIES’ DIRECT MARKETING PURPOSES; IN ORDER TO SUBMIT
                    SUCH A REQUEST, PLEASE CONTACT US AT{" "}
                    <u>
                      <a href="mailto:info@theablab.com">INFO@THEABLAB.COM</a>
                    </u>
                    .
                  </p>
                  <p align="left">
                    <u>
                      <strong>WHAT CHOICES DO I HAVE</strong>
                    </u>
                    <strong>?</strong>
                  </p>
                  <p align="justify">
                    You can always opt not to disclose information to us, but
                    keep in mind some information may be needed to register with
                    us or to take advantage of some of our features.
                  </p>
                  <p align="justify">
                    You may be able to add, update, or delete information as
                    explained above. When you update information, however, we
                    may maintain a copy of the unrevised information in our
                    records. You may request deletion of your account through
                    your account settings by contacting us at{" "}
                    <u>
                      <a href="mailto:info@theablab.com">info@theablab.com</a>
                    </u>
                    . Some information may remain in our records after your
                    deletion of such information from your account. We may use
                    any aggregated data derived from or incorporating your
                    Personal Information after you update or delete it, but not
                    in a manner that would identify you personally.
                  </p>
                  <p align="left">
                    <u>
                      <strong>
                        What if I have questions about this policy?
                      </strong>
                    </u>
                  </p>
                  <p align="left">
                    If you have any questions or concerns regarding our privacy
                    policies, please send us a detailed message to{" "}
                    <u>
                      <a href="mailto:info@theablab.com">info@theablab.com</a>
                    </u>
                    , and we will try to resolve your concerns.
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={7} lg={4} style={{ padding: 20 }}>
              <div style={{ textDecoration: "none" }}>
                <Button
                  className="btn-purple btn-purple-highligted"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    localStorage.setItem("acceptAgreement", "Yes");
                    let isFromLogin = localStorage.getItem("isFromLogin");
                    if (isFromLogin == "true") {
                      this.props.history.replace("/login");
                    } else {
                      this.props.history.replace("/moreInfo");
                    }
                  }}
                >
                  Agree
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(SingupAgreement);
