import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    CardHeader,
    CardActions,
    Typography,
    Fab,
    CardContent,
    TextField,
    IconButton,
    CardMedia,
    MenuItem,
    Button,
    InputAdornment,
    Input,
    FormControl
} from '@material-ui/core';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import SearchIcon from '@material-ui/icons/Search';
import Rating from 'material-ui-rating';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import * as GeneralFunctions from '../../GeneralFunctions';
import OverlayLoader from '../core/OverlayLoader';
import PostService from '../../services/PostService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import AlertDialog from '../core/Disclamer';
import ShareDialog from '../core/Share';
import MenuComponent from '../core/MenuComponent';
import * as moment from 'moment';

const levelTypes = [
    {
        label: 'Beginner',
        value: 'beginner',
        disabled: false
    },
    {
        label: 'Intermediate',
        value: 'intermediate',
        disabled: false
    },
    {
        label: 'Advanced',
        value: 'advanced',
        disabled: false
    },
    {
        label: 'Everyone',
        value: 'everyone',
        disabled: false
    },
    {
        label: 'All',
        value: 'All',
        disabled: false
    }
];
class FeedsPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: true,
                search: false,
                back: true,
                title: 'Feeds',
                static: true
            }
        });
        this.state = {
            loader: false,
            filter: {
                category: JSON.parse(localStorage.getItem('Category'))._id,
                subCategory: JSON.parse(localStorage.getItem('SubCategory'))._id,
                name: null,
                type: JSON.parse(localStorage.getItem('Type'))._id,
                level: 'All'
            },
            sort: {
                createdAt: -1
            },
            page: 1,
            limit: 20,
            pages: 0,
            totalPosts: 0,
            posts: [],
            open: false,
            isSuccess: false,
            message: '',
            playId: '',
            openAlertDialog: false,
            setPlayVideoId: '',
            openShareDialog: false,
            shareTitle: '',
            shareUrl: ''
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getPostByFilter(false);
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.toolBar.selectedSort !== null) {
            this.sortByPosts(nextProps.toolBar.selectedSort, nextProps.toolBar.selectedSortValues[nextProps.toolBar.selectedSort]);
        }
    }

    getPostByFilter = async (isPaginated) => {
        this.setState({ loader: true });
        let obj = {
            filter: this.state.filter,
            sort: this.state.sort,
            page: this.state.page,
            limit: this.state.limit
        }
        try {
            let res = await PostService.getPostByFilter(obj);
            let posts = this.state.posts;
            res.posts.forEach((post) => {
                let index = this.state.posts.findIndex((obj) => obj._id === post._id);
                if (index != -1) {
                    posts[index] = post;
                } else {
                    posts.push(post);
                }
            });
            this.setState({ loader: false });
            this.setState({ posts: isPaginated ? posts : res.posts, totalPosts: res.totalUsers, pages: res.pages });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getPostByFilter(true);
        });
    }

    postsFilterByLevel = async (level) => {
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                level: level
            }
        }, async () => {
            this.getPostByFilter(false);
        });
    }

    sortByPosts = async (sortBy, value) => {
        if (sortBy === 'date') {
            this.setState({ sort: { createdAt: -1 }, page: 1 }, () => { this.getPostByFilter(false); });
        } else if (sortBy === 'rating') {
            this.setState({ sort: { ratings: value ? 1 : -1 }, page: 1 }, () => { this.getPostByFilter(false); });
        } else if (sortBy === 'likes') {
            this.setState({ sort: { likes: value ? 1 : -1 }, page: 1 }, () => { this.getPostByFilter(false); });
        } else if (sortBy === 'comments') {
            this.setState({ sort: { comments: value ? 1 : -1 }, page: 1 }, () => { this.getPostByFilter(false); });
        } else {
            this.setState({ sort: { createdAt: -1 }, page: 1 }, () => { this.getPostByFilter(false); });
        }
    }

    openCommentPage = async (post, openFrom) => {
        localStorage.setItem("openFrom", openFrom);
        this.props.history.push('/feed/' + post);
    }

    handleToggleLike = async (post) => {
        try {
            this.setState({ loader: true });
            let res = await PostService.postLike({ postId: post._id });
            if (res.post) {
                let posts = this.state.posts;
                let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                posts[postIndex].likes = res.post.likes;
                posts[postIndex].likesCount = res.post.likes.length;
                this.setState({ posts: posts });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsLiked = (post) => {
        let user = AuthService.getAuthUser();
        let liked = post.likes.findIndex(_id => _id == user._id);
        if (liked != -1) return true;
        return false;
    }

    handleToggleFavorite = async (post) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.toggleFavoritePost({ postId: post._id });
            AuthService.setAuthUser(res.user);
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFavorite = (post) => {
        let user = AuthService.getAuthUser();
        let favorite = user.favoritePostIds.findIndex(obj => obj._id === post._id);
        if (favorite != -1) return true;
        return false;
    }

    checkIsRated = (post) => {
        let user = AuthService.getAuthUser();
        let rated = post.ratings.findIndex((rating) => rating._id == user._id);
        if (rated != -1) return true;
        return false;
    }

    handleRate = async (post, rating) => {
        try {
            this.setState({ loader: true }, async () => {
                let res = await PostService.postRate({ postId: post._id, rating: rating });
                if (res.post) {
                    let posts = this.state.posts;
                    let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                    posts[postIndex].ratings = res.post.ratings;
                    this.setState({ posts: posts });
                }
                this.setState({ loader: false });
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFlagged = (post) => {
        let user = AuthService.getAuthUser();
        if (post.flags.length && post.flags.includes(user._id)) {
            return true;
        } else {
            return false;
        }
    }

    handelFlag = async (post, rating) => {
        try {
            this.setState({ loader: true }, async () => {
                let res = await PostService.flagPost({ postId: post._id, rating: rating });
                if (res.post) {
                    let posts = this.state.posts;
                    let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                    posts[postIndex].flags = res.post.flags;
                    this.setState({ posts: posts });
                }
                this.setState({ loader: false });
            });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handleToggleFollowPinger = async (pingerUser) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.followPinger({ pingerId: pingerUser._id });
            if (res.pingerUser) {
                let posts = this.state.posts;
                posts = posts.map(obj => {
                    if (obj.ownerId == pingerUser._id) {
                        obj.owner.followers = res.pingerUser.followers;
                    }
                    return obj;
                });
                this.setState({ posts: posts });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFollowed = (pingerUser) => {
        let user = AuthService.getAuthUser();
        if (pingerUser.followers && !pingerUser.followers.length) return false;
        let followed = pingerUser.followers.findIndex(_id => _id === user._id);
        if (followed !== -1) return true;
        return false;
    }

    getAvarageRating = (post) => {
        let ratingSum = 0;
        for (let ratingObj of post.ratings) {
            ratingSum = ratingSum + ratingObj.rating
        }
        if (!ratingSum) return 0;
        return Math.ceil((ratingSum / post.ratings.length));
    }

    setRating = (post) => {
        let user = AuthService.getAuthUser();
        let ratingObj = post.ratings.find((rating) => rating._id == user._id);
        if (ratingObj) return ratingObj.rating;
        return 0;
    }

    playVideo() {
        this.setState({ playId: this.state.setPlayVideoId });
    }

    setAlertOpenDialog = (isOpen, id) => {
        this.setState({ openAlertDialog: isOpen, setPlayVideoId: id });
    }

    handleShare = (post) => {
        let shareTitle = '';
        if (post.owner) {
            shareTitle += post.owner.firstName;
            shareTitle += (post.owner.middleName ? ' ' + post.owner.middleName : '');
            shareTitle += (post.owner.lastName ? ' ' + post.owner.lastName : '');
        }
        this.setState({ openShareDialog: true, shareTitle: shareTitle, shareUrl: 'feed/' + post._id + '/commentBox' });
    }

    handelShareDialogClose = () => {
        this.setState({ openShareDialog: false, shareTitle: '', shareUrl: '' });
    }

    onPlayVideo(id) {

        this.setAlertOpenDialog(true, id);
    }

    handelAlertDialogClose = (isConfirm, id) => {
        this.setAlertOpenDialog(false, id);
        if (isConfirm) {
            this.openCommentPage(this.state.setPlayVideoId, 'videoThumbnail')
            this.playVideo();
        }
    }

    handelSearch = async () => {
        this.setState({
            page: 1
        }, async () => {
            this.getPostByFilter(false);
        });
    }

    render() {
        let user = AuthService.getAuthUser();
        let posts = this.state.posts;
        let { open, isSuccess, message } = this.state;
        let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
        let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Card elevation={0}>
                                <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                    <Grid container justify="center">
                                        <Grid item lg={9} md={9} sm={9} xs={12} style={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                            <FormControl style={{ width: '99%', marginTop: 1 }}>
                                                <Input
                                                    placeholder="Search by pinger" style={{ width: '100%' }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton type="submit" aria-label="search" onClick={() => this.handelSearch()}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    onChange={
                                                        (event) => this.setState({
                                                            filter: {
                                                                ...this.state.filter,
                                                                name: event.currentTarget.value
                                                            }
                                                        })
                                                    } />
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                            <TextField
                                                id="standard-select-currency"
                                                select
                                                style={{ width: '100%' }}
                                                disabled={false}
                                                name="level"
                                                label="By Level"
                                                placeholder={'Select Level'}
                                                value={this.state.filter.level}
                                                onChange={(event) => {
                                                    this.postsFilterByLevel(event.target.value);
                                                }}
                                            >
                                                {
                                                    levelTypes.map((option) => {
                                                        return (
                                                            <MenuItem key={option.label} value={option.value} disabled={option.disabled}>
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {
                            posts.length ?
                                posts.map((post, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                            <Card style={{ boxShadow: 'none' }}>
                                                <CardHeader
                                                    style={{ textAlign: 'left', paddingBottom: 5 }}
                                                    avatar={
                                                        <div>
                                                            <img
                                                                src={post.owner && post.owner.profilePic ? post.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                alt="Post owner"
                                                            />
                                                        </div>
                                                    }
                                                    action={
                                                        <div style={{ width: '100%', display: 'inline-flex' }}>
                                                            {
                                                                post.owner.accountType != 'basic' &&
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleToggleFollowPinger(post.owner)}
                                                                    style={{
                                                                        width: 50,
                                                                        backgroundColor: '#9013fe',
                                                                        fontSize: '0.7rem',
                                                                        marginTop: '7px',
                                                                        float: 'right',
                                                                        fontWeight: 'bold',
                                                                        right: 10
                                                                    }}>
                                                                    {
                                                                        this.checkIsFollowed(post.owner) ?
                                                                            'Unfollow' :
                                                                            'Follow'
                                                                    }
                                                                </Button>
                                                            }
                                                            <MenuComponent props={this.props} items={[{ title: 'Flag', value: this.checkIsFlagged(post) ? 'flagged' : 'unflagged', show: true, }]} type={"myFeedMenu"} selectedItems={(item) => { this.handelFlag(post) }} />
                                                        </div>
                                                    }
                                                    title={
                                                        <span style={{ fontSize: '16px', fontWeight: 400 }}>
                                                            {post.owner && post.owner.firstName} {post.owner && post.owner.middleName} {post.owner && post.owner.lastName}
                                                        </span>
                                                    }
                                                    subheader={moment(post.createdAt).format("MM/DD/YYYY HH:mm")}
                                                />
                                                <CardContent style={{ paddingTop: 0 }}>
                                                    <Grid container style={{ paddingLeft: '4%', paddingRight: '4%', paddingBottom: 10 }}>
                                                        <Grid item xs={6} md={8} lg={8} style={{ display: 'flex' }}>
                                                            {post.title}
                                                        </Grid>
                                                        <Grid item xs={6} md={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            Level: {GeneralFunctions.capitalize(post.level)}
                                                        </Grid>
                                                    </Grid>
                                                    <div className="mediaCardOverlay">
                                                        <Fab aria-label="like" className="overlay-button" onClick={() => { this.onPlayVideo(post._id) }}>
                                                            <img alt="" className="overlay-button-img" src={require('../../assets/icons/playbutton.svg')} />
                                                        </Fab>
                                                    </div>
                                                    <CardMedia
                                                        component="img"
                                                        image={post.wowzaVideoKey ? `${thumbnailUrl}/${post.wowzaVideoKey}` : `${imageBaseUrl}/${post._id}/${post._id}_thumbnail.jpeg`}
                                                        className='mediaCard'
                                                    />
                                                </CardContent>
                                                <CardActions disableSpacing >
                                                    <Grid container style={{ textAlign: 'center' }}>

                                                        <React.Fragment>
                                                            {user.accountType == 'pinger' &&
                                                                <Grid container>
                                                                    <Grid item xs={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                                                                        <Fab size="small" aria-label="share" className="card-bottom-icons" onClick={() => this.openCommentPage(post._id, 'commentBox')} >
                                                                            <ChatBubbleOutlineRoundedIcon />
                                                                        </Fab>
                                                                        <div className="comment-count">
                                                                            {post.commentsCount}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={4} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                        <Fab className={`like-circle-icon ${this.checkIsLiked(post) ? "MuiFab-primary" : "MuiFab-white"}`} size="small" aria-label="share" onClick={() => this.handleToggleLike(post)}>
                                                                            <ThumbUpOutlinedIcon />
                                                                        </Fab>
                                                                        <div className="comment-count">
                                                                            {post.likesCount}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                        <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                                            <ShareOutlined onClick={() => this.handleShare(post)} />
                                                                        </Fab>
                                                                    </Grid>
                                                                    <Grid item xs={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                        <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                                            {
                                                                                this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                                    : <FavoriteBorderOutlinedIcon />
                                                                            }
                                                                        </Fab>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            {(user.accountType === 'ponger' || user.accountType === 'basic') &&
                                                                <Grid container>
                                                                    <Grid item xs={6} className="star-rating-text-holder-no-border">
                                                                        <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} /> {this.getAvarageRating(post)} out of 5
                                                        </Grid>
                                                                    <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                        <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                                            <ShareOutlined onClick={() => this.handleShare(post)} />
                                                                        </Fab>
                                                                    </Grid>
                                                                    <Grid item xs={3} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                                        <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                                            {
                                                                                this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                                    : <FavoriteBorderOutlinedIcon />
                                                                            }
                                                                        </Fab>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </React.Fragment>
                                                    </Grid>
                                                </CardActions>
                                                {
                                                    user.accountType === 'pinger' &&
                                                    <CardActions style={{ padding: '8px 0px' }}>
                                                        <Grid container style={{ display: 'contents' }}>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} className="star-rating-holder">
                                                                <Rating
                                                                    value={this.setRating(post)}
                                                                    max={5}
                                                                    disabled={this.checkIsRated(post)}
                                                                    iconHovered={<StarRoundedIcon name="rating1" style={{ color: '#6fdf93' }} />}
                                                                    iconFilled={<StarRoundedIcon name="rating1" style={{ color: this.checkIsRated(post) ? '#6fdf93' : '#22bf45' }} />}
                                                                    onChange={(value) => {
                                                                        this.handleRate(post, value);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} className="star-rating-text-holder">
                                                                <StarRoundedIcon name="rating1" style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} />
                                                                <span>{this.getAvarageRating(post)} out of 5</span>
                                                            </Grid>
                                                        </Grid>
                                                    </CardActions>
                                                }
                                            </Card>
                                        </Grid>
                                    )
                                })
                                : <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Card elevation={0}>
                                        <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                            <div style={{ textAlign: 'center' }}>No feeds found.</div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    this.state.page < this.state.pages &&
                    <Grid container style={{ justifyContent: 'center' }}>
                        <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                    </Grid>
                }
                <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; and (b) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP."
                    open={this.state.openAlertDialog} onClose={this.handelAlertDialogClose} />
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => this.setState({
                        open: false
                    })}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => this.setState({ open: false })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
                {
                    this.state.openShareDialog && this.state.shareUrl &&
                    < ShareDialog url={this.state.shareUrl} title={this.state.shareTitle} open={this.state.openShareDialog} onClose={this.handelShareDialogClose} />
                }
            </Grid >
        )
    }
}

const mapStateToProps = (state) => ({
    catSubCat: state.catSubCatReducer,
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(FeedsPage);