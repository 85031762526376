/*global google*/
import { useState, useEffect } from "react";

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (options.onMarkerClick)
        marker.addListener("click", options.onMarkerClick);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
