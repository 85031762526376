import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    CardHeader,
    CardActions,
    Fab,
    CardContent,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    Collapse,
    Tooltip
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import SentimentSatisfiedSharpIcon from '@material-ui/icons/SentimentSatisfiedSharp';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import FilterListIcon from '@material-ui/icons/FilterList';
import * as moment from 'moment';
import ReactPlayer from 'react-player';
import OverlayLoader from '../core/OverlayLoader';
import MenuComponent from '../core/MenuComponent';
import ConfirmationDialog from '../core/Confirmation';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import PostService from '../../services/PostService';
import * as GeneralFunctions from '../../GeneralFunctions';
import ShareDialog from '../core/Share';
let selectedPostId;

class MyFeedsPage extends Component {
    constructor(props) {
        super(props);
        let user = AuthService.getAuthUser();
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: (user && user.accountType === 'pinger' ? 'My Posted Videos' : 'My Posted Giggles'),
                static: true
            }
        });
        props.dispatch({ type: 'SHOW', data: true });
        this.state = {
            loader: false,
            showConfirmationDialog: false,
            page: 1,
            limit: 20,
            pages: 0,
            totalPosts: 0,
            posts: [],
            open: false,
            isSuccess: false,
            message: '',
            openShareDialog: false,
            shareTitle: '',
            shareUrl: ''
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getMyFeeds(false);
    }

    getMyFeeds = async (isPaginated) => {
        let obj = {
            page: this.state.page,
            limit: this.state.limit
        }
        try {
            this.setState({ loader: true });
            let res = await UserService.getMyFeeds(obj);
            let posts = this.state.posts;
            res.posts.forEach((post) => {
                let index = this.state.posts.findIndex((obj) => obj._id === post._id);
                if (index != -1) {
                    posts[index] = post;
                } else {
                    posts.push(post);
                }
            });
            posts = posts.map((post) => { post.extended = false; return post; });
            let serverPosts = res.posts.map((post) => { post.extended = false; return post; });
            this.setState({ loader: false });
            this.setState({ posts: isPaginated ? posts : serverPosts, totalPosts: res.totalUsers, pages: res.pages });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getMyFeeds(true);
        });
    }


    deletePost = async (post) => {
        selectedPostId = post._id;
        this.setState({ showConfirmationDialog: true });
    }

    handleConfirmationDailogClose = (isConfirm) => {
        this.setState({ showConfirmationDialog: false });
        if (isConfirm) {
            this.deletePostById()
        }
    }

    deletePostById = async () => {
        try {
            this.setState({ loader: true });
            let res = await PostService.deletePostById({ _id: selectedPostId });
            if (res) {
                let posts = this.state.posts;
                let postIndex = this.state.posts.findIndex((obj) => obj._id === selectedPostId);
                posts.splice(postIndex, 1);
                this.setState({ posts: posts });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    handleToggleFavorite = async (post) => {
        if (post.status != 'approved') return;
        try {
            this.setState({ loader: true });
            let res = await UserService.toggleFavoritePost({ postId: post._id });
            AuthService.setAuthUser(res.user);
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFavorite = (post) => {
        let user = AuthService.getAuthUser();
        let favorite = user.favoritePostIds.findIndex(obj => obj._id === post._id);
        if (favorite != -1) return true;
        return false;
    }

    handleToggleLike = async (post) => {
        if (post.status != 'approved') return;
        try {
            this.setState({ loader: true });
            let res = await PostService.postLike({ postId: post._id });
            if (res.post) {
                let posts = this.state.posts;
                let postIndex = this.state.posts.findIndex((obj) => obj._id === res.post._id);
                posts[postIndex].likes = res.post.likes;
                posts[postIndex].likesCount = res.post.likes.length;
                this.setState({ posts: posts });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsLiked = (post) => {
        let user = AuthService.getAuthUser();
        let liked = post.likes.findIndex(_id => _id === user._id);
        if (liked !== -1) return true;
        return false;
    }

    openComment = (post) => {
        if (post.status != 'approved') return;
        let newPosts = this.state.posts;
        newPosts.forEach((elm) => {
            if (elm._id === post._id) {
                elm.extended = !elm.extended;
            }
        });
        this.setState({
            posts: newPosts
        });
    }

    getAvarageRating = (post) => {
        let ratingSum = 0;
        for (let ratingObj of post.ratings) {
            ratingSum = ratingSum + ratingObj.rating
        }
        if (!ratingSum) return 0;
        return Math.ceil((ratingSum / post.ratings.length));
    }

    handleShare = (post) => {
        if (post.status != 'approved') return;
        let shareTitle = '';
        if (post.owner) {
            shareTitle += post.owner.firstName;
            shareTitle += (post.owner.middleName ? ' ' + post.owner.middleName : '');
            shareTitle += (post.owner.lastName ? ' ' + post.owner.lastName : '');
        }
        this.setState({ openShareDialog: true, shareTitle: shareTitle, shareUrl: 'feed/' + post._id + '/commentBox' });
    }

    handelShareDialogClose = () => {
        this.setState({ openShareDialog: false, shareTitle: '', shareUrl: '' });
    }

    checkReaction = (reactions, reaction) => {
        let user = AuthService.getAuthUser();
        let value = reactions.find(obj => obj.ownerId == user._id)
        if (value && (value.reaction == reaction)) return true
        else return false
    }

    checkReactionCount = (reactions, reaction) => {
        let value = reactions.filter(obj => obj.reaction == reaction)
        return value.length
    }

    render() {
        let { posts, open, isSuccess, message, showConfirmationDialog } = this.state;
        let imageBaseUrl = process.env.REACT_APP_SERVER_URL + "/media/posts";
        let thumbnailUrl = process.env.REACT_APP_SERVER_URL + "/thumbnail";
        return (<div>
            <Grid container className={'contentHolderContainer-90vh'} style={{ justifyContent: 'center' }}>
                {
                    this.state.loader &&
                    <OverlayLoader />
                }
                {
                    posts.length ?
                        posts.map((post, key) => {
                            return (
                                <Grid item xs={12} sm={12} md={11} lg={11} key={key}>
                                    <Card style={{ boxShadow: 'none' }}>
                                        <CardHeader
                                            style={{ textAlign: 'left' }}
                                            action={
                                                <MenuComponent props={this.props} items={[{ title: 'Delete', value: 'delete', show: true }]} type={"myFeedMenu"} selectedItems={(item) => { this.deletePost(post) }} />
                                            }
                                            title={
                                                <React.Fragment>
                                                    <span style={{ fontSize: '16px', fontWeight: 400 }}>
                                                        {post.title}
                                                    </span>
                                                    <label style={{ background: (post.status === 'approved' ? 'green' : (post.status === 'rejected' ? 'red' : '#e8cd0e')), padding: '3px 10px', fontSize: '14px', color: 'white', borderRadius: '20px', float: 'right' }}>
                                                        {GeneralFunctions.capitalize(post.status)}
                                                    </label>
                                                </React.Fragment>
                                            }
                                            subheader={moment(post.createdAt).format("MM/DD/YYYY HH:mm")}
                                        />
                                        <CardContent style={{ paddingTop: 0 }}>
                                            <ReactPlayer
                                                url={post.wowzaVideoKey ? process.env.REACT_APP_WOWZA_HLS_URL + "/vod/mp4:" + post.wowzaVideoKey + "_transcoded.mp4/playlist.m3u8" : `${imageBaseUrl}/${post._id}/${post._id}_video${post.videoFileExt}`}
                                                playing={false}
                                                playsinline
                                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                width="100%"
                                                height="200px"
                                                pip={false}
                                                playIcon={
                                                    <Fab aria-label="like" style={{ background: '#ffffff4a', boxShadow: 'none' }}>
                                                        <img alt="" style={{ width: 20, height: 20, marginLeft: 4 }} src={require('../../assets/icons/playbutton.svg')} />
                                                    </Fab>
                                                }
                                                light={post.wowzaVideoKey ? `${thumbnailUrl}/${post.wowzaVideoKey}` : `${imageBaseUrl}/${post._id}/${post._id}_thumbnail.jpeg`}
                                                controls={true}
                                            />
                                        </CardContent>
                                        <CardActions disableSpacing >
                                            <Grid container style={{ textAlign: 'center' }}>
                                                <React.Fragment>

                                                    <Grid container>
                                                        <Grid item xs={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                                                            <Fab size="small" aria-label="share" className="card-bottom-icons" onClick={() => this.openComment(post)}>
                                                                <ChatBubbleOutlineRoundedIcon />
                                                            </Fab>
                                                            <div className="comment-count">
                                                                {post.commentsCount}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                            <Fab className={`like-circle-icon ${this.checkIsLiked(post) ? "MuiFab-primary" : "MuiFab-white"}`} size="small" aria-label="share" onClick={() => this.handleToggleLike(post)}>
                                                                <ThumbUpOutlinedIcon />
                                                            </Fab>
                                                            <div className="comment-count">
                                                                {post.likesCount}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                            <Fab size="small" aria-label="share" className="card-bottom-icons">
                                                                <ShareOutlined onClick={() => this.handleShare(post)} />
                                                            </Fab>
                                                        </Grid>
                                                        <Grid item xs={2} md={2} lg={2} style={{ textAlign: 'center', justifyContent: 'end', display: 'flex' }}>
                                                            <Fab size="small" aria-label="add to favorites" className="card-bottom-icons" onClick={() => this.handleToggleFavorite(post)} >
                                                                {
                                                                    this.checkIsFavorite(post) ? <FavoriteIcon />
                                                                        : <FavoriteBorderOutlinedIcon />
                                                                }
                                                            </Fab>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            </Grid>
                                        </CardActions>
                                        <CardActions style={{ padding: '8px 0px' }}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="star-rating-text-holder">
                                                    <StarRoundedIcon style={{ marginTop: 5, color: '#22bf45', marginRight: 5 }} /> {this.getAvarageRating(post)} out of 5
                                            </Grid>
                                            </Grid>
                                        </CardActions>
                                        <Collapse in={post.extended} delay={10000} duration={5000}>
                                            <React.Fragment>
                                                <Grid container style={{ padding: '0px 20px' }}>
                                                    <Grid item xs={10} sm={10} md={10} lg={10}>
                                                        <p>Comments</p>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ padding: '0px 20px' }}>
                                                    <List style={{ height: '100%', width: '100%' }}>
                                                        {
                                                            post.comments.length ? post.comments.map((comment, key) => {
                                                                return (
                                                                    <ListItem key={key}>
                                                                        <Grid container>
                                                                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                                <ListItemAvatar>
                                                                                    <Avatar aria-label="recipe" >
                                                                                        <div>
                                                                                            <img
                                                                                                src={comment.owner && comment.owner.profilePic ? comment.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                                                style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                                                alt="Post owner"
                                                                                            />
                                                                                        </div>
                                                                                    </Avatar>
                                                                                </ListItemAvatar>
                                                                            </Grid>
                                                                            <Grid item xs={10} sm={10} md={10} lg={10} >
                                                                                <Grid container style={{ background: '#d3d3d3' }}>
                                                                                    <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '10px 10px' }}>
                                                                                        <span style={{ fontSize: 16 }}>
                                                                                            {
                                                                                                comment.owner.isCommentsAnonymous ? <span>Anonymous</span>
                                                                                                    : <span>{comment.owner.firstName} {comment.owner.middleName} {comment.owner.lastName}</span>
                                                                                            }
                                                                                        </span>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '10px 10px' }}>
                                                                                        <span style={{ fontSize: 16, float: 'right' }}>
                                                                                            {moment(comment.createdAt).format("MM/DD/YYYY HH:mm")}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ background: '#d3d3d3' }}>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ overflowWrap: 'break-word', marginBottom: 10, padding: 10 }} >
                                                                                        <span style={{ fontSize: 16 }}>{comment.comment}</span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container >
                                                                                    <Grid item xs={4} sm={3} md={3} lg={3} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }} >
                                                                                        <Tooltip title="Agree">
                                                                                            <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'agree') ? "MuiFab-primary" : "MuiFab-white"}`}>
                                                                                                <SentimentVerySatisfiedIcon />
                                                                                            </Fab>
                                                                                        </Tooltip>
                                                                                        <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                            {this.checkReactionCount(comment.reactions, 'agree') || 0}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={3} md={3} lg={3} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                        <Tooltip title="Neutral">
                                                                                            <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'neutral') ? "MuiFab-gray" : "MuiFab-white"}`}>
                                                                                                <SentimentSatisfiedSharpIcon />
                                                                                            </Fab>
                                                                                        </Tooltip>
                                                                                        <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                            {this.checkReactionCount(comment.reactions, 'neutral') || 0}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={3} md={3} lg={3} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                        <Tooltip title="Disagree">
                                                                                            <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(comment.reactions, 'disagree') ? "MuiFab-red" : "MuiFab-white"}`}>
                                                                                                <SentimentVeryDissatisfiedIcon />
                                                                                            </Fab>
                                                                                        </Tooltip>
                                                                                        <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                            {this.checkReactionCount(comment.reactions, 'disagree') || 0}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid className="left-boarder" item xs={12} sm={3} md={3} lg={3} style={{ fontSize: '1rem', display: 'flex', paddingTop: 5, marginTop: 5 }}>
                                                                                        <span style={{ marginLeft: 15 }}>
                                                                                            {comment.replies.length} Replies
                                                                                        </span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {
                                                                                    comment.replies && comment.replies.length ?
                                                                                        <Grid container>
                                                                                            <p>Replies</p>
                                                                                        </Grid>
                                                                                        : ''
                                                                                }
                                                                                {
                                                                                    comment.replies && comment.replies.length ?
                                                                                        comment.replies.map((reply, key) => {
                                                                                            return (
                                                                                                <Grid container key={key} >
                                                                                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                                                        <ListItemAvatar>
                                                                                                            <Avatar aria-label="recipe" >
                                                                                                                <div>
                                                                                                                    <img
                                                                                                                        src={reply.owner && reply.owner.profilePic ? reply.owner.profilePic : require('../../assets/default-user-image.png')}
                                                                                                                        style={{ height: 50, width: 50, borderRadius: 50 }}
                                                                                                                        alt="Post owner"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </Avatar>
                                                                                                        </ListItemAvatar>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={10} sm={10} md={10} lg={10} >
                                                                                                        <Grid container style={{ background: '#d3d3d3' }}>
                                                                                                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: '10px 10px' }}>
                                                                                                                <span style={{ fontSize: 16 }}>
                                                                                                                    {
                                                                                                                        reply.owner.isCommentsAnonymous ? <span>Anonymous</span>
                                                                                                                            : <span>{reply.owner && reply.owner.firstName ? (reply.owner.firstName + " " + reply.owner.lastName) : ''}</span>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ padding: 10 }}>
                                                                                                                <span style={{ fontSize: 16, float: 'right' }}>
                                                                                                                    {moment(reply.createdAt).format("MM/DD/YYYY HH:mm")}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ background: '#d3d3d3' }}>
                                                                                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ overflowWrap: 'break-word', marginBottom: 10, padding: 10 }} >
                                                                                                                <span style={{ fontSize: 16 }}>{reply.reply}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        <Grid container  >
                                                                                                            <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }} >
                                                                                                                <Tooltip title="Agree">
                                                                                                                    <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'agree') ? "MuiFab-primary" : "MuiFab-white"}`}>
                                                                                                                        <SentimentVerySatisfiedIcon />
                                                                                                                    </Fab>
                                                                                                                </Tooltip>
                                                                                                                <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                                    {this.checkReactionCount(reply.reactions, 'agree') || 0}
                                                                                                                </div>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                                <Tooltip title="Neutral">
                                                                                                                    <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'neutral') ? "MuiFab-gray" : "MuiFab-white"}`}>
                                                                                                                        <SentimentSatisfiedSharpIcon />
                                                                                                                    </Fab>
                                                                                                                </Tooltip>
                                                                                                                <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                                    {this.checkReactionCount(reply.reactions, 'neutral') || 0}
                                                                                                                </div>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
                                                                                                                <Tooltip title="Disagree">
                                                                                                                    <Fab size="small" style={{ display: 'flex-start' }} className={`like-circle-icon ${this.checkReaction(reply.reactions, 'disagree') ? "MuiFab-red" : "MuiFab-white"}`}>
                                                                                                                        <SentimentVeryDissatisfiedIcon />
                                                                                                                    </Fab>
                                                                                                                </Tooltip>
                                                                                                                <div style={{ display: 'flex-end', fontSize: 20, padding: 10 }}>
                                                                                                                    {this.checkReactionCount(reply.reactions, 'disagree') || 0}
                                                                                                                </div>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )
                                                                                        }) : ''
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItem>
                                                                )
                                                            })
                                                                : ''}
                                                    </List>
                                                </Grid>
                                            </React.Fragment>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            )
                        })
                        :
                        <Grid item lg={11} md={11} sm={12} xs={12}>
                            <Card elevation={0}>
                                <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                    <div style={{ textAlign: 'center' }}>You have not posted any video yet.</div>
                                </CardContent>
                            </Card>
                        </Grid>
                }
                <ConfirmationDialog message="Are you sure you want to delete post?" open={showConfirmationDialog} onClose={this.handleConfirmationDailogClose} />
            </Grid>
            {
                this.state.page < this.state.pages &&
                <Grid container style={{ justifyContent: 'center' }}>
                    <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                </Grid>
            }
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => this.setState({
                    open: false
                })}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="white"
                            style={{ color: 'white' }}
                            onClick={() => this.setState({ open: false })}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }>
                <SnackbarContent style={isSuccess ? {
                    backgroundColor: 'green',
                } : { backgroundColor: 'red', }}
                    message={<span id="client-snackbar">{message}</span>}
                />
            </Snackbar>
            {
                this.state.openShareDialog && this.state.shareUrl &&
                < ShareDialog url={this.state.shareUrl} title={this.state.shareTitle} open={this.state.openShareDialog} onClose={this.handelShareDialogClose} />
            }
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    catSubCat: state.catSubCatReducer,
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(MyFeedsPage);