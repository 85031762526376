import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Card,
    TextField,
    MenuItem,
    Select,
    Button,
    IconButton,
    FormControlLabel,
    Checkbox,
    FormGroup,
    InputLabel,
    List,
    ListItem
} from '@material-ui/core';
import AlertDialog from '../core/Disclamer';
import MessageDialog from '../core/Message';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverlayLoader from '../core/OverlayLoader';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import RewardService from '../../services/RewardService';
import AuthService from '../../services/AuthService';

const Basic = (props) => {
    let errorMessage = '';
    const [loader, setLoader] = useState(false);
    const [pingersLoader, setPingersLoader] = useState(false);
    const [pingers, setPingers] = useState([]);
    const [basicUsers, setBasicUsers] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isSuccess, setMessageStatus] = useState(false);
    const [checkedAllPingers, setCheckedAllPingers] = React.useState(false);
    const [checkedAllBasicUsers, setCheckedAllBasicUsers] = React.useState(false);
    const [miles, setMilles] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState({});

    const handleMilesChange = (state, zipCode, miles) => {
        setMilles(miles);
        if (state && zipCode && miles) {
            getAllPingers(false, false, state, zipCode, miles);
        }
    };

    const handleAllPingersChange = checked => {
        setCheckedAllPingers(checked);
        let allPingers = checked;
        let allBasicUsers = checkedAllBasicUsers;
        if (checked) {
            getAllPingers(allPingers, allBasicUsers, null, null, null);
        } else {
            setPingers([]);
        }
    }

    const handleAllBasicUsersChange = checked => {
        setCheckedAllBasicUsers(checked);
        let allPingers = checkedAllPingers;
        let allBasicUsers = checked;
        if (checked) {
            getAllPingers(allPingers, allBasicUsers, null, null, null);
        } else {
            setBasicUsers([]);
        }
    }

    const getAllPingers = async (allPingers, allBasicUsers, state, zipCode, miles) => {
        try {
            setPingersLoader(true);
            let body = {
                allPingers: allPingers,
                allBasicUsers: allBasicUsers,
                state: state,
                zipCode: zipCode,
                miles: miles
            }
            let res = await RewardService.getAllPingers(body);
            setPingers(res.pingers);
            setBasicUsers(res.basicUsers);
            setPingersLoader(false);
        } catch (error) {
            setPingersLoader(false);
        }
    }

    const handelClose = (value) => {
        setOpenDialog(false);
        if (value) {
            submitForm(selectedValue)
        } else {
            setLoader(false);
            setDisabled(false);
        }
    }

    const handelMessageClose = (value) => {
        setOpenMessageDialog(false);
        props.props.history.push('/myRewards');
    }

    const submitForm = async (values) => {
        try {
            let body = {};
            body = values;
            let res = await RewardService.createReward(body);
            setDisabled(false);
            setLoader(false);
            setOpenMessageDialog(true);
        } catch (e) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(e.message);
            setOpen(true);
        }
    }
    return (<div>
        <Formik
            initialValues={{
                allPingers: false,
                allBasicUsers: false,
                state: '',
                zipCode: '',
                miles: '',
                title: '',
                description: '',
                canDistribute: ''
            }}
            validate={values => {
                const errors = {};
                if (!values.title || !values.title.trim()) {
                    errors.title = 'Please enter title';
                }
                if (!values.description || !values.description.trim()) {
                    errors.description = 'Please enter description';
                }
                if (!values.allPingers && !values.allBasicUsers) {
                    if (!values.zipCode || !values.zipCode.trim()) {
                        errors.zipCode = 'Please enter zip code';
                    }
                    if (!values.state || !values.state.trim()) {
                        errors.state = 'Please enter state';
                    }
                    if (!values.miles) {
                        errors.miles = 'Please select miles';
                    }
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm, errors }) => {
                setDisabled(true);
                setLoader(true);
                setSelectedValue(values);
                setOpenDialog(true);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                isSubmitting
            }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        {
                            pingersLoader &&
                            <OverlayLoader />
                        }
                        <Grid container direction={'row'} style={{ paddingLeft: 15, paddingRight: 15 }}>
                            <Grid item xs={3} md={6} lg={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <p>Send to all</p>
                            </Grid>
                            <Grid item xs={9} md={6} lg={6}>
                                <FormGroup row>
                                    <Grid item xs={6} md={6} lg={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="allPingers"
                                                    value={values.allPingers}
                                                    error={errors.allPingers && touched.allPingers ? true : false}
                                                    helperText={touched.allPingers && errors.allPingers}
                                                    checked={checkedAllPingers}
                                                    onChange={(event) => {
                                                        values.allPingers = event.target.checked;
                                                        handleAllPingersChange(event.target.checked);
                                                        values.state = '';
                                                        values.miles = '';
                                                        values.zipCode = '';
                                                        setMilles('');
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Pingers"
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="allBasicUsers"
                                                    value={values.allBasicUsers}
                                                    checked={checkedAllBasicUsers}
                                                    onChange={(event) => {
                                                        values.allBasicUsers = event.target.checked;
                                                        handleAllBasicUsersChange(event.target.checked);
                                                        values.state = '';
                                                        values.zipCode = '';
                                                        values.miles = '';
                                                        setMilles('');
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Enthusiasts"
                                        />
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            {
                                (!checkedAllPingers && !checkedAllBasicUsers) &&
                                <React.Fragment>
                                    <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <p>Or</p>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <p>Select Location</p>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <TextField
                                            fullWidth
                                            id="byState"
                                            label="By State"
                                            name="state"
                                            value={values.state}
                                            error={errors.state && touched.state ? true : false}
                                            helperText={touched.state && errors.state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <TextField
                                            fullWidth
                                            id="zipCode"
                                            label="Zip code"
                                            name="zipCode"
                                            onBlur={handleBlur}
                                            value={values.zipCode}
                                            error={errors.zipCode && touched.zipCode ? true : false}
                                            helperText={touched.zipCode && errors.zipCode}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            style={{ width: '100%' }}
                                            name="miles"
                                            label="Miles"
                                            error={errors.miles && touched.miles ? true : false}
                                            value={miles}
                                            placeholder={'Miles'}
                                            helperText={touched.miles && errors.miles}
                                            onChange={(event) => {
                                                values.miles = event.target.value;
                                                handleMilesChange(values.state, values.zipCode, values.miles);
                                            }}
                                            required

                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </TextField>
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    fullWidth
                                    id="title"
                                    value={values.title}
                                    error={errors.title && touched.title ? true : false}
                                    label="Reward Title"
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.title && errors.title}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    rowsMax={4}
                                    value={values.description}
                                    error={errors.description && touched.description ? true : false}
                                    name="description"
                                    id="description"
                                    label="Reward Description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.description && errors.description}
                                    required
                                />
                            </Grid>
                            <Grid item xs={2} md={1} lg={1}>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    name="canDistribute"
                                    value={values.canDistribute}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={10} md={11} lg={11} style={{ paddingTop: 10 }}>
                                <span>Allow pingers to forward/distribute this reward</span>
                            </Grid>
                            {
                                (errorMessage !== '') ?
                                    <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                                        <span style={{ color: 'red', fontSize: 12 }}>
                                            {errorMessage}
                                        </span>
                                    </Grid> : ''
                            }
                        </Grid>
                        {
                            pingers && pingers.length ?
                                <React.Fragment>
                                    <Grid container direction={'row'}>
                                        <Grid item xs={12} md={12} lg={12} >
                                            <p style={{ color: '#7c7e82', background: '#e9e9e9', padding: '5px 0 5px 3%', fontSize: '12px' }}>Pingers</p>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} style={{ paddingLeft: 15, paddingRight: 15 }}>
                                        {
                                            <Grid container>
                                                <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <List style={{ overflowY: 'scroll' }}>
                                                        {
                                                            pingers.map((pinger, key) => {
                                                                return (
                                                                    <ListItem key={key} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                        <Grid item xs={2} md={1} lg={1} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                                            <div className="home-profile-image-holder" style={{ position: 'static', width: "40px", height: "40px", border: "none" }}>
                                                                                <img src={pinger.profilePic ? pinger.profilePic : require('../../assets/default-user-image.png')} alt=""
                                                                                    style={{ height: 40, width: 40, borderRadius: 80 }} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={8} md={9} lg={9} style={{ paddingLeft: '2%', paddingRight: 5 }}>{pinger.firstName} {pinger.lastName}</Grid>
                                                                        <Grid item xs={1} md={2} lg={2} style={{ paddingLeft: 5, paddingRight: 5, textAlign: 'right' }}>{pinger.totalPosts}</Grid>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </React.Fragment>
                                : ''
                        }
                        {
                            basicUsers && basicUsers.length ?
                                <React.Fragment>
                                    <Grid container direction={'row'}>
                                        <Grid item xs={12} md={12} lg={12} >
                                            <p style={{ color: '#7c7e82', background: '#e9e9e9', padding: '5px 0 5px 3%', fontSize: '12px' }}>Enthusiasts</p>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction={'row'} style={{ paddingLeft: 15, paddingRight: 15 }}>
                                        {
                                            <Grid container>
                                                <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <List style={{ overflowY: 'scroll' }}>
                                                        {
                                                            basicUsers.map((basicUser, key) => {
                                                                return (
                                                                    <ListItem key={key} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                        <Grid item xs={2} md={1} lg={1} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                                            <div className="home-profile-image-holder" style={{ position: 'static', width: "40px", height: "40px", border: "none" }}>
                                                                                <img src={basicUser.profilePic ? basicUser.profilePic : require('../../assets/default-user-image.png')} alt=""
                                                                                    style={{ height: 40, width: 40, borderRadius: 80 }} />
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={10} md={11} lg={11} style={{ paddingLeft: '2%', paddingRight: 5 }}>{basicUser.firstName} {basicUser.lastName}</Grid>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </React.Fragment>
                                : ''
                        }
                        <Grid item xs={12} md={12} lg={12} style={{ padding: '20px 0 0 0' }}>
                            <div style={{ textDecoration: 'none' }}>
                                {
                                    !disabled ?
                                        <Button
                                            className="btn-purple"
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disableElevation={true}
                                        >
                                            Send Reward
                                        </Button> :
                                        <div style={{ textAlign: 'center' }}>
                                            <CircularProgress />
                                        </div>
                                }
                            </div>
                        </Grid>
                        <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult or a business entity; (b) I have the right to make offers and solicitations to Pingers through THE AB LAB dAPP without violating the rights of any third party and/or causing injury to anyone who makes use of the offers and solicitations; (c) I give THE AB LAB dAPP the right to adapt and use the content that I upload to THE AB LAB dAPP without compensation, including content that is protected under copyright and trademark; (d) I have secured all rights and permissions that are needed for THE AB LAB dAPP’s use of the content I have uploaded, including music clearance and the right to use the names, images and likenesses of individuals depicted in the content; (e) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, other Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP and (f) I have the right to withdraw the content I have uploaded and my personally identifiable information at any time."
                            open={openDialog} onClose={handelClose} />
                        <MessageDialog message="Thank you for submitting your incentive / reward request. We will review your submission and will  contact  you for next steps within 48-72 hours."
                            open={openMessageDialog} onClose={handelMessageClose} />
                    </form>
                )}
        </Formik>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="white"
                        style={{ color: 'white' }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }>
            <SnackbarContent style={isSuccess ? {
                backgroundColor: 'green',
            } : { backgroundColor: 'red', }}
                message={<span id="client-snackbar">{message}</span>}
            />
        </Snackbar>
    </div >
    );
}

class SendRewardPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Submit Reward Request',
                static: true
            }
        });
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <Basic props={this.props} />
                    </Card>
                </Grid>
            </Grid >
        )
    }
}

export default connect()(SendRewardPage);