import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  IconButton,
  List,
  ListItemText,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Label,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import EditIcon from '@material-ui/icons/Edit';
import { Formik } from 'formik';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import OverlayLoader from '../core/OverlayLoader';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchAddress from '../core/Address';
import PhoneInput from 'react-phone-input-2';
import Viewer, { Worker, defaultLayout } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

let certificateArr = [
  {
    id: 1,
    name: 'AFAA',
    value: 'AFAA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 2,
    name: 'NASM',
    value: 'NASM',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 3,
    name: 'ISSA',
    value: 'ISSA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 4,
    name: 'ACE',
    value: 'ACE',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 5,
    name: 'ACSM',
    value: 'ACSM',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 6,
    name: 'NSCA',
    value: 'NSCA',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
  {
    id: 7,
    name: 'Other',
    value: 'Other',
    personalTrainerNo: '',
    groupExerciseNo: '',
    checked: false,
  },
];

const Basic = (props, user) => {
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setMessageStatus] = useState(false);
  const [changeResume, setChangeResume] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [certificates, setCertificates] = useState(
    props.user && props.user.certificates.length
      ? props.user.certificates
      : certificateArr
  );
  const [address, setAddress] = useState({
    city: props.user.city || '',
    state: props.user.state || '',
    zip: props.user.zip || '',
    country: props.user.country || '',
    address: props.user.address || '',
    lat: '',
    lng: '',
  });
  let { onEditted } = props;

  const handleUploadResume = async (media) => {
    try {
      setLoader(true);
      let res = await UserService.uploadResume({ media });
      if (res) {
        setLoader(false);
        setMessageStatus(true);
        setMessage('Resume uploaded successfully.');
        setOpen(true);
        AuthService.setAuthUser(res.user);
        setTimeout(() => {
          setCertificates(res.user.certificates);
          onEditted(true);
        }, 2000);
      }
    } catch (error) {
      setLoader(false);
      setMessageStatus(false);
      setMessage(error.message);
      setOpen(true);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstName: props.user.firstName || '',
          middleName: props.user.middleName || '',
          lastName: props.user.lastName || '',
          email: props.user.email || '',
          address: address.address || '',
          city: address.city || '',
          country: address.country || '',
          state: address.state || '',
          zip: address.zip || '',
          mobileNumber: props.user.mobileNumber || '',
          certificates: props.user.certificates || [],
          website: props.user.website || '',
          isFitnessProf: props.user.isFitnessProf ? 'Yes' : 'No',
          speciality: props.user.speciality || '',
          isCertified: props.user.isCertified ? 'Yes' : 'No',
          otherCertificate: props.user.otherCertificate || '',
          liabilityInsuranceCompany: props.user.liabilityInsuranceCompany,
          dateOfExpiration: props.user.dateOfExpiration,
          certificateVerificationLink: props.user.certificateVerificationLink,
          haveDegree: props.user.haveDegree ? 'Yes' : 'No',
          degreeName: props.user.degreeName || '',
          isMemberOfOrg: props.user.isMemberOfOrg ? 'Yes' : 'No',
          groupName: props.user.groupName || '',
          isCompany: props.user.isCompany ? 'Yes' : 'No',
          companyName: props.user.companyName ? props.user.companyName : '',
          isCommentsAnonymous: props.user.isCommentsAnonymous ? 'Yes' : 'No',
          agreeTermsConditions: 'No',
          media: props.user.resumeFileName || null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName || !values.address.trim()) {
            errors.firstName = 'Please enter first name';
          }
          if (!values.lastName || !values.address.trim()) {
            errors.lastName = 'Please enter last name';
          }
          if (
            !values.email ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          if (!values.zip || !values.zip.trim()) {
            errors.zip = 'Please enter the valid zip code';
          }
          if (!values.address || !values.address.trim()) {
            errors.address = 'Please enter address';
          }
          if (!values.city) {
            errors.city = 'Please enter city';
          }
          if (!values.state) {
            errors.state = 'Please enter state';
          }
          if (!values.country) {
            errors.country = 'Please enter country';
          }
          if (values.isFitnessProf == 'Yes' && !values.speciality) {
            errors.speciality = 'Please enter your specialty';
          }
          if (values.haveDegree == 'Yes' && !values.degreeName) {
            errors.degreeName = 'Please enter your degree name';
          }
          if (values.isMemberOfOrg == 'Yes' && !values.groupName) {
            errors.groupName = 'Please enter your group name';
          }
          if (values.isCompany == 'Yes' && !values.companyName) {
            errors.companyName = 'Please enter your company name';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, errors }) => {
          let isChecked = certificates.find((obj) => obj.checked);
          if (!isChecked && values.isCertified === 'Yes') {
            setMessageStatus(false);
            setMessage('Please select at least one certificate');
            setOpen(true);
            return;
          }
          let certificateValueEmptyCount = 0;
          for (let certificate of certificates) {
            if (certificate.checked) {
              if (certificate.name != 'Other') {
                if (
                  certificate.personalTrainerNo == '' ||
                  certificate.groupExerciseNo == ''
                ) {
                  certificateValueEmptyCount++;
                }
              } else {
                if (values.otherCertificate == '') {
                  certificateValueEmptyCount++;
                }
              }
            }
          }
          if (certificateValueEmptyCount) {
            setMessageStatus(false);
            setMessage('Please provide certificate details');
            setOpen(true);
            return;
          }
          let body = {};
          body = values;
          body.isCertified = values.isCertified === 'Yes' ? true : false;
          body.isFitnessProf = values.isFitnessProf === 'Yes' ? true : false;
          body.haveDegree = values.haveDegree === 'Yes' ? true : false;
          body.isMemberOfOrg = values.isMemberOfOrg === 'Yes' ? true : false;
          body.isCompany = values.isCompany === 'Yes' ? true : false;
          body.isCommentsAnonymous =
            values.isCommentsAnonymous === 'Yes' ? true : false;
          body.profilePic = props.user.profilePic || '';
          body.certificates = [];
          body.certificates = certificates;
          body.otherCertificate = values.otherCertificate || null;
          setLoader(true);
          setDisabled(true);
          try {
            let res = await UserService.updateProfile(body);
            if (res) {
              setLoader(false);
              setDisabled(false);
              setMessageStatus(true);
              setMessage('Profile updated successfully.');
              setOpen(true);
              AuthService.setAuthUser(res.user);
              setTimeout(() => {
                setCertificates(res.user.certificates);
                onEditted(true);
              }, 2000);
            }
          } catch (error) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(error.message);
            setOpen(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ width: '100%', marginTop: '20px' }}
          >
            {loader && <OverlayLoader />}
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={11} lg={11}>
                <Card style={{ boxShadow: 'none' }}>
                  <Grid container justify="center">
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        type="text"
                        name="firstName"
                        disabled={disabled}
                        error={
                          errors.firstName && touched.firstName ? true : false
                        }
                        value={values.firstName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        helperText={touched.firstName && errors.firstName}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={4}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Middle Name"
                        type="text"
                        name="middleName"
                        disabled={disabled}
                        error={
                          errors.middleName && touched.middleName ? true : false
                        }
                        value={values.middleName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        helperText={touched.middleName && errors.middleName}
                        style={{ width: '100%', marginBottom: 15 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        name="lastName"
                        type="text"
                        disabled={disabled}
                        error={
                          errors.lastName && touched.lastName ? true : false
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        helperText={touched.lastName && errors.lastName}
                        value={values.lastName}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Email ID"
                        type="email"
                        name="email"
                        disabled={true}
                        error={errors.email && touched.email ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ padding: 5, marginBottom: 15 }}
                    >
                      <SearchAddress
                        onChange={(value) => {
                          values.address = value.address;
                        }}
                        name="address"
                        onSelect={(value) => {
                          values.city = value.city;
                          values.state = value.state;
                          values.zip = value.zip;
                          values.country = value.country;
                          values.lng = value.lng;
                          values.lat = value.lat;
                          values.address = value.address;
                          setAddress({
                            city: value.city,
                            country: value.country,
                            zip: value.zip,
                            state: value.state,
                            address: value.address,
                            lat: value.lat,
                            lng: value.lng,
                          });
                        }}
                        error={errors.address && touched.address ? true : false}
                        blur={handleBlur}
                        helperText={touched.address && errors.address}
                        value={values.address}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="City"
                        name="city"
                        type="text"
                        disabled={disabled}
                        error={errors.city && touched.city ? true : false}
                        value={values.city}
                        onChange={(e) => {
                          if (
                            !e.currentTarget.value ||
                            !e.nativeEvent.data ||
                            isNaN(e.nativeEvent.data)
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        helperText={
                          touched.city && errors.city ? errors.city : ''
                        }
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="State"
                        name="state"
                        type="text"
                        disabled={disabled}
                        error={errors.state && touched.state ? true : false}
                        onChange={(e) => {
                          if (
                            !e.currentTarget.value ||
                            !e.nativeEvent.data ||
                            isNaN(e.nativeEvent.data)
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        helperText={
                          touched.state && errors.state ? errors.state : ''
                        }
                        value={values.state}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Zip code"
                        name="zip"
                        type="text"
                        disabled={disabled}
                        error={errors.zip && touched.zip ? true : false}
                        value={values.zip}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        helperText={touched.zip && errors.zip}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                      <TextField
                        id="standard-basic"
                        label="Country"
                        name="country"
                        type="text"
                        disabled={disabled}
                        error={errors.country && touched.country ? true : false}
                        onChange={(e) => {
                          if (
                            !e.currentTarget.value ||
                            !e.nativeEvent.data ||
                            isNaN(e.nativeEvent.data)
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        helperText={
                          touched.country && errors.country
                            ? errors.country
                            : ''
                        }
                        value={values.country}
                        style={{ width: '100%', marginBottom: 15 }}
                        required
                      />
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                      >
                        <PhoneInput
                          country="us"
                          value={values.mobileNumber}
                          className="contact-input"
                          prefix="+"
                          enableAreaCodes={true}
                          enableAreaCodes={['us', 'ca']}
                          preferredCountries={['']}
                          name="mobileNumber"
                          id="mobileNumber"
                          placeholder={'Contact Number'}
                          inputExtraProps={{
                            name: 'mobileNumber',
                          }}
                          onChange={(value) => {
                            values.mobileNumber = value;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    style={{ padding: '0px 0px' }}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: '10px', justifyContent: 'center' }}
                      >
                        <Grid item xs={12} md={12} lg={12} style={{}}>
                          <TextField
                            name="website"
                            error={
                              errors.website && touched.website ? true : false
                            }
                            disabled={disabled}
                            defaultValue={values.website}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="standard-basic"
                            label="What is your website?"
                            style={{ width: '100%', marginBottom: 15 }}
                          />
                        </Grid>
                        {props.user.accountType === 'pinger' && (
                          <React.Fragment>
                            <Grid container>
                              <Grid item xs={6} md={8} lg={8}>
                                <p style={{ textAlign: 'left' }}>
                                  Are you a fitness professional?
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                lg={4}
                                style={{ textAlign: 'right' }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{ marginBottom: 15 }}
                                >
                                  <RadioGroup
                                    name="isFitnessProf"
                                    error={
                                      errors.isFitnessProf &&
                                      touched.isFitnessProf
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.isFitnessProf}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    aria-label="gender"
                                    style={{ display: 'inline' }}
                                  >
                                    <FormControlLabel
                                      value={'Yes'}
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="Yes"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      value={'No'}
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="No"
                                      labelPlacement="start"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {values.isFitnessProf === 'Yes' ? (
                                <Grid item xs={12} md={12} lg={12} style={{}}>
                                  <TextField
                                    name="speciality"
                                    error={
                                      errors.speciality && touched.speciality
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.speciality}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic"
                                    label="What specialty?"
                                    style={{ width: '100%', marginBottom: 15 }}
                                    required
                                  />
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                            <Grid container>
                              <Grid item xs={6} md={8} lg={8}>
                                <p style={{ textAlign: 'left' }}>
                                  Are you currently certified? if Yes choose one
                                  or more.{' '}
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                lg={4}
                                style={{ textAlign: 'right' }}
                              >
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    name="isCertified"
                                    error={
                                      errors.isCertified && touched.isCertified
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.isCertified}
                                    disabled={disabled}
                                    onChange={(event) => {
                                      setFieldValue(
                                        'isCertified',
                                        event.target.value
                                      );
                                      setCertificates([
                                        {
                                          id: 1,
                                          name: 'AFAA',
                                          value: 'AFAA',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 2,
                                          name: 'NASM',
                                          value: 'NASM',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 3,
                                          name: 'ISSA',
                                          value: 'ISSA',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 4,
                                          name: 'ACE',
                                          value: 'ACE',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 5,
                                          name: 'ACSM',
                                          value: 'ACSM',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 6,
                                          name: 'NSCA',
                                          value: 'NSCA',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                        {
                                          id: 7,
                                          name: 'Other',
                                          value: 'Other',
                                          personalTrainerNo: '',
                                          groupExerciseNo: '',
                                          checked: false,
                                        },
                                      ]);
                                      setFieldValue('otherCertificate', '');
                                    }}
                                    onBlur={handleBlur}
                                    style={{
                                      display: 'inline',
                                      marginBottom: 15,
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="Yes"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="No"
                                      labelPlacement="start"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                            {values.isCertified == 'Yes' ? (
                              <React.Fragment>
                                {certificates.map((certificate, key) => {
                                  return (
                                    <Grid container id={key} key={key}>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: 'left' }}
                                      >
                                        <div>
                                          <label
                                            className="checkbox-container"
                                            htmlFor={certificate.id}
                                          >
                                            {certificate.name}
                                            <input
                                              type="checkbox"
                                              id={certificate.id}
                                              onChange={(event) => {
                                                let tempCertificates = [];
                                                certificates.forEach((obj) => {
                                                  if (
                                                    obj.value ===
                                                    event.target.value
                                                  ) {
                                                    obj.checked =
                                                      event.target.checked;
                                                    if (!event.target.checked) {
                                                      obj.groupExerciseNo = '';
                                                      obj.personalTrainerNo =
                                                        '';
                                                    }
                                                  }
                                                  tempCertificates.push(obj);
                                                });
                                                setCertificates(
                                                  tempCertificates
                                                );
                                                if (
                                                  event.target.value ==
                                                    'Other' &&
                                                  !event.target.checked
                                                ) {
                                                  setFieldValue(
                                                    'otherCertificate',
                                                    ''
                                                  );
                                                }
                                              }}
                                              checked={certificate.checked}
                                              name={certificate.name}
                                              value={certificate.value}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                      </Grid>
                                      {certificate.checked && (
                                        <Grid container>
                                          {certificate.name == 'Other' && (
                                            <Grid xs={12} md={12} lg={12}>
                                              <TextField
                                                name="otherCertificate"
                                                defaultValue={
                                                  values.otherCertificate
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="standard-basic"
                                                label="Enter other certification(s) along with the certificate ID's"
                                                helperText={
                                                  errors.otherCertificate
                                                }
                                                style={{
                                                  width: '100%',
                                                  marginBottom: 15,
                                                }}
                                              />
                                            </Grid>
                                          )}
                                          {certificate.name != 'Other' && (
                                            <React.Fragment>
                                              <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                style={{
                                                  paddingLeft: 5,
                                                  paddingRight: 5,
                                                }}
                                              >
                                                <TextField
                                                  name={
                                                    'personalTrainer_' +
                                                    certificate.name
                                                  }
                                                  onChange={(event) => {
                                                    let tempCertificates = [];
                                                    certificates.forEach(
                                                      (obj) => {
                                                        if (
                                                          obj.name ===
                                                          certificate.name
                                                        ) {
                                                          obj.personalTrainerNo =
                                                            event.target.value;
                                                        }
                                                        tempCertificates.push(
                                                          obj
                                                        );
                                                      }
                                                    );
                                                    setCertificates(
                                                      tempCertificates
                                                    );
                                                  }}
                                                  id="standard-basic"
                                                  label="Personal trainer certification number"
                                                  value={
                                                    certificate.personalTrainerNo
                                                  }
                                                  style={{
                                                    width: '100%',
                                                    marginBottom: 15,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                style={{
                                                  paddingLeft: 5,
                                                  paddingRight: 5,
                                                }}
                                              >
                                                <TextField
                                                  name={
                                                    'groupExercise_' +
                                                    certificate.name
                                                  }
                                                  onChange={(event) => {
                                                    let tempCertificates = [];
                                                    certificates.forEach(
                                                      (obj) => {
                                                        if (
                                                          obj.name ===
                                                          certificate.name
                                                        ) {
                                                          obj.groupExerciseNo =
                                                            event.target.value;
                                                        }
                                                        tempCertificates.push(
                                                          obj
                                                        );
                                                      }
                                                    );
                                                    setCertificates(
                                                      tempCertificates
                                                    );
                                                  }}
                                                  id="standard-basic"
                                                  label="Group exercise certification number"
                                                  value={
                                                    certificate.groupExerciseNo
                                                  }
                                                  style={{
                                                    width: '100%',
                                                    marginBottom: 15,
                                                  }}
                                                />
                                              </Grid>
                                            </React.Fragment>
                                          )}
                                        </Grid>
                                      )}
                                    </Grid>
                                  );
                                })}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                            <Grid container>
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="liabilityInsuranceCompany"
                                  error={
                                    errors.liabilityInsuranceCompany &&
                                    touched.liabilityInsuranceCompany
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    values.liabilityInsuranceCompany
                                  }
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="Liability Insurance company"
                                  style={{ width: '100%', marginBottom: 15 }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="dateOfExpiration"
                                  error={
                                    errors.dateOfExpiration &&
                                    touched.dateOfExpiration
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.dateOfExpiration}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="Date of expiration"
                                  style={{ width: '100%', marginBottom: 15 }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6} md={8} lg={8}>
                                <p style={{ textAlign: 'left' }}>
                                  Where can we verify your certification?
                                </p>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="certificateVerificationLink"
                                  error={
                                    errors.certificateVerificationLink &&
                                    touched.certificateVerificationLink
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    values.certificateVerificationLink
                                  }
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label={`www.dummyprofileurl.com`}
                                  style={{ width: '100%', marginBottom: 15 }}
                                />
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item xs={6} md={8} lg={8}>
                                <p style={{ textAlign: 'left' }}>
                                  Are you a 4 year degreed fitness/allied health
                                  professional?
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                lg={4}
                                style={{ textAlign: 'right' }}
                              >
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    name="haveDegree"
                                    error={
                                      errors.haveDegree && touched.haveDegree
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.haveDegree}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{
                                      display: 'inline',
                                      marginBottom: 15,
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="Yes"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="No"
                                      labelPlacement="start"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {values.haveDegree === 'Yes' ? (
                                <Grid item xs={12} md={12} lg={12} style={{}}>
                                  <TextField
                                    name="degreeName"
                                    error={
                                      errors.degreeName && touched.degreeName
                                        ? true
                                        : false
                                    }
                                    disabled={disabled}
                                    defaultValue={values.degreeName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic"
                                    label="If yes, where?"
                                    style={{ width: '100%', marginBottom: 15 }}
                                    required
                                  />
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                            <Grid container>
                              <Grid item xs={6} md={8} lg={8}>
                                <p style={{ textAlign: 'left' }}>
                                  Are you referred by an organization or
                                  institution?
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                lg={4}
                                style={{ textAlign: 'right' }}
                              >
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    name="isMemberOfOrg"
                                    error={
                                      errors.isMemberOfOrg &&
                                      touched.isMemberOfOrg
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.isMemberOfOrg}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{
                                      display: 'inline',
                                      marginBottom: 15,
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="Yes"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      disabled={disabled}
                                      control={<Radio />}
                                      label="No"
                                      labelPlacement="start"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {values.isMemberOfOrg === 'Yes' ? (
                                <Grid item xs={12} md={12} lg={12} style={{}}>
                                  <TextField
                                    name="groupName"
                                    error={
                                      errors.groupName && touched.groupName
                                        ? true
                                        : false
                                    }
                                    defaultValue={values.groupName}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="standard-basic"
                                    label="If yes, which one?"
                                    style={{ width: '100%', marginBottom: 15 }}
                                    required
                                  />
                                </Grid>
                              ) : (
                                ' '
                              )}
                            </Grid>
                          </React.Fragment>
                        )}
                        {props.user.accountType === 'ponger' && (
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Are you a company?
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="isCompany"
                                  error={
                                    errors.isCompany && touched.isCompany
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.isCompany}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{
                                    display: 'inline',
                                    marginBottom: 15,
                                  }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.isCompany === 'Yes' ? (
                              <Grid item xs={12} md={12} lg={12} style={{}}>
                                <TextField
                                  name="companyName"
                                  error={
                                    errors.companyName && touched.companyName
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.companyName}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="standard-basic"
                                  label="Name of company"
                                  style={{ width: '100%', marginBottom: 15 }}
                                  required
                                />
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        )}
                        {props.user.accountType === 'pinger' ? (
                          <Grid container>
                            <Grid item xs={6} md={8} lg={8}>
                              <p style={{ textAlign: 'left' }}>
                                Do you want your comments to be anonymous?
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={4}
                              lg={4}
                              style={{ textAlign: 'right' }}
                            >
                              <FormControl component="fieldset">
                                <RadioGroup
                                  name="isCommentsAnonymous"
                                  error={
                                    errors.isCommentsAnonymous &&
                                    touched.isCommentsAnonymous
                                      ? true
                                      : false
                                  }
                                  defaultValue={values.isCommentsAnonymous}
                                  disabled={disabled}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{
                                    display: 'inline',
                                    marginBottom: 15,
                                  }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    disabled={disabled}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {props.user.accountType === 'pinger' ? (
                          <Grid container justify="center">
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              style={{
                                textAlign: 'left',
                                paddingTop: 30,
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {!changeResume && props.user.resumeFileName && (
                                <React.Fragment>
                                  <span style={{ width: '20%' }}>
                                    Attached Resume
                                  </span>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => setOpenDialog(true)}
                                    style={{ width: '47%' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 10,
                                        wordBreak: 'break-all',
                                        float: 'left',
                                      }}
                                    >
                                      {props.user.resumeFileName}
                                    </span>
                                  </a>
                                  <Button
                                    style={{
                                      width: '33%',
                                      marginBottom: 15,
                                      float: 'right',
                                      marginBottom: 15,
                                      height: 40,
                                      marginLeft: 10,
                                    }}
                                    type="button"
                                    className="btn-purple"
                                    color="primary"
                                    onClick={(event) => {
                                      setChangeResume(true);
                                    }}
                                  >
                                    Change File
                                  </Button>
                                </React.Fragment>
                              )}
                              {changeResume || !props.user.resumeFileName ? (
                                <React.Fragment>
                                  <span style={{ width: '20%' }}>
                                    Upload Resume
                                  </span>
                                  <input
                                    id="media"
                                    type="file"
                                    name="media"
                                    accept=".pdf"
                                    style={{
                                      width: '60%',
                                      paddingLeft: 10,
                                      marginBottom: 15,
                                    }}
                                    label="Resume"
                                    onChange={(e) => {
                                      let file = e.target.files[0];
                                      setFieldValue('media', file);
                                    }}
                                  ></input>
                                  <Button
                                    style={{
                                      width: '20%',
                                      marginBottom: 15,
                                      float: 'right',
                                    }}
                                    type="button"
                                    className="btn-purple"
                                    color="primary"
                                    onClick={(event) => {
                                      handleUploadResume(values.media);
                                    }}
                                  >
                                    Upload
                                  </Button>
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{ padding: 20 }}
                        >
                          <Button
                            type="submit"
                            className="btn-purple"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disableElevation
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Dialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false);
              }}
              fullScreen
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent style={{ padding: 0 }}>
                <AppBar className="app-bar-color">
                  <Toolbar>
                    <Typography variant="h6" style={{ width: '100%' }}>
                      Resume
                    </Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      style={{ float: 'right' }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div style={{ marginTop: 60, height: '100vh' }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <Viewer
                      layout={layout}
                      fileUrl={
                        process.env.REACT_APP_SERVER_URL +
                        '/media/resumes/' +
                        props.user._id +
                        '/' +
                        props.user.resumeFileName
                      }
                    />
                  </Worker>
                </div>
              </DialogContent>
            </Dialog>
          </form>
        )}
      </Formik>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="white"
              style={{ color: 'white' }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarContent
          style={
            isSuccess
              ? {
                  backgroundColor: 'green',
                }
              : { backgroundColor: 'red' }
          }
          message={<span id="client-snackbar">{message}</span>}
        />
      </Snackbar>
    </div>
  );
};

const renderToolbar = (toolbarSlot) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        paddingTop: '5px',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: 'center',
        }}
      >
        <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomOutButton}</div>
        <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomPopover}</div>
        <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomInButton}</div>
      </div>
    </div>
  );
};

const layout = (isSidebarOpened, container, main, toolbar, sidebar) => {
  return defaultLayout(
    isSidebarOpened,
    container,
    main,
    toolbar(renderToolbar),
    sidebar
  );
};

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: 'SET_TOOLBAR_CONFIG',
      data: {
        show: true,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: 'My Profile',
        static: false,
      },
    });
    props.dispatch({ type: 'SHOW', data: true });
    this.state = {
      user: {},
      edit: false,
      profileLoader: false,
      openSnackBar: false,
      isSuccess: false,
      message: '',
      openDialog: false,
    };

    this.getUser();
  }
  compoenentDidMount() {
    this.getUser();
    window.scrollTo(0, 0);
  }

  getUser = async () => {
    let user = await AuthService.getAuthUser();
    if (!user) this.props.history.replace('/login');
    await this.setState({
      user: user,
    });
  };

  handelEditted = async () => {
    await this.setState((prevState) => ({
      ...prevState,
      edit: !prevState.edit,
    }));
    await this.getUser();
  };

  handleUploadProfilePic = async (media) => {
    try {
      if (
        media.type != 'image/jpeg' &&
        media.type != 'image/jpg' &&
        media.type != 'image/png'
      ) {
        this.setState({
          openSnackBar: true,
          isSuccess: false,
          message: 'Please select only valid JPEG or PNG image format.',
        });
        return;
      }
      this.setState({ profileLoader: true });
      this.setState({ openSnackBar: false });
      let res = await UserService.uploadProfilePic({ media });
      if (res) {
        this.setState({ profileLoader: false });
        this.setState({
          openSnackBar: true,
          isSuccess: true,
          message: 'Profile pic uploaded successfully.',
        });
        AuthService.setAuthUser(res.user);
        this.setState((prevState) => ({
          ...prevState,
          user: res.user,
          edit: !prevState.edit,
        }));
        this.props.history.replace('/profile');
      }
    } catch (error) {
      this.setState({
        openSnackBar: true,
        isSuccess: true,
        message: 'Profile pic uploaded successfully.',
      });
      this.setState({ profileLoader: false });
    }
  };

  render() {
    return (
      <div>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={12} md={11} lg={11}>
            <Card style={{ boxShadow: 'none' }}>
              {this.state.profileLoader && <OverlayLoader />}
              <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className="sidebar-menu-list-item-profile"
                    style={{
                      marginLeft: 0,
                      position: 'relative',
                      marginLeft: '0px',
                      padding: '15px',
                    }}
                  >
                    <h1
                      style={{
                        width: '90%',
                        textAlign: 'left',
                        paddingLeft: 25,
                        fontFamily: "'GilroyLight', sans-serif",
                        fontSize: '28px',
                        fontWeight: 600,
                        color: 'white',
                      }}
                    >
                      PROFILE
                      <IconButton
                        style={{
                          position: 'absolute',
                          marginTop: '-10px',
                          right: '15px',
                          color: 'white',
                        }}
                        onClick={async () => {
                          await this.setState((prevState) => ({
                            ...prevState,
                            edit: !prevState.edit,
                          }));
                          if (!this.state.edit) {
                            await this.getUser();
                          }
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                      </IconButton>
                    </h1>

                    <div style={{ width: '100%', height: '80px' }}>
                      <div
                        className="home-profile-image-holder"
                        style={{ zIndex: '1000' }}
                      >
                        <img
                          src={
                            this.state.user.profilePic
                              ? `${this.state.user.profilePic}`
                              : require('../../assets/default-user-image.png')
                          }
                          style={{ height: 80, width: 80, borderRadius: 80 }}
                          alt=""
                        />
                        {this.state.edit ? (
                          <span
                            className="home-profile-image-edit"
                            onClick={() => this.fileInput.click()}
                            style={{ color: '#fff' }}
                          >
                            <EditIcon style={{ fontSize: 16 }} />
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={(event) =>
                                this.handleUploadProfilePic(
                                  event.target.files[0]
                                )
                              }
                              ref={(fileInput) => (this.fileInput = fileInput)}
                            />
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="home-profile-name-holder">
                        <span
                          className="name"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {' '}
                          {this.state.user
                            ? this.state.user.firstName
                            : 'anonymous'}{' '}
                          {this.state.user ? this.state.user.lastName : ''}{' '}
                        </span>{' '}
                        <br></br>
                        <span className="state">
                          {' '}
                          {this.state.user && this.state.user.city}{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {!this.state.edit ? (
                <Grid container style={{ paddingBottom: 25 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="titlebar" style={{ marginTop: '35px' }}>
                        Personal info
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="name"
                      >
                        <ListItemText secondary="Name" />
                        <ListItemText
                          primary={
                            (this.state.user.firstName
                              ? this.state.user.firstName
                              : 'Anonymous') +
                            ' ' +
                            (this.state.user.middleName
                              ? this.state.user.middleName
                              : '') +
                            ' ' +
                            (this.state.user.lastName
                              ? this.state.user.lastName
                              : '')
                          }
                        />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="address"
                      >
                        <ListItemText secondary="Address" />
                        <ListItemText primary={this.state.user.address || ''} />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="address"
                      >
                        <ListItemText secondary="City" />
                        <ListItemText primary={this.state.user.city || ''} />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="address"
                      >
                        <ListItemText secondary="State" />
                        <ListItemText primary={this.state.user.state || ''} />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="address"
                      >
                        <ListItemText secondary="Zip code" />
                        <ListItemText primary={this.state.user.zip || ''} />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="address"
                      >
                        <ListItemText secondary="Country" />
                        <ListItemText primary={this.state.user.country || ''} />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="phone"
                      >
                        <ListItemText secondary="Phone" />
                        <ListItemText
                          primary={
                            this.state.user.mobileNumber
                              ? '+' + this.state.user.mobileNumber
                              : ''
                          }
                        />
                      </List>
                      <List
                        className="list-items"
                        component="nav"
                        aria-label="email"
                      >
                        <ListItemText secondary="Email" />
                        <ListItemText primary={this.state.user.email} />
                      </List>
                      <List
                        component="nav"
                        aria-label="accountType"
                        style={{ padding: '10px 20px' }}
                      >
                        <ListItemText secondary="User Type" />
                        <ListItemText
                          primary={
                            this.state.user.accountType == 'pinger'
                              ? 'Pinger'
                              : this.state.user.accountType == 'ponger'
                              ? 'Ponger'
                              : this.state.user.accountType === 'mpk'
                              ? 'MPK'
                              : 'Enthusiast'
                          }
                        />
                      </List>
                      {this.state.user.followers &&
                        (this.state.user.accountType === 'pinger' ||
                          this.state.user.accountType === 'mpk') &&
                        this.state.user.status === 'approved' && (
                          <List
                            className="list-items"
                            component="nav"
                            aria-label="followers"
                          >
                            <ListItemText secondary="Followers" />
                            <ListItemText
                              primary={
                                (this.state.user.followers &&
                                  this.state.user.followers.length) ||
                                0
                              }
                            />
                          </List>
                        )}
                    </Grid>

                    <div style={{ width: '100%' }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          className="titlebar"
                          style={{ marginTop: 0, marginBottom: 10 }}
                        >
                          Other info
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <List
                          className="list-items"
                          component="nav"
                          aria-label="website"
                        >
                          <ListItemText secondary="Website" />
                          <ListItemText primary={this.state.user.website} />
                        </List>
                        {this.state.user.isFitnessProf &&
                          this.state.user.speciality &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="specialty"
                            >
                              <ListItemText secondary="Specialty" />
                              <ListItemText
                                primary={this.state.user.speciality}
                              />
                            </List>
                          )}

                        {this.state.user.haveDegree &&
                          this.state.user.degreeName &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="degree"
                            >
                              <ListItemText secondary="Degree" />
                              <ListItemText
                                primary={this.state.user.degreeName}
                              />
                            </List>
                          )}

                        {this.state.user.isMemberOfOrg &&
                          this.state.user.groupName &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="group"
                            >
                              <ListItemText secondary="Group" />
                              <ListItemText
                                primary={this.state.user.groupName}
                              />
                            </List>
                          )}

                        {this.state.user.isCompany &&
                          this.state.user.companyName &&
                          this.state.user.accountType === 'ponger' && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="companyName"
                            >
                              <ListItemText secondary="Company" />
                              <ListItemText
                                primary={this.state.user.companyName}
                              />
                            </List>
                          )}
                        {this.state.user.isCertified &&
                          this.state.user.certificates &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="currentCertifications"
                            >
                              <ListItemText secondary="Current Certifications" />
                              <TableContainer>
                                <Table aria-label="simple table">
                                  <TableHead
                                    style={{
                                      backgroundColor: '#533bbe',
                                      color: 'white',
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell style={{ color: 'white' }}>
                                        Name
                                      </TableCell>
                                      <TableCell style={{ color: 'white' }}>
                                        Personal Trainer No
                                      </TableCell>
                                      <TableCell style={{ color: 'white' }}>
                                        Group Exercise No
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.user.certificates.map(
                                      (certificate) =>
                                        certificate.checked &&
                                        certificate.name != 'Other' && (
                                          <TableRow key={certificate.id}>
                                            <TableCell>
                                              {certificate.name}
                                            </TableCell>
                                            <TableCell>
                                              {certificate.personalTrainerNo}
                                            </TableCell>
                                            <TableCell>
                                              {certificate.groupExerciseNo}
                                            </TableCell>
                                          </TableRow>
                                        )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </List>
                          )}
                        {this.state.user.otherCertificate &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="otherCertificate"
                            >
                              <ListItemText secondary="Other Certificate" />
                              <ListItemText
                                primary={this.state.user.otherCertificate}
                              />
                            </List>
                          )}

                        {this.state.user.liabilityInsuranceCompany &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="liabilityInsuranceCompany"
                            >
                              <ListItemText secondary="Liability insurance company" />
                              <ListItemText
                                primary={
                                  this.state.user.liabilityInsuranceCompany
                                }
                              />
                            </List>
                          )}
                        {this.state.user.dateOfExpiration &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="dateOfExpiration"
                            >
                              <ListItemText secondary="Date of expiration" />
                              <ListItemText
                                primary={this.state.user.dateOfExpiration}
                              />
                            </List>
                          )}
                        {this.state.user.certificateVerificationLink &&
                          (this.state.user.accountType === 'pinger' ||
                            this.state.user.accountType === 'mpk') && (
                            <List
                              className="list-items"
                              component="nav"
                              aria-label="certificateVerificationLink"
                            >
                              <ListItemText secondary="Certificate Verfication URL" />
                              <ListItemText
                                primary={
                                  this.state.user.certificateVerificationLink
                                }
                              />
                            </List>
                          )}

                        {(this.state.user.accountType === 'pinger' ||
                          this.state.user.accountType === 'mpk') && (
                          <List
                            component="nav"
                            aria-label="resumeFileName"
                            style={{ padding: '10px 20px' }}
                          >
                            <ListItemText secondary="Attached Resume" />
                            {this.state.user.resumeFileName && (
                              <React.Fragment>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    this.setState({
                                      openDialog: true,
                                    });
                                  }}
                                >
                                  {this.state.user.resumeFileName}
                                </a>
                              </React.Fragment>
                            )}
                          </List>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {this.state.edit ? (
                <Grid
                  container
                  justify="center"
                  className={'contentHolderContainer-90vh'}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ padding: 10, textAlign: 'center' }}
                  >
                    <Basic
                      user={this.state.user}
                      onEditted={this.handelEditted}
                    />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Card>

            <Dialog
              open={this.state.openDialog}
              onClose={() => {
                this.setState({
                  openDialog: false,
                });
              }}
              fullScreen
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent style={{ padding: 0 }}>
                <AppBar className="app-bar-color">
                  <Toolbar>
                    <Typography variant="h6" style={{ width: '100%' }}>
                      Resume
                    </Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => {
                        this.setState({
                          openDialog: false,
                        });
                      }}
                      style={{ float: 'right' }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div style={{ marginTop: 60, height: '100vh' }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <Viewer
                      layout={layout}
                      fileUrl={
                        process.env.REACT_APP_SERVER_URL +
                        '/media/resumes/' +
                        this.state.user._id +
                        '/' +
                        this.state.user.resumeFileName
                      }
                    />
                  </Worker>
                </div>
              </DialogContent>
            </Dialog>
            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              onClose={() => this.setState({ openSnackBar: false })}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="white"
                    style={{ color: 'white' }}
                    onClick={() => this.setState({ openSnackBar: false })}
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <SnackbarContent
                style={
                  this.state.isSuccess
                    ? {
                        backgroundColor: 'green',
                      }
                    : { backgroundColor: 'red' }
                }
                message={<span id="client-snackbar">{this.state.message}</span>}
              />
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(ProfilePage);
