import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Card, CardActions, AppBar, Tabs, Tab } from '@material-ui/core';
import OverlayLoader from '../core/OverlayLoader';
import CategoryService from '../../services/CategoryService';
import AuthService from '../../services/AuthService';

class SubCategoriesPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: false,
                title: JSON.parse(localStorage.getItem('Type')).name,
                static: false
            }
        });
        this.state = {
            selectedCategory: props.match.params.categoryId,
            loader: false,
            selectedTab: 0,
            allCategories: [],
            category: {},
            tabs: [],
            subCategories: [],
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getCategories();
    }

    getCategories = async () => {
        try {
            this.setState({ loader: true });
            let res = await CategoryService.getAllCategories();
            this.setState({ loader: false });
            let category = res.categories[this.props.catSubCat.selectedType.id].cats.find((cat) => cat._id === this.state.selectedCategory);
            let subCategories = category.subCats;
            let tabs = [];
            for (let cat of res.categories[this.props.catSubCat.selectedType.id].cats) {
                if (cat._id != 'All') tabs.push(cat);
            }
            this.setState({
                category: category,
                allCategories: res.categories,
                subCategories: subCategories,
                tabs: tabs
            });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    selectSubCategory(subCategory) {
        localStorage.setItem('SubCategory', JSON.stringify(subCategory));
        this.props.dispatch({ type: 'SET_SUB_CAT', subCategory: subCategory });
        localStorage.setItem('Category', JSON.stringify(this.state.category));
        this.props.dispatch({ type: 'SET_CAT', category: this.state.category });
        this.props.history.push(`/feeds/${this.state.category._id}/${subCategory._id}`);
    }

    handleChange = (event, newValue) => {
        let category = this.state.allCategories[this.props.catSubCat.selectedType.id].cats.find((cat) => cat._id === this.state.tabs[newValue]._id);
        let subCategories = category.subCats;
        this.setState({
            category: category,
            subCategories: subCategories,
            selectedCategory: this.state.tabs[newValue]._id,
            selectedTab: newValue
        });
        this.props.dispatch({ type: 'SET_CAT', category: this.state.tabs[newValue] });
    }
    render() {
        let { category, subCategories, selectedTab, tabs } = this.state;
        let user = AuthService.getAuthUser();
        return (
            <div>
                <Grid container className={'contentHolderContainer-90vh'} style={{ justifyContent: 'center', overflow: 'hidden' }}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="sidebar-menu-list-item-profile">
                            <Grid container style={{ background: 'transparent' }}>
                                {
                                    tabs.length ?
                                        <AppBar position="static" elevation={0} style={{ background: 'transparent', zIndex: 1000 }}>
                                            <Tabs value={selectedTab}
                                                onChange={this.handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable width tabs example"
                                                TabIndicatorProps={{ style: { background: 'white' } }}
                                            >
                                                {
                                                    tabs.map((tab, key) => {
                                                        return (<Tab label={tab.name} key={key} disabled={tab.disabled} style={{ textTransform: 'none' }} />)
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        : ''}
                                <div role='tabpanel' hidden={false} style={{ background: 'white', width: '100%' }}>
                                    <Grid container style={{ padding: 20, overflowY: 'auto' }} spacing={1}>
                                        {
                                            subCategories.map((subCategory, key) => {
                                                return (
                                                    <Grid item xs={4} sm={4} md={4} lg={4} key={key} style={{ cursor: 'pointer' }} onClick={async () => { this.selectSubCategory(subCategory) }}>
                                                        <Card elevation={0} >
                                                            <div style={{ backgroundImage: `url(${require('../../assets/category/subcategory/' + subCategory._id + '.png')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderRadius: 7 }}>
                                                                <div className="card-overlay" style={{
                                                                    backgroundImage: ``,
                                                                    borderRadius: 7
                                                                }}></div>
                                                            </div>
                                                            <CardActions style={{ justifyContent: 'center' }}>
                                                                <Button size="small" color="primary" style={{ textTransform: 'capitalize' }} disabled={subCategory.disabled}>
                                                                    {subCategory.name}
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer,
    catSubCat: state.catSubCatReducer
});

export default connect(mapStateToProps)(SubCategoriesPage);