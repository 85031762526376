import React, { useState } from 'react';
import { connect } from 'react-redux';
import Menu from '../core/Menu';
function SideBar(props) {
    return (
        <div>
            <Menu />
        </div>
    );
}
const mapStateToProps = (state) => ({
    menuState: state.menuReducer
});

export default connect(mapStateToProps)(SideBar);