
import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import routes from '../routes';
import ConfirmationDialog from '../core/Confirmation';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import * as GeneralFunctions from '../../GeneralFunctions';
import { useHistory } from 'react-router-dom';

function Menu(props) {
    let history = useHistory();
    const [openSetting, setOpenSetting] = useState((history.location.pathname == '/profile' || history.location.pathname == '/changePassword') ? true : false);
    const [showLogoutLoader, setShowLogoutLoader] = useState(false);
    const [showNotificationUpdateLoader, setShowNotificationUpdateLoader] = useState(false);
    const [showUserDeleteLoader, setShowUserDeleteLoader] = useState(false);
    const [openAccountConfirmationDialog, setAccountConfirmationDialog] = React.useState(false);

    const handleSettingClick = () => {
        setOpenSetting(!openSetting);
    };
    let user = AuthService.getAuthUser();
    if (user && props.userDetails.user && props.userDetails.user.accountType) {
        if (user.accountType != props.userDetails.user.accountType) {
            user.accountType = props.userDetails.user.accountType;
        }
    }
    let isNotification = user && user.isNotification ? true : false;
    const [notificationSetting, setNotificationSetting] = useState(isNotification);
    const handelOnToggle = () => {
        props.dispatch({ type: 'TOGGLE_MENU' });
    }
    const handleLogout = async () => {
        try {
            setShowLogoutLoader(true);
            await UserService.logout();
            GeneralFunctions.clearLocalStorage(false);
            setShowLogoutLoader(false);
            await props.dispatch({ type: 'TOGGLE_MENU' });
            history.replace('/login');
        } catch (error) {
            setShowLogoutLoader(false);
        }
    }
    const handleNotificationSettingChange = async (enable) => {
        try {
            setShowNotificationUpdateLoader(true);
            await UserService.updateNotificationSetting({ enable });
            user = AuthService.getAuthUser();
            Object.assign(user, { isNotification: enable })
            AuthService.setAuthUser(user);
            setNotificationSetting(!notificationSetting);
            setShowNotificationUpdateLoader(false);
        } catch (error) {
            setShowNotificationUpdateLoader(false);
        }
    }

    const deleteAccountConfirmation = async () => {
        setAccountConfirmationDialog(true);
    }

    const handelAccountConfirmationClose = (isConfirm) => {
        setAccountConfirmationDialog(false);
        if (isConfirm) {
            deleteAccount()
        }
    }

    const deleteAccount = async () => {
        try {
            setShowUserDeleteLoader(true);
            user = AuthService.getAuthUser();
            let res = await UserService.deleteUser(user);
            if (res) {
                setShowUserDeleteLoader(false);
                localStorage.removeItem('authorization');
                localStorage.removeItem('user');
                localStorage.removeItem('Category');
                localStorage.removeItem('SubCategory');
                localStorage.removeItem('openFrom');
                history.replace('/login');
            }
        } catch (e) {
            setShowUserDeleteLoader(false);
        }
    }

    return (
        <List className="sidebar-menu-list" style={{ background: '#fff', padding: '20px' }}>
            <ListItem className="sidebar-menu-list-item-profile" style={{ marginLeft: '0px' }}>
                <div style={{ width: '100%', height: "100px" }}>
                    <div className="sidebar-profile-image-holder">
                        <img alt="" src={user && user.profilePic ? user.profilePic : require('../../assets/default-user-image.png')} style={{ height: 80, width: 80, borderRadius: 80 }}>
                        </img>
                    </div>
                    <div className="sidebar-profile-name-holder">
                        <span className="name" style={{ textTransform: 'capitalize' }}> {(user && user.firstName) ? user.firstName : 'anonymous'} {(user && user.lastName) ? user.lastName : ''} </span> <br></br>
                        <span className="state"> {user && user.city} </span>
                        <IconButton className="arrow" onClick={() => handelOnToggle()}>
                            <i className="fa fa-angle-right fa-1x" aria-hidden="true"></i>
                        </IconButton>
                    </div>
                </div>
            </ListItem>
            <div className="menu-list"></div>
            {
                routes && user ? routes.menuRoutes.filter((route) => route.show === true && route.allow.includes(user.accountType)).map((route, key) => {
                    let routeText = route.text;
                    if (route.path === '/myRewards' && user.accountType === 'ponger') {
                        routeText = 'History of Rewards Sent';
                    }
                    if (route.path === '/postVideo' && user.accountType === 'basic') {
                        routeText = 'Post Giggles';
                    }
                    if (route.path === '/myFeeds' && user.accountType === 'basic') {
                        routeText = 'My Posted Giggles';
                    }
                    return (
                        <div key={key}>
                            {
                                route.path === '/myRewards' && user.status === 'pending' ? '' :
                                    route.path === '/postVideo' && user.status === 'pending' ? '' :
                                        route.path === '/myFeeds' && user.status === 'pending' ? '' :
                                            route.path === '/notifications' && user.status === 'pending' ? '' :
                                                <React.Fragment>
                                                    <ListItem button onClick={() => { history.push(route.path) }} disableGutters={true} style={{ backgroundColor: (history.location.pathname == route.path) ? 'rgb(217 221 237)' : '' }}>
                                                        <ListItemIcon className="listItemIcon">
                                                            <img src={require(`../../assets/icons/${route.icon}`)} alt="menuicon" />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Link className="mobileMenu" to={route.path} style={{ textDecoration: 'none' }} onClick={() => {
                                                                props.dispatch({ type: 'TOGGLE_MENU' });
                                                            }}>
                                                                <span style={{ color: (route.path === '/abLabLounge' ? '#9013fe' : 'black'), fontWeight: (route.path === '/abLabLounge' ? 'bolder' : 'inherit'), fontSize: (route.path === '/abLabLounge' ? 18 : 'inherit') }}>{routeText}</span>
                                                            </Link>
                                                            <Link className="webMenu" to={route.path} style={{ textDecoration: 'none' }}>
                                                                <span style={{ color: (route.path === '/abLabLounge' ? '#9013fe' : 'black'), fontWeight: (route.path === '/abLabLounge' ? 'bolder' : 'inherit'), fontSize: (route.path === '/abLabLounge' ? 18 : 'inherit') }}>{routeText}</span>
                                                            </Link>
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                            }
                        </div>
                    )
                }) : ''
            }

            <ListItem button onClick={handleSettingClick} disableGutters={true} style={{ paddingRight: '10px' }}>
                <ListItemIcon className="listItemIcon">
                    <img src={require(`../../assets/icons/Settings.svg`)} alt="menuicon" />
                </ListItemIcon>
                <ListItemText >
                    <span style={{ color: 'black' }}>Settings</span>
                </ListItemText>
                {openSetting ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSetting} timeout="auto" unmountOnExit style={{ overflow: 'hidden' }}>
                <List component="div">
                    <ListItem onClick={() => { history.push('/profile') }} button style={{ backgroundColor: (history.location.pathname == '/profile') ? 'rgb(217 221 237)' : '', paddingLeft: '10%', marginBottom: '15px' }}>
                        <ListItemIcon className="listItemIcon">
                            <PermIdentityIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Link className="mobileMenu" to='/profile' style={{ textDecoration: 'none' }} onClick={() => {
                                props.dispatch({ type: 'TOGGLE_MENU' });
                            }}>
                                <span style={{ color: 'black' }}>My Profile</span>
                            </Link>
                            <Link className="webMenu" to='/profile' style={{ textDecoration: 'none' }}>
                                <span style={{ color: 'black' }}>My Profile</span>
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <Divider style={{ marginBottom: '15px' }} />
                    <ListItem onClick={() => { history.push('/changePassword') }} button style={{ backgroundColor: (history.location.pathname == '/changePassword') ? 'rgb(217 221 237)' : '', paddingLeft: '10%', marginBottom: '15px' }}>
                        <ListItemIcon className="listItemIcon">
                            <LockIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Link className="mobileMenu" to='/changePassword' style={{ textDecoration: 'none' }} onClick={() => {
                                props.dispatch({ type: 'TOGGLE_MENU' });
                            }}>
                                <span style={{ color: 'black' }}>Change Password</span>
                            </Link>
                            <Link className="webMenu" to='/changePassword' style={{ textDecoration: 'none' }}>
                                <span style={{ color: 'black' }}>Change Password</span>
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <Divider style={{ marginBottom: '15px' }} />
                    <ListItem onClick={() => { deleteAccountConfirmation() }} button style={{ paddingLeft: '10%', marginBottom: '15px' }}>
                        <ListItemIcon className="listItemIcon">
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {showUserDeleteLoader ?
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress size={24} />
                                </div>
                                : <span style={{ color: 'black' }} onClick={() => deleteAccountConfirmation()}>
                                    Delete Account
                                            </span>
                            }
                        </ListItemText>
                    </ListItem>
                    <ConfirmationDialog message="Are you sure you want to delete your account?" open={openAccountConfirmationDialog} onClose={handelAccountConfirmationClose} />
                    <Divider style={{ marginBottom: '15px' }} />
                    <ListItem button style={{ paddingLeft: '10%' }}>
                        <ListItemText>
                            <span style={{ color: 'black', fontFamily: 'GilroyLight' }}>
                                Notifications
                                            </span>
                        </ListItemText>
                        {
                            showNotificationUpdateLoader ?
                                <CircularProgress size={24} />
                                :
                                <Switch
                                    checked={notificationSetting}
                                    onChange={(event) => handleNotificationSettingChange(event.target.checked)}
                                    color="primary"
                                />
                        }

                    </ListItem>
                </List>
            </Collapse>
            <Divider />
            <ListItem onClick={() => { handleLogout() }} button disableGutters={true}>
                <ListItemIcon className="listItemIcon">
                    <img src={require(`../../assets/icons/Logout.svg`)} alt="menuicon" />
                </ListItemIcon>
                <ListItemText>
                    {
                        showLogoutLoader ?
                            <CircularProgress size={30} />
                            :
                            <span style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => handleLogout()}>
                                <span style={{ color: 'black' }}>
                                    Logout
                                </span>
                            </span>
                    }

                </ListItemText>
            </ListItem>
            <Divider />
            <ListItem button disableGutters={true}>
                <ListItemIcon className="listItemIcon">
                    <img src={require(`../../assets/icons/Info.svg`)} alt="menuicon" />
                </ListItemIcon>
                <ListItemText>
                    <span style={{ color: 'black' }}>Version 0.71</span>
                </ListItemText>
            </ListItem>
        </List>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.userReducer,
    menuState: state.menuReducer
});

export default connect(mapStateToProps)(Menu);