import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Slider from 'react-slick';

class PreviewPage extends Component {
  constructor(props) {
    super(props);
    this.accountType = '';

    props.dispatch({
      type: 'SET_TOOLBAR_CONFIG',
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: 'Preview Page',
        static: false,
      },
    });
  }

  componentDidMount() {
    this.accountType = localStorage.getItem('accountType');
    if (!this.accountType) this.props.history.replace('/selectUserType');
    window.scrollTo(0, 0);
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Grid
          container
          className={'contentHolderContainer-90vh'}
          justify="center"
          style={{
            marginTop: '0%',
            justifyContent: 'center',
            overflowY: 'auto',
            backgroundColor: 'white',
          }}
        >
          <Grid container justify="center" className={'profile-div-background'}>
            <Grid item>
              <div className="profile-image-holder">
                <img
                  src={require('../../assets/logo.png')}
                  alt=""
                  style={{
                    height: 90,
                    weight: 90,
                    borderRadius: 60,
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ padding: '0px 20px' }}>
            <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: 20 }}>
              <Grid
                container
                justify="center"
                style={{ marginTop: 0, justifyContent: 'center' }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.props.history.replace('/selectUserType');
                    }}
                    style={{
                      width: 50,
                      backgroundColor: '#9013fe',
                      fontSize: '0.7rem',
                      fontWeight: 'bold',
                      float: 'right',
                    }}
                  >
                    Skip
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={8}
                  style={{ padding: 20, textAlign: 'center' }}
                  className="preview-slider"
                >
                  <Slider {...settings}>
                    {this.accountType === 'pinger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/first.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'pinger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/second.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'pinger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/third.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'pinger' && (
                      <div>
                        <img
                          src={require('../../assets/preview/fourth.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'mpk' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/first.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'mpk' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/second.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'mpk' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/third.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'mpk' && (
                      <div>
                        <img
                          src={require('../../assets/preview/fourth.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'ponger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/first.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'ponger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/second.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'ponger' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/third.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'ponger' && (
                      <div>
                        <img
                          src={require('../../assets/preview/fourth.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'basic' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/first.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'basic' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/second.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'basic' && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={require('../../assets/preview/third.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                    {this.accountType === 'basic' && (
                      <div>
                        <img
                          src={require('../../assets/preview/fourth.jpg')}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 'auto',
                          }}
                        />
                      </div>
                    )}
                  </Slider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
});

export default connect(mapStateToProps)(PreviewPage);
