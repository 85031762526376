export const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const clearLocalStorage = (refreshApp) => {
    localStorage.removeItem('authorization');
    localStorage.removeItem('user');
    localStorage.removeItem('Category');
    localStorage.removeItem('SubCategory');
    localStorage.removeItem('openFrom');
    setCookie("theablab-visitorId", "");
    setCookie("theablab-takeawayIds", JSON.stringify([]));
    if (refreshApp) {
        window.location.replace('/login');
    }
}

export const setCookie = (cname, cvalue) => {
    document.cookie = cname + "=" + (cvalue || "") + "; domain=theablab.com; SameSite=None; Secure; path=/";
}

export const getCookie = (cname) => {
    let nameEQ = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const generateVisitorId = () => {
    let str = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsLength = chars.length;
    for (let i = 0; i < 64; ++i) {
        str += chars[Math.floor(Math.random() * ((charsLength - 1) - 0 + 1)) + 0];

    }
    return str;
}