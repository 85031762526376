import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    List,
    Card,
    ListItem,
    Button
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import QRcode from 'qrcode.react';
import * as moment from 'moment';
import OverlayLoader from '../core/OverlayLoader';
import RewardService from '../../services/RewardService';
import AuthService from '../../services/AuthService';
import ShareDialog from '../core/Share';

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reward: props.reward
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Grid container style={{ paddingTop: '50px', textAlign: 'center' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <img src={require('../../assets/icons/RewardOffer.svg')} style={{ width: '250px', height: '250px' }} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 20 }}>
                        <span style={{ width: '100%', fontSize: 24, fontWeight: '500' }}>{this.state.reward.title}</span>
                        <br />
                        <span style={{ fontWeight: '100' }}> {moment(this.state.reward.createdAt).format('MM/DD/YYYY')}</span>
                        <p style={{ padding: '20px 20px', fontSize: 14, fontWeight: 200, textAlign: 'center' }}>
                            {this.state.reward.description}
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Grid item style={{ padding: '0px 0px 40px 0px' }}>
                        <span style={{ width: '100%', marginBottom: 20, fontSize: 24, fontWeight: '500' }}>Scan QR Code</span>
                    </Grid>
                    <Grid item >
                        <QRcode value={this.state.reward.title} renderAs="svg" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


class RewardPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Reward Details',
                static: true
            }
        });
        this.state = {
            loader: false,
            reward: {},
            pingers: [],
            basicUsers: [],
            openShareDialog: false,
            shareTitle: '',
            shareUrl: ''
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        this.getRewardById(this.props.match.params.id);
    }

    getRewardById = async (id) => {
        try {
            this.setState({ loader: true });
            let res = await RewardService.getRewardById(id);
            this.setState({ loader: false });
            this.setState({ reward: res.reward });
            this.setState({ pingers: res.pingers });
            this.setState({ basicUsers: res.basicUsers });
        } catch (e) {
            this.setState({ loader: false });
        }
    }

    handleShare = (reward) => {
        this.setState({ openShareDialog: true, shareTitle: reward.title, shareUrl: 'reward/' + reward._id });
    }

    handelShareDialogClose = () => {
        this.setState({ openShareDialog: false, shareTitle: '', shareUrl: '' });
    }

    render() {
        let user = AuthService.getAuthUser();
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        {
                            this.state.reward && this.state.reward._id &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <img src={require('../../assets/icons/RewardOffer.svg')} style={{ width: '40%', height: '40%' }} alt="" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <span style={{ width: '100%', fontSize: 20, fontWeight: '500' }}>{this.state.reward.title}</span>
                                        <br />
                                        {
                                            user.accountType === 'ponger' ?
                                                <span style={{ color: 'gray', fontSize: 14 }}> {this.state.reward.zipCode ? this.state.reward.zipCode + ',' : ''} {this.state.reward.miles ? this.state.reward.miles + ' miles' : ''}</span>
                                                : <span style={{ fontWeight: '100' }}> {moment(this.state.reward.createdAt).format('MM/DD/YYYY')}</span>
                                        }
                                        <p style={{ padding: '10px 20px', fontSize: '0.89rem', fontWeight: 200, textAlign: 'center' }}>
                                            {this.state.reward.description}
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <Grid item style={{ padding: '0px 0px 40px 0px' }}>
                                        <span style={{ width: '100%', marginBottom: 20, fontSize: '1.3rem', fontWeight: '500' }}>Scan QR Code</span>
                                    </Grid>
                                    <Grid item >
                                        <QRcode value={this.state.reward.title} />
                                    </Grid>
                                    <Grid item style={{ padding: '0px 0px 40px 0px' }}>
                                    </Grid>
                                </Grid>
                                {
                                    this.state.reward.canDistribute && user.accountType != 'ponger' &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        <Button className="btn-purple mb-xs-10" onClick={() => this.handleShare(this.state.reward)} style={{ width: 150, marginLeft: 15 }} startIcon={<ShareIcon />} variant="contained" color="primary" disabled={true}>Share</Button>
                                        <ReactToPrint
                                            trigger={() => <Button className="btn-purple" style={{ width: 150, marginLeft: 15 }} startIcon={<PrintIcon />} variant="outlined" color="primary">Print</Button>}
                                            content={() => this.componentRef}
                                            documentTitle={this.state.reward.title}
                                        />
                                        <div style={{ display: 'none' }}><ComponentToPrint reward={this.state.reward} ref={el => (this.componentRef = el)} /></div>
                                    </Grid>
                                }
                                {
                                    user.accountType === 'ponger' &&
                                    <React.Fragment>
                                        {
                                            this.state.pingers && this.state.pingers.length ?
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div className="titlebar">Pingers</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                        <List style={{ overflowY: 'scroll' }}>
                                                            {
                                                                this.state.pingers.map((pinger, key) => {
                                                                    return (
                                                                        <ListItem key={key} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                            <Grid item xs={2} md={1} lg={1} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                                                <div className="home-profile-image-holder" style={{ position: 'static', width: "40px", height: "40px", border: "none" }}>
                                                                                    <img src={pinger.profilePic ? pinger.profilePic : `${require('../../assets/default-user-image.png')}`} alt="" style={{ height: 40, width: 40, borderRadius: 40 }} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={8} md={9} lg={9} style={{ paddingLeft: '2%', paddingRight: 5 }}>{pinger.firstName} {pinger.lastName}</Grid>
                                                                            <Grid item xs={1} md={2} lg={2} style={{ color: 'gray', paddingLeft: 5, paddingRight: 5, textAlign: 'right' }}>{pinger.totalPosts}</Grid>
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                                : ''
                                        }
                                        {
                                            this.state.basicUsers && this.state.basicUsers.length ?
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div className="titlebar">Enthusiasts</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                        <List style={{ overflowY: 'scroll' }}>
                                                            {
                                                                this.state.basicUsers.map((baiscUser, key) => {
                                                                    return (
                                                                        <ListItem key={key} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                                            <Grid item xs={2} md={1} lg={1} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                                                <div className="home-profile-image-holder" style={{ position: 'static', width: "40px", height: "40px", border: "none" }}>
                                                                                    <img src={baiscUser.profilePic ? baiscUser.profilePic : `${require('../../assets/default-user-image.png')}`} alt="" style={{ height: 40, width: 40, borderRadius: 40 }} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={10} md={11} lg={11} style={{ paddingLeft: '2%', paddingRight: 5 }}>{baiscUser.firstName} {baiscUser.lastName}</Grid>
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                                : ''
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Card>
                </Grid>
                {
                    this.state.openShareDialog && this.state.shareUrl &&
                    < ShareDialog url={this.state.shareUrl} title={this.state.shareTitle} open={this.state.openShareDialog} onClose={this.handelShareDialogClose} />
                }
            </Grid>
        )
    }
}

export default connect()(RewardPage);