import React, { useState } from 'react';
import { Button, Menu, MenuItem, Icon } from '@material-ui/core';
import { MoreVertSharp } from '@material-ui/icons';
import { connect } from 'react-redux';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Grid } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
export function MenuComponent(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedSort, setSelectedSort] = useState({
        date: true,
        rating: false,
        likes: false,
        comments: false,
        name: true
    });
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handelOptionClicked = (value) => {
        let updatedSort = selectedSort;
        updatedSort[value] = !updatedSort[value];
        setSelectedSort(updatedSort);
        props.props.dispatch({ type: 'SELECTED_SORT_VALUES', data: selectedSort });
        if (props.type === 'feedMenu') {
            props.props.dispatch({ type: 'SELECT_SORT', data: value });
        } else if (props.type === 'titleSort') {
            props.props.dispatch({ type: 'SELECT_SORT', data: value });
        } else if (props.type === 'favoriteMenu' || props.type === 'myFeedMenu' || props.type === 'myLiveWorkoutMenu') {
            props.selectedItems(value);
        }
        handleClose();
    }

    return (
        <div>
            {
                props.type === 'feedMenu' || props.type === 'favoriteMenu' || props.type === 'myFeedMenu' || props.type === 'myLiveWorkoutMenu' ?
                    (<Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e)}>
                        <MoreVertSharp />
                    </Button>) :
                    (props.type === 'titleSort' &&
                        (<Button
                            edge="start"
                            color="inherit"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            style={{
                                padding: 0,
                                float: 'right',
                                position: 'relative',
                                right: 0,
                                marginLeft: 10,
                                fontSize: 20,
                                marginRight: 12,
                                color: '#3ec7e6',
                                display: 'iniline-flex',
                                textTransform: 'capitalize'
                            }}
                            onClick={(e) => handleClick(e)}>
                            <Icon style={{ transform: 'rotate(90deg)', fontSize: '1.2rem' }}>
                                sync_alt
                        </Icon>
                        Sort
                        </Button>))
            }
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ top: 45, marginTop: 15 }}
            >

                {
                    props.items &&
                     props.items.map((item, key) => {
                        return (
                            <div key={key}>
                                {
                                    item.show &&
                                        <MenuItem key={key} onClick={() => handelOptionClicked(item.value)} disabled={item.value === 'flagged' ? true : false}>
                                            <Grid container>
                                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                                    {item.title}
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} lg={4} style={{paddingLeft: 10}}>
                                                    {item.title.toLowerCase() !== 'recent' && item.title.toLowerCase() !== 'date' && item.title.toLowerCase() !== 'name' && item.title.toLowerCase() !== 'delete' && item.title.toLowerCase() !== 'edit' && item.title.toLowerCase() !== 'cancel' && item.title.toLowerCase() !== 'remove' && item.title.toLowerCase() !== 'flag' &&
                                                        <div style={{ textAlign: 'end' }}>
                                                            {
                                                                !selectedSort[item.title.toLowerCase()] ? (<ArrowDownwardIcon style={{ fontSize: '16px', paddingTop: 5 }} />) : (<ArrowUpwardIcon style={{ fontSize: '16px', paddingTop: 5 }} />)
                                                            }</div>
                                                    }
                                                    {item.title.toLowerCase() === 'flag' &&
                                                        <div style={{ textAlign: 'end' }}>
                                                            {
                                                                item.value === 'flagged' ? <FlagIcon style={{ fontSize: '16px', paddingTop: 5 }} /> : <FlagOutlinedIcon style={{ fontSize: '16px', paddingTop: 5 }} />
                                                            }
                                                        </div>
                                                    }
                                                    {item.title.toLowerCase() === 'delete' &&
                                                      <div style={{ textAlign: 'end' }}>
                                                          <DeleteIcon style={{ fontSize: '16px', paddingTop: 5 }}/>
                                                      </div>
                                                    }
                                                    {item.title.toLowerCase() === 'cancel' &&
                                                      <div style={{ textAlign: 'end' }}>
                                                          <BlockIcon style={{ fontSize: '16px', paddingTop: 5 }}/>
                                                      </div>
                                                    }
                                                    {item.title.toLowerCase() === 'edit' &&
                                                      <div style={{ textAlign: 'end' }}>
                                                          <EditIcon style={{ fontSize: '16px', paddingTop: 5 }}/>
                                                      </div>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    }
                            </div>
                        )
                    })
                }
            </Menu>
        </div>
    );
}

const mapStateToProps = (state) => ({
    toolBar: state.toolbarReducer
});

export default connect(mapStateToProps)(MenuComponent);
