import React, { Component, useState } from "react";
import { Grid, Button, TextField, IconButton } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import AuthService from "../../services/AuthService";
import SearchAddress from "../core/Address";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Basic = (props) => {
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setMessageStatus] = useState(false);
  const [showAddressField, setShowAddressField] = useState(false);
  const [address, setAddress] = useState({
    city: "",
    state: "",
    zip: "",
    country: "",
    address: "",
    lat: "",
    lng: "",
  });
  const emailAddress = localStorage.getItem("emailAddress");

  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          middleName: "",
          lastName: "",
          email: emailAddress || "",
          address: address.address || "",
          city: address.city || "",
          country: address.country || "",
          state: address.state || "",
          zip: address.zip || "",
          mobileNumber: "",
          lat: address.lat || "",
          lng: address.lng || "",
        }}
        validate={(values) => {
          console.log(values);
          const errors = {};
          if (!values.firstName || !values.firstName.trim()) {
            errors.firstName = "Please enter first name";
          }
          if (!values.lastName || !values.lastName.trim()) {
            errors.lastName = "Please enter last name";
          }
          if (
            !values.email ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.address || !values.address.trim()) {
            errors.address = "Please enter your valid address";
          }
          if (!values.zip || !values.zip.trim()) {
            errors.zip = "Please enter the valid zip code";
          }
          if (!values.city) {
            errors.city = "Please enter city";
          }
          if (!values.state) {
            errors.state = "Please enter state";
          }
          if (!values.country) {
            errors.country = "Please enter country";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, errors }) => {
          let body = {};
          body = values;
          setLoader(true);
          setDisabled(true);
          try {
            AuthService.setAuthorizationHeader(
              "basic " + process.env.REACT_APP_SERVER_BASE64
            );
            let res = await AuthService.sendVerifyEmailOTP(body);
            if (res) {
              props.props.dispatch({
                type: "SET_EMAIL_VERIFICATION_ID",
                emailVerification: {
                  _id: res.emailVerificationId,
                  email: values.email,
                },
              });
              setLoader(false);
              setDisabled(false);
              setMessageStatus(true);
              setMessage("We have sent OTP on your email address.");
              setOpen(true);
              setTimeout(() => {
                props.props.history.replace("/verify");
              }, 2000);
            }
          } catch (error) {
            setDisabled(false);
            setLoader(false);
            setMessageStatus(false);
            setMessage(error.message);
            setOpen(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid container>
              <Grid
                item
                xs={6}
                md={4}
                lg={4}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="First Name"
                  type="text"
                  name="firstName"
                  disabled={disabled}
                  error={errors.firstName && touched.firstName ? true : false}
                  value={values.firstName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.firstName && errors.firstName}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={4}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Middle Initial"
                  type="text"
                  name="middleName"
                  disabled={disabled}
                  error={errors.middleName && touched.middleName ? true : false}
                  value={values.middleName}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.middleName && errors.middleName}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  name="lastName"
                  type="text"
                  disabled={disabled}
                  error={errors.lastName && touched.lastName ? true : false}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.lastName && errors.lastName}
                  value={values.lastName}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Email ID"
                  type="email"
                  name="email"
                  disabled={disabled}
                  error={errors.email && touched.email ? true : false}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ padding: 5, marginBottom: 15 }}
              >
                {showAddressField && (
                  <SearchAddress
                    onChange={(value) => {
                      values.address = value.address;
                    }}
                    name="address"
                    autoFocus={true}
                    onSelect={(value) => {
                      values.city = value.city;
                      values.state = value.state;
                      values.zip = value.zip;
                      values.country = value.country;
                      values.lng = value.lng;
                      values.lat = value.lat;
                      values.address = value.address;
                      setAddress({
                        city: value.city,
                        country: value.country,
                        zip: value.zip,
                        state: value.state,
                        address: value.address,
                        lat: value.lat,
                        lng: value.lng,
                      });
                    }}
                    error={errors.address && touched.address ? true : false}
                    blur={handleBlur}
                    helperText={touched.address && errors.address}
                    value={values.address}
                  />
                )}
                {!showAddressField && (
                  <TextField
                    label="Address"
                    name="address"
                    type="text"
                    error={errors.address && touched.address ? true : false}
                    value={values.address}
                    helperText={touched.address && errors.address}
                    onFocus={(e) => {
                      setShowAddressField(true);
                    }}
                    style={{ width: "100%" }}
                    required
                  />
                )}
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                lg={3}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="City"
                  name="city"
                  type="text"
                  disabled={disabled}
                  error={errors.city && touched.city ? true : false}
                  value={values.city}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  helperText={touched.city && errors.city}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                lg={3}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="State/Province"
                  name="state"
                  type="text"
                  disabled={disabled}
                  error={errors.state && touched.state ? true : false}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  helperText={touched.state && errors.state}
                  value={values.state}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                lg={3}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Zip code"
                  name="zip"
                  type="text"
                  disabled={disabled}
                  error={errors.zip && touched.zip ? true : false}
                  value={values.zip}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  helperText={touched.zip && errors.zip}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                lg={3}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              >
                <TextField
                  id="standard-basic"
                  label="Country"
                  name="country"
                  type="text"
                  disabled={disabled}
                  error={errors.country && touched.country ? true : false}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  helperText={touched.country && errors.country}
                  value={values.country}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid container style={{ marginTop: 0 }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <PhoneInput
                    country="us"
                    className="contact-input"
                    value={values.mobileNumber}
                    prefix="+"
                    enableAreaCodes={true}
                    enableAreaCodes={["us", "ca"]}
                    preferredCountries={[""]}
                    name="mobileNumber"
                    id="mobileNumber"
                    placeholder={"Contact Number"}
                    inputExtraProps={{
                      name: "mobileNumber",
                    }}
                    onChange={(value) => {
                      values.mobileNumber = value;
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
                {!disabled ? (
                  <Button
                    type="submit"
                    disabled={isSubmitting ? true : false}
                    className="btn-purple"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Next
                  </Button>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="white"
              style={{ color: "white" }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <SnackbarContent
          style={
            isSuccess
              ? {
                backgroundColor: "green",
              }
              : { backgroundColor: "red" }
          }
          message={<span id="client-snackbar">{message}</span>}
        />
      </Snackbar>
    </div>
  );
};

class SignupPage extends Component {
  constructor(props) {
    super(props);
    props.dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: false,
        color: true,
        sort: false,
        search: false,
        back: false,
        title: "",
        static: false,
      },
    });
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Grid
          container
          className={"contentHolderContainer-90vh"}
          justify="center"
          style={{
            marginTop: "0%",
            justifyContent: "center",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <Grid container justify="center" className={"profile-div-background"}>
            <Grid item>
              <div className="profile-image-holder">
                <img
                  src={require("../../assets/logo.png")}
                  alt=""
                  style={{
                    height: 90,
                    weight: 90,
                    borderRadius: 60,
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ padding: "0px 20px" }}>
            <Grid item xs={12} md={12} lg={12}>
              <Grid
                container
                justify="center"
                style={{ marginTop: 0, justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <h2
                    style={{
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "28px",
                      fontWeight: 400,
                    }}
                  >
                    Sign-up
                  </h2>
                </Grid>
                <Basic props={this.props} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <p>
              Already onboard ?
              <span>
                <Link to={"/Login"} className="blue-links">
                  &nbsp;&nbsp;Login
                </Link>
              </span>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userReducer.loading,
  response: state.userReducer.response,
  error: state.userReducer.error,
});

export default connect(mapStateToProps)(SignupPage);
