import React, { Component } from 'react';
import { Grid, Button, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import adapter from 'webrtc-adapter';
import WorkoutClassService from '../../services/WorkoutClassService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Plugins } from '@capacitor/core'

const { Device } = Plugins;

let constraints = {
    video: {
        width: { min: "640", ideal: "1280", max: "1920" },
        height: { min: "360", ideal: "720", max: "1080" },
        frameRate: "30"
    },
    audio: true,
}

/*
Info for SDP munging
*/
let mediaInfo = {
    videoBitrate: "1000",
    audioBitrate: "",
    videoFrameRate: "30",
    videoCodec: "42e01f",
    audioCodec: "opus"
};
let browserDetails;
let SDPOutput;
let videoChoice;
let audioChoice;
let videoIndex;
let audioIndex;

/*
Client connection and media details
*/
let cameras = [];
let microphones = [];
let wsConnection, peerConnection, peerConnectionConfig, videoElement, localStream, streamInfo, userData = null;

class WowzaPublisher extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            streamName: props.streamName,
            applicationName: props.applicationName,
            wssUrl: process.env.REACT_APP_WOWZA_WSS_URL,
            showPlayer: false,
            open: false,
            isSuccess: false,
            message: '',
            isLive: false,
            hideCamera: false,
            totalConnections: '',
            streamNotRefreshed: false
        }
        this.videoPlayer = React.createRef() || null;
        this.checkLiveTimer = null;
    }


    async componentDidMount() {
        browserDetails = adapter.browserDetails;
        const info = await Device.getInfo();
        if (info.platform == 'ios') {
            mediaInfo = {
                videoBitrate: "3500",
                audioBitrate: "64",
                videoFrameRate: "30",
                videoCodec: "VP8",
                audioCodec: "opus"
            };
        }
        this.startStream();
        this.checkLive(true);
        this.checkLiveTimer = setInterval(async () => {
            try {
                await this.checkLive(false);
            } catch (error) { }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.checkLiveTimer);
        this.stopBothVideoAndAudio();
    }

    startStream = () => {
        this.setState({
            showPlayer: true
        });
        if ((this.state.applicationName == "") || (this.state.streamName == "" || this.state.wssUrl == "")) {
            alert("Please fill out the connection details")
        } else {
            this.getUserMedia().then(() => {
                this.getDevices().then(() => {
                    constraints.video = Object.assign({}, constraints.video, { deviceId: cameras[0].deviceId });
                    constraints.audio = Object.assign({}, constraints.audio, { deviceId: microphones[0].deviceId });
                    this.wsConnect();
                });
            });
        }
    }

    stopBothVideoAndAudio() {
        localStream.getTracks().forEach(function (track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }

    gotDescription = (description) => {
        console.log("gotDescription: SDP:");
        console.log(description.sdp + '');

        let mungeData = new Object();

        if (mediaInfo.audioBitrate != null)
            mungeData.audioBitrate = mediaInfo.audioBitrate;
        if (mediaInfo.videoBitrate != null)
            mungeData.videoBitrate = mediaInfo.videoBitrate;
        if (mediaInfo.videoFrameRate != null)
            mungeData.videoFrameRate = mediaInfo.videoFrameRate;
        if (mediaInfo.videoCodec != null)
            mungeData.videoCodec = mediaInfo.videoCodec;
        if (mediaInfo.audioCodec != null)
            mungeData.audioCodec = mediaInfo.audioCodec;

        if (this.mungeSDP != null) {
            description.sdp = this.mungeSDP(description.sdp, mungeData);
        }

        console.log("gotDescription: Setting local description SDP: ");
        console.log(description.sdp);


        peerConnection
            .setLocalDescription(description)
            .then(() => wsConnection.send('{"direction":"publish", "command":"sendOffer", "streamInfo":' + JSON.stringify(streamInfo) + ', "sdp":' + JSON.stringify(description) + ', "userData":' + JSON.stringify(userData) + '}'))
            .catch((error) => {
                console.log("Peer connection failed: " + error);
            });
    }

    addAudio = (sdpStr, audioLine) => {
        let sdpLines = sdpStr.split(/\r\n/);
        let sdpSection = 'header';
        let hitMID = false;
        let sdpStrRet = '';
        let done = false;

        for (let sdpIndex in sdpLines) {
            let sdpLine = sdpLines[sdpIndex];

            if (sdpLine.length <= 0)
                continue;

            sdpStrRet += sdpLine;
            sdpStrRet += '\r\n';

            if ('a=rtcp-mux'.localeCompare(sdpLine) == 0 && done == false) {
                sdpStrRet += audioLine;
                done = true;
            }
        }
        return sdpStrRet;
    }

    addVideo = (sdpStr, videoLine) => {
        let sdpLines = sdpStr.split(/\r\n/);
        let sdpSection = 'header';
        let hitMID = false;
        let sdpStrRet = '';
        let done = false;

        let rtcpSize = false;
        let rtcpMux = false;

        for (let sdpIndex in sdpLines) {
            let sdpLine = sdpLines[sdpIndex];

            if (sdpLine.length <= 0)
                continue;

            if (sdpLine.includes("a=rtcp-rsize")) {
                rtcpSize = true;
            }

            if (sdpLine.includes("a=rtcp-mux")) {
                rtcpMux = true;
            }

        }

        for (let sdpIndex in sdpLines) {
            let sdpLine = sdpLines[sdpIndex];

            sdpStrRet += sdpLine;
            sdpStrRet += '\r\n';

            if (('a=rtcp-rsize'.localeCompare(sdpLine) == 0) && done == false && rtcpSize == true) {
                sdpStrRet += videoLine;
                done = true;
            }

            if ('a=rtcp-mux'.localeCompare(sdpLine) == 0 && done == true && rtcpSize == false) {
                sdpStrRet += videoLine;
                done = true;
            }

            if ('a=rtcp-mux'.localeCompare(sdpLine) == 0 && done == false && rtcpSize == false) {
                done = true;
            }

        }
        return sdpStrRet;
    }

    // Filter codec offerings
    deliverCheckLine = (profile, type) => {
        for (let line in SDPOutput) {
            let lineInUse = SDPOutput[line];
            if (lineInUse.includes(profile)) {
                if (profile.includes("VP9") || profile.includes("VP8")) {
                    let output = "";
                    let outputs = lineInUse.split(/\r\n/);
                    for (let position in outputs) {
                        let transport = outputs[position];
                        // NOTE: This block of code is needed for WSE versions older than 4.8.5
                        // if (transport.indexOf("a=extmap") !== -1 ||
                        //   transport.indexOf("transport-cc") !== -1 ||
                        //   transport.indexOf("goog-remb") !== -1 ||
                        //   transport.indexOf("nack") !== -1) {
                        //   continue;
                        // }
                        output += transport;
                        output += "\r\n";
                    }

                    if (type.includes("audio")) {
                        audioIndex = line;
                    }

                    if (type.includes("video")) {
                        videoIndex = line;
                    }

                    return output;
                }
                if (type.includes("audio")) {
                    audioIndex = line;
                }

                if (type.includes("video")) {
                    videoIndex = line;
                }

                return lineInUse;
            }
        }
        return '';
    }

    checkLine = (line) => {
        if (line.startsWith("a=rtpmap") || line.startsWith("a=rtcp-fb") || line.startsWith("a=fmtp")) {
            let res = line.split(":");

            if (res.length > 1) {
                let number = res[1].split(" ");
                if (!isNaN(number[0])) {
                    if (!number[1].startsWith("http") && !number[1].startsWith("ur")) {
                        let currentString = SDPOutput[number[0]];
                        if (!currentString) {
                            currentString = "";
                        }
                        currentString += line + "\r\n";
                        SDPOutput[number[0]] = currentString;
                        return false;
                    }
                }
            }
        }

        return true;
    }

    getrtpMapID = (line) => {
        let findid = new RegExp('a=rtpmap:(\\d+) (\\w+)/(\\d+)');
        let found = line.match(findid);
        return (found && found.length >= 3) ? found : null;
    }

    mungeSDP = (sdpStr, mungeData) => {

        SDPOutput = new Object();
        videoChoice = "42e01f";
        audioChoice = "opus";
        videoIndex = -1;
        audioIndex = -1;

        let sdpLines = sdpStr.split(/\r\n/);

        let sdpSection = 'header';
        let hitMID = false;
        let sdpStrRet = '';

        if (mungeData.videoCodec != null && mungeData.videoCodec !== '')
            videoChoice = mungeData.videoCodec;
        if (mungeData.audioCodec != null && mungeData.audioCodec !== '')
            audioChoice = mungeData.audioCodec;

        // Deliver the requested codecs
        for (let sdpIndex in sdpLines) {
            let sdpLine = sdpLines[sdpIndex];

            if (sdpLine.length <= 0)
                continue;

            let doneCheck = this.checkLine(sdpLine);
            if (!doneCheck)
                continue;

            sdpStrRet += sdpLine;
            sdpStrRet += '\r\n';

        }
        sdpStrRet = this.addAudio(sdpStrRet, this.deliverCheckLine(audioChoice, "audio"));
        sdpStrRet = this.addVideo(sdpStrRet, this.deliverCheckLine(videoChoice, "video"));
        sdpStr = sdpStrRet;
        sdpLines = sdpStr.split(/\r\n/);
        sdpStrRet = '';

        for (let sdpIndex in sdpLines) {
            let sdpLine = sdpLines[sdpIndex];

            if (sdpLine.length <= 0)
                continue;

            if (browserDetails.browser === 'chrome') {
                let audioMLines;
                if (sdpLine.indexOf("m=audio") == 0 && audioIndex !== -1) {
                    audioMLines = sdpLine.split(" ");
                    sdpStrRet += audioMLines[0] + " " + audioMLines[1] + " " + audioMLines[2] + " " + audioIndex + "\r\n";
                    continue;
                }

                if (sdpLine.indexOf("m=video") == 0 && videoIndex !== -1) {
                    audioMLines = sdpLine.split(" ");
                    sdpStrRet += audioMLines[0] + " " + audioMLines[1] + " " + audioMLines[2] + " " + videoIndex + "\r\n";
                    continue;
                }
            }

            sdpStrRet += sdpLine;

            if (sdpLine.indexOf("m=audio") === 0) {
                sdpSection = 'audio';
                hitMID = false;
            }
            else if (sdpLine.indexOf("m=video") === 0) {
                sdpSection = 'video';
                hitMID = false;
            }
            else if (sdpLine.indexOf("a=rtpmap") == 0) {
                sdpSection = 'bandwidth';
                hitMID = false;
            }

            if (browserDetails.browser === 'chrome') {
                if (sdpLine.indexOf("a=mid:") === 0 || sdpLine.indexOf("a=rtpmap") == 0) {
                    if (!hitMID) {
                        if ('audio'.localeCompare(sdpSection) == 0) {
                            if (mungeData.audioBitrate !== undefined) {
                                sdpStrRet += '\r\nb=CT:' + (mungeData.audioBitrate);
                                sdpStrRet += '\r\nb=AS:' + (mungeData.audioBitrate);
                            }
                            hitMID = true;
                        }
                        else if ('video'.localeCompare(sdpSection) == 0) {
                            if (mungeData.videoBitrate !== undefined) {
                                sdpStrRet += '\r\nb=CT:' + (mungeData.videoBitrate);
                                sdpStrRet += '\r\nb=AS:' + (mungeData.videoBitrate);
                                if (mungeData.videoFrameRate !== undefined) {
                                    sdpStrRet += '\r\na=framerate:' + mungeData.videoFrameRate;
                                }
                            }
                            hitMID = true;
                        }
                        else if ('bandwidth'.localeCompare(sdpSection) == 0) {
                            let rtpmapID;
                            rtpmapID = this.getrtpMapID(sdpLine);
                            if (rtpmapID !== null) {
                                let match = rtpmapID[2].toLowerCase();
                                if (('vp9'.localeCompare(match) == 0) || ('vp8'.localeCompare(match) == 0) || ('h264'.localeCompare(match) == 0) ||
                                    ('red'.localeCompare(match) == 0) || ('ulpfec'.localeCompare(match) == 0) || ('rtx'.localeCompare(match) == 0)) {
                                    if (mungeData.videoBitrate !== undefined) {
                                        sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (mungeData.videoBitrate) + ';x-google-max-bitrate=' + (mungeData.videoBitrate);
                                    }
                                }

                                if (('opus'.localeCompare(match) == 0) || ('isac'.localeCompare(match) == 0) || ('g722'.localeCompare(match) == 0) || ('pcmu'.localeCompare(match) == 0) ||
                                    ('pcma'.localeCompare(match) == 0) || ('cn'.localeCompare(match) == 0)) {
                                    if (mungeData.audioBitrate !== undefined) {
                                        sdpStrRet += '\r\na=fmtp:' + rtpmapID[1] + ' x-google-min-bitrate=' + (mungeData.audioBitrate) + ';x-google-max-bitrate=' + (mungeData.audioBitrate);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (browserDetails.browser === 'firefox' || browserDetails.browser === 'safari') {
                if (sdpLine.indexOf("c=IN") == 0) {
                    if ('audio'.localeCompare(sdpSection) == 0) {
                        if (mungeData.audioBitrate !== '') {
                            sdpStrRet += "\r\nb=TIAS:" + (Number(mungeData.audioBitrate) * 1000) + "\r\n";
                            sdpStrRet += "b=AS:" + (Number(mungeData.audioBitrate) * 1000) + "\r\n";
                            sdpStrRet += "b=CT:" + (Number(mungeData.audioBitrate) * 1000) + "\r\n";
                        }
                        continue;
                    }
                    if ('video'.localeCompare(sdpSection) == 0) {
                        if (mungeData.videoBitrate !== '') {
                            sdpStrRet += "\r\nb=TIAS:" + (Number(mungeData.videoBitrate) * 1000) + "\r\n";
                            sdpStrRet += "b=AS:" + (Number(mungeData.videoBitrate) * 1000) + "\r\n";
                            sdpStrRet += "b=CT:" + (Number(mungeData.videoBitrate) * 1000) + "\r\n";
                        }
                        continue;
                    }
                }
            }

            sdpStrRet += '\r\n';
        }
        return sdpStrRet;
    }

    wsConnect = () => {
        let _this = this;
        let repeaterRetryCount;

        userData = { 'iceServers': [] };
        let streamName = this.state.streamName;
        let applicationName = this.state.applicationName;
        streamInfo = { applicationName, streamName };
        peerConnection = new RTCPeerConnection(userData);

        try {
            wsConnection = new WebSocket(this.state.wssUrl);
        }
        catch (e) {
            console.log(e);
            return;
        }

        wsConnection.binaryType = 'arraybuffer';

        wsConnection.onopen = () => {
            console.log("wsConnection.onopen");

            let videoSender = undefined;
            let audioSender = undefined;

            peerConnection.onicecandidate = (event) => {
                if (event.candidate != null) {
                    console.log('gotIceCandidate: ' + JSON.stringify({ 'ice': event.candidate }));
                }
            }

            peerConnection.onnegotiationneeded = (event) => {
                peerConnection.createOffer(this.gotDescription, (e) => (console.log(e)));
            }

            let localTracks = localStream.getTracks();
            for (let localTrack in localTracks) {
                try {
                    let sender = peerConnection.addTrack(localTracks[localTrack], localStream);
                    if (localTracks[localTrack].type === 'audio') {
                        audioSender = sender;
                    }
                    else if (localTracks[localTrack].type === 'video') {
                        videoSender = sender;
                    }
                } catch (error) {
                }

            }
        }

        wsConnection.onmessage = (evt) => {
            console.log("wsConnection.onmessage: " + evt.data);

            var msgJSON = JSON.parse(evt.data);

            var msgStatus = Number(msgJSON['status']);
            var msgCommand = msgJSON['command'];

            if (msgStatus != 200) {
                wsConnection = null;
                //errorHandler({message:msgJSON['statusDescription']});
                this.setState({
                    open: true,
                    message: msgJSON['statusDescription'],
                    isSuccess: false,
                    streamNotRefreshed: true
                });
            }
            else {
                var sdpData = msgJSON['sdp'];
                if (sdpData !== undefined) {

                    var mungeData = new Object();

                    if (mediaInfo.audioBitrate !== undefined)
                        mungeData.audioBitrate = mediaInfo.audioBitrate;
                    if (mediaInfo.videoBitrate !== undefined)
                        mungeData.videoBitrate = mediaInfo.videoBitrate;
                    console.log(sdpData.sdp);

                    peerConnection
                        .setRemoteDescription(new RTCSessionDescription(sdpData),
                            () => { },
                            (e) => console.log(e)
                        );
                }

                var iceCandidates = msgJSON['iceCandidates'];
                if (iceCandidates !== undefined) {
                    for (var index in iceCandidates) {
                        console.log('wsConnection.iceCandidates: ' + iceCandidates[index]);
                        peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidates[index]));
                    }
                }
            }
        }

        wsConnection.onerror = (error) => {
            console.log('wsConnection.onerror');
            console.log(error);
            let message = "Websocket connection failed: " + this.state.wssUrl;
            console.log(message);
            this.setState({
                open: true,
                message: message,
                isSuccess: false
            });
        }
    }
    /*
      Client Media Streams
      Returns Promise
      Sets localStream with result of getUserMedia
    */

    getUserMedia = () => {
        return new Promise((resolve, reject) => {

            console.log('getUserMedia');
            if (this.videoPlayer == null) {
                reject({ message: "videoElementPublish not set" });
            }

            const getUserMediaSuccess = (stream) => {
                localStream = stream;
                try {
                    this.videoPlayer.current.srcObject = stream;
                }
                catch (error) {
                    console.log('getUserMediaSuccess: error connecting stream to videoElement, trying createObjectURL');
                    console.log(error);
                    this.videoPlayer.current.src = window.URL.createObjectURL(stream);
                }
                resolve();
            }
            navigator.getUserMedia = (navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia);
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(constraints)
                    .then(getUserMediaSuccess)
                    .catch((e) => { console.log(e) });
            }
            else if (navigator.getUserMedia) {
                navigator.getUserMedia(constraints, getUserMediaSuccess, (error) => {
                    // errorHandler(error);
                    this.setState({
                        open: true,
                        message: error.message,
                        isSuccess: false
                    });
                    reject(error);
                });
            }
            else {
                // errorHandler({message:"Your browser does not support WebRTC"});
                this.setState({
                    open: true,
                    message: "Your browser does not support WebRTC",
                    isSuccess: false
                });
                reject();
            }
        });
    }

    /*
      Client Devices
      Returns Promise
      sets the camera and microphone variables ( in this example we just grab the first ones off the list but you can impliment some sort of form element to select which AV inputs to use)
    */
    getDevices = () => {
        return new Promise((resolve, reject) => {
            console.log('getDevices');
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                console.log(JSON.stringify(devices));
                for (var i = 0; i < devices.length; i++) {
                    if (devices[i].kind === 'videoinput') {
                        cameras.push(devices[i]);
                    } else if (devices[i].kind === 'audioinput') {
                        microphones.push(devices[i]);
                    }
                }
                resolve();
            }).catch(
                (e) => {
                    console.log("unable to detect AV devices: " + e);
                    reject(e);
                }
            );
        });
    }
    /*
      initialize and publish, wire in publish button here
    */

    checkLive = async (onLoad) => {
        let obj = {
            applicationName: this.state.applicationName,
            wowzaStreamKey: this.state.streamName
        };
        try {
            let response = await WorkoutClassService.checkLive(obj);
            if (response.stream) {
                this.setState({ isLive: true, totalConnections: response.totalConnections || 0 });
            } else {
                this.setState({ isLive: false });
                if (!onLoad) {
                    this.setState({ hideCamera: true });
                    this.stopBothVideoAndAudio();
                }
            }
        } catch (e) {
        }
    }

    render() {
        let { open, isSuccess, message } = this.state;
        return (
            <>
                {
                    this.state.streamNotRefreshed
                        ?
                        <div style={{ textAlign: "center", fontWeight: "bold", color: "red", padding: "30px" }}>Not able to go live, please try after some time.</div>
                        :
                        <Grid container style={{ justifyContent: "center", display: "flex" }}>
                            {this.state.showPlayer &&
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "center", padding: 10 }}>
                                    {
                                        this.state.isLive &&
                                        <div style={{ float: 'right', display: 'flex' }}>
                                            <span style={{ background: 'red', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999 }}>LIVE</span>
                                            <span style={{ background: 'black', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999 }}> <VisibilityIcon style={{ marginTop: -4 }} /></span>
                                            <span style={{ background: 'black', color: 'white', padding: '5px 10px 5px 10px', borderRadius: 2, height: 15, fontSize: 14, fontWeight: 600, top: 46, position: 'relative', zIndex: 999, right: 10, }}>{this.state.totalConnections}</span>
                                        </div>
                                    }
                                    {
                                        !this.state.hideCamera ?
                                            <video ref={this.videoPlayer} id="player-video" autoPlay playsInline muted controls height="100%" width="100%"></video>
                                            : <p>Live session ended.</p>
                                    }
                                </Grid>
                            }
                            <Grid sm={12} lg={12} style={{ justifyContent: "center" }}>
                                <Snackbar
                                    open={open}
                                    autoHideDuration={3000}
                                    onClose={() => this.setState({
                                        open: false
                                    })}
                                    action={
                                        <React.Fragment>
                                            <IconButton
                                                aria-label="close"
                                                color="white"
                                                style={{ color: 'white' }}
                                                onClick={() => this.setState({ open: false })}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    }>
                                    <SnackbarContent style={isSuccess ? {
                                        backgroundColor: 'green',
                                    } : { backgroundColor: 'red', }}
                                        message={<span id="client-snackbar">{message}</span>}
                                    />
                                </Snackbar>
                            </Grid>

                        </Grid>

                }
            </>
        )
    }
}

export default WowzaPublisher;