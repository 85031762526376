import { Card, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UserService from "../../services/UserService";
import AppSnackbar from "../core/AppSnackbar";
import OverlayLoader from "../core/OverlayLoader";

const MpkDashboardPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timeline, setTimeline] = useState("TODAY");
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dashboard, setDashboard] = useState({
    services: 0,
    serviceRequests: 0,
    contactRequests: 0,
  });
  const timelineOptions = [
    { label: "Today", value: "TODAY" },
    { label: "This Week", value: "THIS_WEEK" },
    { label: "This Month", value: "THIS_MONTH" },
  ];

  const notify = (msg, success = false) => {
    setIsSuccess(success);
    setMessage(msg || "Something went wrong, Try again");
    setSnackbar(true);
  };

  const getMpkDashboard = async (tl = "TODAY") => {
    try {
      setLoading(true);
      const res = await UserService.getMpkDashboard({ timeline: tl });
      if (res && res.dashboard) {
        setDashboard(res.dashboard);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notify(error.message, false);
    }
  };

  useEffect(() => {
    dispatch({
      type: "SET_TOOLBAR_CONFIG",
      data: {
        show: true,
        color: true,
        title: "Dashboard",
      },
    });
    getMpkDashboard();
  }, [dispatch]);

  const onTimelineChange = (e) => {
    setTimeline(e.target.value);
    getMpkDashboard(e.target.value);
  };

  return (
    <Grid
      container
      style={{ justifyContent: "center" }}
      className={"contentHolderContainer-90vh"}
    >
      <Grid item xs={12} sm={12} md={11} lg={11}>
        {loading && <OverlayLoader />}
        <Card
          style={{
            boxShadow: "none",
            padding: "15px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 15 }}
          >
            <TextField
              id="timeline"
              select
              style={{ width: "100%" }}
              name="Timeline"
              label="Timeline"
              placeholder={"Select Timeline"}
              value={timeline}
              onChange={onTimelineChange}
              required
            >
              {timelineOptions.map((option) => {
                return (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              marginBottom: 5,
              paddingTop: 10,
            }}
          >
            <Grid item xs={12} md={4} sm={6} lg={4}>
              <Card className="dash-card">
                <p className="dash-card-value">{dashboard.contactRequests}</p>
                <p className="dash-card-title">Contact Requests</p>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
              <Card className="dash-card">
                <p className="dash-card-value">{dashboard.services}</p>
                <p className="dash-card-title">Services</p>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
              <Card className="dash-card">
                <p className="dash-card-value">{dashboard.serviceRequests}</p>
                <p className="dash-card-title">Service Requests</p>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <AppSnackbar
        props={{ message, state: snackbar, setState: setSnackbar, isSuccess }}
      />
    </Grid>
  );
};

export default MpkDashboardPage;
