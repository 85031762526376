import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
function AlertDialog(props) {
  let { onClose, open, message } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Disclaimer</DialogTitle>
      <p style={{ padding: '16px 24px' }}>{message}</p>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={() => handleClose(true)} color="primary">Agree</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog