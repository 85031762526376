import React, { Component } from 'react';
import Script from 'react-load-script';

class ChatBox extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    handleScriptLoad = () => {
        window.CometChatWidget.init({
            'appID': process.env.REACT_APP_COMETCHAT_APP_ID,
            'appRegion': process.env.REACT_APP_COMETCHAT_REGION,
            'authKey': process.env.REACT_APP_COMETCHAT_AUTH_KEY
        }).then(response => {
            console.log("Initialization completed successfully");
            window.CometChatWidget.createOrUpdateUser({
                "uid": this.props.user._id,
                "name": (this.props.user.firstName + (this.props.user.middleName ? ' ' + this.props.user.middleName : '') + (this.props.user.lastName ? ' ' + this.props.user.lastName : ''))
            }).then(response => {
                try {
                    window.CometChatWidget.login({
                        'uid': this.props.user._id
                    }).then(response => {
                        console.log("User logged in successfully.");
                        window.CometChatWidget.launch({
                            'widgetID': process.env.REACT_APP_COMETCHAT_WIDGET_ID,
                            'docked': 'true',
                            'alignment': 'right',
                            'roundedCorners': 'true',
                            'height': '150px',
                            'width': '150px'
                        });
                    }, error => {
                    });
                } catch (error) {
                }
            });

        }, error => {
            console.log("Initialization failed with error:", error);
        });
    }

    componentWillUnmount() {
        window.CometChatWidget.openOrCloseChat(false);
        window.CometChatWidget.logout();
        let chatWidget = document.getElementById('cometchat__widget')
        if (chatWidget) {
            chatWidget.remove();
        }
    }

    render() {
        return (
            <div>
                <Script url={`https://widget-js.cometchat.io/v2/cometchatwidget.js`}
                    onLoad={this.handleScriptLoad}
                    async defer
                />
            </div>
        );
    }
}

export default ChatBox;