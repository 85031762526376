import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import AuthService from "../../services/AuthService";

class Splash extends Component {
  constructor(props) {
    super(props);
    this.hidemenu();
  }

  componentDidMount() {
    let user = AuthService.getAuthUser();
    if (user)
      this.props.history.replace(
        user.accountType === "mpk" ? "/dashboard" : "/home"
      );
    window.scrollTo(0, 0);
  }

  hidemenu() {
    this.props.dispatch({ type: "SHOW", data: false });
    this.props.dispatch({ type: "SHOW_TOOLBAR", data: false });
  }

  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          overflowY: "auto",
          background: `url(${require("../../assets/curlyBackground.png")})`,
          height: "100vh",
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container justify="center" className="splash-container">
          <Grid item xs={12} md={7} lg={7}>
            <Grid
              container
              justify="center"
              style={{ marginTop: 0, justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ padding: "0px 10px", textAlign: "center" }}
              >
                <div
                  className="profile-image-holder"
                  style={{ display: "contents", justifyContent: "center" }}
                >
                  <img
                    src={require("../../assets/logo.png")}
                    alt=""
                    style={{
                      height: 90,
                      weight: 90,
                      borderRadius: 60,
                    }}
                  ></img>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <h1 className="splash-header">
                  THE AB LAB<sup style={{ fontSize: 14 }}>Ⓡ</sup> dAPP
                </h1>
                <h2 className="splash-sub-header">
                  The Official Community for Healthy Core Living
                  <sup style={{ fontSize: 8 }}>TM</sup>
                </h2>
                <h2 className="splash-sub-header">
                  Welcome to the first fitness community that connects ALL
                  fitness participants using the latest technology tools.
                </h2>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
              <div style={{ textDecoration: "none", textAlign: "center" }}>
                <Button
                  className="btn-purple-highligted"
                  style={{ width: "50%" }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.props.history.push("/login");
                  }}
                >
                  Login
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
              <div style={{ textDecoration: "none", textAlign: "center" }}>
                <Button
                  className="btn-purple-highligted"
                  style={{ width: "50%" }}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    this.props.history.push("/loginSignup");
                  }}
                >
                  Explore dAPP
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(Splash);
