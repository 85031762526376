import * as Server from '../Server';

const PostService = {
  createPost: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }

  },
  
  uploadPost: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/upload',
        method: 'POST',
        data: obj.formData || {},
        contentRange: obj.contentRange,
        postId: obj.postId
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }

  },

  statusPost: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/uploadStatus',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }

  },

  finishPost: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/uploadFinish',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }

  },

  getPostByFilter: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/search',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  getPostById: async (obj) => {
    try {
      let response = await Server.request({
        url: `/post/${obj._id}`,
        method: 'GET'
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  postComment: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/comment',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  reply: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/reply',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  reaction: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/reaction',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  postLike: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/like',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  postRate: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/rate',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  flagPost: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/flag',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  flagComment: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/flagComment',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  flagReply: async (obj) => {
    try {
      let response = await Server.request({
        url: '/post/flagReply',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  deletePostById: async (obj) => {
    try {
      let response = await Server.request({
        url: `/post/${obj._id}`,
        method: 'DELETE'
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
}

export default PostService;