import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    List,
    Card,
    CardContent,
    Button,
    ListItem,
    IconButton,
    TextField,
    Input,
    MenuItem,
    Avatar,
    InputAdornment,
    FormControl,
    Tooltip,
    Snackbar,
    SnackbarContent,
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    Typography,
    CardHeader
} from '@material-ui/core';
import * as moment from 'moment';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import InfoIcon from '@material-ui/icons/Info';
import StarRateIcon from '@material-ui/icons/StarRate';
import OverlayLoader from '../core/OverlayLoader';
import SearchIcon from '@material-ui/icons/Search';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import WorkoutClassService from '../../services/WorkoutClassService';
import * as GeneralFunctions from '../../GeneralFunctions';
import CloseIcon from '@material-ui/icons/Close';
import WowzaPlayer from '../core/WowzaPlayer';
import AlertDialog from '../core/Disclamer';
const levelTypes = [
    {
        label: 'Beginner',
        value: 'beginner',
        disabled: false
    },
    {
        label: 'Intermediate',
        value: 'intermediate',
        disabled: false
    },
    {
        label: 'Advanced',
        value: 'advanced',
        disabled: false
    },
    {
        label: 'Everyone',
        value: 'everyone',
        disabled: false
    },
    {
        label: 'All',
        value: 'All',
        disabled: false
    }
];
class WorkoutClassesPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch({
            type: 'SET_TOOLBAR_CONFIG', data: {
                show: true,
                color: true,
                sort: false,
                search: false,
                back: true,
                title: 'Join Live Workouts and Events',
                static: true
            }
        });
        this.state = {
            page: 1,
            limit: 50,
            pages: 0,
            open: false,
            isSuccess: false,
            message: '',
            totalWorkoutClasses: 0,
            filter: {
                category: JSON.parse(localStorage.getItem('Category'))._id,
                subCategory: JSON.parse(localStorage.getItem('SubCategory'))._id,
                name: null,
                level: 'All'
            },
            loader: false,
            openAlertDialog: false,
            workoutClasses: [],
            openFullModal: false,
            selectedWorkout: {}
        }
    }

    componentDidMount() {
        let user = AuthService.getAuthUser();
        if (!user) this.props.history.replace('/login');
        window.scrollTo(0, 0);
        this.getWorkoutClassesByFilter(false);
    }

    getWorkoutClassesByFilter = async (isPaginated) => {
        this.setState({ loader: true });
        let obj = {
            filter: this.state.filter,
            sort: this.state.sort,
            page: this.state.page,
            limit: this.state.limit
        }
        try {
            let res = await WorkoutClassService.getWorkoutClassesByFilter(obj);
            let workoutClasses = this.state.workoutClasses;
            res.workoutClasses.forEach((workoutClass) => {
                let index = this.state.workoutClasses.findIndex((obj) => obj._id === workoutClass._id);
                if (index != -1) {
                    workoutClasses[index] = workoutClass;
                } else {
                    workoutClasses.push(workoutClass);
                }
            });
            this.setState({ loader: false });
            this.setState({ workoutClasses: isPaginated ? workoutClasses : res.workoutClasses, totalWorkoutClasses: res.totalWorkoutClasses, pages: res.pages });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    refreshPagination = async () => {
        this.setState({ page: (this.state.page + 1) }, () => {
            this.getWorkoutClassesByFilter(true);
        });
    }

    workoutClassesFilterByLevel = async (level) => {
        this.setState({
            page: 1,
            filter: {
                ...this.state.filter,
                level: level
            }
        }, async () => {
            this.getWorkoutClassesByFilter(false);
        });
    }

    handelSearch = async () => {
        this.setState({
            page: 1
        }, async () => {
            this.getWorkoutClassesByFilter(false);
        });
    }

    handleToggleFollowPinger = async (pingerUser) => {
        try {
            this.setState({ loader: true });
            let res = await UserService.followPinger({ pingerId: pingerUser._id });
            if (res.pingerUser) {
                let workoutClasses = [];
                for (let workoutClass of this.state.workoutClasses) {
                    if (workoutClass.ownerId == res.pingerUser._id) {
                        workoutClass.owner.followers = res.pingerUser.followers;
                    }
                    workoutClasses.push(workoutClass);
                }
                this.setState({ workoutClasses: workoutClasses });
            }
            this.setState({ loader: false });
        } catch (e) {
            this.setState({ loader: false });
            this.setState({
                open: true,
                message: e.message,
                isSuccess: false
            });
        }
    }

    checkIsFollowed = (pingerUser) => {
        let user = AuthService.getAuthUser();
        let followed = -1;
        if (!pingerUser.hasOwnProperty('followers')) return false;
        if (pingerUser.followers && !pingerUser.followers.length) return false;
        else followed = pingerUser.followers.findIndex(_id => _id === user._id);
        if (followed !== -1) return true;
        return false;
    }

    handelAlertDialogClose = (isConfirm) => {
        this.setState({ openAlertDialog: false });
        if (isConfirm) {
            this.setState({ openFullModal: true });
        }
    }

    toggleFullModal = () => {
        this.setState({
            openFullModal: !this.state.openFullModal
        }, () => {
            if (!this.state.openFullModal) {
                this.getWorkoutClassesByFilter(false);
            }
        });

    }

    render() {
        let user = AuthService.getAuthUser();
        let { open, isSuccess, message } = this.state;
        return (
            <Grid container style={{ justifyContent: 'center' }} className={'contentHolderContainer-90vh'}>
                <Grid item xs={12} sm={12} md={11} lg={11}>
                    {
                        this.state.loader &&
                        <OverlayLoader />
                    }
                    <Card style={{ boxShadow: 'none', padding: '15px' }}>
                        <CardHeader
                            title=""
                            subheader="The class timings are as per your time zone"
                        />
                        <List style={{ overflowY: 'scroll' }}>
                            <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Card elevation={0}>
                                        <CardContent style={{ padding: '10px 20px 15px 30px' }}>
                                            <Grid container justify="center">
                                                <Grid item lg={9} md={9} sm={9} xs={12} style={{ marginTop: 15 }}>
                                                    <FormControl style={{ width: '99%', marginTop: 1 }}>
                                                        <Input
                                                            placeholder="Search by pinger" style={{ width: '100%' }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton type="submit" aria-label="search" onClick={() => this.handelSearch()}>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            onChange={
                                                                (event) => this.setState({
                                                                    filter: {
                                                                        ...this.state.filter,
                                                                        name: event.currentTarget.value
                                                                    }
                                                                })
                                                            } />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                    <TextField
                                                        id="standard-select-currency"
                                                        select
                                                        style={{ width: '100%' }}
                                                        disabled={false}
                                                        name="level"
                                                        label="By Level"
                                                        placeholder={'Select Level'}
                                                        value={this.state.filter.level}
                                                        onChange={(event) => {
                                                            this.workoutClassesFilterByLevel(event.target.value);
                                                        }}
                                                    >
                                                        {
                                                            levelTypes.map((option) => {
                                                                return (
                                                                    <MenuItem key={option.label} value={option.value} disabled={option.disabled}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {
                                    this.state.workoutClasses.length ?
                                        this.state.workoutClasses.map((workout, key) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={12} lg={12} key={key}>
                                                    <ListItem style={{ marginBottom: '20px' }} key={key} boxShadow={2}>
                                                        <Grid container style={{ padding: '10px 10px', boxShadow: '0 1px 10px rgba(0,0,0,0.10), 0 1px 10px rgba(0,0,0,0.10)', borderRadius: '3px' }}>
                                                            <Grid item xs={5} md={3} lg={3} >
                                                                <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Avatar alt={user.accountType} style={{ width: 90, height: 90 }} src={workout.owner.profilePic ? workout.owner.profilePic : require('../../assets/default-user-image.png')} />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} style={{ display: 'flex-inline', justifyContent: 'center', lineHeight: 2 }}>
                                                                        <span style={{ display: "flex", justifyContent: 'center', wordBreak: 'break-word' }}>{GeneralFunctions.capitalize(workout.owner.firstName)} {GeneralFunctions.capitalize(workout.owner.lastName)}</span>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            aria-label="follow/Unfollow"
                                                                            color="primary"
                                                                            onClick={() => this.handleToggleFollowPinger(workout.owner)}
                                                                            style={{ width: '90%', backgroundColor: '#9013fe', color: 'white', float: 'right' }}
                                                                        >
                                                                            <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                {
                                                                                    this.checkIsFollowed(workout.owner) ?
                                                                                        'Unfollow' :
                                                                                        'Follow'
                                                                                }
                                                                            </span>
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={7} md={9} lg={9} style={{ padding: 5 }}>
                                                                <label>
                                                                    {
                                                                        workout.status === 'approved' &&
                                                                        <Button
                                                                            variant="contained"
                                                                            aria-label="live soon"
                                                                            style={{ width: '108px', backgroundColor: 'green', color: 'white', float: 'right', cursor: 'default' }}
                                                                        >
                                                                            <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                Live soon
                                                                                  </span>
                                                                        </Button>
                                                                    }
                                                                    {
                                                                        workout.status === 'live' &&
                                                                        <Button
                                                                            variant="contained"
                                                                            aria-label="live now"
                                                                            endIcon={
                                                                                <PlayCircleOutlineIcon fontSize="small" />
                                                                            }
                                                                            style={{ width: '108px', backgroundColor: 'green', color: 'white', float: 'right' }}
                                                                            onClick={() => this.setState({ openAlertDialog: true, selectedWorkout: workout })}
                                                                        >
                                                                            <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                Live now
                                                                                  </span>
                                                                        </Button>
                                                                    }
                                                                    {
                                                                        workout.status === 'cancelled' &&
                                                                        <Button
                                                                            variant="contained"
                                                                            aria-label="cancelled"
                                                                            endIcon={
                                                                                <Tooltip arrow title={workout.cancelMessage} aria-label="add"><InfoIcon fontSize="small" /></Tooltip>
                                                                            }
                                                                            style={{ width: '108px', backgroundColor: 'red', color: 'white', float: 'right', cursor: 'default' }}
                                                                        >
                                                                            <span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>
                                                                                Cancelled
                                                                                  </span>
                                                                        </Button>
                                                                    }
                                                                </label>
                                                                <Grid item xs={12} md={12} lg={12}>
                                                                    <span>
                                                                        {workout.title}
                                                                    </span>
                                                                </Grid>
                                                                <Grid container style={{ paddingTop: 10 }}>
                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                        {workout.description}
                                                                    </Grid>
                                                                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 5 }}>
                                                                        {moment(workout.scheduledAt).format("llll")} for {workout.duration && workout.duration} min
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container style={{ paddingTop: 10 }}>
                                                                    <Grid item xs={12} md={12} lg={12} style={{ justifyContent: 'flex-start', display: 'flex' }}>
                                                                        {GeneralFunctions.capitalize(workout.level)}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </Grid>
                                            )
                                        })
                                        : <div>No live workouts found.</div>
                                }
                            </Grid>
                            {
                                this.state.page < this.state.pages &&
                                (<Grid container style={{ justifyContent: 'center' }}>
                                    <Button className="btn-purple btn-purple-highligted" style={{ width: '210px', marginTop: 10 }} variant="contained" color="primary" disableElevation onClick={() => this.refreshPagination()}>View more</Button>
                                </Grid>)
                            }
                        </List>
                        <AlertDialog message="I have read and agree to be bound by the Terms of Use and Privacy Policy for THE AB LAB dAPP, including but not limited to the following terms: (a) I am an adult; and (b) I waive and release any claims that I may be entitled to assert against THE AB LAB dAPP, Pingers, Pongers and/or Enthusiasts resulting from any use of content made available by THE AB LAB dAPP."
                            open={this.state.openAlertDialog} onClose={this.handelAlertDialogClose} />
                    </Card>
                    {
                        this.state.openFullModal &&
                        <Dialog
                            open={this.state.openFullModal}
                            onClose={() => {
                                this.toggleFullModal()
                            }}
                            fullScreen
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent style={{ padding: 0 }}>
                                <AppBar className="app-bar-color">
                                    <Toolbar>
                                        <Typography variant="h6" style={{ width: '100%' }}>
                                            {this.state.selectedWorkout ? this.state.selectedWorkout.title : "Unknown"}
                                        </Typography>
                                        <IconButton edge="end" color="inherit" onClick={() => {
                                            this.toggleFullModal()
                                        }}
                                            style={{ float: 'right' }}
                                            aria-label="close">
                                            <CloseIcon />
                                        </IconButton>

                                    </Toolbar>
                                </AppBar>
                                <div style={{ marginTop: 60 }}>
                                    <Grid container direction={'row'} style={{ justifyContent: 'center' }}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Grid container justify="center">
                                                <Grid item lg={8} md={8} sm={12} xs={12} style={{ marginTop: 20, justifyContent: 'center' }}>
                                                    <WowzaPlayer showCastToTV={true} streamName={this.state.selectedWorkout.owner.wowzaStreamKey} hlsKey={this.state.selectedWorkout.owner.wowzaHlsKey} applicationName={this.state.selectedWorkout.owner._id} />
                                                </Grid>
                                                <Grid item lg={4} md={4} sm={12} xs={12} style={{ justifyContent: "center", padding: 10, marginTop: 100 }}>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }}  >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Title :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label> {this.state.selectedWorkout.title}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }}  >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Owner :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <span style={{ justifyContent: 'center' }}>{GeneralFunctions.capitalize(this.state.selectedWorkout.owner.firstName)} {GeneralFunctions.capitalize(this.state.selectedWorkout.owner.lastName)}</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Date & Time :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label> {moment(this.state.selectedWorkout.scheduledAt).format("llll")}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Duration :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label>{this.state.selectedWorkout.duration && this.state.selectedWorkout.duration + " min"}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }}  >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Category :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label> {this.state.selectedWorkout.category}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Sub Category :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label> {this.state.selectedWorkout.subCategory}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Level :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label>{GeneralFunctions.capitalize(this.state.selectedWorkout.level)}</label>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container xs={12} xl={12} md={12} lg={12} style={{ padding: '10px' }} >
                                                        <Grid item xs={6} xl={4} md={4} lg={4}>
                                                            <label>Description :</label>
                                                        </Grid>
                                                        <Grid item xs={6} xl={8} md={8} lg={8}>
                                                            <label> {this.state.selectedWorkout.description}</label>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </DialogContent>
                        </Dialog>
                    }
                </Grid>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => this.setState({
                        open: false
                    })}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="white"
                                style={{ color: 'white' }}
                                onClick={() => this.setState({ open: false })}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }>
                    <SnackbarContent style={isSuccess ? {
                        backgroundColor: 'green',
                    } : { backgroundColor: 'red', }}
                        message={<span id="client-snackbar">{message}</span>}
                    />
                </Snackbar>
            </Grid >
        )
    }
}

export default connect()(WorkoutClassesPage);