import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import {
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookShareButton,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share';

function ShareDialog(props) {
    let { onClose, open, title } = props;
    let shareUrl = process.env.REACT_APP_CLIENT_URL + '/' + props.url;

    const handleClose = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className="share-container">
                <div className="share-button-container">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="share-button"
                    >
                        <FacebookIcon size={35} round />
                    </FacebookShareButton>
                </div>
                <div className="share-button-container">
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="share-button"
                    >
                        <TwitterIcon size={35} round />
                    </TwitterShareButton>
                </div>
                <div className="share-button-container">
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        className="share-button"
                    >
                        <TelegramIcon size={35} round />
                    </TelegramShareButton>
                </div>
                <div className="share-button-container">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="share-button"
                    >
                        <WhatsappIcon size={35} round />
                    </WhatsappShareButton>
                </div>
                <div className="share-button-container">
                    <LinkedinShareButton url={shareUrl} className="share-button">
                        <LinkedinIcon size={35} round />
                    </LinkedinShareButton>
                </div>
            </div>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShareDialog