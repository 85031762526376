import * as Server from "../Server";

const ContactRequestService = {
  getContactRequestsByUser: async (body) => {
    try {
      let response = await Server.request({
        url: "/contactRequest/search",
        method: "POST",
        data: body || {},
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
};

export default ContactRequestService;
