import * as Server from '../Server';

const CategoryService = {
  getAllCategories: async () => {
    try {
      let response = await Server.request({
        url: '/categories',
        method: 'GET'
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },
}

export default CategoryService;