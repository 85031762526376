import * as Server from '../Server';

const AuthService = {
  sendVerifyEmailOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/sendEmailVerification',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  verifyEmailOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/verifyEmailVerification',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  verifyLoginOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/verifyLoginVerification',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  resendEmailOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/resendEmailVerification',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  resendLoginOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/resendLoginVerification',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  signup: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/signup',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  signIn: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/signin',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  sendForgotPasswordOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/sendForgotPassword',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  resendForgotPasswordOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/resendForgotPassword',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  verifyForgotPasswordOTP: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/verifyForgotPassword',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  resetPassword: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/resetPassword',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  setNewPassword: async (obj) => {
    try {
      let response = await Server.request({
        url: '/auth/setNewPassword',
        method: 'POST',
        data: obj || {}
      });
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  },

  setAuthorizationHeader: (authorization) => {
    localStorage.setItem('authorization', authorization);
  },

  setVerifyLoginID: (id) => {
    localStorage.setItem('verifyLoginId', id);
  },

  getVerifyLoginID: () => {
    return localStorage.getItem("verifyLoginId");
  },

  setAuthUser: (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  },

  getAuthUser: () => {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default AuthService;