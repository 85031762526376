import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppTitleBar from "./AppTitleBar";
import MobileSideBar from "./MobileSideBar";
import SideBar from "./SideBar";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import routes from "../routes";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import TakeawayService from "../../services/TakeawayService";
import { Plugins } from "@capacitor/core";
import * as GeneralFunctions from '../../GeneralFunctions';

const { Device } = Plugins;

const styles = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
});

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.takeawaySyncTimer = null;
    this.userSyncTimer = null;
    this.state = {
      safeAreaCSS: {
        paddingBottom: "25px",
      },
    };
  }

  async componentDidMount() {
    const info = await Device.getInfo();
    if (info.platform == "ios") {
      this.setState({
        safeAreaCSS: {
          paddingBottom: "25px",
          marginTop: "constant(safe-area-inset-top)",
          marginTop: "env(safe-area-inset-top)",
        },
      });
    }
    this.syncTakeaway();
    this.syncUser();
  }

  componentWillUnmount() {
    clearInterval(this.takeawaySyncTimer);
    clearInterval(this.userSyncTimer);
  }

  async syncTakeaway() {
    this.takeawaySyncTimer = setInterval(async () => {
      let visitorId = GeneralFunctions.getCookie("theablab-visitorId");
      let user = AuthService.getAuthUser();
      if (visitorId && user) {
        try {
          let obj = {
            visitorId: visitorId
          }
          let res = await TakeawayService.sync(obj);
          AuthService.setAuthUser(res.user);
        } catch (error) { }
      }
    }, 7000 + Math.floor(Math.random() * 9000));
  }

  async syncUser() {
    this.userSyncTimer = setInterval(async () => {
      let user = AuthService.getAuthUser();
      if (user) {
        try {
          if (window.location.hash != "#/profile") {
            let res = await UserService.getAuthUser();
            AuthService.setAuthUser(res.user);
            let visitorId = GeneralFunctions.getCookie("theablab-visitorId");
            let takeawayIds = JSON.parse(GeneralFunctions.getCookie("theablab-takeawayIds"));
            if (res.user.visitorId && !visitorId) {
              GeneralFunctions.setCookie("theablab-visitorId", res.user.visitorId);
            } else if (res.user.visitorId && res.user.visitorId != visitorId) {
              GeneralFunctions.setCookie("theablab-visitorId", res.user.visitorId);
            }
            if (res.user.takeawayIds && (!takeawayIds || !takeawayIds.length)) {
              GeneralFunctions.setCookie("theablab-takeawayIds", JSON.stringify(res.user.takeawayIds));
            }
          }
        } catch (error) { }
      }
    }, 9000 + Math.floor(Math.random() * 12000));
  }

  render() {
    let user = AuthService.getAuthUser();
    return window.location.hash == "#/abLabLounge" ? (
      <Grid item xs={12} sm={12} md={12} lg={12} className="lounge-div-background">

        <Router>
          <Grid item xs={6} md={12} lg={12}>
            <AppTitleBar showWebApp={true} />
          </Grid>
          <Grid container direction={"row"} className="web"></Grid>
          <Switch>
            {routes.authRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
          </Switch>
          <Switch>
            {routes.menuRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </Router>
      </Grid>
    ) : (
        <Grid item xs={12} sm={12} md={11} lg={11}>
          <div
            className="cantainer-white"
            style={{
              width: "100%",
              padding: 0,
              height: "100vh",
            }}
          >
            <Container style={this.state.safeAreaCSS}>
              <Router>
                <Grid item xs={6} md={12} lg={12}>
                  <AppTitleBar />
                </Grid>
                <Grid
                  item
                  style={{
                    height: `${
                      this.props.toolState.toolbarconfig.show ? "57px" : "0px"
                      }`,
                  }}
                ></Grid>
                <Grid container direction={"row"} className="web">
                  {user && (
                    <Grid item xs={6} md={3} lg={3}>
                      <SideBar />
                    </Grid>
                  )}
                  <Grid item xs={12} md={user ? 9 : 12} lg={user ? 9 : 12}>
                    <Switch>
                      {routes.authRoutes.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                          />
                        );
                      })}
                    </Switch>
                    <Switch>
                      {routes.menuRoutes.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                          />
                        );
                      })}
                    </Switch>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="mobile">
                  {user && <MobileSideBar />}
                  <Switch>
                    {routes.authRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      );
                    })}
                  </Switch>
                  <Switch>
                    {routes.menuRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      );
                    })}
                  </Switch>
                </Grid>
              </Router>
            </Container>
          </div>
        </Grid>
      );
  }
}

const mapStateToProps = (state) => ({
  toolState: state.toolbarReducer,
});

export default connect(mapStateToProps)(withStyles(styles)(AppLayout));
