import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { green } from 'color-name';
function MessageDialog(props) {
  let { onClose, open } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title" style={{padding: 10, backgroundColor: '#3f51b5', color: 'white'}}>Success</DialogTitle>
      <p style={{ padding: 20 }}>{props.message}</p>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog