const intialState = {
  open: false,
  static: false,
  show: true,
  user: {},
};


// action 
export const getUser = () => {
  return  {
    type : 'GET_USER'
  }
}

const menuReducer = (state = intialState, action) => {
  switch (action.type) {
    case "TOGGLE_MENU":
      return Object.assign({}, state, {
        open: !state.open,
      });
    case "STATIC":
      return Object.assign({}, state, {
        static: !state.static,
      });
    case "SHOW":
      return Object.assign({}, state, {
        show: action.data,
      });
    case "ADD_USER":
      return Object.assign({}, state, {
        user: action.payload,
      });
    case "GET_USER":
      return Object.assign({}, state, {
        user: state.user,
      });
    default:
      return state;
  }
};

export default menuReducer;
