
const intialState = {
    selectedCategory: '',
    selectedSubCategory: '',
    selectedType: {
        id: 0,
        _id: 'quickpeeks',
        name: 'Quick Peeks'
    }
}

const catSubCatReducer = (state = intialState, action) => {
    switch (action.type) {
        case 'SET_CAT':
            state.selectedCategory = action.category;
            return state;
        case 'SET_SUB_CAT':
            state.selectedSubCategory = action.subCategory;
            return state;
        case 'SET_TYPE':
            state.selectedType = action.tab;
            return state;    
        default:
            return state;
    }
}

export default catSubCatReducer;