/*global google*/
import React, { useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import OverlayLoader from "./OverlayLoader";
import GoogleMap from "./GoogleMap";
import Marker from "./Marker";

const NearbyMap = ({ users = [], currentUser, onMarkerClick, radius }) => {
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lat: currentUser?.location?.coordinates[1],
    lng: currentUser?.location?.coordinates[0],
  });
  const [status, setStatus] = useState("LOADING");

  const onIdle = (m) => {
    setZoom(m?.getZoom());
    setCenter(m?.getCenter()?.toJSON());
  };

  return (
    <Wrapper
      callback={(status) => {
        setStatus(status);
      }}
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      {status === "LOADING" ? (
        <OverlayLoader />
      ) : (
        <GoogleMap
          center={center}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
          radius={radius}
        >
          {users.map((user) => (
            <Marker
              key={user._id}
              position={{
                lat: user?.location?.coordinates[1],
                lng: user?.location?.coordinates[0],
              }}
              title={user.firstName + " " + user.lastName}
              onMarkerClick={() => onMarkerClick(user)}
            />
          ))}
          <Marker
            position={{
              lat: currentUser?.location?.coordinates[1],
              lng: currentUser?.location?.coordinates[0],
            }}
            title={
              currentUser?.firstName + " " + currentUser?.lastName + " (You)"
            }
            label={{
              text: "You",
              color: "white",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          />
        </GoogleMap>
      )}
    </Wrapper>
  );
};

export default NearbyMap;
