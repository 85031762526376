import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
function ConfirmationDialog(props) {
  let { onClose, open } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <p style={{ padding: 20 }}>{props.message}</p>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>No</Button>
        <Button onClick={() => handleClose(true)} color="primary">Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog